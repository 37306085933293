<template>
  <h3 class="max-w-8xl mx-auto py-2 lg:max-w-7xl text-xl mb-6">パスワードをお忘れの方</h3>
    <div class="flex flex-wrap w-full mt-3 mb-8 p-5 border-gray-300 border-2 rounded-lg" >
        <div class="notice_right">
            <span class="f64d4d">*</span>
            会員IDを入力後、『認証コード要請』を選択すると、SMSに認証コードが届きます。<br><br>
            <span class="f64d4d">*</span>
            認証コードを入力後、『パスワード再設定』を選択すると、SMSに臨時パスワードが届きます。
        </div>
    </div>
    <div class="lg:flex lg:flex-wrap w-full my-3">
        <div class="lg:w-1/6"><span class="f64d4d">*</span>&nbsp;会員ID</div>
        <div class="lg:w-3/4 glow"><input type="text" class="rounded-md border border-gray-60"  :disabled="flag == 2 || flag == 3"  v-model="userid" style="height: 34.5px; border: 1px solid rgba(60, 60, 60, 0.2);" ></div>
    </div>
    <div class="lg:flex lg:flex-wrap w-full my-3">
        <div class="lg:w-1/6"><span class="f64d4d">*</span>&nbsp;携帯電話</div>
        <div class="glow lg:w-3/4" style="text-align:left;">
            <input type="text" v-model="mobile" :disabled="flag == 2 || flag == 3" onKeyUp="" maxlength="13" class="type_number rounded-md border border-gray-60" style="ime-mode:disabled; height: 34.5px; border: 1px solid rgba(60, 60, 60, 0.2);">
        </div>
    </div>
    <div class="lg:flex lg:flex-wrap w-full my-3" v-if="flag == 2">
      <div class="lg:w-1/6"><span class="f64d4d">*</span>&nbsp;{{ i18n.resetpw.reset.certinum }}</div>
        <div class="glow lg:w-3/4" style="text-align:left;">
            <input type="text" v-model="a_code" onKeyUp="" maxlength="12" class="type_number rounded-md border border-gray-60" style="ime-mode:disabled; height: 34.5px; border: 1px solid rgba(60, 60, 60, 0.2);">
        </div>
    </div>
    <div class="lg:flex lg:flex-wrap w-full my-3" v-if="flag == 3">
        <h2  v-if="flag == 3" style="text-align: center;font-weight: bold;">{{ i18n.myoffice.PasswordChange }}</h2>
    </div>

    <div class="lg:flex lg:flex-wrap w-full my-3" v-if="flag == 3">

      <div class="lg:w-1/6"><span class="f64d4d">*</span>&nbsp;{{ i18n.myoffice.Password }}</div>
        <div class="glow lg:w-3/4" style="text-align:left;">
            <input type="password" v-model="pw" id="pw" onKeyUp="" maxlength="12" class="type_number rounded-md border border-gray-60" style="ime-mode:disabled;height: 34.5px; border: 1px solid rgba(60, 60, 60, 0.2);">
        </div>
    </div>
    <div class="lg:flex lg:flex-wrap w-full my-3" v-if="flag == 3">
      <div class="lg:w-1/6"><span class="f64d4d">*</span>&nbsp;{{ i18n.myoffice.Passwordcheck }}</div>
        <div class="glow lg:w-3/4" style="text-align:left;">
            <input type="password" v-model="pwcheck" id="pwcheck" onKeyUp="" maxlength="12" class="type_number rounded-md border border-gray-60" style="ime-mode:disabled;height: 34.5px; border: 1px solid rgba(60, 60, 60, 0.2);">
        </div>
    </div>
    <div v-if="flag == 1" class="mt-8"><span class="btn"><input type="button" value='認証番号要請' v-on:click="sendAproval" class="block bg-orange-300 hover:bg-orange-400 text-center text-white font-bold font-heading py-3 px-8 rounded-md uppercase transition duration-200 cursor-pointer"></span></div>
    <div v-if="flag == 2" class="mt-8"><span class="btn"><input type="button" value='認証番号確認' v-on:click="checkAprovalCode" class="block bg-orange-300 hover:bg-orange-400 text-center text-white font-bold font-heading py-3 px-8 rounded-md uppercase transition duration-200 cursor-pointer"></span></div>
    <div v-if="flag == 3" class="mt-8"><span class="btn"><input type="button" :value='i18n.myoffice.Check' v-on:click="setPasswdChange" class="block bg-orange-300 hover:bg-orange-400 text-center text-white font-bold font-heading py-3 px-8 rounded-md uppercase transition duration-200 cursor-pointer"></span></div>

</template>

<script>
import vSelect from "vue-select";
import alert from "vue3-simple-alert";

export default {
    name: "MyFindId",
    components: {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        year : [],
        day : [],
        userid : "",
        mobile : "",
        a_code : "",
        flag : 1,
        pw : "",
        pwcheck : "",
        alertlist : [{
            "-1" : i18n.resetpw.reset.alert3,
            "-2" : i18n.resetpw.reset.alert2,
            "-3" : "Mobile number does not match",
            "-4" : i18n.resetpw.reset.alert8,
            "-5" : i18n.resetpw.reset.alert9,
            "-6" : i18n.resetpw.reset.alert10,
            "-9" : i18n.resetpw.reset.alert4,
            "etc" : i18n.resetpw.reset.alert12}]
    }),
    created : function (){
        for (var i = current_year; i >= lastyear; i--){
            this.year.push(i);
        }
        for (var i = 1; i <= 31; i++){
            var day = i;
            if(day < 10){
                day = "0"+day;
            }
            this.day.push(day);
        }

    },
    methods:{
        sendAproval(){
            if(this.userid == "") {
                alert.alert("会員IDを入力してください","","",{allowOutsideClick : false}).then(r => {});
                return false;
            }
            //비밀번호 확인을 입력하세요.
            if(this.mobile == "") {
                alert.alert("携帯番号を入力してください","","",{allowOutsideClick : false}).then(r => {});
                return false;
            }

            axios.post(window.Laravel.back_url+'/api/sendAproval', {
                userid : this.userid,
                mobile : this.mobile,
                bz_cd : window.Laravel.bz_cd
            }).then(response => {
                if(response.data != 1){
                    alert.alert(this.alertlist[0][response.data],"","",{allowOutsideClick : false}).then(r => {
                    });
                }else{
                    alert.alert(i18n.resetpw.reset.alert1,"","",{allowOutsideClick : false}).then(r => {
                        this.flag = 2;
                    });
                }
            });

        },
        checkAprovalCode(){
            axios.post(window.Laravel.back_url+'/api/checkAprovalCode', {
                userid : this.userid,
                mobile : this.mobile,
                a_code : this.a_code,
                bz_cd : window.Laravel.bz_cd
            }).then(response => {
                if(response.data != 1){
                    alert.alert(this.alertlist[0][response.data],"","",{allowOutsideClick : false}).then(r => {
                    });
                }else{
                    alert.alert("承認しました。","","",{allowOutsideClick : false}).then(r => {
                        this.flag = 3;
                    });
                }

            });

        },
        setPasswdChange(){
            if(this.pw != this.pwcheck){
                alert.alert(i18n.myoffice.myofficeAlert.PasswordCheckAlert,"","",{allowOutsideClick : false}).then(r => {});
                return false;
            }

            if(this.pw.length < 8){
                alert.alert(i18n.myoffice.myofficeAlert.Password8lettersAlert,"","",{allowOutsideClick : false}).then(r => {});
                $("#pw").focus();
                return;
            }

            if(this.pwcheck.length < 8){
                alert.alert(i18n.myoffice.myofficeAlert.PasswordatleastAlert,"","",{allowOutsideClick : false}).then(r => {});
                $("#pwcheck").focus();
                return;
            }


            if(this.pw == "") {
                alert.alert("パスワードを入力して下さい。","","",{allowOutsideClick : false}).then(r => {});
                return false;
            }
            //비밀번호 확인을 입력하세요.
            if(this.pwcheck == "") {
                alert.alert("パスワードを入力して下さい。","","",{allowOutsideClick : false}).then(r => {});
                return false;
            }

            axios.post(window.Laravel.back_url+'/api/setPasswdChange', {
                userid : this.userid,
                pw : this.pw,
                bz_cd : window.Laravel.bz_cd
            }).then(response => {
                if(response.data != 1){
                    alert.alert(this.alertlist[0][response.data],"","",{allowOutsideClick : false}).then(r => {});
                }else{
                    alert.alert(i18n.resetpw.reset.alert14,"","",{allowOutsideClick : false}).then(r => {
                        location.replace("/sso/login");
                    });
                }

            });

        },
    }

}
</script>

<style scoped>

</style>
