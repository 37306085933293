<template>
    <div class="tit">
        {{ row.title }}
        <span class="view-date">{{ row.reg_date }}</span>
    </div>
    <!-- 첨부파일 -->
    <div class="">
        <ul id="myo_form">
            <li class="item">
                <span class="item_title">{{ i18n.inquriy.inquriy.questioner }}</span>
                <span class="item_content">{{ row.reg_date }}, IP Address : {{ row.ip }}</span>
            </li>
            <li class="item">
                <span class="item_title">{{ i18n.inquriy.inquriy.subject }}</span>
                <span class="item_content view_title">{{ row.title }}</span>
            </li>
            <li class="item">
                <span class="item_title">{{ i18n.inquriy.inquriy.Member }}</span>
                <span class="item_content">{{ row.username }}({{ row.userid }}) , {{ row.tel }}</span>
            </li>
            <li class="item">
                <span class="item_title">{{ i18n.inquriy.inquriy.StatusReply }}</span>
                <span class="item_content">{{ row.flags }}</span>
            </li>
            <li class="item item_img">
                <span class="item_title">{{ i18n.inquriy.inquriy.Question }}</span>
                <span class="item_content">
                    <div class="view_contents" >{{ row.ord_no }}
                        <div v-html="row.content"></div>
                        <div class="add_f view-add-file" v-show="a_list">
                            <h4>{{ i18n.inquriy.inquriy.attachment }}</h4>
                            <ul>
                                <li v-for="(Value, index) in a_list">
                                    <a class="attch_link" :href="Value.file_link" target="_blank">{{ index+1 }}. {{ Value.org_name }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </span>
            </li>
        </ul>

        <div class="history-list-wrap">
            <h4>{{ i18n.inquriy.inquriy.Answer }}</h4>
            <ul class="history_table">
                <li class='list' v-for="(Value,index) in reply_list">
                    <h5> {{ Value.reg_date }}</h5>
                    <span v-html="Value.content"></span>
                    <div class="add_f view-add-file" v-show="Value.attach">
                        <ul>
                            <li v-for="(Value2, index2) in Value.attach">
                                <a class="attch_link" :href="Value2.file_link" target="_blank">{{ index2+1 }}. {{ Value2.org_name }}</a>
                            </li>

                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="myo_condition_search2" style="margin-top:10px;">
        <button type="button" class="go_list_btn" @click="listUrl">{{ i18n.inquriy.inquriy.list }}</button>
    </div>
</template>

<script>
export default {
    name: "MyInQuriyView",
    props: ['no', 'mid_mypage'],
    data: () => ({
        i18n: window.i18n,
        total_count: 0,
        row : [],
        a_list : [],
        reply_list : [],

    }),
    created : function (){
        axios.post(window.Laravel.back_url+'/api/InQuiryView',{
            no : this.no,
            mid : this.mid_mypage ? this.mid_mypage : this.mid,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.row = response.data.row;
            this.a_list = response.data.a_list;
            this.reply_list = response.data.reply_list;
            this.loading = false;
        });

    },
    methods : {
        listUrl() {
          location.href = this.mid_mypage ? '/Mypage/Inquiry' : '/MyInQuriy';
        }
    }
}
</script>

<style scoped>
</style>
