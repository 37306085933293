<template>
  <h3 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">会員IDがわからない方</h3>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>お名前</div>
    <div class="w-full lg:w-3/4 glow"><input type="text" class="type_pwd h-10 w-full xl:w-60 lg:w-60" v-model="name"></div>
  </div>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>生年月日</div>
    <div class="w-full lg:w-5/6 flex gap-2 relative z-30" style="text-align:left;">
    <vSelect :options="year" label="value" :clearable="false" class="xl:w-40 lg:w-40 w-full text-sm md:text-base h-[38px] border border-[#6b7280]"  v-model="strtYear"></vSelect>
      <vSelect :options="month" label="value" :clearable="false" :reduce="value => value.title"  class="xl:w-40 lg:w-40 w-full text-sm md:text-base h-[38px] border border-[#6b7280]"  v-model="strtMonth"></vSelect>
      <vSelect :options="day" label="value" :clearable="false" class="xl:w-40 lg:w-40 w-full text-sm md:text-base h-[38px] border border-[#6b7280]"  v-model="strtDay"></vSelect>
    </div>
  </div>
  <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
    <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>携帯番号</div>
    <div class="glow w-full lg:w-3/4" style="text-align:left;">
      <input type="text" v-model="mobile" style="ime-mode:disabled;" onKeyUp="" maxlength="13" class="type_number w-full xl:w-60 lg:w-60">
    </div>
  </div>
    <div class="w-full flex flex-col lg:flex-row justify-start lg:py-2 lg:items-center mb-4">
        <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>メールアドレス</div>
        <div class="glow w-full lg:w-3/4" style="text-align:left;">
            <input type="text" v-model="email1" style="ime-mode:disabled;" onKeyUp="" maxlength="12" class="type_number w-40 xl:w-40 lg:w-40"> @
            <input type="text" v-model="email2" style="ime-mode:disabled;" onKeyUp="" maxlength="12" class="type_number w-40 xl:w-40 lg:w-40">

        </div>
    </div>

    <div id="submit_area" class="mt-8"><span class="btn"><input type="button" id="send_btn" value='確認' v-on:click="send_data" class="my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 xl:w-16 lg:w-16 w-1/2 search_btn rounded-md"></span></div>
</template>

<style>
.vs__dropdown_menu{background:#fff;}
</style>
<script>
import vSelect from "vue-select";
import alert from "vue3-simple-alert";

export default {
  name: "MyFindId",
  components: {
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    year : [],
    day : [],
    month : [{title: "01", value  : "01"},
      {title: "02", value  : "02"},
      {title: "03", value  : "03"},
      {title: "04", value  : "04"},
      {title: "05", value  : "05"},
      {title: "06", value  : "06"},
      {title: "07", value  : "07"},
      {title: "08", value  : "08"},
      {title: "09", value  : "09"},
      {title: "10", value  : "10"},
      {title: "11", value  : "11"},
      {title: "12", value  : "12"},],
    name : "",
    pin_type_list : [{title: "N", value  : "NRIC"},{title : "P" , value : "PASSPORT"}],
    pin_type : "N",
    jumin1 : "",
    jumin2 : "",
    jumin3 : "",
    forign_jumin : "",
    mobile : "",
    strtYear : "",
    strtDay : "",
    strtMonth : "",
      nt_cd : "",
      email1 : "",
      email2 : "",
  }),
  created : function (){
    for (var i = current_year; i >= lastyear; i--){
      this.year.push(i);
    }
    for (var i = 1; i <= 31; i++){
      var day = i;
      if(day < 10){
        day = "0"+day;
      }
      this.day.push(day);
    }

  },
  watch : {
    mobile : function () {
      this.mobile = this.mobile.replace(/[\D]/gi, '');
    }
  },
  methods:{
    send_data(){
      if(this.name == "") {
        alert.alert("お名前を入力してください","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      if(this.strtYear == "" || this.strtMonth == "" || this.strtDay == "") {
        alert.alert("生年月日を入力してください","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

      if(this.mobile == "") {
        alert.alert("携帯番号を入力してください","","",{allowOutsideClick : false}).then(r => {});
        return false;
      }

        axios.post(window.Laravel.back_url+'/api/getSearchId', {
        username : this.name.trim(),
        birthday : this.strtYear+this.strtMonth+this.strtDay,
        mobile : this.mobile,
        bz_cd : window.Laravel.bz_cd,
        e_mail : this.email1.trim()+"@"+this.email2.trim()

        }).then(response => {
        if(response.data != 0){
          alert.alert("あなたの会員IDは "+response.data+" です。","","",{allowOutsideClick : false}).then(r => {
            location.replace("/sso/login");
          });
        }else{
          alert.alert("会員情報を探せません。","","",{allowOutsideClick : false}).then(r => {
          });
        }

      });

    },

  }

}
</script>

<style scoped>
.vs__dropdown-toggle{border:0px solid #fff !important;}
</style>
