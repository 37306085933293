<template>
    <dl id="admin_page">

        <article class="myo_search_area">
            <form name='form_search' style="clear:both;"  v-on:submit.prevent="submitForm('1')">
                <section class="myo_condition_box">
                    <dl class="myo_search_list">
                        <dt>{{ i18n.search.find }}</dt><!-- 검색 -->
                        <dd class="">
                            <section class="flex_box">
                                <div class="myo_select_box">
                                    <vSelect :options="list_temp_str" label="value" :reduce="value => value.title"   :clearable="false" v-model="category"  ></vSelect>
                                </div>

                                <div class="myo_search_box">
                                    <input type="text" class="myo_search_input" name="search_text" v-model="search_text">
                                </div>
                            </section>
                        </dd>
                    </dl>
                </section><!-- // myo_condition_box -->

                <div class="myo_condition_search2">
                    <button type="reset" onclick="location.replace('/MyInQuriy')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                    <button type="submit" class="search_btn btn-eft-none">{{ i18n.myoffice.Search }}</button>
                </div>
            </form>
        </article>

        <div class="w-full p-2 position_relate_box">
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>365日24時間受付可能<br>
                      -但し平日18時以降、土日・祝日に受付したものは、翌営業日の9時以降に順次回答致します。
                    </p>
                    <p>お問い合わせの前に【よくあるご質問】をご参照いただきますよう宜しくお願い申し上げます。
                      よくあるご質問ページは<a onclick="javascript:location.href='/MyFAQ'" style="color:blue;font-weight: bold; border-bottom:1px solid #000; cursor: pointer;">こちら</a>
                    </p>
                </div>
            </div>
        </div>

        <div class="list-table-wrap">
            <table class="list-table notice_list_box">
                <thead>
                <tr class="bg-[#d1d5de] text-[#591734]">
                    <th data-priority="1" class="text-[#591734]">{{ i18n.inquriy.inquriy.no }}</th>
                    <th data-priority="2">{{ i18n.inquriy.inquriy.status }}</th>
                    <th data-priority="3">{{ i18n.inquriy.inquriy.category }}</th>
                    <th data-priority="4">{{ i18n.inquriy.inquriy.title }}</th>
                    <th data-priority="5">{{ i18n.inquriy.inquriy.questionDate }}</th>
                    <th data-priority="6">{{ i18n.inquriy.inquriy.questioner }}</th>
                    <th data-priority="7">{{ i18n.inquriy.inquriy.answerDate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in List" class="list_hover" v-bind:style="Value.notice_yn == 'Y' ? 'background:#f7f7f7' : ''">
                    <th class="px-6 py-3 whitespace-nowrap">{{ total_count - ((page-1)*page_size + index) }}</th><!-- 무실적경과일 -->
                    <td align="center">{{ Value.flags }}</td>
                    <td align="center">{{ Value.cate_name }}</td>
                    <td align="left"><span style='cursor:pointer; '><a :href="Value.href" >{{Value.title}}</a><em v-show="Value.reply_cnt" class="inquriy badge">{{ Value.reply_cnt }}</em></span></td>
                    <td style="overflow: hidden;">{{  Value.reg_date  }}</td>
                    <td align="center">{{  Value.reply_username  }}</td>
                    <td align="center">{{  Value.reply_regdate  }}</td>

                </tr>
                <tr class="h-2" v-if="total_count == 0">
                    <td class="text-left md:text-center pl-5 py-5" colspan="13">データがありません。</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="myo_condition_search2" style="margin-top:20px;">
            <button type="button" class="srh faq_write_btn" @click="goWrite">
            <span class="btn-eft-none">
                {{ i18n.inquriy.inquriy.writing }}
            </span>
            </button>
        </div>

        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
            <v-pagination
                v-model="page"
                :pages=total_page
                :range-size="1"
                active-color="#DCEDFF">
            </v-pagination>
        </div>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyInQuriy",
    components : {
        VPagination,
        Loading,
        vSelect,
    },
    props:['mid_mypage'],
    data: () => ({
        i18n: window.i18n,
        List : [],
        total_count : 0,
        page : 1,
        total_page : 1,
        page_size : 20,
        mid : window.location.pathname,
        search_text : "",
        list_temp_str : [],
        category : ""
    }),
    watch : {
        page : function(){
            this.submitForm();
        }
    },
    created : function (){
        this.submitForm();

    },
    methods : {
        submitForm : function (){
            axios.post(window.Laravel.back_url+'/api/InQuiry',{
                page        : this.page,
                page_size   : this.page_size,
                mid         : this.mid_mypage ? this.mid_mypage : this.mid,
                search_text : this.search_text,
                category    : this.category
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.List          = response.data.list.data;
                this.list_temp_str = response.data.list_temp_str;
                this.total_count   = response.data.total_count;
                this.total_page    = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
                this.loading       = false;

            });

        },
        goWrite(){
            if(this.mid_mypage){
              location.href='/Mypage/InQuiryModify/0';
            }else{
              location.href='/MyInQuiryModify/0';
            }

        }
    }

}
</script>

<style scoped>

</style>
