<template>
    <loading :active="loading"
             :can-cancel="true"
             :is-full-page="true"></loading>

<!--    <div class="member-search">-->
<!--        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">-->
<!--            <section class="myo_condition_box relative">-->
<!--                <dl class="myo_search_list">-->
<!--                    <dt>{{ i18n.myoffice.Commission.DateofBalance }}</dt> &lt;!&ndash; 마감일자 &ndash;&gt;-->
<!--                    <dd class="">-->
<!--                      <div class="flex xl:flex-row lg:flex-row flex-col gap-2">-->
<!--                          <div class="z-[11] relative xl:w-32 lg:w-32 w-full"><vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year" class="xl:w-32 lg:w-32 w-full"></vSelect></div>-->
<!--                        <div class="z-[11] relative xl:w-32 lg:w-32 w-full"><vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month" class="xl:w-32 lg:w-32 w-full"></vSelect></div>-->
<!--                      </div>-->
<!--                    </dd>-->
<!--                </dl>-->
<!--            </section>&lt;!&ndash; // myo_condition_box &ndash;&gt;-->

<!--            <div class="myo_condition_search2">-->
<!--                <button type="reset" class="reset_btn" onclick="location.href='/MyBenefitDetailHistory';">{{ i18n.myoffice.Refresh }}</button>-->
<!--                <button type="submit" class="search_btn" v-on:click="submit">{{ i18n.myoffice.Search }}</button>-->
<!--            </div>-->
<!--        </form>-->
<!--    </div>-->

    <div v-show="true">
        <search_date v-if="isEmptyObject(dash_d_val_ori) === false" :getYear="currentYear" :getMonth="currentMonth" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard"></search_date>
        <search_date v-else :getYear="currentYear" :getMonth="currentMonth" :dash_d_val="dash_d_val_ori" @searchMonthDashBoard="searchMonthDashBoard"></search_date>
    </div>

    <div id="week" style="margin-top:30px;" >
        <b>
        </b>
    </div>

    <div>
      <h2 class="text-xl font-semibold mb-3">合計支給ボーナス</h2>
      <div class="flex flex-row w-full mb-6">
        <div class="w-full border bg-white rounded-xl p-5">
          <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
            <table class="border-separate border-spacing-2 table-auto w-full">
              <thead>
              <tr class="bg-[#172e59]">
                <th class="p-2 text-white break-keep 2xl:w-30">{{ i18n.myoffice.Commission.SettlementDate}}</th>
                <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.AttainmentRank}}</th>
                <th class="p-2 text-white break-keep" v-for="Value in name_list">{{ Value.pay_name }}</th>
<!--                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.SalesBonus}}</th>-->
<!--                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.ManagementBonus}}</th>-->
<!--                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.SharedRankBonus}}</th>-->
<!--                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.RankBonus}}</th>-->
                <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.TotalAmount}}</th>
              </tr>
              </thead>
              <tbody>
              <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                <td class="text-center p-2 min-w-[100px]">{{ List.paydate }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ List.ranknewname }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ List.rankmaxname }}</td>

                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt5 ?? 0, 2) }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt1 ?? 0,2) }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt2 ?? 0,2) }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt3 ?? 0,2) }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt4 ?? 0, 2) }}</td>
                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt6 ?? 0, 2) }}</td>

<!--                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt1 ?? 0,2) }}</td>-->
<!--                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt2 ?? 0,2) }}</td>-->
<!--                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt4 ?? 0,2) }}</td>-->
<!--                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.amt3 ?? 0,2) }}</td>-->
                <td class="text-center p-2 min-w-[100px]">{{ formatPrice(List.totalamt ?? 0,0) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
            <a href="" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
              View More
              <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="text-red-500" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <h2 v-show="false" class="text-xl text-[] font-semibold mb-3">ビジネス現況</h2> <!--비즈니스 현황-->
    <!-- 그래프 -->
    <div v-show="false" class="flex flex-row w-full mb-6">
        <div class="w-full border bg-white rounded-xl p-5">
            <div class="overflow-x-scroll w-full block full:scrollbar-hide full:overflow-hidden">
                <table class="border-separate border-spacing-2 table-auto w-full">
                    <thead>
                    <tr>
                        <th colspan="7" class="py-2 bg-[#2e5db3] text-white">実績状態</th><!--본인 실적 현황-->
                        <th colspan="6" class="py-2 bg-[#2e5db3] text-white">1~3代下位会員実績</th><!--직추천 1대 실적 현황-->
                    </tr>
                    <tr class="bg-[#172e59]">
                        <th class="p-2"></th>
                        <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.MyBusiness.amount}}</th>
                        <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>

                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amount}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>
                    </tr>
                    </thead>
                  <tbody>
                    <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                      <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</th>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_A1 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_R1 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_A1 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_R1 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT1 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV1 ?? 0,1) }}</td>

                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_A1 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_R1 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A1 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R1 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT1 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV1 ?? 0,1) }}</td>
                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                      <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</th>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT12 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT22 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV12 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV22 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT2 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV2 ?? 0,1) }}</td>

                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT12 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT22 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV12 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV22 ?? 0,1) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT2 ?? 0,2) }}</td>
                      <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV2 ?? 0,1) }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
                <a href="" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
                    View More
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="text-red-500" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </a>
            </div>
        </div>
    </div><!-- //나와 -->

    <div v-show="false" class="flex-col w-full gap-5 mb-6 bg-white border rounded-xl p-5">
        <div class="w-full overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                    <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">傘下販売情報</th> <!--산하 매출정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.monthly}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.amount}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">合計PV</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">レグ別合計</th> <!--총 레그 수-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button type="button" @click="getLegList(0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT1 ?? 0 }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV2 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]"><button type="button" @click="getLegList(-1)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT2 ?? 0 }}</button></td>
                </tr>
                </tbody>
            </table>
        </div>
        <p v-show="false" class="text-[#e1253f] p-2 text-sm">*レグ別合計をクリックすると1代下位会員の現況を確認することができます</p>
        <!--* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능-->


        <!-- 모달 팝업 -->
        <div class="fixed w-full h-full bg-black/80 z-[11] top-0 left-0 cursor-pointer" @click="showModal = false" v-if="showModal"></div>
        <!-- 모달 팝업 콘텐츠 -->
        <div class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white p-5 w-full max-w-xl h-1/2 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll sm:overflow-hidden" v-if="showModal">
            <div class="text-right mb-2">
                <button class="close" @click="showModal = false">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block cursor-pointer fill-[#091223]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                </button>
            </div>
            <div class="h-72">
                <h4 class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">1代下位会員の現況</h4> <!-- 산하 1대 레그 별 회원 현황 -->
                <div class="text-center mb-3 h-80 overflow-scroll tailwind-scrollbar-hide scrollbar-hide">
                    <table class="w-full border-separate sm:overflow-hidden">
                        <thead>
                        <tr>
                            <th class="bg-[#172e59] text-center text-white p-3 w-24">会員ID</th>
                            <th class="bg-[#172e59] text-center text-white p-3">会員名</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="leg in legs" class="bg-[#f0f4ff] text-center">
                            <td class="text-center p-2">{{ leg.userid }}</td>
                            <td class="text-center p-2"><a @click="goUrl(leg.sid)" class="text-base block p-1 text-[#1c57ab] hover:text-[] cursor-pointer">{{ leg.username }}</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p class="text-[#e1253f] text-[12px] break-words mb-2">*会員名をクリックすると、当該会員基準のレグ別売上集計を確認することができます。</p>
                <!--  *회원명을 클릭 하시면 해당 회원 기준의 레그 별 매출 집계를 확인하실 수 있습니다.-->
            </div>
        </div>
        <!-- // 핍업 -->
    </div>



    <div v-if="list1_total_count">
      <h2 class="text-xl font-semibold mb-3">販売ボーナス<!-- Sales Bonus --><span class="text-base font-normal">(単位 : 注文PV)<!-- (Unit : Order PV) --></span></h2>
      <div class="flex flex-row w-full mb-6">
          <div class="w-full border bg-white rounded-xl p-5">
              <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                  <table class="border-separate border-spacing-2 table-auto w-full">
                      <thead>
                      <tr class="bg-[#172e59]">
                          <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                          <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Bonusfinance}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Step}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list1">
                          <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.userid}}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,1) }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,0) }}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <v-pagination
                v-model="list1_page" v-show="list1_total_page > 1"
                :pages=list1_total_page
                :range-size="1"
                active-color="#DCEDFF"
                class="my-pagination"
              >
              </v-pagination>
          </div>
      </div>
    </div>
    <div v-if="list2_total_count">
      <h2 class="text-xl font-semibold mb-3">管理ボーナス<!-- Management Bonus --><span class="text-base font-normal">(単位 : 注文PV)<!-- (Unit : Sales bonus) --></span></h2>
      <div class="flex flex-row w-full mb-6">
          <div class="w-full border bg-white rounded-xl p-5">
              <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                  <table class="border-separate border-spacing-2 table-auto w-full">
                      <thead>
                      <tr class="bg-[#172e59]">
                          <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                          <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Bonusfinance}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Step}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedPv }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list2">
                          <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,2) }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,2) }}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <v-pagination
                v-model="list2_page" v-show="list2_total_page > 1"
                :pages=list2_total_page
                :range-size="1"
                active-color="#DCEDFF"
                class="my-pagination"
              >
              </v-pagination>
          </div>
      </div>
    </div>
    <div v-if="list4_total_count">
      <h2 class="text-xl font-semibold mb-3">職級共有ボーナス <span class="text-base font-normal">(単位 : 注文PV)</span></h2>
      <div class="flex flex-row w-full mb-6">
          <div class="w-full border bg-white rounded-xl p-5">
              <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                  <table class="border-separate border-spacing-2 table-auto w-full">
                      <thead>
                      <tr class="bg-[#172e59]">
                          <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                          <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Bonusfinance}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Step}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list4">
                          <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,2) }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,0) }}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <v-pagination
                v-model="list4_page" v-show="list4_total_page > 1"
                :pages=list4_total_page
                :table_kind="4"
                :range-size="1"
                active-color="#DCEDFF"
                class="my-pagination"
              >
              </v-pagination>
          </div>
      </div>
    </div>

    <div v-if="list3_total_count">

      <h2 class="text-xl font-semibold mb-3">職級ボーナス	 <span class="text-base font-normal">(単位 : 注文BV)</span></h2>
      <div class="flex flex-row w-full mb-6">
          <div class="w-full border bg-white rounded-xl p-5">
              <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                  <table class="border-separate border-spacing-2 table-auto w-full">
                      <thead>
                      <tr class="bg-[#172e59]">
                          <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.Commission.No}}</th>
                          <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.Commission.MemberID}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Name}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.CurrentRank}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Bonusfinance}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.PaymentRate}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.Step}}</th>
                          <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.GeneratedAmount}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in list3">
                          <td class="text-center p-2 min-w-[100px]">{{ Value.rn }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.userid }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.username }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rank_name }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_basic ?? 0,1) }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_rate }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ Value.tg_lv }}</td>
                          <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.tg_amt ?? 0,0) }}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <v-pagination
                v-model="list3_page" v-show="list3_total_page > 1"
                :pages=list3_total_page
                :range-size="1"
                active-color="#DCEDFF"
                class="my-pagination"
              >
              </v-pagination>
          </div>
      </div>
    </div>
</template>
<script>
import vSelect from "vue-select";
import Popper from "vue3-popper";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../../modalView.vue";

export default {
    name: "MyBenefitDetailHistory",
    components : {
        vSelect,
        Popper,
        VPagination,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 1,
        checkCondition : false,
        name_list : [],
        name_date : [],
        table_kind : "",
        List : [],
        s_year : "",
        s_month : "",
        s_day : "",
        dashData : "",
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        years : [],
        days : [],

        cs_omni_list : {ord_amt : 0,cs_amt : 0, o_rate : 0,ord_mon : ""},
        mem_stamp_h_user : {pv_now: 0 , pv_ready:0},
        dash_info : [],
        /** S : getDashBoard*/
        dash_d_val : [],
        dash_d_code : [],
        dash_d_val_ori : [],

        //My Rank Information
        bm1 : 0,
        bm2 : 0,
        bm3 : 0,
        bm_total : 0,
        g1 : 0,
        g2 : 0,
        g3 : 0,
        g_total : 0,
        d1 : 0,
        d2 : 0,
        d3 : 0,
        d_total : 0,
        vp1 : 0,
        vp2 : 0,
        vp3 : 0,
        vp_total : 0,
        p1 : 0,
        p2 : 0,
        p3 : 0,
        p_total : 0,
        rank_need : "",
        rank_need1 : 0,
        rank_need2 : 0,

        legs : [],
        list1 : [],
        list2 : [],
        list3 : [],
        list4 : [],
        list5 : [],
        name_list1 : [],
        name_list2 : [],
        name_list3 : [],
        name_list4 : [],
        name_list5 : [],
        list1_total_count: 0,
        list1_page : 1,
        list1_total_page : 1,
        list2_total_count: 0,
        list2_page : 1,
        list2_total_page : 1,
        list3_total_count: 0,
        list3_page : 1,
        list3_total_page : 1,
        list4_total_count: 0,
        list4_page : 1,
        list4_total_page : 1,
        list5_total_count: 0,
        list5_page : 1,
        list5_total_page : 1,
        cnt_top_chk : 0,
        currentYear : '',
        currentMonth : '',
      name_data : [],

        /** E : getDashBoard*/

        loading : true,
        showModal: false,

    }),
    watch : {
        s_year : function(){
            this.get_days();
        },
        s_month : function(){
            this.get_days();
        },
        list3_page : function(val){
          this.get_list("list3", 3, val);
        }
    },
    created : function (){

      //   var year = new Date().getFullYear();
      //   for(year ; year >= 2023 ; year--){
      //       this.years.push(
      //           {value: year}
      //       );
      //   }
      //   if(this.s_year == ""){
      //     let currDate = new Date();
      //     this.s_year = String(currDate.getFullYear());
      //   }
      //
      // if(this.s_month == ""){
      //   let currDate = new Date();
      //
      //   this.s_month = currDate.getMonth() + 1;
      //   if(this.s_month < 10){
      //     this.s_month = String(0) + String(this.s_month);
      //   }
      // }
      //
      //   // this.submit();
      //   this.loading = true;

        const currentDate = new Date();
        this.currentYear = currentDate.getFullYear();
        this.currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    },
    methods : {
        get_list(tit, tit_num, page){
          this.loading = true;
          axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunusList',{
              s_year : this.currentYear,
              s_month : this.currentMonth,
              tit: tit, number: tit_num, page: page,
            page_size : 100,
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
            if(tit == "list1"){
              this.list1 = response.data.list.data;
              this.name_list1 = response.data.name_list;
              this.list1_total_count = response.data.list_total;
              this.list1_total_page = parseInt(this.list1_total_count/100)+parseInt(this.list1_total_count%100 > 0 ? 1 : 0);
            }
            if(tit == "list2"){
              this.list2 = response.data.list.data;
              this.name_list2 = response.data.name_list;
              this.list2_total_count = response.data.list_total;
              this.list2_total_page = parseInt(this.list2_total_count/100)+parseInt(this.list2_total_count%100 > 0 ? 1 : 0);
            }
            if(tit == "list3"){
              this.list3 = response.data.list.data;
              this.name_list3 = response.data.name_list;
              this.list3_total_count = response.data.list_total;
              this.list3_total_page = parseInt(this.list3_total_count/100)+parseInt(this.list3_total_count%100 > 0 ? 1 : 0);
            }
            if(tit == "list4"){
              this.list4 = response.data.list.data;
              this.name_list4 = response.data.name_list;
              this.list4_total_count = response.data.list_total;
              this.list4_total_page = parseInt(this.list4_total_count/100)+parseInt(this.list4_total_count%100 > 0 ? 1 : 0);
            }
          });
          this.loading = false;
        },
        get_days(){
            /*axios.post(window.Laravel.back_url+'/api/getWeekDay',{
                month : this.s_year+""+this.s_month
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
              console.log(response.data);
                this.s_day = response.data.data[0].value;
                this.days = response.data.data;
            });*/
        },
        tooltip(){
            var $this = this;
            var $href = $this.$attrs.href, $top = $this.$attrs.top + 22, $left = $this.$attrs.left ;

            var config = {
                tooltipstyle : {
                    position : 'absolute',
                    top : $top,
                    left : $left,
                    zIndex: 50
                }};

            $($href).css(config.tooltipstyle);
            (this.$type == 'mouseenter') ? $($href).show() : $($href).hide();
            return false;

        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        },
        /**
         * deep copy
         * @param obj
         * @param hash
         * @returns {*[]|{}|RegExp|*|Date|null}
         */
        deepCopy(obj, hash = new WeakMap()) {
            if (obj === null) return null;
            if (typeof obj !== 'object') return obj;
            if (obj instanceof Date) return new Date(obj);
            if (obj instanceof RegExp) return new RegExp(obj.source, obj.flags);
            if (hash.has(obj)) return hash.get(obj);

            const clone = Array.isArray(obj) ? [] : {};
            hash.set(obj, clone);

            Object.keys(obj).forEach(key => {
                clone[key] = this.deepCopy(obj[key], hash);
            });

            return clone;
        },
        apiGetDashBoard() {
            axios.post(window.Laravel.back_url+'/api/getDashBoard',{
              s_date : this.s_year + this.s_month,
                s_year : this.currentYear,
                s_month : this.currentMonth,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
              response.data.forEach((value, index) => {
                this.dash_d_val[value.d_col] = value.d_val;
                if(value.d_col == "LV_1" || value.d_col == "LV_2"){
                  this.dash_d_val[value.d_col + value.d_name] = value.d_val;
                  this.dash_d_code[value.d_col + value.d_name] = value.d_code;
                }

                /*주문합계*/
                if(
                  value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
                  || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
                  || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
                  || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"

                ){
                  this.dash_d_val[value.d_col + value.d_code] = value.d_val;
                }

                if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
                  this.dash_d_val[value.d_col + value.d_code] = value.d_val;
                }

                if(value.d_col == "RANK_NEED"){
                  this.rank_need = value.d_name; //달성인원직급
                  this.rank_need1 = value.d_code; //필요회원수
                  this.rank_need2 = value.d_val; //달성인원수

                }

                if(value.d_col == "GOLD"){
                  this.g1 = value.d_name; //나의 하선 당월
                  this.g2 = value.d_code; //나의 하선 전월
                  this.g3 = value.d_val; //나의 하선 전전월
                  this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
                }
                if(value.d_col == "BRANCH MANAGER"){
                  this.bm1 = value.d_name; //나의 하선 당월
                  this.bm2 = value.d_code; //나의 하선 전월
                  this.bm3 = value.d_val; //나의 하선 전전월
                  this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
                }
                if(value.d_col == "DIRECTOR"){
                  this.d1 = value.d_name; //나의 하선 당월
                  this.d2 = value.d_code; //나의 하선 전월
                  this.d3 = value.d_val; //나의 하선 전전월
                  this.d_total = parseInt(this.d1) + parseInt(this.d2) + parseInt(this.d3);
                }

                if(value.d_col == "VICE PRESIDENT"){
                  this.vp1 = value.d_name; //나의 하선 당월
                  this.vp2 = value.d_code; //나의 하선 전월
                  this.vp3 = value.d_val; //나의 하선 전전월
                  this.vp_total = parseInt(this.vp1) + parseInt(this.vp2) + parseInt(this.vp3);
                }

                if(value.d_col == "PRESIDENT"){
                  this.p1 = value.d_name; //나의 하선 당월
                  this.p2 = value.d_code; //나의 하선 전월
                  this.p3 = value.d_val; //나의 하선 전전월
                  this.p_total = parseInt(this.p1) + parseInt(this.p2) + parseInt(this.p3);
                }
              });
                // deep copy
                if (this.isEmptyObject(this.dash_d_val_ori) === true) {
                    this.dash_d_val_ori = this.deepCopy(this.dash_d_val);
                }

            });
        },
        apiDashboard(){
            axios.post(window.Laravel.back_url+'/api/dashboard',{
                s_year : this.currentYear,
                s_month : this.currentMonth,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                this.mem_stamp_h_user = response.data.mem_stamp_h_user;
            });
        },
        getTotalAllowanceOrBunus(){
            axios.post(window.Laravel.back_url+'/api/getTotalAllowanceOrBunus',{
                s_year : this.currentYear,
                s_month : this.currentMonth,
                s_day : this.s_day.code
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
              this.name_list = response.data.name_list;
              this.List = response.data.name_data[0];
              this.name_date = response.data.name_date;
              this.s_year = response.data.s_year;
              this.s_month = response.data.s_month;
              this.s_day = response.data.s_day;
              this.dashData = response.data.dashData;
              this.cnt_top_chk = response.data.cnt_top_chk;
              this.get_list("list1",1,1);
              this.get_list("list2",2,1);
              this.get_list("list3",3,1);
              this.get_list("list4",4,1);
              this.loading = false;
            });
        },
        searchMonthDashBoard(year, month){

            this.loading = true;
            this.currentYear = (year !== '' ) ? year : this.currentYear;
            this.currentMonth = (month !== '' ) ? month : this.currentMonth;

            this.apiGetDashBoard()
            this.apiDashboard()
            this.getTotalAllowanceOrBunus()
        },
        getLegList(month){//본인 산하 직추천 명단
          this.showModal = true;
          this.legs = [];
          this.loading = true;
          axios.post(window.Laravel.back_url+'/api/getLeglist',{month : month
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.legs = response.data;

          });
          this.loading = false;
        },
        goUrl(sid){
          location.href='/MyOrderLegs/'+sid;
        },
    },
    mounted() {
        this.searchMonthDashBoard('', '');
    }

}
</script>

<style scoped>
:deep(.popper) {
    max-width: 600px;
    word-break: break-all;
    white-space: pre-line;
}
</style>

<style >
.v-select{ min-width: 100px; z-index:0 }
/*배솧비 툴팁*/
.tooltip{display: inline-block;}
.tooltip i{color:#0abab5; font-weight:400; vertical-align: baseline}
.tooltip-content{ display: none; text-align: left; font-size: 12px; background-color: #fff; padding:10px; border-radius:10px; font-size:1em; box-shadow:5px 5px 5px #888; background:#0abab5; color:#fff;}
/* 툴팁 삭제버튼 추가 */
.tooltip-content:after{display:inline-block; content:' '; width:20px;}
.tooltip-close{position:absolute; top:7px; right:12px;}
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.my-pagination {
    justify-content: center;
    height:80px;
}


</style>
