<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <section class="container mx-auto lg:py-6 md:p-0" >
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full divide-y bg-white lg:p-2" id="infomodify" >

                    <div class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6">会員入会に関するお問い合わせ</div>
                      <div class="w-full content-center overflow-x-scroll xl:scrollbar-hide lg:scrollbar-hide">
                          <div class="w-full p-4">
                              <span class="block text-sm lg:text-base">
                              会員入会及びビジネスを始めるためには、推薦者情報が必要です。<br>
                              全ての項目をご入力いただき、内容欄に現住所(市町村名まで)をご記入いただければ、お住いに近い推薦者を選定して、ご連絡いたします。<br>
                              </span>
                          </div>
                          <table class="w-full table-auto border-collapse border border-slate-400">
                            <tbody>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] p-2 xl:text-base lg:text-base text-sm">お名前</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="user_name" class=" border-[#BBB]" v-model="user_name"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">メールアドレス</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="user_email" class=" border-[#BBB]" v-model="user_email"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">携帯番号</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="user_mobile" class=" border-[#BBB]" v-model="user_mobile" maxlength="11" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">件名</td>
                                  <td class="border border-slate-300 p-2"><input type="text" name="title" class=" border-[#BBB]" v-model="title"></td>
                                </tr>
                                <tr>
                                  <td class="border border-slate-300 bg-[#f9f9f9] px-2 xl:text-base lg:text-base text-sm">内容</td>
                                  <td class="border border-slate-300 p-2 "><textarea name="contents" id="contents" class="w-full h-40 border-[#BBB]" type="text" v-model="contents"></textarea></td>
                                </tr>
                            </tbody>
                          </table>
                      </div>
                      <div class="xl:text-left lg:text-left text-center xl:py-3 lg:py-3 py-2">
                        <div><vue-recaptcha ref="recaptcha" sitekey="6Lfrz3wkAAAAAIZkkULRXj9qyUHLO_eTYZJKHtn9"  @verify="verifyMethod"/></div>
                      </div>
                      <button type="button" v-on:click="send"  class="mt-6 my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] w-full md:w-1/6  rounded-md">保存する</button> <!-- 동의하기 -->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>

  </section>



</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";
import MyRegister_agree from "./MyRegister_agree";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "MyRegister_inquiry",
  components : {
    MyRegister_agree,
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect,
    VueRecaptcha
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    recaptchaRes : "",

    user_name :"",
    user_mobile :"",
    user_email :"",
    title :"",
    contents :"",

  }),

  beforeCreate() {
    this.loading = true;
    if (window.Laravel.isLoggedin) {
      VueSimpleAlert.alert("This page is only for guests","","",{allowOutsideClick: false,confirmButtonText:"確認"}).then((result) => {
        location.replace("/");
      });
    }else{
      this.loading = false;
    }

  },
  created() {
  },
  watch : {

  },
  mounted() {

  },
  methods:{

    verifyMethod(response) {//구글 리캡챠
      console.log(response);
      this.recaptchaRes = response;
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    send(){
        this.loading = true;
        if(this.recaptchaRes == ""){
          VueSimpleAlert.alert("チェックボックスに✓を入れてください","","",{allowOutsideClick: false,confirmButtonText:"確認"}).then((result) => {})
          this.loading = false;
          return;
        }
        axios.post(window.Laravel.back_url+'/api/insertSaveInquiryUs', {
          user_name : this.user_name,
          user_mobile : this.user_mobile,
          user_email : this.user_email,
          title : this.title,
          contents : this.contents,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if (response.data.ret == "1") {
          VueSimpleAlert.alert(i18n.inquriy.inquriy.inquriy_notice_ok,"","",{allowOutsideClick: false,confirmButtonText:"確認"}).then((result) => { //문의 접수가 완료 되었습니다.
            if (result) {
              location.replace("/");
            }
          });
        }else{
          VueSimpleAlert.alert("入力情報を確認してください","","",{allowOutsideClick: false,confirmButtonText:"確認"}).then((result) => {}) //
          this.loading = false;
          return;
        }

      })


    },
  },
}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>

