<template>
    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <div class="list_notice">
                        <span class="notice_tit">{{ i18n.myoffice.memberUpChange.notice1 }}</span>
                        <ol>
                            <li>{{ i18n.myoffice.memberUpChange.notice2 }}</li>
                            <li>{{ i18n.myoffice.memberUpChange.notice5 }}</li>
                        </ol>
                    </div>
                    <div class="list_notice">
                        <span class="notice_tit">{{ i18n.myoffice.memberUpChange.notice6 }}</span>
                        <ol>
                            <li>{{ i18n.myoffice.memberUpChange.notice14 }}</li>
                            <li>{{ i18n.myoffice.memberUpChange.notice7 }}</li>
<!--                            <li>{{ i18n.myoffice.memberUpChange.notice8 }}</li>-->
                            <li>{{ i18n.myoffice.memberUpChange.notice15 }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- LEFT END -->
    <form id="frm6" name="frm6" action="" method="post">
        <input type="hidden" id="schUserId" name="schUserId" value="<?=$userid?>" />
    </form>

    <!-- LEFT END -->
    <form id="frmSch" name="frmSch" action="" method="post">
        <input type="hidden" id="browserVersion" name="browserVersion" value="" />
    </form>

    <form id="frm1" name="frm1" action="" method="post" autocomplete="off">
        <input type="hidden" id="nowPage" name="nowPage" value="0" />

        <input type="hidden" id="ppId2" name="ppId2" :value="p_id" />
        <input type="hidden" id="ppName2" name="ppName2" :value="p_name" />
        <input type="hidden" id="ab_pos" name="ab_pos" :value="ab_pos" />
        <input type="hidden" id="statusFlg" name="statusFlg" value="N" />

        <input type="hidden" name="rrId2" id="rrId2" :value="r_id" /> <!-- 기존 유지  -->
        <input type="hidden" name="rrName2" id="rrName2" :value="r_name" /> <!-- 기존 유지  -->

        <!-- 기존 추천인 비교용  -->
        <input type="hidden" id="ppId3" name="ppId3" :value="p_id" />
        <input type="hidden" id="rrId3" name="rrId3" :value="r_id" />

        <!-- 팝업 클릭여부 확인용 -->
        <input type="hidden" id="rrstatus" v-model="rrstatus" />
        <input type="hidden" id="ppstatus" v-model="ppstatus" />

        <div id="content" class="vision">

            <div class="cont_wrap" style="overflow: auto;">
                <h2 class="myo_table_tit">{{ i18n.myoffice.memberUpChange.memberInfo }}</h2>
                <ul id="myo_form" class="mgb_30">
                    <li class="item table_form">
                        <span class="item_title">{{ i18n.myoffice.memberUpChange.regDate }}</span>
                        <span class="item_content" id="regDate2">
                            {{ reg_date }}
                        </span>
                    </li>
                    <li class="item table_form">
                        <span class="item_title">
                            {{ i18n.myoffice.memberUpChange.member }}
                        </span>
                        <span class="item_content" id="userName">{{ username }}</span>
                    </li>
                  <!-- 미국지사 요청 주석 처리 -->
<!--                    <li class="item table_form">
                        <span class="item_title">
                            {{ i18n.myoffice.memberUpChange.campus }}
                        </span>
                        <span class="item_content" id="cntName">{{ cnt_names }}</span>
                    </li>-->
                    <li class="item table_form">
                        <span class="item_title">
                            {{ i18n.myoffice.memberUpChange.rank }}
                        </span>
                        <span class="item_content" id="rankName">{{ rank_names }}</span>
                    </li>
                    <li class="item table_form">
                        <span class="item_title">
                            {{ i18n.myoffice.memberUpChange.rid }}
                        </span>
                        <span class="item_content" id="rrNames2">{{ r_names }}</span>
                    </li>
                </ul>
                <div  v-show="upChangeShow">
                    <h2 class="myo_table_tit">{{ i18n.myoffice.memberUpChange.lineInfo }}</h2>
                    <ul id="myo_form" class="mgb_30">
                        <li class="item table_form">
                            <span class="item_title">
                                {{ i18n.myoffice.memberUpChange.upDate }}
                            </span>
                            <span class="item_content" >
                                {{ reg_date }}
                                <input type="hidden" id="regDate" name="regDate" value="{{ reg_date }}" />
                            </span>
                        </li>
                        <li class="item table_form">
                            <span class="item_title">
                                <span class="oran">{{ i18n.myoffice.memberUpChange.ridNum }}</span>
                            </span>
                            <span class="item_content">
                                <input type="hidden" name="rrNameView"  id="rrNameView" class="required" style="ime-mode:active;" readonly="readonly" />
                                <input type="text" name="rrId" id="rrId" v-model="rr_id"  @click="funcRrMemberSearch" />
                                <input type="hidden" name="targetRrId" id="targetRrId" value="true" />
                                <span class="submit_btn_box">
                                    <button type="button" @click="funcRrMemberSearch"  class="bk_submit_btn2">
                                        {{ i18n.myoffice.Search }}
                                    </button>
                                </span>
                            </span>
                        </li>
                        <li class="item table_form">
                            <span class="item_title">
                                <span class="oran">{{ i18n.myoffice.memberUpChange.ridName }}</span>
                            </span>
                            <span class="item_content">
                                <ul>
                                    <li>
                                        <input type="text" name="rrName" readonly=""  id="rrName" v-model="rr_name"/>
                                    </li>
                                </ul>
                            </span>
                        </li>
                    </ul>

                    <div class="myo_condition_search2">
                        <button type="button" v-show="daychk == 1" v-on:click="goSaveConfirm" id="btnUpdate" class="search_btn">{{ i18n.myoffice.memberUpChange.bottom1 }}</button>
                    </div>
                    <div align="center" style="display:none;" id="loading">
                        <a id="loading-text">{{ i18n.myoffice.memberUpChange.progress }}</a>
                        <img id="loading-image" src="/images/loading.gif" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    </form>
    <modalView v-show="showModal" @close-modal="showModal = false" :harf="false">
        <MyMemberUpChangeSearch :rrId="rr_id"  :schUserId="schUserId" @setiddata="setiddata" ref="MyMemberUpChangeSearch"></MyMemberUpChangeSearch>
    </modalView>
</template>

<script>

import MyMemberUpChangeSearch from "./MyMemberUpChangeSearch.vue";
import modalView from "../../modalView.vue";
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyMemberUpChange",
    components : {
        MyMemberUpChangeSearch,
        modalView,
        VueSimpleAlert
    },
    data: () => ({
        i18n: window.i18n,
        p_id : "",
        r_id : "",
        p_name : "",
        r_name : "",
        pp_id : "",
        rr_id : "",
        pp_name : "",
        rr_name : "",
        ab_pos : "",
        reg_date : "",
        username : "",
        //cnt_names : "",
        rank_names : "",
        r_names : "",
        p_names : "",
        ppstatus : "Y",
        rrstatus : "Y",
        upChangeShow : true,
        line_1_disabled : 1,
        line_2_disabled : 1,
        line_1_checked : 0,
        line_2_checked : 0,
        showModal : false,
        searchType  : "1",
        Inputid : "",
        schUserId : window.Laravel.user,
    }),
    props : ["daychk"],
    created : function (){
        //console.log(this.daychk);
        if(this.daychk == 2){
            //VueSimpleAlert.alert(i18n.myoffice.memberUpChangePopNotice.notice16,"","",{});
        }
        axios.post(window.Laravel.back_url+'/api/memberInfo',{
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.username = response.data.username;
            this.p_id = response.data.p_id;
            this.r_id = response.data.r_id;
            this.p_name = response.data.p_name;
            this.r_name = response.data.r_name;
            this.pp_id = response.data.p_id;
            this.rr_id = response.data.r_id;
            this.pp_name = response.data.p_name;
            this.rr_name = response.data.r_name;
            this.ab_pos = response.data.ab_pos;
            this.reg_date = response.data.reg_date;
            //this.cnt_names = locale == "kr" ? "" : response.data.cnt_name;
            this.rank_names = response.data.rank_name;
            this.r_names = response.data.r_id == "00000001" || response.data.r_id == "00000000" ?  i18n.myoffice.Leg.company : response.data.r_name+"("+response.data.r_id+")" ;
            this.upChangeShow = response.data.upChangeShow == 1 ? true : true;

        });

    },
    methods : {
        funcRrMemberSearch(){
            if(this.rr_id == ""){
                alert(i18n.myoffice.memberUpChangePopNotice.notice1);
            }else{
                this.showModal = true;
            }
        },
        setiddata(userid,username,abposcnt){

            this.showModal = false;
            if(this.searchType == 1){
                this.rr_id = userid;
                this.rr_name = username;
            }
            if(this.searchType == 2){
                this.pp_id = userid;
                this.pp_name = username;

                if(abposcnt == "1"){
                    this.line_1_disabled = 0;
                }
                if(abposcnt == "2"){
                    this.line_2_disabled = 0;
                }
                if(abposcnt == "3"){
                    this.line_1_disabled = 0;
                    this.line_2_disabled = 0;
                }
            }
        },
        goSaveConfirm(){
            var userIdKakao = this.schUserId;
            var berrId = this.r_id; //현재 추천인 아이디
            var aftrrId = this.rr_id; //변경될 추천인아이디
            var aftrrName = this.rr_name; //변경될 추천인명


            var beppId = this.p_id;  //현재 후원 아이디
            var aftppId = this.pp_id;   //변경될 후원인아이디
            var aftppName = this.pp_name; //변경될 후원인명

            var status = '0';

            if($("#rrId").val() ==""|| $("#rrId").val() ==null){
                alert(i18n.myoffice.memberUpChangePopNotice.notice3);
                $("#rrId").focus();
                return;
            }

            if($("#rrName").val() ==""|| $("#rrName").val() ==null){
                alert(i18n.myoffice.memberUpChangePopNotice.notice4);
                $("#rrName").focus();
                return;
            }


            axios.post(window.Laravel.back_url+'/api/upChangeMember',{
                mode : "Member_Line_Check_Web1",
                userid : this.schUserId,
                rrId : this.rr_id,
                rrName : this.rr_name,

            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if (response.data.data.status == "1") {
                    alert(i18n.myoffice.memberUpChangePopNotice.notice12);
                    //$("#btnUpdate").hide();
                    location.reload();
                } else {
                    alert(response.data.data.message);
                }
            });
        }
    }
}
</script>

<style >

</style>
