<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="true"></loading>
  <!-- S : 은행및 지점 코드 입력 -->
  <modalView class="z-50" v-if="bank_search_open" @close-modal="bank_search_open = false"   >
    <bank_search @set_bank="set_bank" @close_bank_search="close_bank_search" :bank_type="this.bank_type" :bank_cd="this.bank.bankCd" />
  </modalView>
  <!-- E : 은행및 지점 코드 입력 -->
  <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
    <div class="ctn-box">
      <div class="board-inner">
        <div class="myoffice_page">

          <section class="accordion_notice_box">
            <div class="accordion_notice_item">
              <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
              </a>
              <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                  <p>{{ i18n.myoffice.myofficeAlert.uploadFileLimitedAlert }}</p>
                </div>
              </div>
            </div>
          </section>

          <ul class="fileUpWRap clr upload-box ">
            <li class="upload-list">
              <h5>通帳等の写し</h5>
              <ul id="myo_form">
                <li>
                  <span class="item_title">{{ i18n.join.join.bank }}</span>
                  <span class="item_content" style="text-align: left;">
                    <div class="relative z-10">
                      <input class="type_number h-10 xl:w-60 lg:w-60 w-full"  type="text" v-model="bank.bankCodeTxt" @click="search_bank('bank')" readonly :disabled="accountCheck.check_yn == 'Y'"><br>
                      <input type="button" @click="goLink('http://zengin.ajtw.net')" class="max-w-[250px] h-10  w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2  lg:mt-0 xl:mt-0" value="銀行コード検索"/>
                      <div class="text-red-500 text-sm">銀行と支店コードを検索してから入力してください。</div>
                    </div>
                  </span>
                </li>
                <li>
                  <span class="item_title">支店コード</span>
                  <span class="item_content" style="text-align: left;">
                    <div class="relative z-10">
                      <input class="type_number h-10 xl:w-60 lg:w-60 w-full"  type="text" v-model="bank.branchCodeTxt" @click="search_bank('branch')" readonly :disabled="accountCheck.check_yn == 'Y'"><br>
                      <div class="text-red-500 text-sm">銀行と支店コードを検索してから入力してください。</div>
                    </div>
                  </span>
                </li>
                <li>
                  <span class="item_title">{{ i18n.join.join.account }}</span>
                  <span class="item_content" style="text-align: left;"><input type="text" v-model="account" class="" :readonly="accountCheck.check_yn == 'Y'" maxlength="7" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @blur="this.account = this.account.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></span>
                </li>
                <li>
                  <span class="item_title">{{ i18n.myoffice.AccountHolder }}</span>
                  <span class="item_content" style="text-align: left;">
                    {{depositor??""}}
                  </span>
                </li>

              </ul>
              <!-- 테이블1 -->
              <dl>
                <dt v-if="accountCheck.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img1"  width="100%" height="100%" style="vertical-align:middle;" :src="accountCheck.fileurl" alt="">
                      <div v-if="accountCheck.check_yn == 'N' && accountCheck.memo != ''" class="res-memo2"><span class="upload-state3">{{ i18n.myoffice.Pending }}</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="accountCheck.check_yn == 'N'" type="file" name="PPFile" id="PPFile" @change="readURL">
                    <div v-if="accountCheck.check_yn == 'N' && accountCheck.memo == '' && accountCheck.fileurl != ''" class="res-depending"><span class="upload-state1">{{ i18n.myoffice.proceeding }}</span></div>
                    <div v-else-if="accountCheck.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="accountCheck.check_yn == 'N' && accountCheck.memo != ''" class="res-memo">{{ accountCheck.memo }}</div>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    次のいずれかの写真をアップロードしてください。
                    (口座情報が確認できるもの)<br><br>
                    - 通帳の見開きページ<br>
                    - キャッシュカード<br>
                    <br><br>
                  </div>
                  <div class="item_content_notice" style="color:#444">
                    ネットバンクの場合は下記の情報が載ったページのスクリーンショット画像でも可能です。<br>
                    以下の情報が必要です。<br>
                    - 口座名義・銀行名・支店名・口座番号<br>
                  </div>
                </dd>
              </dl>
            </li>

            <li class="upload-list">
              <h5 v-if="!isBusiness">マイナンバーまたは運転免許証  (表面)</h5>
              <h5 v-if="isBusiness">会社・法人の登記事項証明書等</h5><!--회사·법인의 등기사항 증명서 등 13자리-->
              <!--START: 마이넘버 운전면허증 앞면 ------------------------------------->
              <ul id="myo_form">
                <li class="xl:bg-white">
                  <span class="grid sm:grid-cols-4 bg-white h-full p-2" style="text-align: left;" >
                    <span class="sm:col-span-1 xl:w-full" v-if="!isBusiness">
                      <select class="flex w-full  h-full text-sm md:text-base" v-model="pin_type"  :disabled="img_mynum.check_yn == 'Y'" >
                        <option value="1" >マイナンバー (マイナンバーカード、通知カード、住民票)</option>
                        <option value="2">運転免許証</option>
                      </select>
                    </span>
                    <span class="sm:col-span-3 xl:w-full ">
                      <input v-if="!isBusiness" name="jumin_no"  maxlength="14"  class="h-full"  v-model="jumin_no" style="ime-mode:disabled;" type="text" :readonly="img_mynum.check_yn == 'Y'" >
                      <input v-if="isBusiness" name="jumin_no"  placeholder="13桁の法人番号を入力してください。" maxlength="13"  class="h-full"  v-model="jumin_no" style="ime-mode:disabled;" type="text" :readonly="img_mynum.check_yn == 'Y'" >
                    </span>
                  </span>
                  <div v-if="!isBusiness" class="item_content_notice mt-3"  style="color:#444">
                    運転免許証をご登録された場合には【確定申告用支払調書】<br>
                    に個人番号は記載されませんのでご注意ください。
                  </div>
                </li>

              </ul>  <!-- 테이블2 -->
              <dl>
                <dt v-if="img_mynum.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2"  width="100%" height="100%" style="vertical-align:middle;" :src="img_mynum.fileurl" alt="">
                      <div v-if="img_mynum.check_yn == 'N' && img_mynum.memo != ''" class="res-memo2"><span class="upload-state3">{{ i18n.myoffice.Pending }}</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="img_mynum.check_yn == 'N'" type="file" name="DDFile" id="DDFile" @change="readURL">
                    <div v-if="img_mynum.check_yn == 'N' && img_mynum.memo == '' && img_mynum.fileurl != ''" class="res-depending"><span class="upload-state1">{{ i18n.myoffice.proceeding }}</span></div>
                    <div v-else-if="img_mynum.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="img_mynum.check_yn == 'N' && img_mynum.memo != ''" class="res-memo">{{ img_mynum.memo }}</div>
                  </div>
                  <div v-if="!isBusiness" class="item_content_notice mt-3" style="color:#444">
                    次のいずれかをアップロードしてください。<br>
                    - マイナンバーカードの表面<br>
                    - マイナンバー通知カードの表面<br>
                    - マイナンバーの記載がある住民票の表面<br>
                  </div>
                  <div v-if="!isBusiness" class="item_content_notice mt-3"  style="color:#444">
                    住民票をアップロードする場合、<br>
                    「マイナンバー裏面」の所にも 住民票の表面をアップロードしてください。<br>
                    - 運転免許証の表面<br>
                  </div>


                  <div v-if="isBusiness" class="item_content_notice mt-3"  style="color:#444">
                    以下のいずれかをアップロードしてください。<br>
                    - 全部事項証明書(謄本)<br>
                    - 履歴事項証明書<br>
                    - 現在事項証明書<br>
                    - 代表者事項証明書<br>
                  </div>

                </dd>
              </dl>
            </li>

            <li class="upload-list">
              <h5 v-if="!isBusiness">マイナンバーまたは運転免許証  (裏面)</h5><!--START: 마이넘버 운전면허증 뒷면 ------------------------------------->
              <h5 v-if="isBusiness">印鑑証明書</h5><!--START: 인감 증명서 ------------------------------------->
              <dl>
                <dt v-if="img_mynum2.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2"  width="100%" height="100%" style="vertical-align:middle;" :src="img_mynum2.fileurl" alt="">
                      <div v-if="img_mynum2.check_yn == 'N' && img_mynum2.memo != ''" class="res-memo2"><span class="upload-state3">{{ i18n.myoffice.Pending }}</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="img_mynum2.check_yn == 'N'" type="file" name="D2File" id="D2File" @change="readURL">
                    <div v-if="img_mynum2.check_yn == 'N' && img_mynum2.memo == '' && img_mynum2.fileurl != ''" class="res-depending"><span class="upload-state1">{{ i18n.myoffice.proceeding }}</span></div>
                    <div v-else-if="img_mynum2.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="img_mynum2.check_yn == 'N' && img_mynum2.memo != ''" class="res-memo">{{ img_mynum2.memo }}</div>
                  </div>
                  <div v-if="!isBusiness" class="item_content_notice mt-3" style="color:#444">
                    次のいずれかをアップロードしてください。<br>
                    - マイナンバーカードの裏面<br>
                    - マイナンバー通知カードの裏面<br>
                    - マイナンバーの記載がある住民票の表面<br>
                    - 運転免許証の裏面<br>
                  </div>

                </dd>
              </dl>
            </li>
            <li class="upload-list" v-if="this.isForigner || this.isBusiness">
              <h5 v-if="!this.isBusiness">{{i18n.myoffice.Memberinfo.Residencecard}}(表面)</h5><!--START:외국인전용 앞면 ------------------------------------->
              <h5 v-if="this.isBusiness">代表者 本人確認書類　表面</h5><!--START:대표자 본인 확인 서류 앞면 ------------------------------------->

              <dl>
                <dt v-if="img_card.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2"  width="100%" height="100%" style="vertical-align:middle;" :src="img_card.fileurl" alt="">
                      <div v-if="img_card.check_yn == 'N' && img_card.memo != ''" class="res-memo2"><span class="upload-state3">{{ i18n.myoffice.Pending }}</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="img_card.check_yn == 'N'" type="file" name="FFFile" id="FFFile" @change="readURL">
                    <div v-if="img_card.check_yn == 'N' && img_card.memo == '' && img_card.fileurl != ''" class="res-depending"><span class="upload-state1">{{ i18n.myoffice.proceeding }}</span></div>
                    <div v-else-if="img_card.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="img_card.check_yn == 'N' && img_card.memo != ''" class="res-memo">{{ img_card.memo }}</div>
                  </div>
                  <div v-if="!this.isBusiness" class="item_content_notice mt-3" style="color:#444">
                    両面の写真をアップロードしてください。<br>

                  </div>
                  <div v-if="this.isBusiness" class="item_content_notice mt-3" style="color:#444">
                    以下のいずれかをアップロードしてください。<br>
                    マイナンバーカード(表面)<br>
                    運転免許証(表面)<br>
                    住民票<br>
                    保険証(表面)<br>
                    在留カード(表面)

                  </div>
                </dd>
              </dl>
            </li>
            <li class="upload-list" v-if="this.isForigner || this.isBusiness">
              <h5 v-if="!this.isBusiness">{{i18n.myoffice.Memberinfo.Residencecard}}(裏面)</h5>              <!--START:외국인전용 뒷면 ------------------------------------->
              <h5 v-if="this.isBusiness">代表者 本人確認書類　裏面</h5>
              <dl>
                <dt v-if="img_card2.fileurl">
                  <div class="fix_rectangle_box">
                    <div class="fix_box_inner">
                      <img id="img2"  width="100%" height="100%" style="vertical-align:middle;" :src="img_card2.fileurl" alt="">
                      <div v-if="img_card2.check_yn == 'N' && img_card2.memo != ''" class="res-memo2"><span class="upload-state3">{{ i18n.myoffice.Pending }}</span></div>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div class="upload_btn_box">
                    <input v-show="img_card2.check_yn == 'N'" type="file" name="F2File" id="F2File" @change="readURL">
                    <div v-if="img_card2.check_yn == 'N' && img_card2.memo == '' && img_card2.fileurl != ''" class="res-depending"><span class="upload-state1">{{ i18n.myoffice.proceeding }}</span></div>
                    <div v-else-if="img_card2.check_yn == 'Y'" class="res-admit"><span class="upload-state2">Completed</span></div>
                    <div v-else-if="img_card2.check_yn == 'N' && img_card2.memo != ''" class="res-memo">{{ img_card2.memo }}</div>
                  </div>
                  <div v-if="this.isBusiness" class="item_content_notice mt-3" style="color:#444">
                    以下のいずれかをアップロードしてください。<br>
                    マイナンバーカード(表面)<br>
                    運転免許証(表面)<br>
                    住民票<br>
                    保険証(表面)<br>
                    在留カード(表面)<br>
                  </div>

                </dd>
              </dl>
            </li>
          </ul>


        </div>

        <div class="myo_condition_search" style="margin-top:20px;">
          <!--<button type="button" onclick="history.back()"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>-->
          <button type="button" onclick="location.href='/MyofficeMain'"  class="go_list_btn">{{ i18n.shop.board.Contents }}</button>
          <button type="submit" id="btn_submit" class="search_btn">{{ i18n.myoffice.save }}</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";
import modalView from "../../modalView.vue";

export default {
  name: "MyforinerUpload",
  components : {
    modalView,
    vSelect,
    Loading,
    VueSimpleAlert
  },
  data: () => ({
    i18n: window.i18n,
    mem : "",
    bankStdRow : "",

    accountCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    img_mynum : {"check_yn" : "","memo" : "","file_name" : ""},
    img_mynum2 : {"check_yn" : "","memo" : "","file_name" : ""},
    img_card : {"check_yn" : "","memo" : "","file_name" : ""},
    img_card2 : {"check_yn" : "","memo" : "","file_name" : ""},

    visaCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    taxCheck : {"check_yn" : "","memo" : "","file_name" : ""},
    loading : false,


    bank_search_open : false,
    bank_type        : "",
    bank        : [],

    account  : "",
    depositor : "",
    jumin_no : "",
    pin_type_list : ['マイナンバー (マイナンバーカード、通知カード、住民票)','運転免許証'],

    forigner_jumin_no : "",

    isForigner : false,
    isBusiness : false,
    pin_type : ['1','2'],
    nt_cd : "",
    accInfoCk : "",
    routingNo : "",


  }),
  beforeCreate() {

    axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
      this.accountCheck = response.data.account;//PPFILE 계좌
      this.img_mynum = response.data.img_mynum;//DDFILE 마이넘버앞면
      this.img_mynum2 = response.data.img_mynum2;//D2File 마이넘버뒷면
      this.img_card = response.data.img_card;//FFFile 외국인 앞면
      this.img_card2 = response.data.img_card2;//F2File 외국인 뒷면
    });

    axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.mem = response.data;
        this.pin_type = response.data.pin_type;
        this.accInfoCk = response.data.acc_type;//accInfoCk
        this.nt_cd = response.data.nt_cd;

        this.isBusiness = response.data.sa_kind == '1' ? true : false;//business 회원 구분

        if(this.nt_cd == '999'){//외국인일경우
          this.isForigner = true;
        };

        this.depositor = response.data.depositor != "null" ? response.data.depositor : "";
        this.account = (response.data.account && response.data.account != "null") ? response.data.account : "";
        this.bank.bankCd = (response.data.bank_cd && response.data.bank_cd != "null") ? response.data.bank_cd:"";
        this.bank.bankCodeTxt = (response.data.bank_cd && response.data.bank_cd != "null") ? response.data.bank_cd+" "+response.data.bank_name : "";
        this.bank.branchCodeTxt = (response.data.branch_cd && response.data.branch_cd != "null") ? response.data.branch_cd+" "+response.data.branch_name : "";
        this.bank.bankBranchCode = (response.data.branch_cd && response.data.branch_cd != "null") ? response.data.branch_cd:"";
        this.bank.bankBranchNameCode = (response.data.branch_name && response.data.branch_name != "null") ? response.data.branch_name:"";
        this.bankkatacode = (response.data.bankkatacode && response.data.bankkatacode != 'null') ? response.data.bankkatacode : '';
        this.bankhiracode = (response.data.bankhiracode && response.data.bankhiracode != 'null') ?response.data.bankhiracode : '' ;

        this.jumin_no = (response.data.jumin_no && response.data.jumin_no != 'null') ? response.data.jumin_no : '';

        // this.routingNo = response.data.cyber_point;
    });

  },
  created() {



  },
  watch : {
    jumin_no : function(){
        if(!this.jumin_no) {
          return;
        }
        if(!this.isBusiness){
          /* 12 자리 숫자만 입력 후 숫자 사이 하이픈 추가 */
          var tmpNum = this.jumin_no.replace(/[^0-9]/g, "");
          var num = "";

          if(tmpNum.length <= 4) {
            this.jumin_no = tmpNum;
            return;
          } else if(tmpNum.length <= 8) {
            num += tmpNum.substr(0, 4);
            num += "-";
            num += tmpNum.substr(4);
          } else if(tmpNum.length <= 12) {
            num += tmpNum.substr(0, 4);
            num += "-";
            num += tmpNum.substr(4, 4);
            num += "-";
            num += tmpNum.substr(8);
          } else {
            num += tmpNum.substr(0, 4);
            num += "-";
            num += tmpNum.substr(4, 5);
            num += "-";
            num += tmpNum.substr(8);
          }
          if(tmpNum.length > 12){
            return;
          }
          this.jumin_no = num;
        }
    },
  },
  methods : {
    /* S : 은행검색 */
    search_bank(bank_type){
      if(bank_type === 'branch' && !this.bank.bankCd) { // 금융 기관 코드를 먼저 입력해주세요.
        VueSimpleAlert.alert("金融機関コードを最初に入力してください。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
        return;
      }
      this.bank_type        = bank_type;
      this.bank_search_open = true;
    },
    set_bank(value){
      this.close_bank_search();
      if(value.bank_type === 'bank') {
        this.bank.bankCodeTxt       = value.code + " " + value.name + "(" + value.kana + ")";
        this.bank.bankCd            = value.code ?? '';
        this.bank.bankName          = value.name ?? '';
        this.bank.bankKataCode      = value.kana ?? '';
        this.bank.bankHiraCode      = value.hira ?? '';
      }
      if(value.bank_type === 'branch') {
        this.bank.branchCodeTxt      = value.code + " " + value.name + "(" + value.kana + ")";
        this.bank.bankBranchCode     = value.code ?? '';
        this.bank.bankBranchNameCode = value.name ?? '';
        this.bank.bankBranchKataCode = value.kana ?? '';
        this.bank.bankBranchHiraCode = value.hira ?? '';
      }
    },
    close_bank_search(){
      this.bank_search_open = false;
    },
    /* E : 은행검색 */
    submitForm: function (event) {
      // this.loading =true;
     /* if(!this.validationCheck()) { /!* 필수 입력값 체크 및 유효성 체크 *!/
        this.loading = false;
        return;
      }*/
      let formData = new FormData();
      formData.append('bz_cd', window.Laravel.bz_cd);

      var PPFile = document.getElementById("PPFile");
      var DDFile = document.getElementById("DDFile");
      var D2File = document.getElementById("D2File");

      /*외국인일 경우 재류카드 표시*/ // if($foreignCheck->nt_cd == '999')
      if(this.isForigner || this.isBusiness) {
        var FFFile = document.getElementById("FFFile");
        var F2File = document.getElementById("F2File");
      }

      if(this.accountCheck.check_yn == "N"){
        Object.entries(this.bank).forEach(([key, value]) => {
          formData.append(key, value);
        });
        if(!this.isBusiness){
          formData.append('pin_type', this.pin_type);
        }
        formData.append('jumin_no', this.jumin_no);
        formData.append('accInfoCk', this.accInfoCk);
        /*formData.append('depositor', this.depositor); //수정금지*/
        formData.append('account', this.account);
      }




      if(PPFile.files[0] !== undefined){
        formData.append('PPFile',PPFile.files[0] );
      }
      if( DDFile.files[0] !== undefined){
        formData.append('DDFile',DDFile.files[0] );
      }
      if( D2File.files[0] !== undefined){
        formData.append('D2File',D2File.files[0] );
      }

      if(this.isForigner || this.isBusiness){
        if( FFFile.files[0] !== undefined){
          formData.append('FFFile',FFFile.files[0] );
        }

        if( F2File.files[0] !== undefined){
          formData.append('F2File',F2File.files[0] );
        }
      }

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      }

      this.loading = true;

      axios.post(window.Laravel.back_url+'/api/forienUpload',
        formData
        , config).then(response => {
        VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
          if(result){
            location.replace("/MyforinerUpload");
          }
        });
        this.loading = false;
      });

    },


    goLink(url) {
      window.open(url);
    },

    readURL: function (event) {
      var file = event.target.files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        if(event.target.id == "PPFile") this.accountCheck.fileurl = e.target.result;
        if(event.target.id == "DDFile") this.img_mynum.fileurl = e.target.result;
        if(event.target.id == "D2File") this.img_mynum2.fileurl = e.target.result;
        if(event.target.id == "FFFile") this.img_card.fileurl = e.target.result;
        if(event.target.id == "F2File") this.img_card2.fileurl = e.target.result;
      }
      reader.readAsDataURL(file);
    }
  }
}
</script>

<style >
.jumin_check_y { pointer-events:none;background-color:#BDBDBD }
.upload-box{display: flex; width:100%; justify-content: space-evenly;  -webkit-justify-content: space-evenly; flex-wrap: wrap;}
.upload-box .upload-list{font-size:1em; padding:30px 15px; word-break: break-word; width:calc(95% / 2); margin:10px; border-radius: 10px; min-height: 150px; box-shadow: 0px 0px 2px 2px #e7e7e7; /*border:1px solid #ccc;*/ }
.upload-box .upload-list h5{font-size:1.3em; font-weight:500; margin-top:10px; margin-bottom:20px; text-align: center;}
.upload-box .upload-list table{border:1px solid #eee; vertical-align: middle; width:100%;}
.upload-box .upload-list table tr{border-bottom:1px solid #fff;}
.upload-box .upload-list table tr td{padding: 10px; border-bottom:1px solid #ddd; word-break: break-all;}
.upload-box .upload-list table tr td:nth-child(1){width:30%; text-align: center; border-right:1px solid #ddd; background:#f9f9f9; word-break: keep-all;}
.upload-box .upload-list table input{width:100%;}
.upload-box .upload-list input[type=text], .upload-box .upload-list input[type=password]{height: 35px; border: 1px solid #BBB; width: 100%; font-size: 15px; }
.upload-box .upload-list dl{margin-top:20px;}
.upload-box .upload-list dl dt .fix_box_inner:before{content: "Please upload the file"; display: inline-block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; text-align: center; color: #999;}
.res-depending{text-align: center; margin:15px 0;}
.res-admit{text-align: center; margin:15px 0;}
.res-memo{text-align: center; margin:15px 0; color:#ff5722;} /*보류 박스*/
.res-memo2{position:absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255,255,255,0.7);} /*보류 박스*/
.upload-state1{border:3px double red; padding:10px 15px; display: inline-block; color:red;} /*대기*/
.upload-state2{border:3px double blue; padding:10px 15px; display: inline-block; color:blue;} /*승인*/
.upload-state3{text-align: center; color: #ff5722; position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%) rotate(348deg); border: 7px double #ff5722; padding: 5px 8px; font-size:35px;} /*보류*/
.upload_btn_box{margin-top:10px;}

@media all and (max-width:1023px){
  .upload-box{display: flex; width:100%;}
  .upload-box .upload-list{width:100%; margin:0px; margin-bottom:20px;}
  .upload-state3{font-size:20px; border: 3px double #ff5722;} /*보류*/
}
</style>
