<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <section class="container mx-auto lg:py-6 md:p-0" v-if="is_join == false">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full bg-white lg:p-2 flex flex-col items-center" id="infomodify" >

                    <div class="w-full flex flex-col items-center max-w-[720px] w-full">
                      <h2 class="xl:text-xl lg:text-xl text-base font-extrabold tracking-tight text-gray-900 xl:mt-4 lg:mt-4 mt-3 xl:mb-5 lg:mb-3 mb-3">{{i18n.join.join.memJointext0}}</h2>
                          <div class="xl:my-4 lg:my-4 my-2 text-center xl:text-base lg:text-base text-sm">
                            {{i18n.join.join.memJointext1}}<br><br>
                            {{i18n.join.join.memJointext2}}<br>
                          </div>
                          <div class="xl:my-4 lg:my-4 my-2 text-left xl:text-base lg:text-base text-sm ">
                            {{i18n.join.join.memJointext3}}<br>
                            {{i18n.join.join.memJointext4}}<br>
                          </div>
                          <p class="xl:my-4 lg:my-4 my-2 text-center xl:text-base lg:text-base text-sm">
                            {{i18n.join.join.memJointext5}}<br><br>
<!--                            {{i18n.join.join.memJointext6}}<br>-->
                            {{i18n.join.join.memJointext7}}<br>
                          </p>
                    </div>

                    <div class="flex justify-center items-center gap-4 xl:mt-6 lg:mt-6 mt-3 max-w-[700px] w-full">
                        <button type="button" v-on:click="home"  class="w-1/2 xl:h-36 lg:h-36 h-28 p-4 leading-6 text-[#243a63] bg-[#d1d5de] hover:bg-[#afb2ba] rounded-md flex flex-col justify-center items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="fill-[#243a63] xl:w-6 lg:w-6 w-5" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                          <div class="mt-3">ログイン</div>
                        </button> <!-- 메인 페이지로 -->
                        <button type="button" v-on:click="inquiry"  class="w-1/2 xl:h-36 lg:h-36 h-28 p-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] rounded-md flex flex-col justify-center items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="fill-white xl:w-6 lg:w-6 w-5" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM105.8 229.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L216 328.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V314.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H158.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM160 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
                          <div class="mt-3">1：1お問い合わせ</div>
                        </button> <!-- 1:1문의 -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>



</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";
import MyRegister_agree from "./MyRegister_agree";

export default {
  name: "MyRegister_notice",
  components : {
    MyRegister_agree,
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    is_login: false,
    is_join : false,
  }),
  beforeCreate() {
    axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
      this.myconfig = response.data;

    });

  },
  created() {
  },
  watch : {

  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    get_join(data) {
      this.is_join = !this.is_join;
    },

    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    home(){
      location.href = "/sso/login?bz_cd="+window.Laravel.bz_cd;
    },
    inquiry(){
      location.href='/RegisterInquiry';
    },
  },
}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>

