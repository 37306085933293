<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <!-- 마이오피스 추가 부분 임시 -->
  <div class="xl:p-6 lg:p-6 md:p-0 sm:p-0"> <!-- bg-[#f0f2f4]  -->
    <div>
      <div class="xl:flex lg:flex block flex-row items-center justify-between w-full rounded-md mb-6 shadow-zinc-500/40 shadow-md p-5 bg-[#272c33]">

        <div class="flex flex-row items-center xl:gap-10 lg:gap-5 gap-2 xl:mb-0 lg:mb-0 mb-4 w-full max-w-[440px]">
          <div class="xl:w-28 xl:h-28 lg:w-28 lg:h-28 w-20 h-20 rounded-full overflow-hidden">
            <img v-show="rank_max_cd == '00'" src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_basic.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '01'" src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_basic.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '05'" src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_basic.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '10'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_bronze.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

            <img v-show="rank_max_cd == '20'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_silver.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '30'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_gold.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

            <img v-show="rank_max_cd == '40'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_BM.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '50'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_DR.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '60'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_VP.png?ver=1.0" class="object-scale-down bg-cover" alt="" />
            <img v-show="rank_max_cd == '70'"  src="https://cdn.gcoop.me/jp/images/jp_badge/jp_badge_P.png?ver=1.0" class="object-scale-down bg-cover" alt="" />

          </div>
          <div class="flex flex-col justify-items-center items-start p-2">
            <div class="text-left text-white font-semibold text-xl py-2">{{ username }} {{ bb_username}}</div>
            <div class="text-left text-white text-base">{{ userid }} </div>
            <div class="text-left text-white text-base">{{  i18n.myoffice.Commission.AttainmentRank }} : {{ rank_max_name??"" }}</div>
            <div class="text-left text-white text-base">{{  i18n.myoffice.Commission.previousRank }} : {{ rank_old_name??""}}</div>
            <div class="text-left text-white text-base">{{  i18n.myoffice.Commission.CurrentRank }} : {{ dash_d_val.RANK_NEW??""}}</div>
          </div>
        </div>

        <!-- 사용 가능 포인트 / 적립 예정 포인트가 실시간으로 적용 되는지 확인 -->
        <div class="no-show-vcm flex flex-col items-center justify-items-center">
          <div class="flex xl:flex-row lg:flex-row  flex-col gap-4 w-full flex-wrap justify-end">
            <div class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
              <div class="w-full text-center flex items-center">
                <div class="rounded-full p-2 bg-[#c5edec]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#0abab5]" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                </div>
                <!-- 사용 가능 포인트-->
                <div class="w-full text-left pl-2 text-slate-800">
                    {{  i18n.myoffice.Commission.availablePoints }}
                </div>
              </div>
              <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ formatPrice(mem_stamp_h_user.pv_now) }}</div>
            </div>
            <div class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
              <div class="w-full text-center flex items-center">
                <div class="rounded-full p-2 bg-[#dfe8f8]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#6ba0f8]" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                </div>
                <!--적립 예정 포인트-->
                <div class="w-full text-left pl-2 text-slate-800">
                    {{  i18n.myoffice.Commission.holdPoint }}
                </div>
              </div>
              <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ formatPrice(mem_stamp_h_user.pv_ready) }}</div>
            </div>

            <div v-if="this.rank_cd != '00'" class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
              <div class="w-full text-left flex items-center">
                <div class="rounded-full p-2 bg-[#f7efd5]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#f7bc0c]" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM128 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 432c0-44.2 35.8-80 80-80h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16z"/></svg>
                </div>
                <!-- 수당 적용 직급-->
                <div class="w-full text-left pl-2 text-slate-800">
                    {{  i18n.myoffice.Commission.bonusAppliedPosition }}
                </div>
              </div>
              <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full">{{ rank_name??"" }}</div>
            </div>

            <div v-if="this.rank_cd != '00'" class="py-2 px-3 border rounded-md flex flex-col bg-white items-center justify-items-center justify-between xl:w-48 lg:w-40 w-full">
              <div class="w-full text-center flex items-center">
                <div class="rounded-full p-2 bg-[#ffe3e7]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-[#FF4560]" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M352 128c0 70.7-57.3 128-128 128s-128-57.3-128-128S153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                </div>
                <!--유지 상태-->
                <div class="w-full text-left pl-2 text-slate-800">
                    {{  i18n.myoffice.Commission.currentMaintenanceStatus }}
                </div>
              </div>
              <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full" v-show="this.act_yn =='Y'">{{  i18n.myoffice.active }}</div>
              <div class="text-xl font-semibold xl:mt-3 lg:mt-3 mt-1 text-[#0d6efd] text-right w-full" v-show="this.act_yn =='N'">{{  i18n.myoffice.inactive }}</div>
            </div>
          </div>
          <!--표시되는 데이터는 일 마감 기준 데이터입니다.-->
          <p class="text-xs xl:text-right lg:text-right text-left p-1.5 w-full text-white mt-2 word-">
            {{  i18n.myoffice.Commission.message1 }}
          </p>
        </div>

      </div>

      <article v-if="this.rank_cd != '00'" class="relative ">

        <!--  블러처리 안내 메세지 -->
        <div v-if="isCommissionBlur===true" class="absolute z-[100] w-full h-full bg-white/30 z-[10] top-0 left-0 rounded-xl">
          <div class="bg-[#172e59] absolute top-1/3 left-2/4 z-[11] translate-x-[-50%] text-lg font-semibold text-black border-2 border-gray-500 xl:w-[500px] lg:w-[450px] w-5/6 xl:h-[500px] lg:w-[450px] h-auto rounded-xl flex flex-col justify-center items-center xl:p-10 lg:p-10 p-4">
            <div class="bg-[#172e59] absolute top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] text-lg font-semibold text-black border-2 border-gray-500 xl:w-[500px] lg:w-[450px] w-5/6 xl:h-[500px] lg:w-[450px] h-auto rounded-md flex flex-col justify-center items-center p-10">
              <p class="xl:text-4xl text-3xl text-white p-3 xl:mb-16 lg:mb-16 mb-10 mt-10">{{  i18n.myoffice.Commission.comingSoon }}</p>
              <div class="flex flex-row w-full bg-white">
                <span class="w-1/5 h-5 bg-red-400"></span>
                <span class="w-1/5 h-5 bg-red-400"></span>
                <span class="w-1/5 h-5 bg-red-400"></span>
                <span class="w-1/5 h-5 bg-red-400"></span>
                <span class="w-1/5 h-5"></span>
              </div>
              <img alt="GSC_logo" class="xl:w-28 lg:w-28 w-20 py-5" src="/images/GCOOP_JAPAN_CI_E_C.png" />
            </div>
          </div>
        </div>

        <div :class="isCommissionBlur===true ? 'blur' : ''">
          <section class="flex xl:flex-row lg:flex-row flex-col gap-4">
            <!-- 직급 현황 -->
            <div class="rounded-xl bg-white mb-6 shadow-zinc-500/40 shadow-md p-5 w-full flex flex-col">
              <div class="py-3 flex flex-row justify-between">
                <h3 class="font-semibold text-[#172e59] text-lg mb-4 min-h-1/3">{{  i18n.myoffice.Commission.statusOfJobClass }}
                  <span class="text-sm font-normal xl:block lg:block hidden"><br>&nbsp;</span>
                </h3> <!--승급 진행상황-->
              </div>
              <!-- 여기에 프로그래스 바 추가 -->
              <div class=" blur flex flex-col justify-around xl:mt-12 lg:mt-12" v-if="dash_d_val.RANK_NEW">
                <div class="flex flex-row items-center w-full">
                  <div class="w-1-7 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                      <div v-if="rank_name != 'Branch Manager'" class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-gray-400">1</span></div>
                      <div v-if="rank_name == 'Branch Manager'" class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-red-500">1</span></div>
                    </div>
                  </div>
                  <div class="w-1-7 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                      <div v-if="rank_name == 'Branch Manager'" class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                      <div v-if="rank_name != 'Branch Manager'" class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                  </div>

                  <div class="w-1-7 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                      <div v-if="rank_name != 'Director'" class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-gray-400">2</span></div>
                      <div v-if="rank_name == 'Director'" class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-red-500">2</span></div>
                    </div>
                  </div>
                  <div class="w-1-7 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                      <div v-if="rank_name == 'Director'" class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                      <div v-if="rank_name != 'Director'" class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                  </div>

                  <div class="w-1-7 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                      <div v-if="rank_name != 'Vice President'" class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-gray-400">3</span></div>
                      <div v-if="rank_name == 'Vice President'" class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-red-500">3</span></div>
                    </div>
                  </div>
                  <div class="w-1-7 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                      <div v-if="rank_name == 'Vice President'" class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                      <div v-if="rank_name != 'Vice President'" class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                  </div>

                  <div class="w-1-7 text-center lg:text-base mx-1">
                    <div class="flex flex-col justify-center items-center">
                      <div v-if="rank_name != 'President'" class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-gray-400">4</span></div>
                      <div v-if="rank_name == 'President'" class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center"><span class="text-lg text-red-500">4</span></div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-row items-center w-full">
                  <p v-if="rank_name != 'Branch Manager'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">Branch Manager</p>
                  <p v-if="rank_name == 'Branch Manager'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1 text-red-500 font-semibold">Branch Manager</p>
                  <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">&nbsp;</p>
                  <p v-if="rank_name != 'Director'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">Director</p>
                  <p v-if="rank_name == 'Director'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1 text-red-500 font-semibold">Director</p>
                  <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">&nbsp;</p>
                  <p v-if="rank_name != 'Vice President'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">Vice President</p>
                  <p v-if="rank_name == 'Vice President'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1 text-red-500 font-semibold">Vice President</p>
                  <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">&nbsp;</p>
                  <p v-if="rank_name != 'President'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">President</p>
                  <p v-if="rank_name == 'President'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1 text-red-500 font-semibold">President</p>
                </div>
                <!--현재 직급은 이며,
                President 승급을 위해 직추천산하 총 3명의 Vice President가 필요하며,
                현재 1명 달성했습니다.-->
                <div v-if="dash_d_val.RANK_NEXT == 'Bronze' " class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの資格は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>を達成するためには、1回の注文で1,000PV以上購入されるか、<br>
                  購入累積が2,000PV以上必要です。
                </div>
                <div v-if="dash_d_val.RANK_NEXT == 'Silver'" class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの資格は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>を達成するためには、1回の注文で2,000PV以上購入されるか、<br>
                  購入累積が5,000PV以上必要です
                </div>
                <div v-if="dash_d_val.RANK_NEXT == 'Gold' " class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの資格は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>を達成するためには、1回の注文で3,000PV以上購入されるか、<br>
                  購入累積が10,000PV以上必要です
                </div>

                <div v-if="dash_d_val.RANK_NEXT == 'Branch Manager'" class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの資格は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>を達成するためには、
                  直推薦1代で1回のご注文3,000PV以上購入された方が3名必要です。
                </div>

                <div v-if="dash_d_val.RANK_NEXT == 'Director'" class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの職級は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>
                  を達成するためには、本人傘下全体の売上が50,000PV以上且つ直推薦傘下が3レグ必要であり、<br>
                  少なくとも2つのレグで各10,000PV以上の売上が必要です。
                </div>
                <div v-if="dash_d_val.RANK_NEXT == 'Vice President'" class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの職級は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>
                  を達成するためには、本人傘下全体の売上が200,000PV以上且つ直推薦傘下が3レグ必要であり、<br>
                  少なくとも2つのレグで各50,000PV以上の売上が必要です。
                </div>
                <div v-if="dash_d_val.RANK_NEXT == 'President' " class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在、あなたの職級は<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                  <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEXT }}</span>
                  を達成するためには、本人傘下全体の売上が500,000PV以上且つ直推薦傘下が3レグ必要であり、<br>
                  少なくとも2つのレグで各150,000PV以上の売上が必要です。
                </div>

                <div v-if="rank_name == '99'" class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                  現在のあなたの職級はPresidentです。<br>
                  最高職級の<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">President</span>達成おめでとうございます。
                </div>
              </div>
            </div><!--// -->
          </section>

          <h2 v-if="false" class="text-xl text-[] font-semibold mb-3">{{  i18n.myoffice.Commission.businessStatus }}</h2> <!--비즈니스 현황-->
          <!-- 그래프 -->
          <div v-if="false"  class="flex flex-row w-full mb-6">
            <div class="w-full border bg-white rounded-xl p-5">
              <div class="overflow-x-scroll w-full block full:scrollbar-hide full:overflow-hidden">
                <table class="border-separate border-spacing-2 table-auto w-full">
                  <thead>
                  <tr>
                    <th colspan="7" class="py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.performanceState }}</th><!--본인 실적 현황-->
                    <th colspan="6" class="py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.subordinateMembershipPerformance }}</th><!--직추천 1대 실적 현황-->
                  </tr>
                  <tr class="bg-[#172e59]">
                    <th class="p-2"></th>
                    <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.MyBusiness.amount}}</th>
                    <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>

                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amount}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountRefunded}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal}}</th>
                    <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                    <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</th>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_A1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_R1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_A1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_R1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV1 ?? 0,1) }}</td>

                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_A1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT_R1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R1 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT1 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV1 ?? 0,1) }}</td>
                  </tr>
                  <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</th>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT12 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT22 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV12 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV22 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV2 ?? 0,1) }}</td>

                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT12 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT22 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV12 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV22 ?? 0,1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT2 ?? 0,2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV2 ?? 0,1) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
                <a href="" class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
                    {{  i18n.myoffice.Commission.seeMoreOf }}
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path class="text-red-500" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                </a>
              </div>
            </div>
          </div><!-- //나와 -->

          <div class="flex-col w-full gap-5 mb-6 bg-white border rounded-xl p-5">
            <div class="w-full overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden block">
              <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                  <th colspan="4" class="text-center text-base py-2 bg-[#2e5db3] text-white">{{i18n.myoffice.MyBusiness.downlineMemberRankStatus}}</th><!--직 추천 하선정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Eligibility  }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.memberOfNumber}}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Eligibility  }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.memberOfNumber}}</th>

                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="text-center p-2 min-w-[100px]">Member</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_05 != '0' && dash_d_code.D_RANK_05 !=''" type="button" @click="getBonusLeglist('05',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_05 ?? "" }}</button></td>
                  <td class="text-center p-2 min-w-[100px]">Branch Manager</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_40 != '0' && dash_d_code.D_RANK_40 !=''" type="button" @click="getBonusLeglist('40',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_40 ?? "" }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="text-center p-2 min-w-[100px]">Bronze</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_10 != '0' && dash_d_code.D_RANK_10 !=''" type="button" @click="getBonusLeglist('10',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_10 ?? "" }}</button></td>
                  <td class="text-center p-2 min-w-[100px]">Director</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_50 != '0' && dash_d_code.D_RANK_50 !=''" type="button" @click="getBonusLeglist('50',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_50 ?? "" }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="text-center p-2 min-w-[100px]">Silver</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_20 != '0' && dash_d_code.D_RANK_20 !=''" type="button" @click="getBonusLeglist('20',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_20 ?? "" }}</button></td>
                  <td class="text-center p-2 min-w-[100px]">Vice President</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_60 != '0' && dash_d_code.D_RANK_60 !=''" type="button" @click="getBonusLeglist('60',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_60 ?? "" }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="text-center p-2 min-w-[100px]">Gold</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_30 != '0' && dash_d_code.D_RANK_30 !=''" type="button" @click="getBonusLeglist('30',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_30 ?? "" }}</button></td>
                  <td class="text-center p-2 min-w-[100px]">President</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button v-if="dash_d_code.D_RANK_70 != '0' && dash_d_code.D_RANK_70 !=''" type="button" @click="getBonusLeglist('70',0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.D_RANK_70 ?? "" }}</button></td>
                </tr>


                </tbody>
              </table>
            </div>

            <div  class="w-full pt-4 overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
              <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                  <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.statusOfJobClass }}</th> <!--나의 직급현황-->
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Commission.leg }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Commission.member }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Commission.totalByLeg }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{  i18n.myoffice.Rank }}</th>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">1</td>
                  <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N1 ? dash_d_code.LEG_N1: ""}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N1 ?? 0,0) }}</td>
                  <td class="text-center p-2 min-w-[100px]" rowspan="7">{{ dash_d_val.RANK_NEW ?? 0}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">2 </td>
                  <td class="text-center p-2 min-w-[100px]]">{{dash_d_code.LEG_N2 ?dash_d_code.LEG_N2: ""}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N2 ?? 0,0)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">3 </td>
                  <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N3 ?dash_d_code.LEG_N3: ""}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N3 ?? 0,0)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">4 </td>
                  <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N4 ?dash_d_code.LEG_N4: ""}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N4 ?? 0,0)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">5 </td>
                  <td class="text-center p-2 min-w-[100px]">{{dash_d_code.LEG_N5 ?dash_d_code.LEG_N5: ""}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_N5 ?? 0,0)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]" colspan="2">その他合算レグ</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_E ?? 0,0)}}</td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]" colspan="2">{{ i18n.myoffice.MyBusiness.totalPv}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.LEG_T ?? 0,0)}}</td>
                </tr>

                </tbody>
              </table>
            </div>

            <div v-if="false" class="w-full pt-4 overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
              <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                  <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">{{  i18n.myoffice.Commission.affiliatedSalesInformation }}</th> <!--산하 매출정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.monthly}}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.orderPV}}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.refundedPV}}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.totalPv }}</th>
                  <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.Commission.totalByLeg }}</th> <!--총 레그 수-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A1 ?? 0,2) }}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R1 ?? 0,2) }}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV1 ?? 0,1) }}</td>
                  <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500"><button type="button" @click="getLegList(0)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT1 ?? 0 }}</button></td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                  <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth}}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A2 ?? 0,2) }}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R2 ?? 0,2) }}</td>
                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV2 ?? 0,1) }}</td>
                  <td class="text-center p-2 min-w-[100px]"><button type="button" @click="getLegList(-1)" class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.D_LEG_CNT2 ?? 0 }}</button></td>
                </tr>
                <!--                <tr class="text-left">
                                  <td colspan="5" class="text-[#e1253f] p-2 text-sm">* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능</td>
                                </tr>-->
                </tbody>
              </table>
              <p class="text-[#e1253f] p-2 text-sm">{{  i18n.myoffice.Commission.message2 }}</p>
              <!--* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능-->

            </div>

            <!-- 모달 팝업 -->
            <div class="fixed w-full h-full bg-black/80 z-[10] top-0 left-0 " v-if="showModal"></div>
            <!-- 모달 팝업 콘텐츠 -->
            <div class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white p-5 w-full max-w-xl h-2/3 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll sm:overflow-hidden" v-if="showModal">
              <div class="text-right mb-2">
                <button class="close" @click="showModal = false">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block  fill-[#091223]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg>
                </button>
              </div>
              <div class="h-72">
                <h4 v-if='legs_type=="sales"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">{{  i18n.myoffice.Commission.numberOfFirstGenerationMembers }}</h4> <!-- 산하 1대 레그 별 회원 현황 -->
                <h4 v-if='legs_type=="bonus"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">{{  i18n.myoffice.Commission.salesUnderTheUmbrellaOfTheCurrentMonth }}</h4> <!-- 당월 산하매출 표기-->
                <div class="text-center mb-3 h-80 overflow-scroll tailwind-scrollbar-hide scrollbar-hide">

                  <!--Total amount of legs-->
                  <table class="w-full border-separate sm:overflow-hidden">
                    <thead>
                    <tr>
                      <th v-if='legs_type=="bonus"' class="bg-[#172e59] text-center text-white p-3" >{{  i18n.myoffice.Commission.stage }}</th>
                      <th class="bg-[#172e59] text-center text-white p-3 w-24">{{  i18n.myoffice.Commission.MemberID }}</th>
                      <th class="bg-[#172e59] text-center text-white p-3" >{{  i18n.myoffice.Commission.membershipName }}</th>
                      <th class="bg-[#172e59] text-center text-white p-3">{{  i18n.myoffice.Commission.theHighestRank }}</th><!--최고직급-->
                      <th v-if='legs_type=="bonus"' class="bg-[#172e59] text-center text-white p-3" >{{  i18n.myoffice.Commission.OrderPV }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="leg in legs" class="bg-[#f0f4ff] text-center cursor-pointer">
                      <td v-if='legs_type=="bonus"' class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.lv }}</router-link></td>
                      <td class="text-center p-2"> <router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.userid }}</router-link></td>
                      <td class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.username }}</router-link></td>
                      <td class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.rank_name_max }}</router-link></td>
                      <td v-if='legs_type=="bonus"' class="text-center p-2"><router-link  @click="goUrl(leg.sid);" class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{formatPrice( leg.r_pv_grp,1) }}</router-link></td>
                    </tr>
                    </tbody>
                  </table>




                </div>
                <p class="text-[#e1253f] text-sm break-words mb-2">{{  i18n.myoffice.Commission.message3 }}</p>
                <!--  *회원명을 클릭 하시면 해당 회원 기준의 레그 별 매출 집계를 확인하실 수 있습니다.-->
              </div>

            </div>
            <!--                        <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">-->
            <!--                            <v-pagination-->
            <!--                                v-model="page"-->
            <!--                                :pages=total_page-->
            <!--                                :range-size="1"-->
            <!--                                active-color="#DCEDFF">-->
            <!--                            </v-pagination>-->
            <!--                        </div>-->
            <!-- // 팝업 -->
          </div>
        </div><!--// 준비중 영역 박스 -->
      </article>
    </div>
  </div>
  <!-- 메인페이지 진입시 수당 여부 팝업 -->
  <!--    <modalView v-if="isUfPayModal" @close-modal="isUfPayModal = false" class="relative">-->
  <!--        <IsUfPay v-if="isUfPayModal" @close-modal="isUfPayModal = false" class="z-[999] bg-white min-w-[360px] max-w-[650px] m-auto mt-[50px] p-[20px] lg:min-h-[450px] min-h-[650px] max-h-[700px] rounded-2xl"></IsUfPay>-->
  <!--    </modalView>-->

</template>

<script>

import numeral from "numeral";
import TabItem from './TabItem.vue'
import VueApexCharts from 'vue3-apexcharts'
import 'floating-vue/dist/style.css';
import Loading from 'vue3-loading-overlay';
import VPagination from "@hennge/vue3-pagination";
import modalView from "../modalView";
import IsUfPay from "./IsUfPay";

export default {
  name: "MyofficeMain",
  components : {
    apexcharts: VueApexCharts,
    Loading,
    modalView,
    VPagination,
    IsUfPay
  },
  data: () => ({
    loading : false,
    isCommissionBlur : false,
    today : window.Laravel.today,
    showModal: false,
    isUfPayModal: false,
    title : "",
    notice_no : "",
    board_title : "",
    username : "",
    bb_username : "",
    rank_name : "",
    act_yn : "",
    user_kinds : "",
    rank_max_cd : "",
    rank_max_name : "",
    rank_old_name : "",
    rank_cd : "",
    cnt_name : "",
    e_mail : "",
    mobile : "",
    week4Data : [],
    weekATotal : "",
    weekBTotal : "",
    local_edate : "",
    uji_date : "",
    global_edate : "",
    ujiG_date : "",
    autoship_admits_cnt : "",
    autoship_admits_day : "",
    favorite_total_count : "",
    pay_date : "",
    total_amt : "",
    boardlist1 : [],
    boardlist2 : [],
    boardlist3 : [],
    boardlist4 : [],
    Tablist: [
      { id: 1, label: i18n.menu.MyNotice, content: 'board-list1', href:'MyNotice' },
      { id: 2, label: i18n.menu.MyOrderList, content: 'board-list2', href:'MyOrderList' },
      { id: 3, label: i18n.menu.MyInQuriy, content: 'board-list3', href:'MyInQuriy' },
      { id: 4, label: i18n.menu.MyData, content: 'board-list4', href:'MyData' }

    ],
    currentId: 1,
    working_nation : working_nation,
    i18n: window.i18n,
    userid : window.Laravel.user,
    Autoship : false,
    view_pay : true,
    myconfig : "",
    series: [0],
    chartOptions: {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          }
        },
      },
      labels: ['Achievement Rate'],
    },
    series2: [10, 20, 30, 40],
    chartOptions2: {
      chart: {
        type: 'donut',
        width: '100%',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },

    cs_omni_list : {ord_amt : 0,cs_amt : 0, o_rate : 0,ord_mon : ""},
    mem_stamp_h_user : {pv_now: 0 , pv_ready:0},
    dash_info : [],

    /** S : getDashBoard*/
    dash_d_val : [],
    dash_d_code : [],
    dash_d_name : [],

    //My Rank Information
    bm1 : 0,
    bm2 : 0,
    bm3 : 0,
    bm_total : 0,
    g1 : 0,
    g2 : 0,
    g3 : 0,
    g_total : 0,
    d1 : 0,
    d2 : 0,
    d3 : 0,
    d_total : 0,
    vp1 : 0,
    vp2 : 0,
    vp3 : 0,
    vp_total : 0,
    p1 : 0,
    p2 : 0,
    p3 : 0,
    p_total : 0,
    rank_need : "",
    rank_need1 : 0,
    rank_need2 : 0,

    legs : [],
    legs_type : "", //sales , bonus
    /** E : getDashBoard*/

    /*    chartOptions: {
          chart: {
            id: 'basic-bar'
          },
          xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
          }
        },
        series: [{
          name: 'series-1',
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }]*/
  }),
  created() {

    // 0401 수당 정산이 완료되기 전까지 blur 처리
    if ( Number(this.today) >= 20240301 ){
      this.isCommissionBlur = true
    }

    //this.loading = true;
    axios.post(window.Laravel.back_url+'/api/memberInfo',{
    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
      // 수당 발생여부 팝업
      if(response.data.isUfPay === 'N') {
        this.isUfPayModal = true;
      }

      this.username = response.data.username;
      if(response.data.bb_name != "" && response.data.bb_name != null){
        this.bb_username = " / "+response.data.bb_name;
      }
      this.rank_name = response.data.rank_name;
      this.cnt_name = response.data.cnt_name;
      this.e_mail = response.data.e_mail;
      this.mobile = response.data.mobile;
      this.user_kinds = response.data.user_kinds;
      this.rank_max_cd = response.data.rank_max_cd;
      this.rank_max_name = response.data.rank_max_name;
      this.rank_cd = response.data.rank_cd;
    });

    this.loading = false;
  },
  mounted() {
    //this.loading = true;
    axios.post(window.Laravel.back_url+'/api/getDashBoard',{
    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
      response.data.forEach((value, index) => {

        this.dash_d_val[value.d_col] = value.d_val??"";
        this.dash_d_name[value.d_col] = value.d_name??"";
        this.dash_d_code[value.d_col] = value.d_code??"";

        if(value.d_col == "ACT_YN"){
          this.act_yn = value.d_code;
        }
        /* 해당월, 전월, 전전월 구분은 D_CODE 컬럼을 이용하여 구분함
          따라서, D_COL 컬럼과 D_CODE 컬럼을 합처서 변수명으로 사용하여 월을 구분함
        */
        if(value.d_col == "LV_1" || value.d_col == "LV_2"){
          this.dash_d_val[value.d_col + value.d_name] = value.d_val;
          this.dash_d_code[value.d_col + value.d_name] = value.d_code;
        }
        /*주문합계*/
        if(
          value.d_col == "ORD_AMT_A" || value.d_col == "ORD_AMT_R" || value.d_col == "ORD_AMT"
          || value.d_col == "ORD_PV_A" || value.d_col == "ORD_PV_R" || value.d_col == "ORD_PV"
          || value.d_col == "R_ORD_AMT_A" || value.d_col == "R_ORD_AMT_R"  || value.d_col == "R_ORD_AMT"
          || value.d_col == "R_ORD_PV_A" || value.d_col == "R_ORD_PV_R" || value.d_col == "R_ORD_PV"

        ){
          this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;

        }
        if(value.d_col == "D_ORD_PV_A" || value.d_col == "D_ORD_PV_R" || value.d_col == "D_ORD_PV" || value.d_col == "D_LEG_CNT"){
          this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
        }
        //산하 PV
        if(value.d_col == "LEG_N"){
          this.dash_d_code[value.d_col + value.d_code] = value.d_name;
          this.dash_d_val[value.d_col + value.d_code] = value.d_val??0;
        }

        if(value.d_col == "RANK_NEED"){
          this.rank_need = value.d_name; //달성인원직급
          this.rank_need1 = value.d_code; //필요회원수
          this.rank_need2 = value.d_val; //달성인원수
        }
        if(value.d_col == "RANK_OLD"){
          this.rank_old_name = value.d_val; //달성인원수
        }


        if(value.d_col == "D_RANK_30"){//GOLD
          this.g1 = value.d_name; //나의 하선 당월
          this.g2 = value.d_code; //나의 하선 전월
          this.g3 = value.d_val; //나의 하선 전전월
          this.g_total =  parseInt(this.g1) + parseInt(this.g2) + parseInt(this.g3);
        }
        if(value.d_col == "D_RANK_40"){//BRANCH MANAGER
          this.bm1 = value.d_name; //나의 하선 당월
          this.bm2 = value.d_code; //나의 하선 전월
          this.bm3 = value.d_val; //나의 하선 전전월
          this.bm_total =  parseInt(this.bm1) + parseInt(this.bm2) + parseInt(this.bm3);
        }
        if(value.d_col == "D_RANK_50"){//DIRECTOR
          this.d1 = value.d_name; //나의 하선 당월
          this.d2 = value.d_code; //나의 하선 전월
          this.d3 = value.d_val; //나의 하선 전전월
          this.d_total = parseInt(this.d1) + parseInt(this.d2) + parseInt(this.d3);
        }

        if(value.d_col == "D_RANK_60"){//VICE PRESIDENT
          this.vp1 = value.d_name; //나의 하선 당월
          this.vp2 = value.d_code; //나의 하선 전월
          this.vp3 = value.d_val; //나의 하선 전전월
          this.vp_total = parseInt(this.vp1) + parseInt(this.vp2) + parseInt(this.vp3);
        }

        if(value.d_col == "D_RANK_70"){//PRESIDENT
          this.p1 = value.d_name; //나의 하선 당월
          this.p2 = value.d_code; //나의 하선 전월
          this.p3 = value.d_val; //나의 하선 전전월
          this.p_total = parseInt(this.p1) + parseInt(this.p2) + parseInt(this.p3);
        }

      });

    }),

      axios.post(window.Laravel.back_url+'/api/dashboard',{
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.cs_omni_list = response.data.cs_omni_list;
        this.cs_omni_list.ord_mon = this.cs_omni_list.ord_mon.substring(4,6)+"/"+this.cs_omni_list.ord_mon.substring(0,4)
        this.series[0] = this.cs_omni_list.o_rate;
        this.mem_stamp_h_user = response.data.mem_stamp_h_user;
      });
    // axios.post(window.Laravel.back_url+'/api/getMyofficeMainAutoShipStatus',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.autoship_admits_cnt = response.data.autoship_admits_cnt == 1 ? i18n.myoffice.asMstStatus.b : i18n.myoffice.asMstStatus.f ;
    //   this.autoship_admits_day = response.data.autoship_admits_day;
    //
    // });

    // axios.post(window.Laravel.back_url+'/api/favoriteCount',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.favorite_total_count = response.data.length ;
    //
    // });
    // axios.post(window.Laravel.back_url+'/api/chart1',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.drawChart(response.data);
    // });
    // axios.post(window.Laravel.back_url+'/api/chart2',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.drawChart2(response.data);
    // });
    // axios.post(window.Laravel.back_url+'/api/getMainBoard1',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.mainBoard1(response.data);
    // });
    // axios.post(window.Laravel.back_url+'/api/getMainBoard2',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.mainBoard2(response.data);
    // });
    // axios.post(window.Laravel.back_url+'/api/getMainBoard3',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.mainBoard3(response.data);
    // });
    // axios.post(window.Laravel.back_url+'/api/getMainBoard4',{
    // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
    //   this.mainBoard4(response.data);
    // });
    // axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
    //   this.myconfig = response.data;
    //
    // });
    this.loading = false;
  },
  methods:{
    goUrl(sid){
      location.href='/MyOrderLegs/'+sid;
    },
    getLegList(month){//본인 산하 직추천 명단
      this.showModal = true;
      this.legs_type = "sales";
      this.legs = [];
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getLeglist',{month : month,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.legs = response.data;

      });
      this.loading = false;
    },
    getBonusLeglist(rank,type){//본인 산하 직추천 명단
      this.showModal = true;
      this.legs_type = "bonus";
      this.legs = [];
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getBonusLeglist',{rank : rank, type : type,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.legs = response.data;
      });
      this.loading = false;
    },
    get4WeekData($list) {
      var i = 3 ;
      for( i ; i >= 0 ; i--){
        if( i == 3){
          var a_week_tot_data = $list.A_WEEK_TOT4.d_val;
          var b_week_tot_data = $list.B_WEEK_TOT4.d_val;
          var d_remark_data = $list.A_WEEK_TOT4.d_remark;
        }
        if( i == 2){
          var a_week_tot_data = $list.A_WEEK_TOT3.d_val;
          var b_week_tot_data = $list.B_WEEK_TOT3.d_val;
          var d_remark_data = $list.A_WEEK_TOT3.d_remark;
        }
        if( i == 1){
          var a_week_tot_data = $list.A_WEEK_TOT2.d_val;
          var b_week_tot_data = $list.B_WEEK_TOT2.d_val;
          var d_remark_data = $list.A_WEEK_TOT2.d_remark;
        }
        if( i == 0){
          var a_week_tot_data = $list.A_WEEK_TOT1.d_val;
          var b_week_tot_data = $list.B_WEEK_TOT1.d_val;
          var d_remark_data = $list.A_WEEK_TOT1.d_remark;
        }
        this.week4Data.push({
          pay_date : $list.PAY_DATE.d_code - i ,
          remark : d_remark_data ,
          a_week_tot : a_week_tot_data ,
          b_week_tot : b_week_tot_data ,

        });
      }

      this.weekATotal = $list.A_WEEK_TOT.d_val;
      this.weekBTotal = $list.B_WEEK_TOT.d_val;

    },
    drawChart($list) {
      var chartwidth = $('#piechart').width();
      if($list)
      {

        var chartchl = $list.chartchl.split("|");
        var ctx = document.getElementById('myChart2').getContext('2d');

        var cartchd = $list.chartchd;
        var txtArray = new Array();
        cartchd = cartchd.split(",");
        for(var i = 0 ; i < cartchd.length; i++){
          var subArray = new Array(cartchd[i]);
          txtArray.push(cartchd[i]);

        }

        var myChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: chartchl,
            datasets: [{
              label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
              data: txtArray,
              backgroundColor: [
                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
              ],
              borderColor: [
                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
              ],
              borderWidth: 1
            }]
          },

          options: {
            title: {
              display: true,
              text: ''
            },
            maintainAspectRatio: false,
          }
        });

      }
    },
    drawChart2($list) {
      var chartwidth = $('#piechart').width();
      if($list)
      {

        var chartchl = new Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
          i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

        var ctx = document.getElementById('myChart1').getContext('2d');

        var cartchd = $list.chartchd;
        var txtArray = new Array();
        cartchd = cartchd.split(",");
        for(var i = 0 ; i < cartchd.length; i++){
          var subArray = new Array(cartchd[i]);
          txtArray.push(cartchd[i]);

        }
        var labels  = Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
          i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec);

        var myChart = new Chart(ctx, {
          type: 'horizontalBar',
          data: {
            labels: Array(i18n.myoffice.jan,i18n.myoffice.feb,i18n.myoffice.mar,i18n.myoffice.apr,i18n.myoffice.may,i18n.myoffice.jun,
              i18n.myoffice.jul,i18n.myoffice.aug,i18n.myoffice.sep,i18n.myoffice.oct,i18n.myoffice.nov,i18n.myoffice.dec),
            datasets: [{
              label: i18n.myoffice.Commission.BonusRate+'(%)',  /* 수당비율 */
              data: txtArray,
              backgroundColor: [
                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
              ],
              borderColor: [
                '#3366CC','#DC3912','#FF9900','#109618','#990099','#0099C6','#979896','#4698c0','#ee98c0','#eee237','#ee9837','#29f0d6'
              ],
              borderWidth: 1
            }]
          },
          options: {
            title: {
              display: true,
              text: i18n.myoffice.dashboard.yearmonthbonus /* 년 월별 수당금액 */
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  userCallback: function(value, index, values) {
                    if(parseInt(value) >= 1000){
                      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    return value;
                  }
                }
              }]
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  var value = $list.data[tooltipItem.index];
                  if(parseInt(value) >= 1000){
                    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                  return value;
                }
              } // end callbacks:
            },
          },
        });

      }
    },
    mainBoard1($list){
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        this.boardlist1.push({
          no: value.no,
          title: value.title,
          date: value.reg_date,
          url: "MyNotice/"+value.no,

        });
      });
    },
    mainBoard2($list){
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        this.boardlist2.push({
          ord_no: value.ord_no,
          title: value.title,
          date: value.reg_date,
          ord_date : value.ord_date,
          ord_amt : value.ord_amt,
          ord_pv : value.ord_pv,
          kind_name : value.kind_name,
          cnt_name : value.cnt_name,
          deli_finish : value.deli_finish,
          ord_kind_name : value.ord_kind_name,
          //url: value.i_list ? "",

        });
      });
    },
    mainBoard3($list){
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        this.boardlist3.push({
          flag: value.flag,
          title: value.title,
          reg_date: value.reg_date,
          reply_cnt: value.reply_cnt,
          reply_reg_date: value.reply.reg_date,
          reply_user_name: value.reply.username,
          url: "MyInQuiryView/"+value.no,

        });
      });
    },
    mainBoard4($list){
      $list.forEach((value, index) => {
        numeral.defaultFormat('$0,0[.]00');
        // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
        this.boardlist4.push({
          notice_yn : value.notice_yn == 'Y' ? "<span class='notice'>Notice</span>" : "",
          attach_cnt : value.attach_cnt ? "<i class=\"fas fa-file-text-o attach\"></i>" : "",
          no: value.no,
          title: value.title,
          date: value.reg_date,
          url: "MyData/"+value.no,

        });
      });
    },
    tabChange(id){
      this.Tablist.id;
      this.currentId = id;
    },
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    goAutoLogin(mode, url) {
      window.open("https://register-global.gcoop.com/AutoLogin?xid=" + window.Laravel.xid_info + "&token=" + window.Laravel.xid_info);

      // let config = {
      //     headers: {
      //         Authorization: 'Bearer ' + window.Laravel.access_token
      //     }
      // };
      //
      // axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
      //     mode: mode,
      //     url: url,
      //     bz_cd: window.Laravel.bz_cd,
      // },config).then(response => {
      //     if(response.data.href_url != ""){
      //         window.open(response.data.href_url);
      //     }
      // }).catch(e => {
      // });
    },
    isUfPayModalClose() {
      this.isUfPayModal = false;
    }

  },
}

</script>

<style scoped>
.board-inner{background:#f0f2f4 !important;}

/*.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}*/

.w-1-7{width:14.28%;}
.break-keep{word-break: keep-all;}

</style>
