<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
      <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="this.nt_cd" />
    </modalView>

    <div id="mem_info" class="myo-modi-mem">

        <section class="accordion_notice_box">
            <div class="accordion_notice_item">
                <a class="accordion_notice_tit" href="#n">
                    <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                    {{ i18n.myoffice.myoSearchForm.notice }}
                    <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>
                </a>
                <div class="accordion_notice_cont">
                    <div class="myo_search_notice">
                        <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.join.join.required }}</span></p>
                    </div>
                </div>
            </div>
        </section>

        <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
            <input type=hidden name=exec_mode value='modify'>

            <!--회원정보 시작-->
            <h2 class="myo_table_tit">{{ i18n.myoffice.MemberInfomation }}</h2> <!--회원정보 -->
            <ul id="myo_form" class="mgb_30">
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.memberName }}</span>
                    <span class="item_content">{{ mem.username }}</span>
                </li><!-- 회원명-->

                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.MemberId }}</span>
                    <span class="item_content">{{ mem.userid }}</span>
                </li><!-- 회원아이디-->

                <li class="item table_form">
                    <span class="item_title title_4">{{ i18n.myoffice.birthday }}</span>
                    <span class="item_content m_contents">{{ mem.birthday2 }}</span>
                </li>

                <li class="item table_form" v-if="false">
                    <span class="item_title title_4">{{ i18n.myoffice.tel }}</span>
                    <span class="item_content m_contents">
                      <input name="tel" id="tel" v-model="tel" class="type_tel tel" style="ime-mode:disabled;" type="text">
                    </span>
                </li>

                <li class="item table_form position-box">
                    <span class="item_title title_4_2"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.handphone }}</span>
                    <span class="item_content m_contents">
                        <input type="text" v-model="mobile" id="mobile" class="" maxlength="11">
                        <span class="bk_submit_btn btn_mobileChk btn-hide" style="display:inline-block">
                          <input type="button" class="btn smschBtn osMem_btn ml-2" id="mobileCheck" v-on:click="checkMobile"  value="重複確認"/><br>
                        </span>
                    <div id="mobileCheckTxt" class="mgt_8 mgb_8"></div>
                        <input type="hidden" id="mobileCheckYn" name="mobileCheckYn" v-model = "mobileCheckYn"/>
                        <input type="hidden" id="vertifyMobile" name="vertifyMobile" >
                    </span>
                </li>

              <li class="item table_form position-box">
                        <span class="item_title title_4_2"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.email }}</span>
                        <span class="item_content m_contents">
                          <span class="">
                            <input name="e_mail" id="e_mail" class="type_email" v-model="e_mail" style="ime-mode:disabled;" type="text">
                          </span>
                          <span class="bk_submit_btn btn_emailChk">
                            <input type="button" class="btn smschBtn osMem_btn ml-2" id="emailCheck" v-on:click="checkEmail" value="重複確認"  >
                          </span>
                          <input type="hidden" id="emailCheckYn" name="emailCheckYn" v-model = "emailCheckYn"/>

                          <div id="emailCheckTxt" class="mgt_8 mgb_8"></div>

                          <span class="myo-checkbox">
                            <input id="rec_mail" name="rec_mail" style="border:0px; padding:0; line-height:0; height:15px;" v-model="rec_mail" type="checkbox">
                            <label for="rec_mail" name="rec_mail" @click="rec_mail_check">
                              <i class="far fa-check-square checked" aria-hidden="true"></i>
                              <span class="gray01">{{ i18n.myoffice.E_mailReception}}</span><!-- 이메일 수신합니다. -->
                            </label>
                          </span>
                      </span>
               </li>
                <li class="item table_form">
                    <span class="item_title"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.Password }}</span>
                    <span class="item_content m_contents">
                      <div class="flex xl:flex-row lg:flex-row flex-col">
                        <input type='password'  v-if="!show" v-model="pass1" class="w-3/5" autocomplete="new-password">
                        <input type='text' v-if="show" v-model="pass1" class="w-3/5" autocomplete="new-password">
                        <span class="bk_submit_btn w-2/5">
                            <input type="button" v-if='!show' @click="showPwd('show')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="見せる"/>
                            <input type="button" v-if='show' @click="showPwd('show')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="見せる"/>
                        </span>
                        </div>
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.Passwordcheck }}</span>
                    <span class="item_content m_contents">
                        <input type='password' v-if="!show_confirm" class="w-3/5" v-model="pass2" autocomplete="off">
                        <input type='text' v-if="show_confirm"  class="w-3/5" v-model="pass2" autocomplete="off">
                         <span class="bk_submit_btn w-2/5">
                           <input type="button" v-if='!show_confirm' @click="showPwd('show_confirm')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="見せる"/>
                           <input type="button" v-if='show_confirm' @click="showPwd('show_confirm')"  class="btn smschBtn osMem_btn relative z-1 ml-2 " value="見せる"/>
                        </span>
                    </span>
                </li>
                <li class="item table_form relative z-1 border-b-0 bg-white">
                  <span class="item_title title_5 !border-b-0">{{ i18n.myoffice.Form.address }}</span>
                  <div class="w-full">
                    <span class="item_content m_contents">
                      <div class="flex xl:flex-row lg:flex-row flex-col">
                          <input name="post" id="post" class="type_number relative z-1" @click="search_address" v-model="post" maxlength='5' type="text" readonly>
  <!--                        <input type="button" @click="search_address" value="search address" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-40 cursor-pointer">-->
                          <span class="bk_submit_btn">
                            <input type="button" @click="search_address"  class="btn smschBtn osMem_btn relative z-1 ml-2" value="郵便番号検索"/><br>
                          </span>
                      </div>
                    </span>
                  </div>
                  <div class="flex xl:flex-row lg:flex-row flex-col">
                   <span class="item_content m_contents max-w-none w-full">
                     <div class="flex xl:flex-row lg:flex-row flex-col w-full">
                      <input name="addr1" id="addr1" class="!max-w-[350px] add-input1" v-model="addr1" type="text">
                    </div>
                   </span>
                  </div>
                  <div class="flex xl:flex-row lg:flex-row flex-col ">
                   <span class="item_content m_contents max-w-none w-full">
                     <div class="flex xl:flex-row lg:flex-row flex-col w-full">
                      <input name="addr2" id="addr2" class="!max-w-[350px] add-input1" v-model="addr2" type="text">
                    </div>
                     <div class="flex xl:flex-row lg:flex-row flex-col " v-show="is_enrol_autoship">
                        <span class="myo-checkbox">
                          <input id="flag_autoship" name="flag_autoship" style="border:0px; padding:0; line-height:0; height:15px;" v-model="flag_autoship" type="checkbox">
                          <label for="flag_autoship" name="flag_autoship" @click="flag_autoship_check">
                            <i class="far fa-check-square checked" aria-hidden="true"></i>
                            <span class="gray01">定期購入の配送先も上記住所に変更する</span><!-- 정기구매 배송지도 상기 주소로 변경함 -->
                                </label>
                            </span>
                     </div>
                   </span>
                  </div>
                </li>
                <li class="item table_form position-box">
                  <span class="item_title title_4_2"><span class="signiture remark1">&#42;</span>{{ i18n.myoffice.registerNoYn }}</span>
                  <span class="item_content m_contents">
                            <!--인풋 라디오 샘플-->
                            <div class="myo-mem-check">
                                <span class="radio-box">
                                    <input type="radio" id="jp-myflag1" v-model="flag_register_no"  value="Y" onclick="" :checked="flag_register_no == 'Y'" >
                                    <label for="jp-myflag1" class="">
                                        <i class="far fa-dot-circle"></i>
                                        {{ i18n.myoffice.registerNoY}}
                                    </label>
                                </span><!--허용-->
                                <span class="radio-box">
                                    <input type="radio" id="jp-myflag2" v-model="flag_register_no"  value="N" :checked="flag_register_no == 'N'" >
                                    <label for="jp-myflag2" class="">
                                        <i class="far fa-dot-circle"></i>
                                        {{ i18n.myoffice.registerNoN}}
                                    </label>
                                </span><!--차단-->
                            </div>
                            <span v-show="flag_register_no == 'Y'">
                              <span class="text-xl font-bold">
                                T <input name="regster_no" id="regster_no" class="ml-2" v-model="register_no" style="ime-mode:disabled;" type="text" maxlength="13" >
                              </span>
                              <span class="bk_submit_btn btn_registerNoChk">
                                <input type="button" class="btn smschBtn osMem_btn ml-2" id="registerNoCheck" v-on:click="checkRegisterNo" value="重複確認"  >
                              </span>
                              <input type="hidden" id="registerNoCheckYn" name="registerNoCheckYn" v-model ="registerNoCheckYn"/>
                              <div id="registerNoCheckTxt" class="mgt_8 mgb_8"></div>

                              <span class="myo-checkbox">
                                  <span class="gray01 text-left text-xs text-gray-400">
                                    *登録番号とは、適格請求書発行事業者の登録を受けようとする事業者が、納税地を所轄する税務署長に<br>
                                    「適格請求書発行事業者の登録申請書」を提出し、税務署長の登録を受けた場合に事業者に通知される番号<br>
                                     です。また、登録番号は事業者へ通知されます。<br>
                                     登録番号の構成は、次のとおりです<br>
                                     1. 法人番号を有する課税事業者<br>
                                     「T」（ローマ字）　＋　法人番号（数字13桁）<br>
                                     2. 上記以外の課税事業者（個人事業者、人格のない社団等）<br>
                                     「T」（ローマ字）　＋　数字13桁（注）<br><br>
                                   （注）13桁の数字には、マイナンバー（個人番号）は用いず、法人番号とも重複しない事業者ごとの番号になります。

                                  </span>
                              </span>
                        </span>
                  </span>
                </li>
            </ul>
            <!-- 회원정보 끝 -->

            <!-- 비즈니스 정보 시작 -->
            <h2 class="myo_table_tit" v-show="mem.rank_cd != '00'">{{ i18n.myoffice.BusinessInfomation }}</h2> <!--비즈니스 정보-->
            <ul id="myo_form" v-show="mem.rank_cd != '00'">
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.myoffice.RegistrationDate }}</span>
                    <span class="item_content">{{ mem.reg_date2 }}</span>
                </li>
                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.Rank }}</span>
                    <span class="item_content">{{ mem.rank_name }}</span>
                </li>
                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.center }}</span>
                    <span class="item_content">{{ mem.cnt_name }}</span>
                </li>
                <li class="item table_form">
                    <span class="item_title">{{ i18n.myoffice.EnrollmentSponsor }}</span>
                    <span class="item_content info_content">{{(mem.r_id == '00000001' ? __("myoffice.company") : mem.r_names)}}</span> <!-- 회사 -->
                </li>


                <li style="display: none !important;" class="item table_form hidden">
                    <span class="item_title item_etc">{{ i18n.myoffice.setUp }}</span>

                    <span class="item_content m_contents">
                        <p class="m_config">{{ i18n.myoffice.LookallowuplineList }}</p> <!-- 업라인에게 본인의 4주실적 보이기 -->

                        <div class="myo-mem-check">
                            <span class="radio-box">
                                <input type="radio" id="jp-flag1" v-model="flag_4week" value="Y" onclick="" :checked="myconfig.flag_4week == 'Y'" >
                                <label for="jp-flag1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.allow }}
                                </label>
                            </span>
                            <span class="radio-box">
                                <input type="radio" id="jp-flag2" v-model="flag_4week" value="N" onclick="" :checked="myconfig.flag_4week == 'N'" >
                                <label for="jp-flag2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.block }}
                                </label>
                            </span><!--차단-->
                        </div>

                        <p class="m_config">{{ i18n.myoffice.LookmyofficeList }}</p><!-- 마이오피스 실적 보기 (본인) -->

                        <!--인풋 라디오 샘플-->
                        <div class="myo-mem-check hidden">
                            <span class="radio-box">
                                <input type="radio" id="jp-myflag1" v-model="flag_profit" value="Y" onclick="" :checked="myconfig.flag_profit == 'Y'" >
                                <label for="jp-myflag1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.allow }}
                                </label>
                            </span><!--허용-->
                            <span class="radio-box">
                                <input type="radio" id="jp-myflag2" v-model="flag_profit" value="N" onclick=""  :checked="myconfig.flag_profit == 'N'" >
                                <label for="jp-myflag2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    {{ i18n.myoffice.MyMember.block }}
                                </label>
                            </span><!--차단-->
                        </div>
                    </span>
                </li>
            </ul>

            <h2 class="myo_table_tit" v-if="false">{{ i18n.myoffice.AccountInfomation }}</h2>
            <ul id="myo_form" v-if="false">
                <li class="item table_form top-li">
                    <span class="item_title">Account Holder</span>
                    <span class="item_content m_contents">
                        <input type='text' v-model="depositor" :readonly="readonly == true"  >
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">Name of Financial Institution</span>
                    <span class="item_content m_contents">
                        <!--인풋 라디오 샘플-->
                        <vSelect :options="bank_key_list" label="bank_name" :reduce="value => value.bank_cd" v-model="bank" :clearable="false" ></vSelect>

                        <div class="myo-mem-check">
                            <span class="radio-box">
                                <input type="radio" id="us-account1" v-model="accInfoCk" value="C" onclick="" :checked="mem.acc_type == 'C'" >
                                <label for="us-account1" class="">
                                    <i class="far fa-dot-circle"></i>
                                    Checking Account
                                </label>
                            </span><!--허용-->
                            <span class="radio-box">
                                <input type="radio" id="us-account2" v-model="accInfoCk" value="S" onclick="" :checked="mem.acc_type == 'S'" >
                                <label for="us-account2" class="">
                                    <i class="far fa-dot-circle"></i>
                                    Savings Account
                                </label>
                            </span><!--차단-->
                        </div>
                    </span>
                </li>

                <li class="item table_form">
                    <span class="item_title">Bank account No</span>
                    <span class="item_content m_contents">
                        <input type="text" v-model="account" class="type_number" :readonly="doc_void.check_yn == 'Y'">
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">Routing No</span>
                    <span class="item_content m_contents">
                        <input type="text" v-model="routingNo" class="type_number" maxlength='20' :readonly="doc_void.check_yn == 'Y'">
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">W-9 Upload</span>
                    <span class="item_content m_contents">
                        <div v-if="doc_w9.check_yn == 'Y'">
							{{ doc_w9.ori_file_name }}&nbsp;&nbsp;
							<a :href="doc_w9.url">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="doc_w9.file_name">
                                {{ doc_w9.ori_file_name }}&nbsp;&nbsp;
                                <a :href="doc_w9.url">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="W9File" id="W9File">
                            </div>
                        </div>
                    </span>
                </li>
                <li class="item table_form">
                    <span class="item_title">Void Check Upload</span>
                    <span class="item_content m_contents">
                        <div v-if="doc_void.check_yn == 'Y'">
							{{ doc_void.ori_file_name }}&nbsp;&nbsp;
							<a :href="doc_void.url" >[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="doc_void.file_name">
                                {{ doc_void.ori_file_name }}&nbsp;&nbsp;
                                <a :href="doc_void.url" >[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="VOIDFile" id="VOIDFile">
                            </div>
                        </div>
				    </span>
                </li>

                <li class="item table_form" v-if="mem.sa_kind == '1' || mem.sa_kind == '2'">
                    <span class="item_title">Resale Certificate</span>
                    <span class="item_content m_contents">
                        <div v-if="mem.rc_yn == 'Y'">
							{{ doc_rc.ori_file_name }}&nbsp;&nbsp;
							<a href="https://cdn3.gcooperp.com/{{ doc_rc.file_path }}/{{ doc_rc.file_name }}">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="mem.rc_yn == 'Y'">
                                {{ doc_rc.ori_file_name }}&nbsp;&nbsp;
                                <a href="https://cdn3.gcooperp.com/{{ doc_rc.file_path }}/{{ doc_rc.file_name }}">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="RCFile" id="RCFile">
                            </div>
                        </div>
				    </span>
                </li>
                <li class="item table_form" v-if="mem.sa_kind == '1' || mem.sa_kind == '2'">
                    <span class="item_title">Sales Tax License</span>
                    <span class="item_content m_contents">
                        <div v-if="mem.st_yn == 'Y'">
							{{ doc_st.ori_file_name }}&nbsp;&nbsp;
							<a href="https://cdn3.gcooperp.com/{{ doc_st.file_path }}/{{ doc_st.file_name }}">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="mem.st_yn == 'Y'">
                                {{ doc_st.ori_file_name }}&nbsp;&nbsp;
                                <a href="https://cdn3.gcooperp.com/{{ doc_st.file_path }}/{{ doc_st.file_name }}">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="STFile" id="STFile">
                            </div>
                        </div>
    				</span>
                </li>
                <li class="item table_form" v-if="mem.sa_kind == '1' || mem.sa_kind == '2'">
                    <span class="item_title">Business Entity Addendum<br>(Signed by all participants)</span>
                    <span class="item_content m_contents">
                        <div v-if="mem.ls_yn == 'Y'">
							{{ doc_ls.ori_file_name }}&nbsp;&nbsp;
							<a href="https://cdn3.gcooperp.com/{{ doc_ls.file_path }}/{{ doc_ls.file_name }}">[View]</a>
                        </div>
                        <div v-else>
                            <div v-if="mem.ls_yn == 'Y'">
                                {{ doc_ls.ori_file_name }}&nbsp;&nbsp;
                                <a href="https://cdn3.gcooperp.com/{{ doc_ls.file_path }}/{{ doc_ls.file_name }}">[View]</a>
                            </div>

							<div style="width: 25%; margin-top: 10px">
                                <input type="file" name="LSFile" id="LSFile">
                            </div>
                        </div>

                    </span>
                </li>
            </ul>
            <div class="myo_condition_search2">
                <button type="button" onclick="location.replace('/MyPersonInfo');"  class="reset_btn">{{ i18n.myoffice.Goback }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.save }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { createPopper } from '@popperjs/core'
import alert from "vue3-simple-alert";
import modalView from "../../modalView";

export default {
    name: "MyPersonInfoModify",
    components : {
        vSelect,
        VueSimpleAlert,
        modalView,
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        mem: "",
        myconfig : "",
        flag_4week : "",
        flag_profit : "",
        flag_register_no : "N",
        flag_autoship : false,
        is_enrol_autoship : false,
        tel : "",
        mobile : "",
        mobile_prev : "",
        register_no_prev : "",
        e_mail : "",
        e_mail_prev : "",
        pass1 : "",
        pass2 : "",
        post : "",
        addr1 : "",
        addr2 : "",
        selectState : "",
        register_no : "",
        depositor : "",
        bank : "",
        bank_key_list : "",
        accInfoCk : "",
        account : "",
        routingNo : "",
        acc_type: "",
        doc_void : "",
        doc_w9 : "",
        mobileCheckYn : "N",
        emailCheckYn : "N",
        registerNoCheckYn : "N",
        selectCity : "",
        loading : false,
        fullPage : true,
        addr_list : "",
        nt_cd : "",
        pin_type : "",
        pin_type_list : [{ title : "" , value : "Select"},{title :"S" , value :"SSN"},{title :"I" , value :"ITIN" }],
        jumin_1 : "",
        jumin_2 : "",
        jumin_3 : "",
        rec_mail : false,

        accountCheck : {"check_yn" : "N","memo" : "","file_name" : ""},
        address_search_open:false,
        isMatchStateCity:false,

        show : false,
        show_confirm : false,

    }),
    beforeCreate() {

        axios.post(window.Laravel.back_url + '/api/getBankList',{ bz_cd : window.Laravel.bz_cd }).then(response => {
            this.bank_key_list = response.data;

        });

        axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            if(response.data.doc_void != "" && response.data.doc_void != undefined){
                response.data.doc_void.url = "https://cdn3.gcooperp.com/"+response.data.doc_void.file_path+"/"+response.data.doc_void.file_name;
            }
            this.doc_void = response.data.doc_void ?? "";
            if(response.data.doc_w9 != "" && response.data.doc_w9 != undefined){
                response.data.doc_w9.url = "https://cdn3.gcooperp.com/"+response.data.doc_w9.file_path+"/"+response.data.doc_w9.file_name;
            }
            this.doc_w9 = response.data.doc_w9 ?? "";
        });

        axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.mem = response.data;
            this.post = response.data.post;
            this.addr1 = response.data.addr1;
            this.addr2 = response.data.addr2;
            this.mobile = response.data.mobile??"";
            this.mobile_prev = response.data.mobile??"";
            this.register_no = response.data.sa_state??"";
            if(this.register_no != ""){
              this.flag_register_no = "Y";
            }
            this.register_no_prev = response.data.sa_state;
            this.tel = response.data.tel != "null" ? response.data.tel : '';
            this.e_mail = response.data.e_mail;
            this.e_mail_prev = response.data.e_mail;
            this.mobile = response.data.mobile != "null" ? response.data.mobile : '';
            // this.depositor = response.data.depositor;
            // this.account = response.data.account != "null" ? response.data.account : "";
            // this.bank = response.data.bank_cd;
            //
            // this.acc_type = response.data.acc_type;
            // this.routingNo = response.data.cyber_point;

            this.nt_cd = response.data.nt_cd;
            // if(this.nt_cd == "002"){
            //     this.pin_type_list = [{ title : "" , value : "Select"},{title :"I" , value :"ITIN"}];
            // }
            // this.pin_type = response.data.pin_type;

            this.rec_mail = response.data.rec_mail === "Y" ? true : false;
            this.is_enrol_autoship =  response.data.is_enrol_autoship ?? false; // 정기구매 등록된 것이 있으면 true
        });

        axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.myconfig = response.data;

        });


    },
    created() {


    },
    watch : {
      selectCity : function (){
        this.city = this.selectCity;
      },
      flag_register_no: function () {

      },
      register_no: function () {
        this.register_no=this.register_no.replace(/[^0-9]/g,'');//숫자만입력
      },
      mobile : function () {
        if (this.mobile !== null && this.mobile !== undefined) {
          this.mobile = this.mobile.replace(/[\D]/gi, '');
        }
      },

        immediate: true
    },
    computed : {

    },
  beforeUpdate() {
      this.rec_mail_check();
  },
  methods : {
        showPwd : function(select){
          if(select == 'show'){
            this.show = !this.show;
          }else if(select == 'show_confirm'){
            this.show_confirm = !this.show_confirm;
          }

        },
        submitForm: function (event) {
          //mobile, email,sa_sate 데이터는 이전 값과 같을 때는 중복검사를 패스한다.

            if(this.mobile_prev.replace(/[^\w\s]/gi, '') == this.mobile.replace(/[^\w\s]/gi, '')){//하이픈제거
              this.mobileCheckYn = 'Y';
            }
            if(this.e_mail_prev == this.e_mail){
              this.emailCheckYn = 'Y';
            }
            if(this.flag_register_no =="N" || this.register_no_prev == this.register_no){
              this.registerNoCheckYn = 'Y';
            }


            if(this.emailCheckYn != 'Y'){
              VueSimpleAlert.alert(i18n.join.twalert.emailchk,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
              return;
            }
            if(this.mobileCheckYn != 'Y'){
              VueSimpleAlert.alert(i18n.join.twalert.phonechk,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
              return;
            }
            if(this.registerNoCheckYn != 'Y'){
              VueSimpleAlert.alert(i18n.join.twalert.registernochk,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
              return;
            }

            let formData = new FormData();

            var W9File = document.getElementById("W9File");
            var VOIDFile = document.getElementById("VOIDFile");
            var RCFile = document.getElementById("RCFile");
            var STFile = document.getElementById("STFile");
            var LSFile = document.getElementById("LSFile");


            formData.append('post', this.post);
            formData.append('addr1', this.addr1);
            formData.append('addr2', this.addr2);

            formData.append('flag_autoship', this.flag_autoship ? 'Y' : 'N'); // 정기구매 주소도 같이 변경할지 Y : N


            formData.append('mobile', this.mobile);
            formData.append('tel', this.tel);
            formData.append('e_mail', this.e_mail);
            if(this.flag_register_no =="N"){
              formData.append('register_no', "");
            }else{
              formData.append('register_no', this.register_no);
            }
            formData.append('depositor', this.depositor);
            formData.append('account', this.account);
            formData.append('bank', this.bank);
            formData.append('accInfoCk', this.accInfoCk);
            formData.append('routingNo', this.routingNo);
            formData.append('mobileCheckYn', this.mobileCheckYn);
            formData.append('registerNoCheckYn', this.registerNoCheckYn);
            formData.append('emailCheckYn', this.emailCheckYn);
            formData.append('pass1', this.pass1);
            formData.append('pass2', this.pass2);
            formData.append('flag_4week', this.flag_4week);
            formData.append('flag_profit', this.flag_profit);

            if(this.doc_w9.check_yn != "Y" && W9File){
                formData.append('W9File',W9File.files[0] );
            }
            if( this.doc_void.check_yn != "Y" && VOIDFile){
                formData.append('VOIDFile',VOIDFile.files[0] );
            }
            if(this.rec_mail) {
              formData.append('rec_mail', 'Y');
            } else {
              formData.append('rec_mail', 'N');
            }

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }

            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/memberModify',
                formData
            , config).then(response => {
                if(response.data.result == "1"){
                    VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                            location.replace("/MyPersonInfo");
                        }
                    });

                }
                if(response.data.result == "0"){
                    VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
                        if(result){
                        }
                    });
                }

                this.loading = false;
            });

        },
        checkEmail(){
            if(!this.e_mail) { // 이메일을 입력해주세요.
              VueSimpleAlert.alert("メールを入力してください。","","",{allowOutsideClick : false}).then((result) => {});
              return;
            }
            axios.post(window.Laravel.back_url+'/api/getComparisonEmail', {
                email : this.e_mail,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if (response.data.chk.checker == "Y") {
                    $("#e_mail").attr("readonly", true);
                    $("#e_mail").css("background", "#d4d4d4");

                    $("#emailCheckYn").val("Y");
                    this.emailCheckYn = "Y";
                    $("#emailCheck").hide();
                    $("#emailCheckTxt").text(i18n.join.join.alert_emailchk4); /* 사용할 수 있는 핸드폰 번호입니다. */
                    $(".btn_emailChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리

                } else {
                    $("#emailCheckYn").val("N");
                    this.emailCheckYn = "N";
                    $("#emailCheck").show();
                    alert(i18n.join.join.alert_emailchk1);
                    $("#emailCheckTxt").text(i18n.join.join.alert_emailchk1); /* 사용할 수 없는 핸드폰 번호입니다. */
                    // $(".btn_emailChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리

                }
            });
        },
        checkMobile(){
            if(this.mobile.length < 10) { // 휴대폰 번호를 정확하게 입력하세요.
              VueSimpleAlert.alert("携帯電話番号を正確に入力してください。","","",{allowOutsideClick : false}).then((result) => {});
              return false;
            }
            axios.post(window.Laravel.back_url+'/api/getComparisonMobile', {
                mobile : this.mobile,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if (response.data.chk.checker == "0") {
                    $("#mobile").attr("readonly", true);
                    $("#mobile").css("background", "#eaeaea");

                    $("input[id='mobileCheckYn']").val("Y");
                    this.mobileCheckYn = "Y";
                    $("#vertifyMobile").val("Y");
                    $("#mobileCheck").hide();
                    $(".btn_mobileChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리
                    $("#mobileCheckTxt").text(i18n.myoffice.myofficeAlert.alert20); /* 사용할 수 있는 핸드폰 번호입니다. */
                } else {
                    $("input[id='mobileCheckYn']").val("N");
                    this.mobileCheckYn = "N";
                    $("#vertifyMobile").val("N");
                    $("#mobileCheck").show();
                    // $(".btn_mobileChk").css("display","block");
                    VueSimpleAlert.alert(i18n.join.join.memformtext27,"","",{allowOutsideClick : false}).then((result) => {});
                    $("#mobileCheckTxt").text(i18n.myoffice.myofficeAlert.alert21); /* 사용할 수 없는 핸드폰 번호입니다. */
                }
            });

        },
        searchAddress(){
            axios.post(window.Laravel.back_url+'/api/getUsZipcode', {
                post_code : this.post,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                if( response.data.address.length > 0 ){
                    this.selectState = response.data.address[0]['state'];
                    this.city = response.data.address[0]['city'];

                    //$('#post').val(seq);
                    $("#selectCity").html("");
                    var option = "";
                    var postcode_localities = response.data.address;
                    if (postcode_localities !== undefined && postcode_localities.length > 1) {
                        option += "<option>select</option>";
                        for (var i = 0; i < postcode_localities.length; i++) {
                            option += "<option value='"+postcode_localities[i]["city"]+"'>"
                                + postcode_localities[i]["city"]
                                + "</option>";
                        }
                        $("#selectCity").html(option);

                    }
                }else {
                    $('#post').val($('#r_post').val());
                    alert("Please enter a valid 5 digit zipcode.");
                }
            });

        },
        checkRegisterNo(){
          //register_no
          if(this.flag_register_no =="Y"){
            if(this.register_no.length < 13) { // 등록번호를 정확하게 입력하세요.
              VueSimpleAlert.alert("登録番号を正確に入力してください。","","",{allowOutsideClick : false}).then((result) => {});
              return false;
            }

            axios.post(window.Laravel.back_url+'/api/getComparisonRegisterNo', {
              mobile : this.register_no,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
              if (response.data.chk.checker == "Y") {
                $("#regster_no").attr("readonly", true);
                $("#regster_no").css("background", "#eaeaea");

                $("input[id='registerNoCheckYn']").val("Y");
                this.registerNoCheckYn = "Y";
                // $("#vertifyRegisterNo").val("Y");
                $("#registerNoCheck").hide();
                $(".btn_registerNoChk").css("display","none"); // 휴대폰 번호 체크 후에는 버튼 영역도 숨김 처리
                $("#registerNoCheckTxt").text(i18n.myoffice.myofficeAlert.alert23); /* 사용할 수 있는 핸드폰 번호입니다. */
              } else {
                $("input[id='mobileCheckYn']").val("N");
                this.registerNoCheckYn = "N";
                // $("#vertifyRegisterNo").val("N");
                $("#registerNoCheck").show();
                // $(".btn_mobileChk").css("display","block");
                VueSimpleAlert.alert(i18n.join.join.memformtext83,"","",{allowOutsideClick : false}).then((result) => {});
                $("#registerNoCheckTxt").text(i18n.myoffice.myofficeAlert.alert22); /* 이미 등록된 등록번호 입니다. */
                //
              }
            });
          }
        },

        withPopper(dropdownList, component, { width }) {
            /**
             * We need to explicitly define the dropdown width since
             * it is usually inherited from the parent with CSS.
             */
            dropdownList.style.width = width

            /**
             * Here we position the dropdownList relative to the $refs.toggle Element.
             *
             * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
             * the dropdownList overlap by 1 pixel.
             *
             * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
             * wrapper so that we can set some styles for when the dropdown is placed
             * above.
             */
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: "top",
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                ],
            })

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy()
        },
        // 주소록 레이어 오픈
        close_address_book(){
          this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value,digit) {
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        search_address(){
          this.address_search_open = true;
        },
        set_address(value){
          console.log(value);
          this.addr1 = value.address + value.address2 + value.address3;
          this.address_search_open = false;
/*          this.selectState =value.state;
          this.city = value.district;
          this.Street = "";*/
          this.post = value.postcode;
        },
        close_address_search(){
          this.address_search_open = false;
        },
        rec_mail_check() {
            if(this.rec_mail) {
              document.getElementsByName('rec_mail')[0].checked = true;
            }
        },
        flag_autoship_check() {
          if(this.flag_autoship) {
            document.getElementsByName('flag_autoship')[0].checked = true;
          }
        }
    }
}
</script>

<style >
.v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
