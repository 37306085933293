<template>
  <!--  <section class="relative block w-full">-->
      <section class="relative container mx-auto xl:mt-12 lg:mt-12">
        <div class="main_visual max-w-8xl mx-auto xl:py-4 lg:py-4 lg:max-w-7xl bg-white">
      <carousel v-if="banners.length !== 0" :settings=" settings " ref="myCarousel">
        <slide v-for=" (item) in banners " :key=" item.seq_si">
          <div class="carousel__item">
            <div @click="goBannerLink(item.link_url)" :class="[item.link_url ? 'onCursor' : '']">
              <img :src="item.href" v-bind:alt=" item.title ">
            </div>
          </div>
        </slide >
        <template #addons>
          <Pagination />
          <Navigation>
          </Navigation>
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

/* javascript numberFormat library */
let numeral = require("numeral");

export default{
  name: "VisualBanners",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },setup() {
    // const { isMobile } = useMobileDetection();   // 모바일 여부 체크
    // return { isMobile };
  },
  data: () => ({
    // carousel settings
    settings: {
      wrapAround: true,
      snapAlign: 'end',
      autoplay: '5000',
      mouseDrag: false,
      touchDrag: false
    },
    seen: false,
    banners: [],
  }),
  created() {
    // 배너
    axios.post(window.Laravel.back_url+'/api/banner', {
      bz_cd: window.Laravel.bz_cd,
      limit: 8
    }).then(response => {
      response.data.forEach((value, index) => {
        this.banners.push({ seq_si:value.seq_si, link_url: value.linkurl, href: 'https://cdn.gcoop.me/shop' + value.path_name + value.file_name, current: false, title: value.title });
      });
    });
  },
  methods: {
    goBannerLink(link) {
      if(link) {
        location.href = link;
      }
    }
  }
}
$(window).scroll(function(e){
  if($(".main_visual").length == 1){
    if($(document).scrollTop() > 820){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","820px");
    }
  }else if($(".main_visual").length == 1){
    if($(document).scrollTop() > 641){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","641px");
    }
  }else if($(".main_visual").length == 0){
    if($(document).scrollTop() > 155){
      $(".quick-menu").addClass("fix");
      $(".quick-menu").removeAttr("style");
    }else{
      $(".quick-menu").removeClass("fix");
      $(".quick-menu").css("top","155px");
    }
  }
});

</script>
<style>
.quick-menu{position:absolute;z-index:10;right:20px;top:820px;}
.main_visual .carousel__prev,
.main_visual .carousel__next {
  background-color: #9ca4ac;
  box-sizing: content-box;
  border: 2px solid white;
  opacity: 0.6;
}
.main_visual .carousel__prev{ margin-left:20px;}
.main_visual .carousel__next {margin-right:20px;}

.onCursor { cursor: pointer; }
.main_visual .carousel__viewport {border-radius:0;}
@media (min-width: 768px) {
  .main_visual .carousel__viewport {
    border-radius: 1.5rem;
  }
}

</style>

