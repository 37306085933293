<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.MyFavorites.ConsumerChangeDay }}</dt>
        <dd>
            <select name='diff' id="diff" v-model="diff" class="select1 wide">
                <option value="" :data-display="i18n.myoffice.Select">{{ i18n.myoffice.Select }}</option>
                <option v-for="Value in diff_array" :value="Value.id" >{{ Value.title }}</option>
            </select>
        </dd>
    </dl>

</template>

<script>
export default {
    name: "consumerChangeForm",
    data: () => ({
        i18n: window.i18n,
        diff : "",
        diff_array : [
            {id:0 , title:window.i18n.myoffice.None},
            {id:30 , title:"30"+window.i18n.myoffice.Day+window.i18n.myoffice.innotime},
            {id:40 , title:"40"+window.i18n.myoffice.Day+window.i18n.myoffice.innotime},
            {id:50 , title:"50"+window.i18n.myoffice.Day+window.i18n.myoffice.innotime},
            {id:60 , title:"60"+window.i18n.myoffice.Day+window.i18n.myoffice.innotime}]
    }),
}
</script>

<style scoped>

</style>
