<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <direct_auth v-if="isDirect===true"></direct_auth>
  <section class="container mx-auto py-6">
      <div class="bg-white">
        <div class="max-w-8xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
          <div class="flex flex-wrap -mx-4 mb-4">
              <div class="xl:w-2/5 lg:w-2/5 w-full px-4 mb-8 md:mb-0">
                  <div class="relative mb-10">
                      <a class="absolute top-1/2 xl:left-[-35px] lg:left-[-35px] left-0 transform translate-1/2 border bg-white hover:bg-black/20 focus:bg-black/20 transition duration-200 py-3 px-2" href="#" @click="select_image(cur_image - 1)" v-if="images.length >= 2"><!--추가 이미지 사용안함 이동 아이콘 히든-->
                          <svg width="10" height="18" viewBox="0 0 10 18" xmlns="http://www.w3.org/2000/svg" class="fill-gray-500">
                              <path d="M9 16.0185C9.268 16.2905 9.268 16.7275 9 16.9975C8.732 17.2675 8.299 17.2685 8.031 16.9975L0.201 9.0895C-0.067 8.8195 -0.067 8.3825 0.201 8.1105L8.031 0.2025C8.299 -0.0675 8.732 -0.0675 9 0.2025C9.268 0.4735 9.268 0.9115 9 1.1815L1.859 8.6005L9 16.0185Z" fill="#6b7280"></path>
                          </svg>
                      </a>
                      <img v-if="image" class="w-full aspect-square aspect-w-1 aspect-h-1" v-bind:src="'https://cdn.gcoop.me/public' + image.path + '/' + image.filename" v-bind:alt=" image.filename ">
                      <img v-else class="w-full aspect-square aspect-w-1 aspect-h-1" src="/images/error/noImage.png" alt="none image">
                      <div class="sold-out-box2" v-if="statuss == 2">
                          <img src="/images/badge/jp/pc_temp_sold_out.png" alt="sold out" />
                      </div>
                      <div class="sold-out-box2" v-if="statuss == 3">
                          <img src="/images/badge/jp/pc_sold_out.png" alt="sold out" />
                      </div>
                      <div class="coming_box" v-if="statuss == 4">
                          <img src="/images/badge/jp/pc_coming_soon.png" alt="sold out" />
                      </div>
                      <div class="badge_box" v-if="badge != ''">
                          <img :src="badge" alt="sold out" />
                      </div>
                      <a class="absolute top-1/2 xl:right-[-35px] lg:right-[-35px] right-0 transform translate-1/2 border bg-white hover:bg-black/20 focus:bg-black/20 transition duration-200 py-3 px-2" href="#" @click="select_image(cur_image + 1)" v-if="images.length >= 2"><!--추가 이미지 사용안함 이동 아이콘 히든-->
                          <svg width="10" height="18" viewBox="0 0 10 18" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.19922 1.1817C-0.0687795 0.909696 -0.0687794 0.472695 0.19922 0.202695C0.46722 -0.0673054 0.90022 -0.0683048 1.16822 0.202695L8.99822 8.11069C9.26622 8.3807 9.26622 8.81769 8.99822 9.08969L1.16822 16.9977C0.900219 17.2677 0.467218 17.2677 0.199219 16.9977C-0.0687809 16.7267 -0.0687808 16.2887 0.199219 16.0187L7.34022 8.5997L0.19922 1.1817Z" fill="#6b7280"></path>
                          </svg>
                      </a>
                  </div>
                  <div class="flex flex-wrap gap-1 w-full" v-if="images.length >= 2"><!--추가 이미지 썸네일은 사용안함-->
                      <div class="xl:w-1/4 lg:w-1/4 w-1/6 xl:h-1/3 lg:h-1/3 h-1/6 p-2 relative before:content before:content-[''] before:block before:pt-[100%]" v-for="(image, index) in images" :key="image.seq_pdi">
                          <a class="w-full h-full block absolute top-0 right-0 bottom-0 left:0 bg-white border border-gray-300 hover:border-blue-500 focus:border-blue-500 transition duration-200 cursor-pointer p-1" href="#" @click="select_image(index)">
                              <img class="object-cover absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]" v-bind:src="'https://cdn.gcoop.me/public' + image.path + '/' + image.filename" v-bind:alt=" image.filename ">
                          </a>
                      </div>
                  </div>
              </div>
              <div class="w-full xl:w-1/2 lg:w-1/2 px-4">
                  <div class="lg:pl-20">
                      <div class="w-full">
                          <div class="text-[#899cc1] xl:mb-2 lg:mb-2 mb-1 xl:text-base lg:text-base text-sm">{{ parent_cate_name }} > {{ title }}</div>
                          <h2 class="mt-2 mb-6 max-w-xl xl:text-2xl lg:text-2xl text-xl font-bold font-heading">{{ products.pdt_name }}</h2>
                          <p class="mb-2 text-sm text-gray-500 border-b-2 pb-2">{{ products.sysnopsis }}</p>
                          <div class="w-full inline-block font-heading">
                            <div class="mb-1 py-2 " v-if="deli_date2" v-html="deli_date2"></div>

<!--                            <div v-if="isDirect === false" class="flex mb-1 py-2 border-b">-->
<!--                                <div class="w-1/2 text-[#0e293e] font-bold text-base">消費者価格</div>-->
<!--                                <div-->
<!--                                    :class="(isLoggedIn===true) ? 'line-through' : ''"-->
<!--                                    class="w-1/2 text-right xl:text-xl lg:text-xl text-base font-bold text-gray-900 ">{{ r_price }}</div>-->
<!--                            </div>-->
                            <ConsumerPrice v-if="r_price!==''" :isLoggedIn="isLoggedIn" :price="r_price" :toBeSold="to_be_sold" type="detail"></ConsumerPrice>

                            <div v-if=" isLoggedIn && !to_be_sold" class="flex mb-1 py-2 border-b">
                              <div class="w-1/2 text-[#0e293e] font-bold text-base">会員価格</div>
                              <div class="w-1/2 xl:text-xl lg:text-xl text-base font-bold text-gray-900 text-right">
                                {{ m_price }}
                                <span v-if="isDirect===true" class="Q_mark_icon" style="margin-left:10px;">
                                  <i class="fal fa-question-circle direct-question-circle" aria-hidden="true" @click="priceTooltip"></i>
                                </span>
                              </div>
                            </div>

                            <div v-if=" isLoggedIn && (isDirect === false) && !to_be_sold" class="flex mb-1 py-2 border-b">
                              <div class="w-1/2 text-[#0e293e] font-bold text-base">PV</div>
                              <div class="w-1/2 xl:text-xl lg:text-xl text-base font-bold text-blue-500 text-right">{{ pv }}</div>
                            </div>
                            <div v-show=" false "  class="flex mb-1 py-2 border-b">
                              <div class="w-1/2 text-[#0e293e] font-bold text-base">積立ポイント</div>
                              <div class="w-1/2 xl:text-xl lg:text-xl text-base font-bold text-red-500 text-right">{{ point }}</div>
                            </div>

                            <div class="">
                              <div v-if=" isLoggedIn " class="custom-number-input flex items-center py-3 border-b">
                                <label for="custom-input-number" class="block text-[#0e293e] font-heading w-1/2 font-bold">数量選択</label>
                                <div class="w-1/2  text-right">
                                  <div class="inline-flex items-center px-4 font-semibold font-heading text-gray-500 border border-gray-200 rounded-md">
                                    <button @click="decrement" class="py-2">
                                      <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#172e59]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                    </button>
                                    <input type="number" name="qty" v-model="count" @input="changeQty" class="w-12 m-0 px-2 py-2 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                    <button @click="increment" class="py-2">
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#172e59]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <!--옵션 사용시-->
                              <div v-if="false" class="custom-number-input flex items-center py-3 border-b">
                                <span class="block text-[#0e293e] font-heading uppercase w-1/2 font-bold">Size</span>
                                <div class="w-1/2 text-left">
                                  <select class="pl-6 pr-10 py-2 font-semibold font-heading text-gray-500 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md" name="" id="">
                                    <option value="1">Medium</option>
                                    <option value="2">Small</option>
                                    <option value="3">Large</option>
                                  </select>
                                </div>
                              </div>
                              <!-- 총 합계 -->
                              <div v-if=" sum_amount " > <!--v-if=" sum_amount "-->
                                <div class="flex items-center py-2 border-b">
                                  <div class="w-1/2 text-[#0e293e] font-bold">Amount</div>
                                  <div v-if="isDirect===true" class="w-1/2 text-left text-xl font-bold">
                                    <span class="text-gray-900">{{ sum_amount }}円</span>
                                  </div>
                                  <div v-else class="w-1/2 text-left text-xl font-bold">
                                    <span class="text-gray-900 py-1 inline-block">{{ sum_amount }}円</span><br>
                                    <span class="text-blue-500 py-1 inline-block">PV {{ sum_bv }} </span><br>
                                    <span class="text-red-500 py-1 inline-block">Points{{ sum_point }} </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="flex items-center py-2 border-b">
                              <div class="w-1/2 text-[#0e293e] cursor-pointer font-bold" @click="accordion">製品構成</div>
                              <div class="w-1/2 text-right text-xl font-bold text-gray-600 text-right cursor-pointer pdt_composition" @click="accordion">
                                <i class="fas fa-plus"></i></div>
                            </div>
                            <div v-show="false" class="flex py-2 border-b">
                              <div class="w-1/2 text-[#0e293e] font-bold text-base">Weight (lb)</div>
                              <div class="w-1/2 text-left xl:text-xl lg:text-xl text-base font-bold text-gray-600">{{ weight }}</div>
                            </div>

                            <div class="allcontents hidden last:border-none" v-for="(Value,index) in bom_list">
                              <div class="flex py-1 border-b border-dashed">
                                <div class="w-3/4 text-[#133653] md:text-sm text-xs">{{ Value.set_name }}</div>
                                <div class="text-gray-600 md:text-sm text-xs">{{ Value.qty }}ea</div>
                              </div>
                            </div>

                          </div>
                      </div>

                      <div v-if=" isLoggedIn && isVcMember===false && !to_be_sold" class="flex gap-2 items-center mt-6 "> <!-- v-if=" isLoggedIn " -->
                          <div v-if="categoryIdx > 0" class="w-full bg-[#172e59] hover:bg-[#0e1b35] xl:py-4 lg:py-4 py-3 px-3 rounded-md uppercase transition duration-200 cursor-pointer">
                              <a class="block text-center font-bold font-heading xl:text-lg lg:text-lg text-base text-white" href="/cart" v-on:click.prevent="addCart">
                                <span v-if="isDirect === true">
                                  [韓国直]買い物かご
                                </span>
                                <span v-else>
                                  買い物かご
                                </span>
                              </a>
                          </div>
                          <div v-show="autoship_price != 0" class="w-full bg-[#86efac] hover:bg-[#79d79a] xl:py-4 lg:py-4 py-3 px-3 rounded-md uppercase transition duration-200 cursor-pointer">
                              <a class="block text-center font-bold font-heading xl:text-lg lg:text-lg text-base text-white" href="#" v-on:click.prevent="addAutoship">
                                  定期購入
                              </a>
                          </div>
                      </div>
                      <div v-if="isLoggedIn === true && isDirect === true" class="w-full px-2 mt-6">
                        <p style="color:#ff0000;">
                          <span style="font-weight: 800;">※海外発送商品の返品・交換は不可となります（クーリングオフの対象外になります）。</span>
                          個人輸入代行に関する弊社業務は注文代行であり、商品内容に関する責任は注文者自身に帰属します。
                          但し、弊社の手違いによりご注文と異なる商品をお届けした場合に限り、無料で交換いたします。
                        </p>
                      </div>

                      <div class="mt-14">
                        <div v-if=" isLoggedIn " class="w-full px-4">
                          <div v-if="false">
                            <a class="ml-auto sm:ml-0 flex-shrink-0 inline-flex mr-4 items-center justify-center w-16 h-16 rounded-md border hover:border-gray-500" href="#">
                              <svg class="w-6 h-6" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.4993 26.2061L4.70067 16.9253C3.9281 16.1443 3.41815 15.1374 3.24307 14.0471C3.06798 12.9568 3.23664 11.8385 3.72514 10.8505V10.8505C4.09415 10.1046 4.63318 9.45803 5.29779 8.96406C5.96241 8.47008 6.73359 8.14284 7.54782 8.00931C8.36204 7.87578 9.19599 7.93978 9.98095 8.19603C10.7659 8.45228 11.4794 8.89345 12.0627 9.48319L13.4993 10.9358L14.9359 9.48319C15.5192 8.89345 16.2327 8.45228 17.0177 8.19603C17.8026 7.93978 18.6366 7.87578 19.4508 8.00931C20.265 8.14284 21.0362 8.47008 21.7008 8.96406C22.3654 9.45803 22.9045 10.1046 23.2735 10.8505V10.8505C23.762 11.8385 23.9306 12.9568 23.7556 14.0471C23.5805 15.1374 23.0705 16.1443 22.298 16.9253L13.4993 26.2061Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </a>
                            <a class="flex-shrink-0 inline-flex items-center justify-center w-16 h-16 rounded-md border hover:border-gray-500" href="#">
                              <svg class="w-6 h-6" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.01328 18.9877C2.05682 16.7902 2.71436 12.9275 6.3326 9.87096L6.33277 9.87116L6.33979 9.86454L6.3398 9.86452C6.34682 9.85809 8.64847 7.74859 13.4997 7.74859C13.6702 7.74859 13.8443 7.75111 14.0206 7.757L14.0213 7.75702L14.453 7.76978L14.6331 7.77511V7.59486V3.49068L21.5728 10.5736L14.6331 17.6562V13.6558V13.5186L14.4998 13.4859L14.1812 13.4077C14.1807 13.4075 14.1801 13.4074 14.1792 13.4072M2.01328 18.9877L14.1792 13.4072M2.01328 18.9877C7.16281 11.8391 14.012 13.3662 14.1792 13.4072M2.01328 18.9877L14.1792 13.4072M23.125 10.6961L23.245 10.5736L23.125 10.4512L13.7449 0.877527L13.4449 0.571334V1V6.5473C8.22585 6.54663 5.70981 8.81683 5.54923 8.96832C-0.317573 13.927 0.931279 20.8573 0.946581 20.938L0.946636 20.9383L1.15618 22.0329L1.24364 22.4898L1.47901 22.0885L2.041 21.1305L2.04103 21.1305C4.18034 17.4815 6.71668 15.7763 8.8873 15.0074C10.9246 14.2858 12.6517 14.385 13.4449 14.4935V20.1473V20.576L13.7449 20.2698L23.125 10.6961Z" fill="black" stroke="black" stroke-width="0.35"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="flex items-center" v-if="false">
                          <span class="mr-8 text-gray-500 font-bold font-heading uppercase">SHARE IT</span>
                          <a class="mr-1 w-8 h-8" href="#">
                            <svg class="h-8" id="Layer_1" style="enable-background:new 0 0 67 67;" version="1.1" viewBox="0 0 67 67" width="67px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M29.765,50.32h6.744V33.998h4.499l0.596-5.624h-5.095  l0.007-2.816c0-1.466,0.14-2.253,2.244-2.253h2.812V17.68h-4.5c-5.405,0-7.307,2.729-7.307,7.317v3.377h-3.369v5.625h3.369V50.32z   M34,64C17.432,64,4,50.568,4,34C4,17.431,17.432,4,34,4s30,13.431,30,30C64,50.568,50.568,64,34,64z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#3A589B;"/></svg>
                          </a>
                          <a class="mr-1 w-8 h-8" href="#">
                            <svg class="h-8" id="Layer_1" style="enable-background:new 0 0 67 67;" version="1.1" viewBox="0 0 67 67" width="67px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M43.271,26.578v-0.006c0.502,0,1.005,0.01,1.508-0.002  c0.646-0.016,1.172-0.57,1.172-1.217c0-0.963,0-1.927,0-2.89c0-0.691-0.547-1.24-1.236-1.241c-0.961,0-1.922-0.001-2.883,0  c-0.688,0.001-1.236,0.552-1.236,1.243c-0.001,0.955-0.004,1.91,0.003,2.865c0.001,0.143,0.028,0.291,0.073,0.426  c0.173,0.508,0.639,0.82,1.209,0.823C42.344,26.579,42.808,26.578,43.271,26.578z M34,27.817c-3.384-0.002-6.135,2.721-6.182,6.089  c-0.049,3.46,2.72,6.201,6.04,6.272c3.454,0.074,6.248-2.686,6.321-6.043C40.254,30.675,37.462,27.815,34,27.817z M22.046,31.116  v0.082c0,4.515-0.001,9.03,0,13.545c0,0.649,0.562,1.208,1.212,1.208c7.16,0.001,14.319,0.001,21.479,0  c0.656,0,1.215-0.557,1.215-1.212c0.001-4.509,0-9.02,0-13.528v-0.094h-2.912c0.411,1.314,0.537,2.651,0.376,4.014  c-0.161,1.363-0.601,2.631-1.316,3.803s-1.644,2.145-2.779,2.918c-2.944,2.006-6.821,2.182-9.946,0.428  c-1.579-0.885-2.819-2.12-3.685-3.713c-1.289-2.373-1.495-4.865-0.739-7.451C23.983,31.116,23.021,31.116,22.046,31.116z   M46.205,49.255c0.159-0.026,0.318-0.049,0.475-0.083c1.246-0.265,2.264-1.304,2.508-2.557c0.025-0.137,0.045-0.273,0.067-0.409  V21.794c-0.021-0.133-0.04-0.268-0.065-0.401c-0.268-1.367-1.396-2.428-2.78-2.618c-0.058-0.007-0.113-0.02-0.17-0.03H21.761  c-0.147,0.027-0.296,0.047-0.441,0.08c-1.352,0.308-2.352,1.396-2.545,2.766c-0.008,0.057-0.02,0.114-0.029,0.171V46.24  c0.028,0.154,0.05,0.311,0.085,0.465c0.299,1.322,1.427,2.347,2.77,2.52c0.064,0.008,0.13,0.021,0.195,0.03H46.205z M34,64  C17.432,64,4,50.568,4,34C4,17.431,17.432,4,34,4s30,13.431,30,30C64,50.568,50.568,64,34,64z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#517FA6;"/></svg>
                          </a>
                          <a class="w-8 h-8" href="#">
                            <svg class="h-8" id="Layer_1" style="enable-background:new 0 0 67 67;" version="1.1" viewBox="0 0 67 67" width="67px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M38.167,22.283c-2.619,0.953-4.274,3.411-4.086,6.101  l0.063,1.038l-1.048-0.127c-3.813-0.487-7.145-2.139-9.974-4.915l-1.383-1.377l-0.356,1.017c-0.754,2.267-0.272,4.661,1.299,6.271  c0.838,0.89,0.649,1.017-0.796,0.487c-0.503-0.169-0.943-0.296-0.985-0.233c-0.146,0.149,0.356,2.076,0.754,2.839  c0.545,1.06,1.655,2.097,2.871,2.712l1.027,0.487l-1.215,0.021c-1.173,0-1.215,0.021-1.089,0.467  c0.419,1.377,2.074,2.839,3.918,3.475l1.299,0.444l-1.131,0.678c-1.676,0.976-3.646,1.526-5.616,1.567  C20.775,43.256,20,43.341,20,43.405c0,0.211,2.557,1.397,4.044,1.864c4.463,1.377,9.765,0.783,13.746-1.568  c2.829-1.674,5.657-5,6.978-8.221c0.713-1.715,1.425-4.851,1.425-6.354c0-0.975,0.063-1.102,1.236-2.267  c0.692-0.678,1.341-1.419,1.467-1.631c0.21-0.403,0.188-0.403-0.88-0.043c-1.781,0.636-2.033,0.551-1.152-0.402  c0.649-0.678,1.425-1.907,1.425-2.267c0-0.063-0.314,0.042-0.671,0.233c-0.377,0.212-1.215,0.53-1.844,0.72l-1.131,0.361l-1.027-0.7  c-0.566-0.381-1.361-0.805-1.781-0.932C40.766,21.902,39.131,21.944,38.167,22.283z M34,64C17.432,64,4,50.568,4,34  C4,17.431,17.432,4,34,4s30,13.431,30,30C64,50.568,50.568,64,34,64z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#598DCA;"/></svg>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
          <div class="relative block">
              <ul id="pdt_desc" class="flex items-end my-10 border-b-2" v-if="isSelectedTab == 'description'" >
                  <li class="w-1/3 border-b-2 border-blue-500 cursor-pointer"><a class="xl:text-lg lg:text-lg text-base w-full inline-block p-4 text-blue-500 font-bold font-heading text-center" @click="tabChange('description');">製品名</a></li>
                  <li v-show="false" class="w-1/4 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('faq');">FAQ</a></li>
                  <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center"  @click="tabChange('info');">製品詳細</a></li>
                  <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center"  @click="tabChange('delivery');">配送 /交換/返品</a></li>
              </ul>
            <ul id="pdt_faq" class="flex items-end my-10 border-b-2" v-if="isSelectedTab == 'faq'" >
              <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('description');">製品名</a></li>
              <li v-show="false" class="w-1/4 border-b-2 border-blue-500 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-blue-500 font-bold font-heading text-center" @click="tabChange('faq');">FAQ</a></li>
              <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center"  @click="tabChange('info');">製品詳細</a></li>
                <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center"  @click="tabChange('delivery');">配送 /交換/返品</a></li>
            </ul>
            <ul id="pdt_info" class="flex items-end my-10 border-b-2" v-if="isSelectedTab == 'info'">
              <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('description');">製品名</a></li>
              <li v-show="false" class="w-1/4 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('faq');">FAQ</a></li>
              <li class="w-1/3 border-b-2 border-blue-500 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-blue-500 font-bold font-heading text-center"  @click="tabChange('info');">製品詳細</a></li>
                <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('delivery');">配送 /交換/返品</a></li>
            </ul>
              <ul id="pdt_delivery" class="flex items-end my-10 border-b-2" v-if="isSelectedTab == 'delivery'">
                  <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('description');">製品名</a></li>
                  <li v-show="false" class="w-1/4 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-gray-500 font-bold font-heading text-center" @click="tabChange('faq');">FAQ</a></li>
                  <li class="w-1/3 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-blue-500 font-bold font-heading text-center" @click="tabChange('info');">製品詳細</a></li>
                  <li class="w-1/3 border-b-2 border-blue-500 cursor-pointer"><a class="xl:text-lg lg:text-lg text-sm w-full inline-block p-4 text-blue-500 font-bold font-heading text-center"  @click="tabChange('delivery');">配送 /交換/返品</a></li>
              </ul>
  <!--            // 모바일 여부 체크 -->
              <div class="w-full relative block overflow-x-hidden" v-html="description.pdt_desc_m?.replace(/cdn3.gcooperp.com/gi,'cdn.gcoop.me')" v-if=" isMobile() && isSelectedTab == 'description'"></div>
              <div class="w-full relative block overflow-x-hidden" v-html="description.pdt_desc?.replace(/cdn3.gcooperp.com/gi,'cdn.gcoop.me')" v-if=" !isMobile() && isSelectedTab == 'description'"></div>

              <div v-show="false" v-html="description.pdt_faq" v-if="isSelectedTab == 'faq' "></div>

              <div v-html="description.pdt_noti" v-if="isSelectedTab == 'info'"></div>
              <div v-html="description.pdt_info" v-if="isSelectedTab == 'delivery'"></div>


  <!--            <h3 class="mb-8 text-3xl font-bold font-heading text-blue-300">Summer collection and laoreet get</h3>-->
  <!--            <p class="max-w-2xl text-gray-500">I had interdum at ante porta, eleifend feugiat nunc. In semper euismod mi a accums lorem sad. Morbi at auctor nibh. Aliquam tincidunt placerat mollis. Lorem euismod dignissim, felis tortor ollis eros, non ultricies turpis.</p>-->
          </div>
        </div>
      </div>
    </section>

    <form name="directPurchase" class="hidden" method="post">
        <input type="hidden" name="_token" :value="csrf">
        <input type="hidden" name="bz_cd">
        <input type="hidden" name="qty">
    </form>
</template>

<script>
import { useMobileDetection } from "vue3-mobile-detection";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import ConsumerPrice from '@/components/shop/ConsumerPrice';
import {eventBus} from "../../app";
import alert from "vue3-simple-alert";

/* javascript numberFormat library */
var numeral = require("numeral");

export default {
    name: "ProductList",
    props: ['no'],
    components : {
        VueSimpleAlert,
        Loading,
        ConsumerPrice
    },
    setup() {
        const { isMobile } = useMobileDetection();  // 모바일 여부 체크
        return { isMobile };
    },
    data: () => ({
        i18n: window.i18n,
        isDirect : (window.isDirect === true),
        isVcMember : (window.Laravel.isVcMember === true),
        products: [],
        title: '',
        status: '',
        r_price: '',
        m_price: '',
        pv: '',
        weight: '',
        total_amt: '',
        images: [],
        image: '',
        cur_image: 1,
        description: '',
        count: 1,
        max_qty: 99,
        sum_amount: '',
        sum_bv: '',
        isLoggedIn: false,
        statuss : "",
        badge : "",
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        deli_date2 : "",
        pdt_cd : "",
        bom_list : [],
        loading : false,
        point : 0,
        sum_point : 0,
        isSelectedTab : 'description',
        parent_cate_name : '',
        autoship_price : 0,
        categoryIdx : 0,
        to_be_sold : false, // 판매예정 : true, 판매중 : false
    }),
    created() {

        if(this.no){
            this.loading = true;
            // 신상품
            axios.post(process.env.MIX_BACK_URL+'/api/product/item',{
                no: this.no
            }).then(response => {
              console.log(response.data);
                this.point            = numeral(response.data.point).format('0,0');
                this.products         = response.data;
                this.parent_cate_name = response.data.parent_cate_name;
                this.title            = this.products.cate_name;
                this.status           = this.text_status(this.products.status);
                this.deli_date2       = this.products.deli_date2;
                this.r_price          = ''+ numeral(this.products.prices.set1_amt).format('0,0')+'円';
                this.m_price          = ''+ numeral(this.products.prices.amt).format('0,0')+'円';
                this.pv               = numeral(this.products.prices.pv).format('0,0.00');
                //this.pv = numeral(this.products.prices.pv).format('0,0.0') + ' PV';
                this.autoship_price = this.products.prices.set3_amt;
                this.weight           = numeral(this.products.pdt_weight).format('0,0.0');
                this.total_amt        = this.products.prices.amt;
                // this.image            = this.products.images[1];
                this.pdt_cd           = response.data.pdt_cd;
                this.products.images.forEach(e => {
                  if (e.kind_cd == 'L') this.images.push(e);
                });
                this.select_image();
                this.description      = this.products.contents;
                this.statuss          = this.products.status;
                this.badge            =  this.products.badge != "" ? "https://cdn.gcoop.me/"+this.products.badges[0].image_url : "";
                //if(response.data&&this.isLoggedIn) this.setPdtMember();
                this.loading          = false;
                this.categoryIdx      = this.products.categories.seq_pdc;
                this.to_be_sold  = (response.data.status == '4') ? true : false;

                // 다른 컴퍼넌트의 메소드를 호출
                this.emitter.emit('setCategoryCode', this.products.categories.refseq)

                axios.post(process.env.MIX_BACK_URL+'/api/product/bom',{
                  no: this.pdt_cd,
                  bz_cd: window.Laravel.bz_cd
                }).then(response => {
                  this.bom_list = response.data
                });
            });

            // bom


        }

    },
    mounted() {
        if (window.Laravel.isLoggedin) {
          this.isLoggedIn = true;
          this.user = window.Laravel.user;
        }
    },
    updated(){
      //this.accordion();
    },
    methods:{

      priceTooltip(){
        VueSimpleAlert.alert("※関税・消費税等の諸費用は全て会員ご負担となりますのでご了承ください。", "", "", { confirmButtonText:"確認"}).then((result) => {
        });



      },
      // 수량 변경
      select_image($i){
        if(!$i || $i<0) $i = 0;
        if($i>this.images.length-1) $i = this.images.length-1;
        this.cur_image = $i;
        this.image            = this.images[$i];
      },
      //document.getElementById("link")
      accordion : function (){
        if($(".pdt_composition > .fas").hasClass('fa-plus')){ //닫겨있을때
          $(".allcontents").removeClass('hidden');
          $(".pdt_composition > .fas").removeClass('fa-plus');
          $(".pdt_composition > .fas").addClass('fa-minus');
        }else{ //열려있을 때
          $(".allcontents").addClass('hidden');
          $(".pdt_composition > .fas").removeClass('fa-minus');
          $(".pdt_composition > .fas").addClass('fa-plus');
        }
      },
      // 수량 변경
      decrement(){
        if(this.count > 1) {
          this.count--;
            this.sumAmount();
        }
      },
      increment(){
        if(this.count < this.max_qty) {
          this.count++;
            this.sumAmount();
        }
      },
      changeQty(){
        if(this.count < 1) {
          this.count = 1;
        }
        if(this.count > this.max_qty) {
          this.count = this.max_qty;
        }
          this.sumAmount();
      },
        sumAmount(){
            this.sum_amount = numeral(parseFloat(this.total_amt) * parseInt(this.count)).format('0,0.00');
            this.sum_bv = numeral(parseFloat(this.products.prices.pv) * parseInt(this.count)).format('0,0.00');
            this.sum_point = numeral(parseFloat(this.point) * parseInt(this.count)).format('0,0.00');
        },
        setPdtMember(){
            axios.post(process.env.MIX_BACK_URL+'/api/setPdtMember',{
                item: this.no,
                client_ip: window.Laravel.clientIp,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
                // console.log(response.data);
            });
        },

        /**
         * 상품이 품절 여부 확인
         */
      isSoldOut(){
        return (this.statuss === '2' || this.statuss === '3');
        // return false;
      },

        /**
         * 상품이 품절인 경우, 알럿용 함수
         */
      solidOutAlert(){

          alert.alert(this.i18n.shop.shopping.cart_notice3, "", "", {
              returnFocus: false, focusConfirm: false,
              didOpen: () => {
              },
              didDestroy: (toast) => {
                  this.$refs.d_firstname.focus();
              }
          }).then((result) => {
          });
      },

      addCart(){

        if (this.isSoldOut() === true){
            this.solidOutAlert();
            return ;
        }

        this.loading = true;
        const apiPath = (this.isDirect===true) ? '/api/add_dir_cart' : '/api/add_cart';

        axios.post(process.env.MIX_BACK_URL+apiPath,{
        // axios.post(process.env.MIX_BACK_URL+'/api/add_cart',{
          item: this.no,
          qty: this.count,
          client_ip: window.Laravel.clientIp,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
            if(response.data.msg){
                if(response.data.result == 'Y') {

                    VueSimpleAlert.alert("", "", "", { confirmButtonText:"確認", background:'transparent', timer:1000, imageHeight: 300, imageUrl: '/images/my_cart_icon_500x500.png', showCancelButton:false,  showCloseButton: false, showConfirmButton : false}).then((result) => {
                        if (result) {
                            //location.replace("/MyforinerUpload");
                        }
                    });
                    this.emitter.emit('getCountCartItems', '')
                }else{
                    VueSimpleAlert.alert(response.data.msg, "", "", { confirmButtonText:"確認"}).then((result) => {
                        if (result) {
                            //location.replace("/MyforinerUpload");
                        }
                    });

                    //alert(response.data.msg);
                }
            }
            if(response.data.result == 'Y'){
              //window.location.reload()
            }
            this.loading = false;
          });
      },
        directPurchase(){
          // alert(this.count);
            this.loading = true;
            let form = document.directPurchase;
            form.action = '/directPurchase/'+this.no+'/'+this.count;
            form._token.value = this.csrf;
            form.bz_cd.value = window.Laravel.bz_cd;
            // form.qty.value = this.count;
            form.submit();
        },
        addAutoship(){

            if (this.isSoldOut() === true){
                this.solidOutAlert();
                return ;
            }

            this.loading = true;

            axios.post(process.env.MIX_BACK_URL+'/api/insertAsCartPdt',{
                pdt_cd: this.pdt_cd,
                qty: this.count,
                client_ip: window.Laravel.clientIp,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(response.data.msg){0
                        if(response.data.result == 'Y') {

                            VueSimpleAlert.alert("", "", "", { background:'transparent', timer:1000, imageHeight: 300, imageUrl: '/images/putin_cart_icon_jp.png', showCancelButton:false,  showCloseButton: false, showConfirmButton : false}).then((result) => {
                                if (result) {
                                    //location.replace("/MyforinerUpload");
                                }
                            });
                            this.emitter.emit('getCountAutoShipItems', '')
                        }else{
                            VueSimpleAlert.alert(response.data.msg, "", "", { }).then((result) => {
                                if (result) {
                                    //location.replace("/MyforinerUpload");
                                }
                            });

                            //alert(response.data.msg);
                        }
                    }
                    if(response.data.result == 'Y'){
                        //window.location.reload()
                    }

                    this.loading = false;
                });
        },
      text_status($status){
        switch ($status){
          case "1":
            return "Available";
            break;
          case "2":
            return "Temporarily out of stock";
            break;
          case "3":
            return "Sold Out";
            break;
          case "4":
            return "Coming Soon";
            break;
        }
      },
      tabChange(type){
        this.isSelectedTab = type;
      }

    },

}
</script>
<style>
.product_info-table>ul>li>div {display: inline-block}
.table-div-tit {width:200px;}

 input[type='number']::-webkit-inner-spin-button,
 input[type='number']::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.active {color:skyblue; font-weight: bold}
.badge_box{position: absolute; top: 0; right: 0; width: 100%; }
.badge_box>img {width: 25% !important; top:8px; right: 8px; position: absolute; z-index: 1; background:transparent !important;}

.coming_box{position: absolute; top: 0; right: 0; width: 100%; }
.coming_box>img{width:25% !important;  position: absolute; right:8px; top: 8px;  z-index: 1; background:transparent !important;}

.sold-out-box2{position: absolute; top: 0; right: 0; width: 100%; }
.sold-out-box2>img {width: 100% !important; top:0px; right: 0px; position: absolute;  z-index: 1; background:transparent !important;}

.product_info-table{border:1px solid #ccc}
.product_info-table li{display:table;table-layout:fixed;width:100%;border-bottom:1px solid #dedede;background-color:#f5f5f5;font-weight:500;color:#333}
.product_info-table li:last-child{border-bottom:0}
.product_info-table li>div:first-child{display:table-cell}
.product_info-table li>div:last-child{display:table-cell;background-color:#fff;vertical-align:middle}

/*faq 신규추가 css*/
/*Question*/
.product_faq .product_info-table ul{width:calc(100% - 5px);}
.product_faq .product_info-table .faq_li_q{background:#f7f7f7; border-left:5px solid red; padding:8px 0; width:100%; word-break: break-all; }
.product_faq .product_info-table .faq_tit_q{color:red; font-size:1.5em; padding:0 5px; display: table-cell; width: 4%; vertical-align: middle; text-align: center; }
.product_faq .product_info-table .faq_cont_q{font-size:1em; vertical-align: middle; display: table-cell; padding: 0 10px; background:#f7f7f7;}
/*answer*/
.product_faq .product_info-table .faq_li_a{background:#fff; border-left:5px solid #eee; padding:8px 0; width:100%; word-break: break-all;}
.product_faq .product_info-table .faq_tit_a{color:#666; font-size:1.5em; padding:0 5px; display: table-cell; width: 4%; vertical-align: middle; text-align: center;}
.product_faq .product_info-table .faq_cont_a{font-size:1em; color:#888; vertical-align: middle; display: table-cell; padding: 0 10px;}

.product_info-table li{font-size:20px}
.product_info-table li>div:first-child{width:322px;padding:13px 0 13px 18px}
.product_info-table li>div:last-child{padding:13px 25px}
.product_info-table .indent{padding-left:23px}
@media all and (max-width:1023px) {
    .product_info-table {
        border-bottom: 1px solid #ccc
    }

    .product_info-table li {
        font-size: 13px
    }

    .product_info-table li > div:first-child {
        width: 112px;
        padding: 6px 9px
    }

    .product_info-table li > div:last-child {
        padding: 7px 12px;
        word-break: break-all
    }

    .product_info-table .indent {
        padding-left: 12px
    }
}
</style>
