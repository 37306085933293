<template>
  <div class="relative z-[999]">
    <div class="w-full p-1 justify-between">
      <div class="text-right"><span @click="isUfPayModalClose"  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
      <div style="font-size: 15px">
        <p>Congratulations on your commission!</p><br>
        <p>In order to receive your accrued commission, you need at least 80% of consumer sales registered based on the amount purchased.</p>
        <p>In the My Information menu, upload your Void Check information along with W9 (W8) in order to receive your commission.</p>
        <p>If your registered member information differs from what is listed on your documents, you will not be able to receive your commission. </p><br>
        <p>For additional inquiries or questions, please contact us through the 1:1 inquiry board.</p>
        <p>Thank you.</p><br>
        <p>* W9 (W8), Void documents can be uploaded in the Library section under the Customer Service Center menu.</p>
      </div>
    </div>
    <div class="absolute bottom-0 text-center m-auto my-4 w-[90%]">
      <input type="button"  class="h-10 w-full lg:w-32 leading-6 text-white bg-[#172e59] cursor-pointer mt-2  ml-2 xl:ml-2 lg:mt-0 xl:mt-0" @click="locationAfterConfirm" value="Confirm"/>
      <input type="button" class="h-10 w-full lg:w-32 leading-6 bg-[#d1d5de] hover:bg-[#cbcfdd] cursor-pointer mt-2  ml-2 xl:ml-2 lg:mt-0 xl:mt-0" @click="isUfPayModalClose" value="Cancel">
    </div>
  </div>
</template>


<script>

export default {
name: "Is_uf_Pay",
components : {
},
data: () => ({
}),
methods:{
  isUfPayModalClose(){
    this.$emit("close-modal");
  },
  locationAfterConfirm() {
    location.replace('/MyforinerUpload');
  }

}
}
</script>
