<template>
    <div class="modal">
        <div class="overlay" @click="$emit('close-modal')"></div>
        <div :class="{'modal-card' : harf == false,'modal-harf-card' : harf == true}">
            <iframe name="wechatframe" height="400" width="400"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "modalView",
    props : ['harf','request'],
    data: () => ({
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
    }),
    mounted() {
        this.wechat_proc();
    },

    methods: {
        wechat_proc(){
            //window.open('','popUpWindow','height=400,width=400,left=150,top=150,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');

            let form = document.frmPayment;
            form.action = process.env.MIX_BACK_URL+"/wechatpay";
            form.target = "wechatframe";
            form.method = "post";
            form._token.value = this.csrf;
            form.PaymentID.value = this.request["payment_id"];
            form.OrderNumber.value = this.request["order_seq"];
            form.Amount.value = this.request["pay_total_amt"];
            form.CustIP.value = window.Laravel.clientIp;
            form.CustName.value = this.request["name"]; //+' '+this.request["lastName"];
            form.CustEmail.value = this.request["email"]??$("#email").val();
            form.CustPhone.value =this.request["mobile"].replace(/-/g,""); //this.request["mobile"];
            form.HashValue.value = this.hashdata;
            form.MerchantReturnURL.value = location.protocol.concat("//").concat(location.host)+"/api/payResult";
            form.MerchantCallbackURL.value = process.env.MIX_BACK_URL+"/api/payCallBack";
            // form.MerchantCallbackURL.value = location.protocol.concat("//").concat(location.host)+"/api/payCallBack";
            this.key = 0;
            //this.loading = true;
            form.submit();

        }
    }
}
</script>

<style >
/* Modal */
.modal,
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.overlay {
  opacity: 0.8;
  background-color: black;
}
.modal-card {
  position: relative;
  max-width: 90%;
  max-height: 600px;
  width: 100%;
  padding: 20px;
  background-color: white;
  z-index: 999;
  opacity: 1;
  overflow-y: auto;
  border-radius: 20px;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.modal-harf-card {
  /*position: relative;*/
  max-width: 50%;
  min-width: 360px;
  margin: auto;
  /*margin-top: 30px;*/
  padding: 20px;
  background-color: white;
  min-height: 600px;
  z-index: 999;
  opacity: 1;
  overflow-y: auto;
  max-height: 800px;
}
</style>
