<template>
<my_registerMember v-if='this.request["register_type"] == "member"'></my_registerMember>
<my_registerBusiness v-if='this.request["register_type"] == "business"'></my_registerBusiness>
<my_registerConsumer v-if='this.request["register_type"] == "consumer"'></my_registerConsumer>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";

export default {
  name: "MyRegister",
  props: ['request'],
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
  }),

  beforeCreate() {


  },
  created() {

  },


  mounted() {
      if (window.Laravel.registerRid) {
          this.isLoggedIn = true;
          // this.loginId = window.Laravel.user;
          this.recommUserId = window.Laravel.registerRid;
          this.recommUserName = window.Laravel.registerRname;
      }else{
          location.href='/';
      }
  },
  methods:{

  },

}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
</style>
