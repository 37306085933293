<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <div class="board-top">

        <div id="div_modify" style="display:block;">

            <form name='form_search' style="clear:both;"  v-on:submit.prevent="submitForm">

                <section class="accordion_notice_box">
                    <div class="accordion_notice_item">
                        <a class="accordion_notice_tit" href="#n">
                            <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                            {{ i18n.myoffice.myoSearchForm.notice }}
                            <div class="bmenu x7">
                                <span class="btop">
                                    <i class="fas fa-chevron-up" aria-hidden="true"></i>
                                </span>
                            </div>
                        </a>

                        <div class="accordion_notice_cont">
                            <div class="myo_search_notice">
                                <p><span class="signiture remark1">*</span>{{ i18n.myoffice.myoSearchForm.requiredNotice }}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <input type=hidden name=exec_mode value='write'>
                <input type=hidden name=no id="bid" value='<?=$row->no?>'>
                <input type=hidden name="sid" id="sid" value='<?=$sid?>'>
                <ul id="myo_form" class="normal-form">
                    <li class="item">
                        <span class="item_title req_mark">{{ i18n.inquriy.inquriy.title }}</span><!-- 제목 -->
                        <span class="item_content content_name xl:bg-white lg:bg-white md:bg-white"><input type="text" name="title" id="title" class="w-full" v-model="row.title" maxlength="200" /></span>
                    </li>
                    <li class="item">
                        <span class="item_title req_mark">{{ i18n.inquriy.inquriy.category }}</span><!-- 분류 -->
                        <span class="item_content xl:bg-white lg:bg-white md:bg-white">
                            <vSelect class="w-full md:w-64 bg-white" :options="list_temp_str" label="value" :reduce="value => value.title"   :clearable="false" v-model="row.grp_cd"  ></vSelect>
                        </span>
                    </li>
                    <li class="item">
                        <span class="item_title req_mark">{{ i18n.inquriy.inquriy.handphone }}</span><!-- 휴대전화 -->
                        <span class="item_content xl:bg-white lg:bg-white md:bg-white">
                            <input type="text" name="tel" id="tel" class="type_number_str" maxlength="15"  v-model="row.tel" />
                            <div class="text-rose-500 text-xs mt-2 ml-2.5 break-words text-sm font-medium">* {{ i18n.inquriy.inquriy.autocomplete }}</div>
                        </span>
                    </li>
                    <li class="item">
                        <span class="item_title title_Question req_mark">{{ i18n.inquriy.inquriy.content }}</span><!-- 문의내용 -->
                        <span class="item_content content_Question xl:bg-white lg:bg-white md:bg-white">
                            <div class="out_frame">
                                <textarea id="boardContent" name="memo"  style="width:96%; height: 200px" v-model="row.content">{{ row.content }}</textarea>
                            </div>
                        </span>
                    </li>
                </ul>
                <section class="bg-contwrap" id="bg-upload">
                    <h3>{{ i18n.inquriy.inquriy.attachment }}</h3>
                    <div class="bg-cont xl:bg-white lg:bg-white md:bg-white xl:py-2.5 lg:py-2.5 md:py-2.5">
                        <li v-for="(Value, index) in a_list">
                          <a class="attch_link" :href="Value.file_link" target="_blank">{{ index+1 }}. {{ Value.org_name }}</a>
                        </li>
                        <div class="attach_upload padding0">
                            <div style="clear:both" id="end_list"></div>
                        </div><!--/images_upload-->
                        <div class="file_div"><input type="file" id='upfile' name='upfile' @change="getFile($event)" /></div>
                        <p class="text-rose-500 text-xs mt-1 ml-2.5 mb-2 break-words text-sm font-medium">* {{ i18n.inquriy.inquriy.attachmentfile }}</p>
                        <p class="text-rose-500 text-xs mt-1 ml-2.5 mb-2 break-words text-sm font-medium">* {{ i18n.inquriy.inquriy.jpg }}</p>
                    </div><!--/bg-cont-->
                </section>

            <div class="myo_condition_search2">
                <button type='button'  @click="listUrl" class="go_list_btn">{{ i18n.inquriy.inquriy.list }}</button> <!-- 목록 -->
                <button type='submit' id="upload_submit" class="faq_write_btn">{{ i18n.inquriy.inquriy.saveofinquiry }}</button> <!-- 문의등록 -->
            </div>

        </form>
    </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyInQuriyModify",
    props: ['no', 'mid_mypage'],
    components : {
        VPagination,
        Loading,
        vSelect,
    },
    data: () => ({
        i18n: window.i18n,
        total_count : 0,
        page : 1,
        total_page : 1,
        mid : window.location.pathname,
        search_text : "",
        list_temp_str : [],
        category : "",
        row : [],
        upfile : "",
        content : "",
        a_list : [],
        loading : false,
    }),
    created : function (){
        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/InQuiryModify',{
            no : this.no,
            page : this.page,
            page_size : 20,
            mid : this.mid_mypage ? this.mid_mypage : this.mid,
            search_text : this.search_text,
            category : this.category
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.row = response.data.row;
            this.list_temp_str = response.data.list_temp_str;
            this.loading = false;
            this.a_list = response.data.a_list;
        });

    },
    methods : {
        getFile(event) {
            this.file = event.target.files[0];
        },
        submitForm : function (){
          let formData = new FormData();
          var upfile = document.getElementById("upfile");
          formData.append('title', this.row.title);
          formData.append('tel', this.row.tel);
          formData.append('category', this.row.grp_cd);
          formData.append('memo', this.row.content);
          formData.append('upfile', upfile.files[0]);
          formData.append('no', this.no ?? 0);

          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + window.Laravel.access_token
            }
          }

          this.loading = true;
          axios.post(window.Laravel.back_url+'/api/InQuiryWrite',
            formData
            , config).then(response => {
            VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
              if(result){
                if(response.data.result == 1) this.listUrl();
              }
            });
            this.loading = false;
          });

        },
        listUrl() {
          location.href = this.mid_mypage ? '/Mypage/Inquiry' : '/MyInQuriy';
        }
    }

}
</script>

<style scoped>

</style>
