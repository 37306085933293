<template>
  <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true"  >
    <address_book :mypage_yn='mypage_yn' @close_address_book="close_address_book" />
  </modalView>
  <div class="w-full mb-auto xl:w-56 lg:w-56 rounded divide-y bg-white rounded-md shadow-zinc-500/40 shadow-md">

    <!-- pc 노출 메뉴 -->
<!--    <div class="xl:flex lg:flex hidden justify-around gap-2 mb-4 border rounded-md bg-white p-4 shadow-zinc-500/40 shadow-md">
      <div class="bg-red-400 w-auto">MENU</div>

      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-800 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-slate-200">
          <div class="relative">
            <svg class="fill-current text-indigo-800 w-6 absolute top-[-15px] left-[-20px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"/></svg>
            <svg class="fill-current text-indigo-800 w-6 absolute top-[-4px] left-[2px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"/></svg>
          </div>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">View Order/<br>Delivery</div>
      </a>
      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-228-32c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H118.4C106 384 96 375.4 96 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">Shipping<br>Address List</div>
      </a>

      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">&lt;!&ndash;! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">My<br>Information</div>
      </a>
      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M633.8 458.1L362.3 248.3C412.1 230.7 448 183.8 448 128 448 57.3 390.7 0 320 0c-67.1 0-121.5 51.8-126.9 117.4L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3zM96 422.4V464c0 26.5 21.5 48 48 48h350.2L207.4 290.3C144.2 301.3 96 356 96 422.4z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">Member<br>Withdrawal</div>
      </a>
      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">Enrollment<br>Status Check</div>
      </a>

      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">1:1 Inquiries</div>
      </a>
      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">Announcements</div>
      </a>
      <a class="flex flex-col items-center" href="">
        <div class="border border-2 border-indigo-600 bg-white rounded-md w-16 h-16 flex items-center justify-center shadow-zinc-200/40 shadow-md hover:bg-indigo-100">
          <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">&lt;!&ndash;! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;<path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"/></svg>
        </div>
        <div class="text-center text-xs text-blue-500 font-bold mt-1">FAQ</div>
      </a>
    </div>-->

    <!-- 기존 메뉴 -->
    <div class="xl:block lg:block hidden">
      <h3 class="text-white text-lg xl:block lg:block hidden font-bold px-4 py-4 bg-[#172e59] text-center">My Page Menu</h3>
<!--      <h3 class="text-[#4e64ad] text-lg xl:block lg:block hidden font-bold px-4 py-4 border-b border-slate-400">My Page Menu</h3>-->

      <div class="">

        <!-- 개인 정보 박스 -->
        <div class="flex items-center p-4 w-full mb-4 xl:mb-0 lg:mb-0 bg-white border-b border-dotted border-[#4e64ad]"> <!-- 33.3% -->
          <div class="w-[35px] h-[35px] rounded-md mr-3 bg-[#0e293e] flex items-center justify-center aspect-square">
            <svg class="fill-current text-white w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
          </div>
          <div class="">
            <a href="/Mypage" class="block">
              <div class="text-left text-md text-[#172e59] font-bold mb-1 text-ellipsis overflow-hidden">{{ this.username }}</div>
              <div class="text-left text-sm text-slate-700 hidden">({{ this.user }})</div>
            </a>
          </div>
        </div>

      </div>

      <div class="p-4">
        <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">{{ i18n.menu.MyOrder}}</div>
        <ul class="px-0.5">
          <li class="py-4 cursor-pointer text-[#0e293e] hover:text-[#172e59] font-semibold">
            <a href="/Mypage/Order" class="flex items-center">
              <span class="w-2/12 mr-2"><svg class="fill-current w-[24px] h-[24px] inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"/></svg></span>
              <span class="w-10/12 text-sm">View Order/Delivery</span>
            </a>
          </li>
          <li class="py-4 cursor-pointer text-[#0e293e] hover:text-[#172e59] font-semibold">
            <a href="/Mypage/AddressList">
            <span class="w-2/12 mr-2"><svg class="fill-current w-[24px] h-[24px] inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-228-32c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H118.4C106 384 96 375.4 96 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z"/></svg></span>
            <span class="w-10/12 text-sm">Shipping Address List</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="p-4" v-if=" false ">
        <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Member Information</div>
        <ul class="px-0.5" >
          <li class="py-4-2 text-sm"  v-show="rank_cd != '00'"><a href="/MyofficeMain">{{ i18n.menu.gomyoffice}}</a></li>

          <li class="py-4 text-sm" v-show="rank_cd == 0">
            <a href="/Mypage/idpwchk">
              <svg class="fill-current text-blue-500 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">&lt;!&ndash;! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
              <span class="w-10/12 text-sm">{{ i18n.menu.MyPersonInfo}}</span>
            </a>
          </li>
          <li class="py-4 text-sm" v-show="rank_cd == 0"><a href="/Mypage/userDel">{{ i18n.menu.MyOfficeUserDel }}</a></li>

          <li class="py-4 text-sm" v-show="rank_cd != 0"><a href="/MyPersonInfo">{{ i18n.menu.MyPersonInfo}}</a></li>
          <li class="py-4 text-sm" v-show="rank_cd != 0"><a href="/MyMemberWithdrawal">{{ i18n.menu.MyOfficeUserDel }}</a></li>

          <li class="py-2 text-sm"><a href="/Mypage/memberFind">{{ i18n.menu.MemberCheck }}</a></li>
        </ul>
      </div>
      <div class="p-4">
        <div class="pt-1 pb-2 text-base dark:text-white font-semibold leading-4 text-left text-gray-800">{{ i18n.menu.MyCommunity}}</div>
        <ul class="px-0.5">
          <li class="py-4 cursor-pointer text-[#0e293e] hover:text-[#172e59] font-semibold">
            <a href="/Mypage/Inquiry">
              <span class="w-2/12 mr-2"><svg class="fill-current w-[24px] h-[24px] inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"/></svg></span>
              <span class="w-10/12 text-sm">{{ i18n.menu.MyInQuriy}}</span>
            </a>
          </li>
          <li class="py-4 cursor-pointer text-[#0e293e] hover:text-[#172e59] font-semibold">
            <a href="/Mypage/Notice">
              <span class="w-2/12 mr-2"><svg class="fill-current w-[24px] h-[24px] inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 0c-41.8 0-77.4 26.7-90.5 64H48C21.5 64 0 85.5 0 112V464c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H282.5C269.4 26.7 233.8 0 192 0zm0 128c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm-80 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg></span>
              <span class="w-10/12 text-sm">{{ i18n.menu.MyNotice}}</span>
            </a>
          </li>
          <li class="py-4 cursor-pointer text-[#0e293e] hover:text-[#172e59] font-semibold">
            <a href="/Mypage/FAQ">
              <span class="w-2/12 mr-2"><svg class="fill-current w-[24px] h-[24px] inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"/></svg></span>
              <span class="w-10/12 text-sm">FAQ</span>
            </a>
          </li>
          <!--                <li class="py-0.5 text-sm"><a href="">{{ i18n.menu.MyPushMessages}}</a></li>-->
        </ul>
      </div>
    </div>
    <!-- //기존 메뉴 -->
  </div>

  <!-- 모바일용 아이콘 메뉴 박스 -->
  <div class="m_menu xl:hidden lg:hidden block mb-6">
    <h3 class="my-2 bg-[#2c7fc3] text-md text-white rounded-md flex items-center justify-between py-2 px-2" v-on:click="showMessage = !showMessage">My Page Menu
      <button type="button" class="px-2 py-1">
        <svg class="fill-current text-white w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
      </button>
    </h3>
    <div class="block mt-4" v-if="showMessage">
      <div class="xl:hidden lg:hidden grid grid-cols-3 justify-items-center gap-2 mb-4">
        <a class="flex flex-col items-center" href="/Mypage/Order">
          <div class="flex items-center justify-center">
            <div class="relative w-16 h-16 block bg-white hover:bg-[#2c7fc3] text-[#2f88d0] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
              <svg class="fill-current w-6 absolute top-[12px] left-[12px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"/></svg>
              <svg class="fill-current w-6 absolute top-[30px] left-[30px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"/></svg>
            </div>
          </div>
          <div class="text-center text-xs text-[#172e59] font-bold mt-2">View Order/<br>Delivery</div>
        </a>
        <a class="flex flex-col items-center" href="/Mypage/AddressList">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#2f88d0] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-228-32c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H118.4C106 384 96 375.4 96 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z"/></svg>
          </div>
          <div class="text-center text-xs text-[#172e59] font-bold mt-2">Shipping<br>Address List</div>
        </a>
      </div>

      <!-- 미사용 처리 -->
<!--      <div class="xl:hidden lg:hidden grid grid-cols-3 justify-items-center gap-2 mb-4">
        <a class="flex flex-col items-center" href="">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#4e64ad] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">&lt;!&ndash;! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
          </div>
          <div class="text-center text-xs text-[#4e64ad] font-bold mt-2">My<br>Information</div>
        </a>
        <a class="flex flex-col items-center" href="">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#4e64ad] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M633.8 458.1L362.3 248.3C412.1 230.7 448 183.8 448 128 448 57.3 390.7 0 320 0c-67.1 0-121.5 51.8-126.9 117.4L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3zM96 422.4V464c0 26.5 21.5 48 48 48h350.2L207.4 290.3C144.2 301.3 96 356 96 422.4z"/></svg>
          </div>
          <div class="text-center text-xs text-[#4e64ad] font-bold mt-2">Member<br>Withdrawal</div>
        </a>
        <a class="flex flex-col items-center" href="">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#4e64ad] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">&lt;!&ndash; Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) &ndash;&gt;<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z"/></svg>
          </div>
          <div class="text-center text-xs text-[#4e64ad] font-bold mt-2">Enrollment<br>Status Check</div>
        </a>
      </div>-->

      <div class="xl:hidden lg:hidden grid grid-cols-3 justify-items-center gap-2">
        <a class="flex flex-col items-center" href="/Mypage/Inquiry">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#2f88d0] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"/></svg>
          </div>
          <div class="text-center text-xs text-[#172e59] font-bold mt-2">1:1 Inquiries</div>
        </a>
        <a class="flex flex-col items-center" href="/Mypage/Notice">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#2f88d0] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"/></svg>
          </div>
          <div class="text-center text-xs text-[#172e59] font-bold mt-2">Announcements</div>
        </a>
        <a class="flex flex-col items-center" href="/Mypage/FAQ">
          <div class="flex justify-center items-center w-16 h-16 block bg-white hover:bg-[#172e59] text-[#2f88d0] hover:text-white rounded-md shadow-[#8c94ad] shadow-md">
            <svg class="fill-current w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32z"/></svg>
          </div>
          <div class="text-center text-xs text-[#172e59] font-bold mt-2">FAQ</div>
        </a>
      </div>
    </div>
  </div>

</template>

<script>
  import modalView from "../modalView";
  export default {
    name: "SideMenu",
    props:['laravel'],
    components : {
      modalView,
    },
    data: () => ({

      //show hide
      showMessage: false,
      showMessage2: false,
      laravel: window.Laravel,
      isLoggedIn: false,
      i18n: window.i18n,
      address_book_open:false,
      mypage_yn : 'Y',
      rank_cd:window.Laravel.rank_cd,
        user : [],
    }),
    mounted() {
      if (window.Laravel.isLoggedin) {
        this.isLoggedIn = true;
        this.user = window.Laravel.user;
        this.username = this.laravel.username;
      }
    },
    methods : {
      // 주소록 레이어 오픈
      close_address_book(){
        this.address_book_open = false;
      },
      open_address_book(){
        this.address_book_open = true;
      },
    }
  }
</script>

<style scoped>

</style>
