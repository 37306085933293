'use strict'
/**
 * api common option
 * api 내역
 * @type {{isLogin: boolean}}
 */

/**
 *  url 기본 경로
 */
const absoluteUrlPath = () => {
  return process.env.MIX_BACK_URL + '/api/'
}

/**
 * default option
 * @type {{isLogin: boolean}}
 */
const apiDefaultInfo = {
  isLogin: true
}

/**
 *  허용 가능한 api 정보
 */
const allowApiList = {
  isDirectMember: {
    ...apiDefaultInfo,
    path: 'isDirectMember'
  },
  directMemberJoin: {
    ...apiDefaultInfo,
    path: 'directMemberJoin'
  },
  dirCartCount: {
    ...apiDefaultInfo,
    path: 'dir_cart_count'
  },
  cartCount: {
    ...apiDefaultInfo,
    path: 'cartCount'
  },
  getMemberInfo: {
    ...apiDefaultInfo,
    path: 'getMemberInfo'
  }

}

export {absoluteUrlPath, allowApiList}
