<template>
  <loading :active="loading"
           :can-cancel="true"
           :opacity="0.0"
           :is-full-page="true"></loading>
</template>

<script>
import Loading from "vue3-loading-overlay";
import {getApiCall} from "../../assets/api/rest";
import alert from "vue3-simple-alert";

export default {
  name: 'DirectAuth',
  components: {Loading},
  props:['join_check'],
  data: () => ({
    // isDirect: (window.isDirect === true),
    loading: false,
  }),
  mounted() {
    this.isDirectMember();
  },
  methods: {
    isDirectMember() {

        this.loading = true;

        if ( window.Laravel.access_token === undefined){
          alert.alert('ログイン後利用可能です', "", "", {}).then((result) => {
            if (result) {
              window.location.replace('/');
              return false;
            }
          });

        }else {
          getApiCall('isDirectMember').then(response => {
            if (response.data.resultCode === 0) {

              // 가입여부 판단 : 가입된 회원이 접근하면 안되는 경우
              if (this.join_check === 'nonMember') {
                if (response.data.isDirectMember === true) {
                  alert.alert('もう入会された会員です', "", "", {}).then((result) => {
                    if (result) {
                      // window.location.replace('/dirInfo');
                      window.location.replace('/product/list/114');
                    }
                  });
                } else {
                  this.loading = false;
                }
                // 가입여부 판단 : 미가입된 사용자가 접근하면 안되는 경우
              } else {
                if (response.data.isDirectMember === false) {
                  alert.alert('アクセス権限がありません。', "", "", {}).then((result) => {
                    if (result) {
                      window.location.replace('/dirInfo');
                    }
                  });
                } else {
                  this.loading = false;
                }
              }
            }
          })
        }
      //}
    },
  }
};
</script>

<style scoped>

</style>
