<template>
    <div id="mem_info">

        <h2 class="myo_table_tit">{{ i18n.myoffice.dashboard.myinformation }}</h2>
        <br>
        <div class="member_info_button">
            <span class="btn dark2">
                <input type="button" onclick="location.href='/MyforinerUpload'" :value="i18n.myoffice.foreignerformupload2">
            </span>
        </div>

        <ul id="myo_form" class="mgb_30">
            <li class="item table_form" >
                <span class="item_title">{{ i18n.myoffice.MemberId }}</span>
                <span class="item_content">{{ mem.userid }}</span>
            </li>
            <li class="item table_form">
                <span class="item_title info_title">{{ i18n.myoffice.memberName }}</span>
                <span class="item_content info_content">{{ mem.username }}({{mem.username_eng}})</span>
            </li>
            <li class="item table_form">
                <span class="item_title">{{ i18n.myoffice.RegistrationDate }}</span>
                <span class="item_content">{{ mem.reg_date2 }}</span>
            </li>
            <li class="item table_form" v-if="mem.rank_cd !='00' ">
                <span class="item_title">{{ i18n.myoffice.Rank }}</span>
                <span class="item_content" style='position: relative;'>{{ mem.rank_name }}</span>
            </li>
            <li class="item item table_form" v-show="false">
                <span class="item_title">{{ i18n.myoffice.Campus }}</span>
                <span class="item_content">{{ mem.cnt_name }}</span>
            </li>
<!--            <li class="item item table_form">-->
<!--                <span class="item_title">{{ i18n.myoffice.PlacementSponsor }}</span>-->
<!--                <span class="item_content info_content">{{ mem.p_names }}</span>-->
<!--            </li>-->
            <li class="item item table_form">
                  <span class="item_title">{{ i18n.myoffice.EnrollmentSponsor }}</span>
                  <span class="item_content info_content">{{ mem.r_names }}</span>
            </li>
            <li class="item table_form" v-if="mem.rank_cd !='00' ">
              <span class="item_title">{{ i18n.myoffice.dashboard.goUrl }}</span>
              <span class="item_content info_content">
                      <span v-show="mem.xid"><button type="button" class="download_btn3"  v-on:click="copy_register_url(mem.xid)">{{ i18n.myoffice.Copy }}</button></span>
                  </span>
            </li>
<!--            <li class="item table_form" v-if="mem.rank_cd !='00' "> --><!-- 추후 회원등록증필요시 주석 해제 필요-->
            <li class="item table_form">
                <span class="item_title">{{ i18n.myoffice.dashboard.downFile }}</span>
                <span class="item_content info_content">
                    <span v-show="mem.xid"><button type="button" class="download_btn3"  v-on:click="go_Membership_Certificate(mem.xid)">{{ i18n.myoffice.MyMember.MembercardDownload }}</button></span>
                </span>
            </li>
            <!--li class="item item table_form">
                <span class="item_title">Membership Enrollment Form</span>
                <span class="item_content info_content">
                    <span v-if="doc_w9.file_name"><button type="button" class="download_btn3"  v-on:click="popup_member_form()">DownLoad</button></span>
                </span>
            </li-->

        </ul>

        <h2 class="myo_table_tit">{{ i18n.myoffice.PersonalInformation }}</h2>
        <ul id="myo_form">
          <li class="item item table_form" >
            <span class="item_title">{{ i18n.myoffice.gender }}</span>
            <span class="item_content">{{ mem.sex == "M" ? "男" : "女" }}</span>
          </li>
          <li class="item item table_form">
            <span class="item_title">{{ i18n.myoffice.birthday }}</span>
            <span class="item_content">{{ mem.birthday2 }}</span>
          </li>
          <li class="item item table_form">
            <span class="item_title">{{ i18n.myoffice.tel }}</span>
            <span class="item_content">{{ mem.tel }}</span>
          </li>
          <li class="item item table_form">
            <span class="item_title">{{ i18n.myoffice.handphone }}</span>
            <span class="item_content">{{ mem.mobile }}</span>
          </li>

<!--            <li class="item item table_form">-->
<!--                <span class="item_title">{{ i18n.myoffice.tel }}</span>-->
<!--                <span class="item_content">{{ mem.tel != "null" ? mem.tel : '' }}</span>-->
<!--            </li>-->

            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.email }}</span>
                <span class="item_content">{{ mem.e_mail }}</span>
            </li>
          <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.registerNo }}</span>
                <span class="item_content">T{{ mem.sa_state }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.Address }}</span>
                <span class="item_content">&nbsp;{{ mem.post }} {{ mem.addr1 }} {{ mem.addr2 }}</span>
            </li>
            <li class="item item table_form">
              <span class="item_title">国籍</span>
              <span class="item_content">{{ mem.nt_cd == "081" ? i18n.shop.Countries.JP : "外国" }}</span>
            </li>
            <li style="display: none !important;" class="item item table_form hidden">
                <span class="item_title">{{ i18n.myoffice.Select }}</span>
                <span class="item_content">
                  <p class="mgb_8">{{ i18n.myoffice.LookallowuplineList }} :
                    <span class="point_text">{{ myconfig.flag_4week == 'N' ? i18n.myoffice.MyMember.block : '' }} </span>
                    <span class="signiture">{{ myconfig.flag_4week == 'Y' ? i18n.myoffice.MyMember.allow : '' }}</span>
                  </p>
                    <p>{{ i18n.myoffice.LookmyofficeList }} :
                    <span class="point_text">{{ myconfig.flag_profit == 'N' ? i18n.myoffice.MyMember.block  : '' }}</span>
                    <span class="signiture">{{ myconfig.flag_profit == 'Y' ? i18n.myoffice.MyMember.allow : '' }}</span>
                  </p>
				        </span>
            </li>
<!--             헬스스위치 인증서 confirm_yn1, confirm_yn2 가 모두 Y일 경우에만 표기-->
            <li class="item item table_form" v-if="mem.health_info">
              <span class="item_title">ヘルスイッチプログラムリーダー</span><!-- 헬스 스위치 프로그램 리더 -->
              <span class="item_content">
<!--                인증된 경우-->
                <div v-if="mem.health_info.confirm_yn1 == 'Y' && mem.health_info.confirm_yn2 == 'Y'">
                  <div class="mgb_8" >
                    <span class="signiture">認証</span>
                    <span>{{mem.health_info.confirm_yn2_date}}</span>
                  </div>
                  <button type="button" class="download_btn3" onclick='go_print("hs");'>認定書ダウンロード</button><!-- 인증서 출력 -->
                </div>
<!--                비인증된 경우-->
                <div v-else class="mgb_8"><span class="point_text">非認証</span></div>
              </span>
            </li>
<!--          일본 공인강사 인증-->

          <li class="item item table_form" v-if="mem.certified_instructor_info">
            <span class="item_title">公認講師</span>
            <span class="item_content">
<!--							인증된 경우-->
            <div v-if="mem.certified_instructor_info.use_yn == 'Y'">
							<div class="mgb_8">
								<span class="signiture">認証</span>
								<span></span>
							</div>
							<button type="button" class="download_btn3" onclick='go_print("ci");'>認定書ダウンロード</button><!-- 인증서 출력 -->
            </div>

<!--						비인증된 경우-->
            <div v-else class="mgb_8"><span class="point_text">非認証</span></div>
					</span>
          </li>
        </ul>

        <div class="myo_condition_search2">
            <button type="button" class="reset_btn" onclick="location.href='/MyMemberWithdrawal'">{{ i18n.myoffice.withdrawal }}</button>
            <button type="button" class="search_btn" onclick="location.href='/MyPersonInfo/modify'">{{ i18n.myoffice.MemberInfoChange }}</button>
        </div>


        <h2 v-show="mem.user_kind != 0 || mem.rank_cd !='00'" class="myo_table_tit">{{ i18n.myoffice.AccountInfomation }}</h2>
        <ul v-show="mem.user_kind != 0 || mem.rank_cd !='00'" id="myo_form">
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.bankname }}</span>
                <span class="item_content">{{ mem.bank_name != "null" ? mem.bank_name : "" }}</span>
            </li>
            <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.bankaccountno }}</span>
                <span class="item_content">{{ mem.account != "null" ? mem.account : "" }}</span>
            </li>

          <li class="item item table_form">
                <span class="item_title">{{ i18n.myoffice.AccountHolder }}</span>
                <span class="item_content">{{ mem.depositor != "null" ? mem.depositor : "" }}</span>
          </li>
        </ul>
<!--        <h2 v-show="mem.user_kind != 0 || mem.rank_cd !='00'" class="myo_table_tit">{{ i18n.myoffice.Memberinfo.visainfo }}</h2>-->
<!--        <ul v-show="mem.user_kind != 0 || mem.rank_cd !='00'" id="myo_form">-->
<!--            <li v-if="mem.nt_cd !='082'" class="item item table_form">-->
<!--                <span class="item_title">{{ i18n.myoffice.Memberinfo.visakind }}</span>-->
<!--                <span class="item_content">-->
<!--&lt;!&ndash;                  <vSelect :options="visaList" label="value" :reduce="value => value.title" :clearable="false" v-model=""  ></vSelect>&ndash;&gt;-->
<!--                </span>-->
<!--            </li>-->
<!--            <li  class="item item table_form">-->
<!--              <span class="item_title">{{  i18n.myoffice.foreignercard }}</span>-->
<!--                <span v-if="mem.fileup2 == 'Y'" class="item_content">{{ mem.filecheck2 == "Y" ? i18n.myoffice.Notyetsummit : "" }}</span>-->
<!--                <span v-if="mem.fileup2 == null || mem.fileup2 == 'N'" class="item_content"><strong class="point_text">{{i18n.myoffice.Notyetsummit}}</strong></span>-->
<!--            </li>-->
<!--        </ul>-->
    </div>
</template>

<script>
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";
import Loading from "vue3-loading-overlay";

export default {
    name: "MyPersonInfo",
    components : {
        VueSimpleAlert,
    },
    data: () => ({
        i18n: window.i18n,
        mem: "",
        myconfig : "",
        state : "",
        doc_void : "",
        doc_w9 : {"file_name" : ""},
        rec_form : "",
        commition_check  : false,
        visaList : [
          {title : "1.在留資格", value : '1'},
          {title : "2.永住者", value : '2'},
          {title : "3.定住者", value : '3'},
          {title : "4.日本人の配偶者等", value : '4'},
          {title : "5.永住者の配偶者等", value : '5'},
          {title : "6.芸術", value : '6'},
          {title : "7.宗教", value : '7'},
          {title : "8.報道", value : '8'},
          {title : "9.法律会計業務", value : '9'},
          {title : "10.医療", value : '10'},
          {title : "11.研究", value : '11'},
          {title : "12.技術", value : '12'},
          {title : "13.人文知識", value : '13'},
          {title : "14.国際業務", value : '14'},
          {title : "15.企業内転勤", value : '15'},
          {title : "16.家族滞在", value : '16'}
      ],
    }),
    beforeCreate() {
        axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.mem = response.data;
            this.mem.addr2 = this.mem.addr2.replaceAll('|@|', ' ');
            this.mem.addr1 = this.mem.addr1.replaceAll('|@|', ' ');
        });

        axios.post(window.Laravel.back_url + '/api/getMyConfig', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.myconfig = response.data;

        });

    },methods:{
        goWidhtral(){
            VueSimpleAlert.alert("Please use the 1:1 inquiries to withdraw membership during the pre-registration period.","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
            });

        },
        copy_register_url(xid){
            this.detailshow = false;
            var link = window.Laravel.front_url+'/RegToken?xid='+encodeURIComponent(xid);

          var md = $.Zebra_Dialog('<div class="url_tit"><i class="fas fa-link"></i>&nbsp;会員登録 URL</div><textarea id="reg_url" readonly>'+link+'</textarea><div class="url_notice min-h-[20]"><div class="url_tit"><!--<i class="fas fa-lightbulb-exclamation" aria-hidden="true"></i>&nbsp;Notice--></div><!--Notice to join as a member, the sponsor is automatically designated for the left line based on the major performance.--></div>', {
                width: 1000,
                title:  i18n.myoffice.myofficeAlert.RegisterURLTit,
                type :  false,
                modal: true,
                overlay_close: false,
                buttons:
                    [
                        {caption: 'コピー', callback: function() {
                                $('#reg_url').select();
                                document.execCommand('copy');
                            }}
                    ]
            });
        },
        go_Membership_Certificate(xid){

            var link = window.Laravel.back_url+'/MembershipCertificate?xid='+encodeURIComponent(xid);
            if (is_iPhone()){
              location.href="gcoop://go?url={{ urlencode("+link+") }}";
            } else{
              if (navigator.userAgent.match(/jungbaksa/i) != null ) {
                window.open(link, '_self');
              }
              else {
                var ret = window.open('', '_blank');
                if (!ret) {
                  VueSimpleAlert.alert(i18n.myoffice.myofficeAlert.allowwebsitepopup,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
                  });
                }
                ret.location.href = link;
              }
            }
        },
        popup_member_form() {
            window.open("https://cdn3.gcooperp.com/"+this.doc_w9.path_name+this.doc_w9.file_name+".pdf");
        },

    }
}
</script>

<style>
/* 해당 페이지 URL 팝업에만 css 수정 적용 */
.ZebraDialog{background: #fff; border-radius: 20px !important;}
.ZebraDialog .ZebraDialog_Title{background: transparent !important; border-bottom:1px solid #ddd !important; padding-right: 30px !important; line-height: 1.2;}
.ZebraDialog .ZebraDialog_ButtonsOuter{background: transparent !important; border: 0px solid !important;}
.ZebraDialog .ZebraDialog_Buttons{ position: absolute; top: 210px; left: -15px;}
.ZebraDialog .ZebraDialog_Body{padding: 20px 20px 5px 20px !important;}
#reg_url{border-radius: 10px; border: 1px solid #fff; background: #eefaff!important; width: calc(100% - 30px)!important; height: 75px !important; font-size: 13px !important;}
.url_notice{margin-top:70px; padding-top: 10px; border-top:1px dotted #ddd;}
.url_tit{color: #5c98f3; margin-bottom:5px;}
</style>
