<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <direct_auth v-if="isDirect===true"></direct_auth>
    <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true"  >
        <address_book @get_info_address_book="get_info_address_book" @close_address_book="close_address_book" />
    </modalView>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search"/>
    </modalView>
    <!--    <modalView class="z-50" v-if="tax_detail_open" @close-modal="tax_detail_open = false" :harf="true"  >
          <tax_detail @set_address="set_address" @close_tax_detail="close_tax_detail" :tax_rate="tax_rate" />
        </modalView>-->

    <!--    <div>-->
    <!--      <address_book @close_address_book="close_address_book" @get_info_address_book="get_info_address_book" v-if="address_book_open" />-->
    <!--  </div>-->
    <section class="bg-[#f0f2f4]">
        <div class="container mx-auto " :class="[isDirect ? '' : 'py-6']">
            <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="flex flex-wrap">
                    <div class="w-full sm:w-3/4">
                        <div class="flex justify-between pb-8 max-w-8xl lg:max-w-7xl mx-auto" v-show="isDirect===false">
                            <button @click="link('/cart')" class="bg-[#2c80c4] font-semibold border hover:bg-indigo-600 py-3 text-sm text-white uppercase w-1/2 max-w-2xl"><a >買い物かご({{ products.length }})</a></button>
                            <button @click="link('/AsCart')" class="bg-white font-semibold hover:bg-gray-300 py-3 text-sm text-black uppercase w-1/2 max-w-2xl"><a >定期購入({{ other_products.length }})</a></button>
                        </div>

                        <div class="flex justify-between mb-2 lg:mb-6">

                            <h1 v-if="isDirect===true" class="font-semibold text-2xl">買い物かご</h1>
                            <h1 v-else class="font-semibold text-2xl">買い物かご</h1>


                            <h2 class="font-semibold text-lg md:text-xl">{{ products.length }} 件</h2>
                        </div>
                        <div class=" pb-2 mb-1 ">
                            <button class="float-left mt-1 p-2 xl:text-sm lg:text-sm text-xs text-[#4f65ae] bg-[#d1d5de] hover:bg-[#cbcfdd] rounded" @click="allSelect">全選択</button>
                            <button class="float-right mt-1 p-2 xl:text-sm lg:text-sm text-xs text-[#4f65ae] bg-[#d1d5de] hover:bg-[#cbcfdd] rounded" @click="removeSelect">選択削除</button>
                        </div>

                        <section class="clear-both	mt-14 bg-white shadow-zinc-500/40 shadow-md rounded-md xl:p-6 lg:p-6 p-3">

                            <div class="hidden lg:flex w-full" >
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-2/12 w-full p-2 border">製品情報</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-5/12 w-full p-2 border">数量</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-5/12 w-full p-2 border">製品金額</h3>
                            </div>
                            <!--// 장바구니 제품 목록-->
                            <div v-for="(product, i) in products" :key="product.seq_pd" v-if="isEnterProduct" class="sm:flex sm:items-center border-b border-slate-200 last:border-b-0 xl:py-5 lg:py-5 py-0 w-full">
                                <div class="xl:flex lg:flex w-full xl:w-7/12 lg:w-7/12 "> <!-- product -->
                                    <span class="p-2 flex items-center justify-between">
                                      <input type="checkbox" :name="product.seq_pd" :value="product.seq_pd" v-model="selectedItems" class="cursor-pointer text-[#4e64ad]">
                                      <a href="#" v-on:click.prevent="removeItem($event,i)" class="w-5 font-semibold hover:text-red-500 text-gray-500 text-xs xl:border lg:border border-slate-400 inline-block p-1 xl:hidden lg:hidden block">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                      </a>
                                    </span>
                                    <div class="flex w-full xl:px-0 lg:px-0 px-2">
                                        <a :href="(isDirect===true) ? '/product/item/' + product.seq_pd + '/direct' : '/product/item/' + product.seq_pd" class="inline-block">
                                          <div class="relative xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 aspect-w-1 aspect-h-1 border rounded-md overflow-hidden">
                                                <img v-if="product.images.length>0" class="xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 max-w-none	" v-bind:src="'https://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="" onerror="this.src='/images/error/noImage.png'">
                                                <div class="sold-out-box2" v-if="product.product.status == 2">
                                                    <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="sold-out-box2" v-if="product.product.status == 3">
                                                    <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="coming_box" v-if="product.product.status == 4">
                                                    <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                                                </div>
                                                <div class="badge_box" v-if="product.badges.image_link != ''">
                                                    <img :src="product.badges.image_link" alt="sold out" />
                                                </div>
                                            </div>
                                        </a>
                                        <div class="flex flex-col justify-between flex-grow relative px-2">
                                            <a :href="(isDirect===true) ? '/product/item/' + product.seq_pd + '/direct' : '/product/item/' + product.seq_pd" class="block h-full">
                                                <div class=" xl:text-base lg:text-base text-sm font-semibold leading-6 text-gray-800 mb-2">{{ product.product.pdt_name }}</div>
                                                <div class="text-red-500 text-xs lg:text-sm" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                                <p class="text-xs ">
                                                    {{ formatPrice(product.prices.amt) }}円
                                                    <span v-if="isDirect === false"> /
                                                      <span class="text-blue-500">PV {{ formatPrice(product.prices.pv,2) }}</span>
                                                    </span>
                                                </p>
                                                <div class="text-right absolute bottom-0 right-0 z-[5]">
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="xl:flex lg:flex hidden justify-center xl:w-2/12 lg:w-2/12 p-2">
                                    <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                        <button @click="decrement($event,i)" class="py-2 sm:py-1">
                                            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                        </button>
                                        <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)" class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                        <button @click="increment($event,i)" class="py-2 sm:py-1">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-center xl:w-2/12 lg:w-2/12 font-semibold text-md p-2 hidden xl:block lg:block">
                                    <p class="text-sm xl:text-md lg:text-md ">{{ formatPrice(product.prices.amt * product.qty) }}円</p>
                                    <p v-if="isDirect === false" class="text-sm xl:text-md lg:text-md text-blue-500">PV {{ formatPrice(product.prices.pv * product.qty,2) }}</p>
                                </div>

                                <div class="text-center xl:w-1/12 lg:w-1/12 p-2 flex justify-center">
                                    <a href="#" v-on:click.prevent="removeItem($event,i)" class="border border-gray-300 xl:flex lg:flex hidden w-8 h-8  items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="hover:text-red-500 text-gray-500 text-xs w-4 h-4"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                    </a>
                                </div>

                                <!-- 모바일 카운트 토탈 -->
                                <div class="xl:hidden lg:hidden flex xl:justify-around lg:justify-around justify-between">
                                    <div class="flex justify-center xl:w-1/2 lg:w-1/2 w-full p-2 ">
                                        <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                            <button @click="decrement($event,i)" class="py-2 sm:py-1">
                                                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                            </button>
                                            <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)" class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                            <button @click="increment($event,i)" class="py-2 sm:py-1">
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="text-center xl:w-1/2 lg:w-1/2 w-full font-semibold text-md p-2 ">
                                        <p class="text-sm xl:text-md lg:text-md ">{{ formatPrice(product.prices.amt * product.qty) }}円</p>
                                        <p class="text-sm xl:text-md lg:text-md text-blue-500">{{ formatPrice(product.prices.pv * product.qty,2) }}PV</p>
                                    </div>
                                </div>

                            </div>


                            <div class="flex justify-center my-4">
                                <a :href="addOrderLink" class="font-semibold text-blue-500 text-sm">
                                    追加注文
                                </a>
                            </div>
                        </section>


                        <div class="w-full text-center mb-12 pt-3 " v-if="isEnterProduct==false">
                            <button @click="isEnterProduct = !isEnterProduct" class="bg-[#2c80c4] text-white px-10 py-2 rounded-xl">View Product Details</button>
                        </div>


                        <div class="w-full text-center mb-12 mt-3">
                            <button v-if="false && !isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-[#2c80c4] text-white px-10 py-2 rounded-xl">Enter shipping information</button>
                            <button v-if="false && isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-gray-200 text-white px-10 py-2 rounded-xl">Close shipping information</button>
                        </div>
                        <div class="mb-12 bg-white shadow-zinc-500/40 shadow-md rounded-md p-6" v-if="isEnterShipping">
                            <div class="flex justify-between border-b pb-8">
                                <h1 class="text-lg xl:text-xl lg:text-xl font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">配送地情報</h1>
                            </div>
                            <form name="shipping_info" class="justify-center w-full mx-auto" method="post">
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="order_seq">
                                <input type="hidden" name="shipping_fee">
                                <input type="hidden" name="tax_fee">
                                <input type="hidden" name="pay_total_amt">
                                <input type="hidden" name="postcode" :value='this.shipping_info["d_post"]'>
                                <input type="hidden" name="city" :value='this.shipping_info["d_city"]'>
                                <input type="hidden" name="state" :value='this.shipping_info["d_state"]'>
                                <input type="hidden" name="address2_1" :value='this.shipping_info["d_address"]'>
                                <input type="hidden" name="address2_2" :value='this.shipping_info["d_address2"]'>
                                <div class="mt-10">

                                    <div class="space-x-0 sm:flex lg:space-x-4">
                                        <div class="w-full flex sm:w-1/4" >
                                            <input name="order-info" id="order-info1" ref="order-info1" type="radio" class="mt-0.5" value="1" @click="change_shipping('Same')" checked>
                                            <label for="order-info1" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                注文者情報と同じ </label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4">
                                            <input name="order-info" id="order-info2" type="radio" class="mt-0.5" value="2" @click="change_shipping('Enroll')" >
                                            <label for="order-info2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                新しい配送地</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4" >
                                            <input name="order-info" id="order-info3" type="radio" class="mt-0.5" value="3" @click="change_shipping('Manage')">
                                            <label for="order-info3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                配送地管理</label>
                                        </div>
                                    </div>

                                    <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box w-full lg:w-1/2 mt-6">
                                            <input name="d_firstName" id="d_firstName" ref="d_firstname" type="text" placeholder=" " v-model='this.shipping_info["d_name"]'
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <label for="d_firstName" class="md-label block mb-6 text-sm font-semibold text-gray-500">名前</label>
                                        </div>
                                        <div class="md-input-box w-full lg:w-1/2 mt-6 ">
                                          <input name="mobile" id="mobile" ref="d_mobile" type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder=" " v-model='this.shipping_info["d_mobile"]'
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <label for="mobile" class="md-label block mb-3 text-sm font-semibold text-gray-500">携帯電話</label>
                                        </div>
                                    </div>

                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                      <div class="md-input-box relative z-[11] mt-6 w-full lg:w-1/2">
                                        <input name="postcode" id="postcode" ref="postcode" type="text" placeholder=" " v-model='this.shipping_info["d_post"]' readonly @click="search_address('M')"
                                               class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="40">
                                        <label for="postcode" class="md-label block mb-6 text-sm font-semibold text-gray-500">郵便番号</label>
                                      </div>
                                      <div class="md-input-box relative z-[11] mt-6 w-full lg:w-1/2" v-show="deli_kind !='1'">
                                        <input type="button" @click="search_address('M')" value="住所検索" class="bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full cursor-pointer">
                                      </div>
                                    </div>

                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box relative z-[13] mt-6 w-full lg:w-1/2 ">
                                            <input name="city" id="city" ref="d_city" type="text" placeholder=" " v-model='this.shipping_info["d_addr1"]' readonly @click="search_address('M')"
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                            <label for="city" class="md-label block mb-6 text-sm font-semibold text-gray-500">住所</label>
                                        </div>

                                        <div class="md-input-box w-full lg:w-1/2 mt-6">
                                            <input name="address" id="address" ref="d_address" type="text" placeholder=" " :readonly="deli_kind =='1'" v-model='this.shipping_info["d_addr2"]'
                                                   @keyup="regText('d_addr2')"  class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                            <label for="address" class="md-label block mb-6 text-sm font-semibold text-gray-500">番地(建物名・部屋番号まで入力してください)</label>
                                            <div v-if="isDirect===true" class="text-xs text-red-400 pl-1">例: 1-28-12 4F</div>
                                            <div v-else class="text-xs text-red-400 pl-1">例: 1-28-12 VORT 麻布 maxim 4F</div>
                                        </div>
                                    </div>

                                  <!-- S : 비고란 -->
                                  <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                    <div class="md-input-box relative z-[13] mt-6 w-full ">
                                      <input name="r_memo" id="r_memo" type="text" placeholder=" " v-model='this.shipping_info["r_memo"]'
                                             class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="100">
                                      <label for="r_memo" class="md-label block mb-6 text-sm font-semibold text-gray-500">{{ i18n.shop.shopping.ShippingInstructions }}</label>
                                      <div class="text-xs text-red-400 pl-1">
                                        例 : 14-16時指定、納品書不要等 <br/>
                                        ※置き配、配達日指定はできません。
                                      </div>
                                    </div>
                                  </div>
                                  <!-- S : 비고란 -->
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="summary" class="w-full sm:w-1/4 xl:px-4 lg:px-4 px-0">
                        <div class="px-6 py-6 bg-white shadow-zinc-500/40 shadow-md rounded-md">
                            <h1 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">決済金額</h1>
                            <div class="flex justify-between mt-10 mb-5 py-1">
                                <label class="font-semibold uppercase w-3/5">注文製品 ({{ products.length }})</label>
                                <div class="font-semibold w-2/5">
                                    <p class="hidden text-base text-right">{{ formatPrice(sum_item.amt) }}円</p>
                                    <p class="hidden text-blue-500 text-base text-right">{{ formatPrice(sum_item.vat,1) }}円</p>
                                    <p class="text-base text-right">{{ formatPrice(sum_item.amt) }}円</p>
                                    <p v-show="false" class="text-gray-700 text-sm text-right">{{ formatPrice(total_weight,3) }} lbs</p>
                                </div>
                                <div v-if="isDirect===true" class="Q_mark_icon" style="margin-left:10px;display: flex;align-items: center;">
                                  <i class="fal fa-question-circle direct-question-circle" aria-hidden="true" @click="amtTooltip"></i>
                                </div>
                            </div>
                            <div v-if="isDirect === false" class="flex justify-between mt-10 mb-5 py-1">
                                <label class="font-semibold uppercase w-3/5">PV</label>
                                <div class="font-semibold w-2/5">
                                    <p class="text-blue-500 text-base text-right">{{ formatPrice(sum_item.bv,2) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between mt-10 mb-5 py-1">
                                <label class="font-semibold uppercase w-3/5">送料</label>
                                <div class="font-semibold w-2/5">
                                    <p class="text-lg text-right">{{ formatPrice(shipping_selected) }}円</p>
                                </div>
                                <div v-if="false && isDirect===true" class="Q_mark_icon" style="margin-left:10px;display: flex;align-items: center;">
                                  <i class="fal fa-question-circle direct-question-circle" aria-hidden="true" @click="deliveryChargeTooltip"></i>
                                </div>
                            </div>
                            <div v-if="isDirect === true" class="flex justify-between py-1">
                              <label class="font-semibold text-xs uppercase text-red-400">
                                * ただし、離島・へき地など一部の地域では追加料金が必要となる場合があります。
                              </label>
                            </div>
                            <div v-if="false && isDirect===false" class="border-t mt-8">
                                <div class="flex justify-between py-6">
                                    <label class="font-semibold uppercase w-3/5">積立ポイント</label>
                                    <div class="font-semibold w-2/5">
                                        <p class="text-lg text-right">{{ formatPrice(point) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button v-show="block" @click="order" class="bg-[#2c80c4] hover:bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full mt-4">決済</button>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "../modalView";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Swal from 'sweetalert2'
import { Tooltip } from "floating-vue";
import datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
    name: "Cart",
    components : {
        datepicker,
        // address_book,
        vSelect,
        alert,
        modalView,
        Loading,
        Swal,
        VTooltip : Tooltip
    },
    props: ['orderer','fail_message','mem_center','last_order_ceter','block'],
    setup() {
        return {
            // products,
        }
    },
    watch: {
        insurance_yn : function (val){
            if(val == 'Y'){
                this.insurance_yn = "Y";
            }else{
                this.insurance_yn = "N";
            }
        },
        ups_kind : function (val){
            if(val == '1'){
                this.ord_deli_price = this.ground_price;
            }else if(val == '2'){
                this.ord_deli_price = this.signature_price;
            }
        },
        pickup_cd: function(val){
            if(val != '0'){
                this.pickup_list.forEach((value, index) => {
                    if(value.pickup_cd == val){
                        this.shipping_info["d_state"] = value.state;
                        this.shipping_info["d_city"] = value.city;
                        this.shipping_info["d_post"] = value.zip_code;
                        this.shipping_info["d_address"] = value.addressLine1;
                        this.shipping_info["d_address2"] = value.addressLine2;
                        this.shipping_info["country"] = "USA";

                    }
                });

            }
        }
    },
    data: () => ({
        i18n: window.i18n,
        isDirect : (window.isDirect === true),
        addOrderLink : (window.isDirect===true) ? '/product/list/114' : '/product/list/all',
        paymentLink : (window.isDirect===true) ? '/directOrder' : '/order',
        title: [],
        products: [],
        max_qty: 200,
        sum_item: [],
        total_cost: 0,
        total_weight: 0,
        fee_shipping: [],
        shipping_selected: 0,
        fee_tax: [],
        tax_selected: 0,
        sub_delipay:0,
        isEnterShipping: true,
        isEnterProduct: true,
        shipping_info: [],
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        selectedItems: [],
        state_list: [],
        city_list: [],
        street_list: [],
        post_list: [],
        center_list: [],
        msg_post:'',
        address_book_open: Boolean,  // 주소록 레이어 오픈
        loading:false,
        onCancel:false,
        address_search_open:false,
        tax_detail_open : false,
        is_order_possible : window.Laravel.is_order_possible,
        other_products : [],
        commition_cnt_cd : "",
        radioCondition : "1",
        ups_kind : "1",
        insurance_yn : "",
        insurance_price : 0,
        point : 0,
        o_name : "",
        o_mobile : "",
        o_post : "",
        o_addr1 : "",
        o_addr2 : "",
        address_kind : "M",
        deli_kind : "",
        ord_deli_price : "",
        signature_price : 0,
        ground_price : 0,
        gel_price : 0,
        pickup_list : [
            {"pickup_cd" : "0","pickup_name" : "Select Pickup"},
            {"pickup_cd" : "1","pickup_name" : "3PL","zip_code" : "90001","city" : "Los Angeles","state" : "CA","addressLine1" : "6710 Wilson Ave","addressLine2" : ""},
            {"pickup_cd" : "2","pickup_name" : "LA OFFICE","zip_code" : "90010","city" : "Los Angeles","state" : "CA","addressLine1" : "3435 Wilshire Blvd","addressLine2" : "Ste 2125"},
            //{"pickup_cd" : "3","pickup_name" : "GSC","zip_code" : "90040","city" : "COMMERCE","state" : "CA","addressLine1" : "5835 E 61st st","addressLine2" : ""}
        ],
        pickup_time_list : [
            {"pickup_cd" : "0","pickup_name" : "Select Pickup Time"},
            {"pickup_cd" : "13:00~13:20","pickup_name" : "13:00~13:20"},
            {"pickup_cd" : "13:20~13:40","pickup_name" : "13:20~13:40"},
            {"pickup_cd" : "13:40~14:00","pickup_name" : "13:40~14:00"},
            {"pickup_cd" : "14:00~14:20","pickup_name" : "14:00~14:20"},
            {"pickup_cd" : "14:20~14:40","pickup_name" : "14:20~14:40"},
            {"pickup_cd" : "14:40~15:00","pickup_name" : "14:40~15:00"},
            {"pickup_cd" : "15:00~15:20","pickup_name" : "15:00~15:20"},
            {"pickup_cd" : "15:20~15:40","pickup_name" : "15:20~15:40"},
            {"pickup_cd" : "15:40~16:00","pickup_name" : "15:40~16:00"},
        ],
        pickup_cd : "0",
        pickup_time_cd : "0",
        format : "MM/dd/yyyy",
        pickup_date : "",
        start_date : date_min_week,
        end_date : date_max_week,
    }),
    created() {
        this.loading = true;
        this.address_book_open = false;
        this.change_shipping('');
        this.change_billing('');
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        this.sum_item.deliveryFee = 0;
        // 장바구니 가져오기
        this.getCart();
        // this.getCenterList();
        if(this.fail_message != ""){
            alert.alert(this.fail_message,"","",{returnFocus : false,  didClose: () => {
                    this.$refs.d_firstname.focus();
                }, didDestroy: ()=>{
                    this.$refs.d_firstname.focus();
                }
            }).then((result) => {
                if(result){
                    this.$refs.d_firstname.focus();
                }
            });
        }
    },
    mounted() {

    },
    methods:{



        amtTooltip() {
          Swal.fire({
            icon: 'info',
            html: `<div><ul style="text-align:center;"><li>個人輸入となる為、</li><li><span style="color:#ff0000;">合計金額が16,667円以上</span>の場合、</li><li>関税・消費税等が課税され、</li><li><span style="color:#ff0000;text-decoration:underline">関税等の費用は全て会員様負担</span>となります。</li></ul></div>`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: true,
          }).then((result) => {
          })
        },
        minimumAmtTooltip() {
          alert.alert('合計金額５万円以上のみ注文可能です。');
          return ;
        },
        maxAmtTooltip() {
          Swal.fire({
            icon: 'warning',
            // html: `<div><ul style="text-align:center;"><li><span style="color:#ff0000;">輸入通関手続き簡素化のため</span>、</li><li>合計金額<span style="color:#ff0000;font-weight: 600;">199,900円(送料5,000円含む)</span>以上の</li><li>決済は出来ません。</li></ul></div>`,

            html: `<div><ul style="text-align:center;"><li><span style="color:#ff0000;">輸入通関手続き簡素化のため</span>、</li><li>合計金額<span style="color:#ff0000;font-weight: 600;">199,900円</span>以上の</li><li>決済は出来ません。</li></ul></div>`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: true,
          }).then((result) => {
          })
        },
        deliveryChargeTooltip() {
          alert.alert('送料(EMS)は一律5,000円になります。');
        },

        regText(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replaceAll( reg, "");
            this.shipping_info[colum] = text;
        },
        regNumber(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replaceAll(/[^0-9]/g,'');
            this.shipping_info[colum] = text;
        },
        // 장바구니 가져오기
        getCart(){
            this.loading = true;
            const apiPath = (this.isDirect===true) ? '/api/dir_cart' : '/api/cart';

            axios.post(process.env.MIX_BACK_URL+apiPath,{
            // axios.post(process.env.MIX_BACK_URL+'/api/cart',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.products = response.data.cart_info;
                    if(this.products != []) { // 상품이 있으면 배송비, 택스 처리
                        this.total_weight = parseFloat(response.data.weight_info.result).toFixed(3);
                        if(this.deli_kind != "1"){
                            //this.getShippingFee();
                            this.loading = false;

                        }

                    }

                    this.sum_item.price = this.sumPrice(this.products);
                    this.sum_item.vat = this.sumVat(this.products);
                    // this.point = this.sum_item.price * 0.05;
                    this.sum_item.amt = this.sumAmt(this.products);
                    this.sum_item.bv = this.sumBV(this.products);
                    this.point = Math.floor(this.sum_item.bv *100 * 0.05);
                    this.getShippingFee();
                })
                .catch(e => {
                });
            axios.post(process.env.MIX_BACK_URL+'/api/getAsCartList',{
                bz_cd: window.Laravel.bz_cd,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        this.other_products = response.data;
                        this.loading = false;   // this.getCartList(response.data);
                })
            .catch(e => {
            });

        },
        getCenterList(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCenter',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.center_list = response.data;
                })
                .catch(e => {
                });

        },
        get_info_address_book(id)
        {
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
                seq:id
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(this.address_kind == "B"){
                        this.o_firstname = response.data.r_firstname;
                        this.o_lastname = response.data.r_lastname;
                        // this.o_state = (response.data.r_addr1).split("|@|")[1];
                        this.o_city = (response.data.r_addr1).split("|@|")[0];
                        this.o_post = response.data.r_post;
                        this.o_mobile = response.data.r_mobile;
                        this.o_addr2_1 = (response.data.r_addr1).replace("|@|", "");
                        this.o_addr2_2 = (response.data.r_addr2).replace("|@|", "");
                        // this.o_country = response.data.r_country;
                        this.address_book_open = false;
                        this.loading = false;
                    }else{
                        this.shipping_info["d_name"] = response.data.r_firstname + ' ' + response.data.r_lastname;
                        // this.shipping_info["d_state"] = (response.data.r_addr1).split("|@|")[1];
                        this.shipping_info["d_city"] = (response.data.r_addr1).split("|@|")[0];
                        this.shipping_info["d_post"] = response.data.r_post;
                        this.shipping_info["d_telephone"] = response.data.r_tel;
                        this.shipping_info["d_mobile"] = response.data.r_mobile;
                        this.shipping_info["d_addr1"] = (response.data.r_addr1).replace("|@|", "");
                        this.shipping_info["d_addr2"] = (response.data.r_addr2).replace("|@|", "");
                        // this.shipping_info["country"] = response.data.r_country;

                        this.address_book_open = false;
                        this.loading = false;

                        //this.getShippingFee();
                    }
                })
        },
        // 수량 변경
        decrement(e,i){
            this.loading = true;
            if(this.products[i].qty > 1) {
                this.products[i].qty--;
                this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
                //this.getCart();
            }else{
              this.loading = false;
            }
        },
        increment(e,i){
            this.loading = true;
            if(this.products[i].qty < this.max_qty) {
                this.products[i].qty++;
                this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
                //this.getCart();
            }else{
              this.loading = false;
            }
        },
        changeQty(e,i){
            this.loading = true;
            if(this.products[i].qty < 1) {
                this.products[i].qty = 1;
                this.totalCost(this.products);
            }else if(this.products[i].qty > this.max_qty) {
                this.products[i].qty = this.max_qty;
                this.totalCost(this.products);
            }
            this.setChangeQty(this.products[i].seq_pd,this.products[i].qty);
            //this.getCart();
        },
        setChangeQty(item, qty){

          const apiPath = (this.isDirect===true) ? '/api/change_dir_qty' : '/api/change_qty';

            axios.post(process.env.MIX_BACK_URL+apiPath,{
            // axios.post(process.env.MIX_BACK_URL+'/api/change_qty',{
                item: item,
                qty: qty,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.getCart();
                    this.emitter.emit('getCountCartItems', '')

                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                });
        },
        // 장바구니 아이템 삭제
        removeItem(e,i){
            this.loading = true;
          const apiPath = (this.isDirect===true) ? '/api/remove_dir_cart' : '/api/remove_cart';

          console.log(apiPath)

          axios.post(process.env.MIX_BACK_URL+apiPath,{
          // axios.post(process.env.MIX_BACK_URL+'/api/remove_cart',{
              item: this.products[i].seq_pd,
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
              .then(response => {
                  this.loading = true;
                  if(response.data.msg){
                      alert(response.data.msg);
                  }
                  this.getCart(window.Laravel.bz_cd);
                  this.emitter.emit('getCountCartItems', '')
              });
        },
        // 선택 아이템 삭제
        removeSelect(){

            const apiPath = (this.isDirect===true) ? '/api/remove_dir_cart' : '/api/remove_cart';

            if(this.selectedItems.length === 0){
                alert.alert("製品を選択してください");
                return false;
            }
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+apiPath,{
                item: this.selectedItems,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.selectedItems = [];
                    this.getCart(window.Laravel.bz_cd);
                    this.emitter.emit('getCountCartItems', '')
                });
        },
        // 쇼핑 계속하기
        prevUrl(){
            // window.history.go(-1);//
          window.location.href = '/';
        },
        isJapaneseCheck(str){
          const japaneseRegex = /[\u3040-\u309F\u30A0-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uFF66-\uFF9F]+/;
          return (japaneseRegex.test(str))
        },
        // 주문하기
        async order() {


          const totalAmt = parseInt(this.sum_item.amt) + parseInt(this.shipping_selected);

            // if (this.sum_item.bv < 3000) {
            //     alert.alert('3,000PV以上で決済が可能です。');
            //     return false;
            // }

          if ( this.isDirect === true ){
            if ( totalAmt < 50000  ) {
              this.minimumAmtTooltip();
              return false;

            } else if ( totalAmt >= 199900 ) {
              this.maxAmtTooltip();
              return false;

            } else if(this.isJapaneseCheck(this.shipping_info["d_name"]) || this.shipping_info["d_name"]===''){
              alert.alert('氏名を英字で入力して下さい。','','',{
              }).then((result) => {
                if(result){
                  this.shipping_info["d_name"] = '';
                  setTimeout(() => {
                    this.$refs.d_firstname.focus();
                  }, 500);
                }
              });
              return ;

            }else if(this.isJapaneseCheck(this.shipping_info["d_addr1"])){
                alert.alert('郵便番号を利用してお届け先の住所を英文でご記入ください。','','',{
                }).then((result) => {
                  if(result){
                    this.shipping_info["d_post"] = '';
                    this.shipping_info["d_addr1"] = '';
                    this.shipping_info["d_addr2"] = '';
                    setTimeout(() => {
                      this.$refs.postcode.focus();
                    }, 500);
                  }
                });
              return ;
            }else if(this.isJapaneseCheck(this.shipping_info["d_addr2"])){
              alert.alert('詳細住所を英文で入力してください。','','',{
              }).then((result) => {
                if(result){
                  this.shipping_info["d_addr2"] = '';
                  setTimeout(() => {
                    this.$refs.d_address.focus();
                  }, 500);
                }
              });
              return ;
            }
          }

            if (this.sum_item.amt == 0) {
                alert.alert('買い物かごに製品がありません。');
                return false;
            }

            if (!this.shipping_info["d_name"]) {
                // alert.alert('受取人の名前を記入してください。');
              alert.alert('受取人の名前を記入してください。', "", "", {}).then((result) => {
                if (result) {
                  setTimeout(() => {
                    this.$refs.d_firstname.focus();
                  }, 500);
                }
              });
              return false;
            }
            if (!this.shipping_info["d_mobile"]) {
                // alert.alert('受取人の連絡先(電話または携帯電話)を正確に記入してください。');
                alert.alert('受取人の連絡先(電話または携帯電話)を正確に記入してください。', "", "", {}).then((result) => {
                  if (result) {
                    setTimeout(() => {
                      this.$refs.d_mobile.focus();
                    }, 500);
                  }
                });
                return false;
            }
            if (!this.shipping_info["d_addr1"]) {
              alert.alert('郵便番号を利用して配送先の住所を正確に記入してください。');
              return false;
            }
            if (!this.shipping_info["d_addr2"]) {
                // alert.alert('郵便番号を利用して配送先の住所を正確に記入してください。');
              alert.alert('郵便番号を利用してお届け先の詳細住所を正確にご記入ください。', "", "", {}).then((result) => {
                if (result) {
                  setTimeout(() => {
                    this.$refs.d_address.focus();
                  }, 500);
                }
              });

              return false;
            }

            this.loading = true;
            //await this.sleep(5000);
            let formdata = new FormData();
            formdata.append("_token", this.csrf);
            formdata.append("isDirect", this.isDirect);
            formdata.append("bz_cd", window.Laravel.bz_cd);
            formdata.append("client_ip", window.Laravel.clientIp);
            formdata.append("orderHost", location.host);
            formdata.append("orderNation", window.Laravel.bz_cd);
            formdata.append("orderPath", '일반주문');
            formdata.append("sumPrice", this.sum_item.price);
            formdata.append("sumVat", this.sum_item.vat);
            formdata.append("sumAmt", this.sum_item.amt);
            formdata.append("sumBv", this.sum_item.bv);
            formdata.append("shippingFee", this.shipping_selected);
            formdata.append("taxFee", this.tax_selected);
            formdata.append("name", this.shipping_info.d_name);
            formdata.append("mobile", this.shipping_info.d_mobile);
            formdata.append("addr1", this.shipping_info.d_addr1);
            formdata.append("addr2", this.shipping_info.d_addr2);
            formdata.append("post", this.shipping_info.d_post);
            formdata.append("note", "");
            formdata.append("deli_kind", this.deli_kind);
            formdata.append("note", this.shipping_info.r_memo ?? ''); // 비고란

            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/order', formdata,
                {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    if (response.data.msg) {
                        alert.alert(response.data.msg);
                        this.loading = false;
                    }
                    if (response.data.result == 'Success') {
                        let form = document.shipping_info;
                        // form.action = '/order';
                        form.action = this.paymentLink;
                        form.order_seq.value = response.data.order_seq;
                        form.shipping_fee.value = this.shipping_selected;
                        form.tax_fee.value = this.tax_selected;
                        form.pay_total_amt.value = this.total_cost;
                        form.address2_1.value = this.shipping_info["d_addr1"];
                        form.address2_2.value = this.shipping_info["d_addr2"];
                        // this.loading = false;
                        form.submit();
                    }
                })
                .catch(e => {
                    this.loading = false;
                });


            // axios.post(process.env.MIX_BACK_URL + '/api/getShippingFee', {
            //     bz_cd: window.Laravel.bz_cd,
            //     products: this.products,
            //     state: this.shipping_info["d_state"],
            //     postcode: this.shipping_info["d_post"],
            //     ups_kind: this.ups_kind,
            //     insurance_yn: this.insurance_yn,
            //     country: this.shipping_info["country"],
            //     deli_kind: this.deli_kind
            // }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
            //     .then(response => {
            //         this.insurance_price = response.data.insurance_price;
            //         this.signature_price = response.data.signature_price;
            //         this.ground_price = response.data.ground_price;
            //         this.ord_deli_price = response.data.ground_price;
            //         this.gel_price = response.data.gel_price;
            //
            //         formdata.append("insurance_price", this.insurance_price);
            //         formdata.append("ord_deli_price", this.ord_deli_price);
            //         formdata.append("signature_price", this.signature_price);
            //         formdata.append("ground_price", this.ground_price);
            //         formdata.append("gel_price", this.gel_price);
            //
            //         formdata.append("_method", "PUT");
            //     });
        },
        getShippingFee(){
            var totalAmt = this.sum_item.amt;
            var post = this.shipping_info.d_post;
            var is_jp_gfeata = 0;

            if ( this.isDirect === true) {
              this.shipping_selected = "0";
            }else {
              if (parseInt(post.replace("-", "")) >= 9000000 && parseInt(post.replace("-", "")) < "9099999") {
                if (parseInt(totalAmt) < 10000) {
                  this.shipping_selected = "1400";
                } else {
                  this.shipping_selected = "2000";
                }
              } else {
                if (parseInt(totalAmt) < 10000) {
                  this.shipping_selected = "600";

                } else {
                  this.shipping_selected = "0";
                }
              }
            }
        },
        totalCost(data){  // 토탈 금액 합산 기능

            this.sum_item.price = this.sumPrice(data);
            this.sum_item.vat = this.sumVat(data);
            this.sum_item.amt = this.sumAmt(data);
            this.sum_item.bv = this.sumBV(data);
            this.total_cost = this.sum_item.amt;
            if (this.tax_selected) this.total_cost += parseFloat(this.tax_selected);

        },
        sumPrice(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.price*el.qty), 0);
        },
        sumVat(data) { // 상
            // 품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.vat*el.qty), 0);
        },
        sumAmt(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.amt*el.qty), 0);
        },
        sumBV(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.prices.pv*el.qty), 0);
        },
        change_billing(data){  // 배송지 변경
            this.msg_post = "";
            if(data=='Same' || data == '') {
                this.o_firstname = this.orderer["o_name"];
                this.o_addr1 = this.orderer["o_addr1"];
                this.o_addr2 = this.orderer["o_addr2"];
                this.o_post = this.orderer["o_post"];
                this.o_mobile = this.orderer["o_mobile"];
                // this.o_country = this.orderer["o_country"] ?? "USA";
            }else if(data=='Above' ) {
                this.o_firstname = this.shipping_info["o_name"];
                this.o_addr1 = this.orderer["o_addr1"];
                this.o_addr2 = this.orderer["o_addr2"];
                this.o_post = this.shipping_info["d_post"];
                this.o_mobile = this.shipping_info["d_mobile"];
                // this.o_country = this.shipping_info["country"] ;
            }else{
                this.o_name = '';
                this.o_post = '';
                this.o_addr1 = '';
                this.o_addr2 = '';
                this.o_mobile = '';
                this.o_country = '';
            }
            if (data=='Manage'){
                this.address_kind = "B";
                this.address_book_open = true;
            }else{
                // this.address_book_open = false;
            }
        },
        change_shipping(data){  // 배송지 변경
            this.msg_post = "";
            if(data=='Same' || data == '') {
                this.shipping_info["d_name"] = this.orderer["o_name"];
                this.shipping_info["d_addr2"] = this.orderer["o_addr2"];
                this.shipping_info["d_addr1"] = this.orderer["o_addr1"];
                this.shipping_info["d_post"] = this.orderer["o_post"];
                this.shipping_info["d_telephone"] = this.orderer["o_telephone"];
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];
                // this.shipping_info["country"] = this.orderer["o_country"] ?? "USA";
                this.deli_kind = "2";
                if(data == 'Same'){
                    //this.getShippingFee()
                }
            }else{
                this.shipping_info["d_name"] = '';
                this.shipping_info["d_post"] = '';
                this.shipping_info["d_addr2"] = '';
                this.shipping_info["d_addr1"] = '';
                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = '';
                // this.shipping_info["country"] = '';
                this.deli_kind = "2";
            }

            if (data=='Manage'){
                this.address_kind = "M";
                this.address_book_open = true;
            }else{
                // this.address_book_open = false;
            }
        },
        // 주소록 레이어 오픈
        close_address_book(){
            this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value,digit) {
            let val = val = (value/1).toFixed(digit).replace('.', '.')
            val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            //val = (value/1).toFixed(digit).replace(/(.?0+$)/, '')// 소수점 끝자리 0 제거
            return val;
        },
        search_address(addr_kind){
            this.address_kind = addr_kind;
            if(this.deli_kind !='1'){
                this.address_search_open = true;
            }
        },

        tax_detail(){
            this.tax_detail_open = true;
        },
        set_address(value){
            if(this.address_kind == "B"){
                this.address_search_open = false;
                this.o_addr1   = value.address + " " + value.address2 + " " + value.address3;
                this.o_post    = value.postcode;
                this.o_country = value.country;
            }else{
                this.address_search_open      = false;
                this.shipping_info["d_addr1"] = value.address + " " + value.address2 + " " + value.address3;
                this.shipping_info["d_city"]  = value.address;
                this.shipping_info["d_post"]  = value.postcode;
                //
            }
            this.getShippingFee();
        },
        close_address_search(){
            this.address_search_open = false;
        },
        close_tax_detail(){
            this.tax_detail_open = false;
        },
        link(value){
            location.href = value;
        },
        allSelect(){
            if(this.selectedItems.length > 0){
                this.selectedItems = [];
            }else{
                this.products.forEach((value, index) => {
                    this.selectedItems.push(value.seq_pd);
                });


            }

        }
    }
}
</script>
<style>
.vs__search {
    font-size: 1.5em;
    font-weight: 500;
    padding: 0.05rem;
    border: none;
    /* margin-top: 35px; */
}
.badge_box{position: absolute; top: 0; right: 0; width: 100%; }
.badge_box>img {width: 25% !important; top:8px; right: 8px; position: absolute; z-index: 1; background:transparent !important;}

.coming_box{position: absolute; top: 0; right: 0; width: 100%; }
.coming_box>img{width:25% !important;  position: absolute; right:8px; top: 8px;  z-index: 1; background:transparent !important;}

.sold-out-box2{position: absolute; top: 0; right: 0; width: 100%; }
.sold-out-box2>img {width: 100% !important; top:0px; right: 0px; position: absolute;  z-index: 1; background:transparent !important;}

</style>
