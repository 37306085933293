<template>
    <div class="modal">
        <div class="fixed w-full h-[100vh] bg-black/80 z-[10] top-0 left-0 cursor-pointer" @click="$emit('close-modal')"></div>
        <div :class="{'modal-card' : harf == false,'modal-harf-card' : harf == true}" class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white max-w-3xl xl:w-full lg:w-full w-[90%] xl:max-h-[700px] lg:max-h-[700px] max-h-[700px] h-[calc(100%-80px)] rounded-md shadow-zinc-500/40 shadow-xl">
            <slot :selectid = "selectid"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "modalView",
    props : ['harf','selectid'],
}
</script>

<style >
/* Modal */
.modal,
.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}
.modal{z-index: 998;}

.overlay {
  opacity: 0.8;
  background-color: black;
}
/*
.modal-card {
    position: relative;
    max-width: 90%;
    max-height: 600px;
    width: 100%;
    padding: 20px;
    background-color: white;
    z-index: 999;
    opacity: 1;
    overflow-y: auto;
    border-radius: 20px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.modal-harf-card {
    position: relative;
    max-width: 50%;
    min-width: 360px;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    min-height: 600px;
    z-index: 999;
    opacity: 1;
    overflow-y: auto;
    max-height: 800px;
}*/
</style>
