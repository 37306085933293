<template>
    <!-- 그래프 -->
    <div class="flex flex-row w-full mb-6">
        <div class="w-full border bg-white rounded-xl p-5">
            <div class="overflow-x-scroll w-full block full:scrollbar-hide full:overflow-hidden">
                <table class="border-separate border-spacing-2 table-auto w-full">
                    <thead>
                    <tr>
                        <th colspan="7" class="py-2 bg-[#2e5db3] text-white">Performance Status of 1st &#126; 3rd
                            Downline
                        </th><!--직추천 1대 실적 현황-->
                        <th colspan="6" class="py-2 bg-[#2e5db3] text-white">My Performance Status</th><!--본인 실적 현황-->
                    </tr>
                    <tr class="bg-[#172e59]">
                        <th class="p-2"></th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amount }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountRefunded }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv }}</th>
                        <th class="p-2 text-white break-keep w-20">{{ i18n.myoffice.MyBusiness.amount }}</th>
                        <th class="p-2 text-white break-keep max-w-full">{{ i18n.myoffice.MyBusiness.amountRefunded }}
                        </th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountpv }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.refundedPv }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.amountTotal }}</th>
                        <th class="p-2 text-white break-keep">{{ i18n.myoffice.MyBusiness.totalPv }}</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.thisMonth }}
                        </th>
                        <td class="text-center p-2 min-w-[100px]">{{
                                formatPrice(dash_d_val.R_ORD_AMT_A1 ?? 0, 2)
                            }}
                        </td>
                        <td class="text-center p-2 min-w-[100px]">{{
                                formatPrice(dash_d_val.R_ORD_AMT_R1 ?? 0, 2)
                            }}
                        </td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A1 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R1 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT1 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV1 ?? 0, 1) }}</td>

                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_A1 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT_R1 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_A1 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV_R1 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT1 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV1 ?? 0, 1) }}</td>

                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">{{ i18n.myoffice.MyBusiness.prevMonth }}
                        </th>
                        <td class="text-center p-2 min-w-[100px]">{{
                                formatPrice(dash_d_val.R_ORD_AMT_A2 ?? 0, 2)
                            }}
                        </td>
                        <td class="text-center p-2 min-w-[100px]">{{
                                formatPrice(dash_d_val.R_ORD_AMT_R2 ?? 0, 2)
                            }}
                        </td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A2 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R2 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT2 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV2 ?? 0, 1) }}</td>

                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT12 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT22 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV12 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV22 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT2 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV2 ?? 0, 1) }}</td>

                    </tr>
                    <tr class="bg-[#f0f4ff] text-center text-slate-800">
                        <th class="p-2 text-white bg-[#172e59] min-w-[100px]">
                            {{ i18n.myoffice.MyBusiness.prev2Month }}
                        </th>

                        <td class="text-center p-2 min-w-[100px]">{{
                                formatPrice(dash_d_val.R_ORD_AMT_A3 ?? 0, 2)
                            }}
                        </td>
                        <td class="text-center p-2 min-w-[100px]">{{
                                formatPrice(dash_d_val.R_ORD_AMT_R3 ?? 0, 2)
                            }}
                        </td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_A3 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV_R3 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_AMT3 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.R_ORD_PV3 ?? 0, 1) }}</td>

                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT13 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT23 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV13 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV23 ?? 0, 1) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_AMT3 ?? 0, 2) }}</td>
                        <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.ORD_PV3 ?? 0, 1) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center border-gray-500 py-3 flex justify-center mt-3" v-if="false">
                <a href=""
                   class="border border-gray-500 px-8 py-2 bg-[#172e59] text-white text-base inline-block w-auto rounded-xl flex items-center justify-between hover:bg-[#254a8f]">
                    View More
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-white inline-block"
                         viewBox="0 0 384 512">
                        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path class="text-red-500"
                              d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                    </svg>
                </a>
            </div>
        </div>
    </div><!-- //나와 -->
    <!-- // My Rank Information -->
    <div class="w-full mb-6 bg-white rounded-xl p-5 border">
        <div class="overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <tr>
                    <th colspan="6" class="bg-[#2e5db3] text-white text-center p-3 min-w-[150px]">My Rank Information
                    </th> <!--나의 직급 정보-->
                </tr>

                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3">Downline’s Rank</th> <!--직추천산하직급-->
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth - 1) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth - 2) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">
                        <template
                            v-if="this.currentYear != '2023' || (this.currentYear == '2023' && 10 < this.currentMonth)">
                            {{ getMonthName(this.currentMonth - 3) }}
                        </template>
                    </th>
                </tr>
                <template v-for="(arr, key) in mrArr" :key="key">
                    <tr v-if="key == 'RANK_40' || key == 'RANK_50'|| key == 'RANK_60'|| key == 'RANK_70'|| key == 'UJI_ORD' "
                        class="bg-[#f0f4ff] text-center">
                        <td class="bg-[#2e5db3] text-white text-center p-3 font-bold">
                            <template v-if="key == 'RANK_40'">Branch Manager</template>
                            <template v-if="key == 'RANK_50'">Director</template>
                            <template v-if="key == 'RANK_60'">Vice President</template>
                            <template v-if="key == 'RANK_70'">President</template>
                            <template v-if="key == 'UJI_ORD'">Downline Sales PV</template>
                        </td>
                        <template v-for="(arr1, key1) in arr" :key1="key1">
                            <td v-if="key1 < 4"
                                class="bg-[#f0f4ff] text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                                <button v-if="key != 'UJI_ORD'" type="button"
                                        @click="getRankList(this.currentMonth - key1 ,key)"
                                        class="text-[#1c57ab] border-b border-[#1c57ab]">
                                    <template
                                        v-if="this.currentYear != '2023' || (this.currentYear == '2023' && 8 <= this.currentMonth - key1)">
                                        {{ arr1 ? arr1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "" }}
                                    </template>
                                </button>
                                <template v-if="key == 'UJI_ORD'">
                                    <template
                                        v-if="this.currentYear != '2023' || (this.currentYear == '2023' && 8 <= this.currentMonth - key1)">
                                        {{ arr1 ? arr1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "" }}
                                    </template>
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-for="(arr, key) in mrArr">
                    <tr v-if="key == 'MY_RANK' " class="bg-[#f0f4ff] text-center cursor-pointer">
                        <td class=" bg-[#172e59] text-white text-center p-3 font-bold">My Rank</td> <!--나의 직급-->
                        <template v-for="(arr1, key1) in arr">
                            <td v-if="key1 < 4" class="bg-[#172e59] text-white text-center p-3 font-bold">
                                <template
                                    v-if="this.currentYear != '2023' || (this.currentYear == '2023' && 8 <= this.currentMonth - key1)">
                                    {{ arr1 ? arr1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "" }}
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-for="(arr, key) in mrArr">
                    <tr v-if="key == 'RANK_KIND'" class="bg-[#f0f4ff] text-center">
                        <td class="bg-[#2e5db3] text-white text-center p-3 font-bold ">&nbsp; &nbsp; &nbsp;&nbsp;
                            Criteria &nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <template v-for="(arr1, key1) in arr">
                            <td v-if="key1 < 4" class="text-center p-3 min-w-[100px] font-bold">
                                <template
                                    v-if="this.currentYear != '2023' || (this.currentYear == '2023' && 8 <= this.currentMonth - key1)">
                                    {{ arr1 ? arr1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "" }}
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <p class="text-[#e1253f] p-2 text-sm">* The number of rankers displayed under each month in ‘My Rank
            Information’ is the sum of newly promoted members in my downline from generations 1-3 in this month and the
            past 2 months.</p>
        <p class="text-[#e1253f] p-2 pt-0 text-sm">* Clicking on the member count reveals details about individuals at
            that rank through a popup screen.</p>
        <p class="text-[#e1253f] p-2 pt-0 text-sm">* This information will be available starting from September 2023
            (based on August 2023’s monthly closing).</p>
        <p class="text-[#e1253f] p-2 pt-0 text-sm">* Demoted rankers are not newly promoted individuals.</p>
        <div class="bg-[#f0f4ff] rounded-md p-2">
            <p class="text-[#e1253f] p-2 pt-0 text-sm">&lt;NOTE&gt;</p>
            <p class="text-[#e1253f] p-2 pt-0 text-sm"> ⑴ Rank = New Rank Achieved Downline (Gen 1-3)</p>
            <p class="text-[#e1253f] p-2 pt-0 text-sm"> ⑵ Sales = Sales within Downline</p>
        </div>
    </div>
    <!--나의 직급 정보-->
    <div v-if="false" class="w-full mb-6 bg-white rounded-xl p-5 border">
        <div class="overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
            <table class="border-separate w-full table-auto text-slate-800">
                <tbody>
                <tr>
                    <th colspan="6" class="bg-[#2e5db3] text-white text-center p-3 min-w-[150px]">My Downline’s New
                        Ranker
                    </th>
                </tr>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3">Downline’s Rank</th> <!--직추천산하직급-->
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth - 1) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth - 2) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth - 3) }}</th>
                    <th class="bg-[#172e59] text-center text-white p-3">{{ getMonthName(this.currentMonth - 4) }}</th>
                </tr>
                <template v-for="(arr, key) in newArr">
                    <tr v-if="key == 'RANK_40' || key == 'RANK_50'|| key == 'RANK_60'|| key == 'RANK_70'|| key == 'UJI_ORD' "
                        class="bg-[#f0f4ff] text-center">
                        <td class="bg-[#2e5db3] text-white text-center p-3 font-bold">
                            <template v-if="key == 'RANK_40'">Branch Manager</template>
                            <template v-if="key == 'RANK_50'">Director</template>
                            <template v-if="key == 'RANK_60'">Vice President</template>
                            <template v-if="key == 'RANK_70'">President</template>
                        </td>
                        <template v-for="(arr1, key1) in arr">
                            <td v-if="key1 < 5" class="bg-[#f0f4ff] text-center">
                                {{ arr1 ? arr1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "0" }}
                            </td>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <p class="text-[#e1253f] p-2 text-sm">* Only the newly promoted members within your 1st ~ 3rd generation
            downlines are displayed.</p>
    </div>
    <div class="flex-col w-full gap-5 mb-6 bg-white border rounded-xl p-5">
        <div
            class="w-full overflow-x-scroll sm:overflow-hidden block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                    <th colspan="4" class="text-center text-base py-2 bg-[#2e5db3] text-white">
                        {{ i18n.myoffice.MyBusiness.downlineMemberStatus }}
                    </th><!--직 추천 하선정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">{{
                            i18n.myoffice.Eligibility
                        }}
                    </th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.memberOfNumber }}
                    </th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.downline1Member }}
                    </th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.downline2Member }}
                    </th>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Member</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('01',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK01 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('01',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK01 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('01',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK01 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Bronze</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('10',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK10 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('10',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK10 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('10',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK10 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Silver</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('20',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK20 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('20',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK20 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('20',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK20 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Gold</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('30',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK30 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('30',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK30 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('30',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK30 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">BM(Gold)</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('35',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK35 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('35',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK35 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('35',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK35 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Branch Manager</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('40',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK40 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('40',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK40 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('40',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK40 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Director</td>

                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('50',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK50 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('50',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK50 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('50',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK50 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">Vice President</td>

                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('60',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK60 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('60',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK60 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('60',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK60 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="text-center p-2 min-w-[100px]">President</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('70',0)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_name.RANK70 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('70',1)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_code.RANK70 ?? 0 }}
                        </button>
                    </td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getBonusLeglist('70',2)"
                                class="text-[#1c57ab] border-b border-[#1c57ab]">{{ dash_d_val.RANK70 ?? 0 }}
                        </button>
                    </td>
                </tr>


                </tbody>
            </table>
            <p class="text-[#e1253f] p-2 pb-0 text-sm">* Downline rank count is based on current rank holders at the
                closing of the month and may differ from My Rank Information.</p>
            <p class="text-[#e1253f] p-2 text-sm">* Branch Manager (Gold): If you are promoted to Branch Manager or
                higher and do not maintain the rank, you will be labeled as BM (Gold). This does not impact the rank
                maintenance and promotion of your sponsor.</p>

        </div>


        <div class="w-full pt-4 overflow-x-scroll block sm:overflow-hidden"> <!--xl:scrollbar-hide lg:scrollbar-hide-->
            <table class="border-separate border-spacing-2 w-full table-auto">
                <thead>
                <tr>
                    <th colspan="5" class="text-center text-base py-2 bg-[#2e5db3] text-white">Downline Sales
                        Information
                    </th> <!--산하 매출정보-->
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.monthly }}
                    </th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.orderPV }}
                    </th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.refundedPV }}
                    </th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Total PV</th>
                    <th class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">Total amount of legs</th>
                    <!--총 레그 수-->
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.thisMonth }}
                    </td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A1 ?? 0, 2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R1 ?? 0, 2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV1 ?? 0, 1) }}</td>
                    <td class="text-center p-2 min-w-[100px] decoration-solid decoration-indigo-500">
                        <button type="button" @click="getLegList(0)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                            {{ dash_d_val.D_LEG_CNT1 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.prevMonth }}
                    </td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A2 ?? 0, 2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R2 ?? 0, 2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV2 ?? 0, 1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">
                        <button type="button" @click="getLegList(-1)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                            {{ dash_d_val.D_LEG_CNT2 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <tr class="bg-[#f0f4ff] text-center text-slate-800">
                    <td class="bg-[#172e59] text-center text-white p-3 min-w-[100px]">
                        {{ i18n.myoffice.MyBusiness.prev2Month }}
                    </td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_A3 ?? 0, 2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV_R3 ?? 0, 2) }}</td>
                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(dash_d_val.D_ORD_PV3 ?? 0, 1) }}</td>
                    <td class="text-center p-2 min-w-[100px]">
                        <button type="button" @click="getLegList(-2)" class="text-[#1c57ab] border-b border-[#1c57ab]">
                            {{ dash_d_val.D_LEG_CNT3 ?? 0 }}
                        </button>
                    </td>
                </tr>
                <!--                <tr class="text-left">
                                  <td colspan="5" class="text-[#e1253f] p-2 text-sm">* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능</td>
                                </tr>-->
                </tbody>
            </table>
            <p class="text-[#e1253f] p-2 text-sm">* If you click the total amount of legs, you can view the 1st
                downline’s members’ status</p>
            <!--* 총 레그 수 클릭 시 해당 레그의 회원 확인 가능-->

        </div>

        <!-- 모달 팝업 -->
        <div class="fixed w-full h-full bg-black/80 z-[10] top-0 left-0 " v-if="showModal"></div>
        <!-- 모달 팝업 콘텐츠 -->
        <div
            class="fixed top-2/4 left-2/4 z-[11] translate-x-[-50%] translate-y-[-50%] bg-white p-5 w-full max-w-xl h-2/3 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll sm:overflow-hidden"
            v-if="showModal">
            <div class="text-right mb-2">
                <button class="close" @click="showModal = false">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline-block  fill-[#091223]"
                         viewBox="0 0 320 512">
                        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                    </svg>
                </button>
            </div>
            <div class="h-72">
                <h4 v-if='legs_type=="rank"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">
                    My Rank Downline’s Members’ Status</h4> <!-- 직급달성 산하 내역 -->
                <h4 v-if='legs_type=="sales"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">
                    1st Downline’s Members’ Status</h4> <!-- 산하 1대 레그 별 회원 현황 -->
                <h4 v-if='legs_type=="bonus"' class="text-center font-semibold text-[#172e59] text-lg mb-3 break-words">
                    Downline’s Bonus Satus</h4> <!-- 산하 1대 레그 별 회원 현황 -->
                <div class="text-center mb-3 h-80 overflow-scroll tailwind-scrollbar-hide scrollbar-hide">

                    <!--Total amount of legs-->
                    <table class="w-full border-separate sm:overflow-hidden">
                        <thead>
                        <tr>
                            <th v-if='legs_type=="bonus"' class="bg-[#172e59] text-center text-white p-3">LV</th>
                            <th class="bg-[#172e59] text-center text-white p-3 w-24">Member ID</th>
                            <th class="bg-[#172e59] text-center text-white p-3">Name</th>
                            <th v-if='legs_type !="rank"' class="bg-[#172e59] text-center text-white p-3">Eligibility
                            </th>
                            <th v-if='legs_type=="bonus"' class="bg-[#172e59] text-center text-white p-3">Amount PV</th>
                            <th v-if='legs_type=="rank"' class="bg-[#172e59] text-center text-white p-3">Rank Up Date
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="leg in legs" class="bg-[#f0f4ff] text-center cursor-pointer">
                            <td v-if='legs_type=="bonus"' class="text-center p-2">
                                <router-link @click="goUrl(leg.sid);"
                                             class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.lv }}
                                </router-link>
                            </td>
                            <td class="text-center p-2">
                                <router-link @click="goUrl(leg.sid);"
                                             class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.userid }}
                                </router-link>
                            </td>
                            <td class="text-center p-2">
                                <router-link @click="goUrl(leg.sid);"
                                             class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{ leg.username }}
                                </router-link>
                            </td>
                            <td v-if='legs_type !="rank"' class="text-center p-2">
                                <router-link @click="goUrl(leg.sid);"
                                             class="text-base block p-1 text-[#1c57ab] hover:text-[]">{{
                                        leg.rank_name
                                    }}
                                </router-link>
                            </td>
                            <td v-if='legs_type=="bonus"' class="text-center p-2">
                                <router-link @click="goUrl(leg.sid);"
                                             class="text-base block p-1 text-[#1c57ab] hover:text-[]">
                                    {{ formatPrice(leg.ord_pv, 1) }}
                                </router-link>
                            </td>
                            <td v-if='legs_type=="rank"' class="text-center p-2">{{ leg.rank_up_date }}</td>
                        </tr>
                        </tbody>
                    </table>


                </div>
                <p class="text-[#e1253f] text-sm break-words mb-2">*If you click on the member’s ID, you can see the
                    member’s leg sales status.</p>
                <!--  *회원명을 클릭 하시면 해당 회원 기준의 레그 별 매출 집계를 확인하실 수 있습니다.-->
            </div>

        </div>


    </div>

</template>
<script>
export default {
    name: "BusinessStatus",
    props: ['legs', 'legs_type', 'showModal', 'dash_d_name', 'dash_d_val', 'dash_d_code', 'currentYear', 'currentMonth', 'mrArr', 'newArr'],
    components: {},
    data: () => ({
        i18n: window.i18n,
    }),
    methods: {
        formatPrice(value, digit) {
            let val = (value / 1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getBonusLeglist(rank, type) {//본인 산하 직추천 명단
            this.showModal = true;
            this.legs_type = "bonus";
            this.legs = [];
            this.loading = true;
            axios.post(window.Laravel.back_url + '/api/getBonusLeglist', {
                rank: rank, type: type,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                this.legs = response.data;
            });
            this.loading = false;
        },
        goUrl(sid) {
            location.href = '/MyOrderLegs/' + sid;
        },
        getMonthName: function(monthNumber) {
            const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthNumber_ = Number(monthNumber);
            return monthNames[monthNumber_];
        },
    }
}
</script>

<style scoped>

</style>
