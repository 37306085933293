<template>

<!--  main_visual -->
<!--  <div v-if="is_top_category === true" class="swiper-container container max-w-8xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-white" >-->
  <div v-if="is_top_category === true" class="swiper-container container max-w-8xl mx-auto lg:max-w-7xl bg-white" >
    <!-- Slides -->
    <swiper
      :pagination="true"
      :modules="modules"
      :slides-per-view="this.product_category.categories.length"
      :space-between="0"
      :autoHeight="true"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="swiper-wrapper"
    >
      <swiper-slide v-for="data in this.product_category.categories" :key="data.id" class="swiper-slide text-xs lg:text-base sm:text-xs md:text-xs" :class="selectCategory[data.id]">
        <div class="" v-if="data.sections[0]['href']!==undefined">
          <a :href="data.sections[0]['href']">
            {{ data.name }}
          </a>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// import required modules
import {Pagination} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default {
  name: 'navigation_menu',
  props: ['product_category', 'is_top_category'],

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      selectCategory: {},
      categoryCode : ''
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };

    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination],
    };
  },
  created() {
    this.matchCategory();
    this.emitter.on('setCategoryCode',this.setCategoryCode)
  },
  methods : {

    /**
     * 카테고리 코드 값 반영
     * @param categoryCode
     */
    setCategoryCode : function(categoryCode){
      if ( categoryCode !== '') {
        this.categoryCode = categoryCode;
        this.selectCategory[this.categoryCode] = 'category_select';
      }
    },
    /**
     * 리스트 페이지에서 파라미터로 넘어오는 카테고리를 값을 기준으로 카테고리 UI 에 반영한다.
     */
    matchCategory : function(){
      const url = window.location.href;
      const regex = /\/product\/item\//;
      const baseUrl = url.split('#')[0];

      // 상품 상세
      if (regex.test(url)) {

        const match = baseUrl.match(/\/product\/item\/(\d+)/);
        let productNum = match ? match[1] : null;
        console.log('productNum => ' + productNum);

      // 상품 리스트
      }else{

        const match = baseUrl.match(/[^\/]+$/);
        let categoryCode = match ? match[0] : null;
        this.categoryCode = '';

        this.product_category.categories.forEach((item) => {
          this.selectCategory[item.id] = '';
          if (categoryCode === item.id){
            this.categoryCode = item.id;
            return false;
          }

          if ( item.sections !== undefined ){
            item.sections.forEach((subItem) => {
              if (categoryCode === subItem.id) {
                this.categoryCode = item.id;
                return false;
              }
            })
          }
        });

        this.categoryCode = this.categoryCode || 'all';
        this.selectCategory[this.categoryCode] = 'category_select';
      }
    }
  }

};
</script>
<style lang="scss" scoped>


.swiper-container {

  overflow-y: hidden;
  //margin: 20px auto;

  .swiper-wrapper{

    .swiper-slide {
      padding: 20px 10px;
      font-weight: 600;
      height:70px;
      text-align: center;
      border: 0;
      border-left: 1px solid rgb(229, 231, 235);
      border-right: 1px solid rgb(229, 231, 235);
      border-bottom: 1px solid rgb(229, 231, 235);
      width: 100%;
      position: relative;
      transition: color 0.5s ease, font-size 0.1s ease;

      &.category_select {
        //border-bottom: 5px solid #ff0000;
        border-bottom: 5px solid rgb(0 0 0 / 0.2);
      }

      &:hover {
        color: red; /* 마우스를 올렸을 때의 색상 */
        //font-size: 1.1em;
      }

      display: flex;
      justify-content: center;
      align-items: center;

      div{
        a {
          white-space: normal;
          overflow-wrap: break-word;
          word-break: break-all;
        }
      }

      span {
        font-size: 12px;
      }

      img {
        display: block;
        width: 100%;
        //height: 100%;
        object-fit: cover;
      }
    }
  }
}


</style>
