<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>

    <div id="mem_info" class="myo-modi-mem">

        <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
            <!--            <h2 class="myo_table_tit">{{ i18n.shop.autoshipse2.as_title7009 }}</h2>-->
            <!--            <br>-->
            <!--            <div class="cart-notice" style="width: 100%;">-->
            <!--                <p class="" style="font-weight:bold;">【決済代行会社の変更による決済サービスの改善及び定期購入用 クレジットカード情報再登録お願いのご案内】<br>-->
            <!--                    GCOOP JAPANでは、会員様により良い決済サービスを提供する為、2023年1月31日(火)18時より決済代行会社の変更を行います。<br>-->
            <!--                    変更により総務省の必修勧告事項として、クレジットカード決済時にセキュリティコード（カード裏面に記載のある3桁の番号）のご登録が必要となりました。<br>-->
            <!--                    2023年2月1日以降、定期購入をなさる会員様は必ずセキュリティコードの事前登録をして頂きますようお願い申し上げます。</p>-->
            <!--            </div>-->

            <h2 class="myo_table_tit">登録済みのカード情報</h2>
            <ul id="myo_form" class="mgb_30">
                <!-- 회원명-->
                <li class="item table_form top-li">
                    <span class="item_title">選択された次回決済日</span>
                    <span class="item_content">
                        <div class="md-input-box relative z-[10] w-full bg-white w-full">
                            {{ select_day }}日
                        </div>
                    </span>
                </li>
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.shop.shopping.CardNumber }}</span>
                    <span class="item_content">
                        <div class="md-input-box relative z-[10] w-full bg-white w-full">
                            {{ cardnumber }}
                        </div>
                    </span>
                </li>
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.shop.shopping.ExpirationDate }}</span>
                    <span class="item_content">
                        <div class="md-input-box relative z-[10] w-full bg-white w-full">
                            {{ cardexpire }}
                        </div>
                    </span>
                </li>
            </ul>
            <h2 class="myo_table_tit">新しいカード情報</h2>
            <ul id="myo_form" class="mgb_30">
                <!-- 회원명-->
                <li class="item table_form top-li">
                    <span class="item_title">次回決済日の選択</span>
                    <span class="item_content">
                        <div class="md-input-box relative z-[10] w-full bg-white w-full">
                            <select class="w-full rounded-md border-gray-300 text-sm md:text-base md:mr-0.5 " v-model="admits_day">
                                <option value="1">1</option>
                                <option value="11">11</option>
                                <option value="21">21</option>
                            </select>
                        </div>
                    </span>
                </li>

                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.shop.shopping.CardNumber }}</span>
                    <span class="item_content">
                        <input name="card_no" id="card_no" ref="card_no" type="text" placeholder="カード番号"
                               class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="16"
                               v-model="card_no">
                    </span>
                </li>
                <!-- 휴대전화 -->
                <li class="item table_form top-li">
                    <span class="item_title">{{ i18n.shop.shopping.ExpirationDate }}</span>
                    <span class="item_content m_contents">
                        <input name="valid_date" id="valid_date" ref="valid_date" type="text" placeholder="MM/YY"
                               class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="4"
                               v-model="valid_date">
                  </span>
                </li>
                <li class="item table_form top-li">
                    <span class="item_title">CVV</span>
                    <span class="item_content m_contents">
                        <input name="cvv" id="cvv" ref="cvv" type="text" placeholder="CVV"
                               class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="4"
                               v-model="cvv">
                  </span>
                </li>

            </ul>
            <div class="myo_condition_search2">
                <button type="submit" class="search_btn">{{ i18n.myoffice.save }}</button>
            </div>
        </form>
    </div>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import modalView from "../../modalView";
import vSelect from "vue-select";

export default {
    name: "AutoshipCardChange",
    components : {
        VueSimpleAlert,
        ShoppingCartIcon,
        Loading,
        modalView,
        vSelect,
    },
    data: () => ({
        i18n: window.i18n,
        laravel: window.Laravel,
        no_search_messsage: '',
        isLoggedIn: false,
        loading : false,
        address_search_open:false,
        autoship : [],
        card_no : "",
        valid_date : "",
        cvv : "",
        admits_day : "",
        token_api_key : "8a42ac09-7266-47f5-b998-4a28f070a784",
        cardnumber : "",
        cardexpire: "",
        select_day: "",
    }),
    beforeCreate() {

    },
    created() {

    },
    mounted() {
        this.loading = true;
        axios.post(process.env.MIX_BACK_URL+'/api/getNormalAsMst',{
            bz_cd: window.Laravel.bz_cd,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
                if(response.data == ""){
                    VueSimpleAlert.alert("定期購入を登録してください。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
                        if(result){
                            location.replace("/MyofficeMain");
                        }
                    });
                }

                this.autoship = response.data;
                this.select_day = response.data.select_day;
                this.cardnumber = response.data.card_list.cardnumber ?? response.data.card_no_org;
                this.cardexpire = response.data.card_list.cardexpire ?? response.data.card_expire;
                this.loading = false;   // this.getCartList(response.data);
            })

    },
    methods:{
        submitForm: function (event) {

            if(this.card_no != "" && this.valid_date != "" && this.cvv != ""){
                var data = {};
                data.token_api_key = "8a42ac09-7266-47f5-b998-4a28f070a784";
                if (this.card_no) {
                    data.card_number = this.card_no;
                }
                if (this.valid_date) {
                    data.card_expire = this.valid_date.substring(0,2)+"/"+this.valid_date.substring(2,4);
                }
                if (this.cvv) {
                    data.security_code = this.cvv;
                }

                data.lang = "ja";
                let url = "https://api.veritrans.co.jp/4gtoken";
                var card_no = this.card_no;
                var valid_date = this.valid_date;
                var cvv = this.cvv;
                var e_mail = this.e_mail;
                var token = "";

                axios.post(url,
                    {
                        card_number : this.card_no,
                        card_expire : this.valid_date.substring(0,2)+"/"+this.valid_date.substring(2,4),
                        security_code : this.cvv,
                        lang : "ja",
                        token_api_key : this.token_api_key
                    },{})
                    .then(response => {
                        if(response.data.code == "success"){
                            token = response.token;
                            axios.post(process.env.MIX_BACK_URL+'/api/autoShipCardModify',
                                {
                                    card_no : this.card_no,
                                    valid_date : this.valid_date,
                                    cvv : this.cvv,
                                    token : response.data.token,
                                    admits_day : this.admits_day,
                                    as_no : this.autoship.as_no,
                                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                                .then(response => {
                                    this.loading = false;
                                    VueSimpleAlert.alert(i18n.myoffice.memberUpChangePopNotice.notice12,"","",{confirmButtonText:"確認"}).then((result) => {
                                        if(result){
                                            location.replace("/MyAutoshipCardChange");
                                        }
                                    });

                                });
                        }else{
                            this.loading = false;
                            VueSimpleAlert.alert(response.data.message,"","",{confirmButtonText:"確認"});

                        }
                    });

            }else{
                axios.post(process.env.MIX_BACK_URL+'/api/autoShipCardModify',
                    {
                        token : "",
                        admits_day : this.admits_day,
                        as_no : this.autoship.as_no,
                    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        this.loading = false;
                        VueSimpleAlert.alert(i18n.myoffice.memberUpChangePopNotice.notice12,"","",{confirmButtonText:"確認"}).then((result) => {
                            if(result){
                                location.replace("/MyAutoshipCardChange");
                            }
                        });
                    });

            }

        },
        search_address(){
            this.address_search_open = true;
        },
        set_address(value){
            this.autoship.r_post     = value.postcode;
            this.autoship.r_addr1    = value.address + value.address2 + value.address3;
            this.address_search_open = false;
        },
        close_address_search(){
            this.address_search_open = false;
        },
    },

}

</script>

<style scoped>

</style>