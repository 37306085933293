<template>

    <div class="member-search">
        <loading :active="false"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>

        <section class="myo_condition_box lg:grid lg:grid-cols-4 lg:gap-2">
            <dl class="myo_search_list w-full relative">
                <dt>{{ i18n.myoffice.Commission.Theyear }}</dt> <!-- 해당연도 -->
                <dd>
                  <div class="relative z-[11] lg:mb-0 mb-2 bg-white">
                    <vSelect :options="years" label="title" v-model="s_year" :clearable="false" ></vSelect>
                  </div>
                </dd>
            </dl>
            <dl class="myo_search_list w-full relative" >
                <dt>{{ i18n.join.join.birthday }}</dt> <!-- 생년월일 -->
                <dd>
                  <input type="text" id="birthday" name="birthday" placeholder="ex : 20221231" style="padding-left:8px !important;" v-model="birthday"
                         oninput="this.value = this.value.replace(/\D/gi, '')">
                </dd>
            </dl>
        </section>
        <div class="myo_condition_search">
<!--                <button type="reset" onclick="" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>-->
            <button class="search_btn" @click="go_print">{{ i18n.myoffice.Commission.ReceiptForEarnedIncomeTax }}</button>
        </div>

    </div>
    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.myofficeAlert.ExploreNoChromeYes }}</p><!-- ※ 인터넷 익스플로어에서는 호환이 불가하오니  크롬 브라우져로 사용하시기 바랍니다. -->
                    <p>{{ i18n.myoffice.myofficeAlert.WaitOneMinute }}</p><!-- ※ 원천징수영수증 조회시 1~2분의 대기시간이 소요될 수 있습니다.  支払調書の照会に1～2分掛かる場合があります。-->
                </div>
            </div>
        </div>
    </section>


</template>

<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyBenefitTaxBreakHistory",
    components : {
        Loading,
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        loading  : false,
        fullPage : true,
        years    : [],
        s_year   : new Date().getFullYear(),
        birthday : "",
        mem      : [],
        value    : [],
    }),
  created() {
      var year = new Date().getFullYear();
      for(year; year >= 2023 ; year--){
          this.years.push(
              year
          );
      }

      axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          this.mem = response.data;
      });
  },
  watch: {
    birthday : function() {
      let date  = new Date();
      let year  = date.getFullYear();
      let month = ("0" + (1 + date.getMonth())).slice(-2);
      let day   = ("0" + date.getDate()).slice(-2);

      this.birthday = this.birthday > year + month + day ? "" : this.birthday;
    }
  },
  methods : {
      go_print : function (){
          if(!this.birthday) {
            VueSimpleAlert.alert(i18n.shop.autoshipse2.as_title7004,"","",{confirmButtonText:"確認"});
            return;
          }
          var link = window.Laravel.back_url+'/api/MyBenefitTaxBreakHistory?userid='+this.mem.userid + '&year=' + this.s_year + "&birthday=" + this.birthday;
          // var link = window.Laravel.back_url+'/api/MyBenefitTaxBreakHistory?xid='+encodeURIComponent(this.mem.xid) + '&year=' + this.s_year + "&birthday=" + this.birthday;
          // if (is_iPhone()){
          //   location.href="gcoop://go?url={{ urlencode("+link+") }}";
          // } else{
          //   if (navigator.userAgent.match(/jungbaksa/i) != null ) {
          //     window.open(link, '_self');
          //   }
          //   else {
          //     var ret = window.open('', '_blank');
          //     if (!ret) { /* 홈페이지의 팝업을 허용해 주세요! */
          //       VueSimpleAlert.alert(i18n.myoffice.myofficeAlert.allowwebsitepopup,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
          //       });
          //     }
          //     ret.location.href = link;
          //   }
          // }
          window.open(link);
        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    }
}
</script>

<style scoped>

</style>
