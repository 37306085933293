<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>

    <article class="myo_search_area">
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.Commission.giveDate }}</dt>
                    <dd class="myo_search_list">
                        <section class="flex_box benefit_date_box">
                            <vSelect :options="years" label="value" :reduce="value => value.value"  :clearable="false" v-model="s_year"  ></vSelect>
                            <vSelect :options="months" label="value" :reduce="value => value.title" :clearable="false" v-model="s_month"  ></vSelect>
                            <!--vSelect :options="days" label="value" :clearable="false" v-model="s_day" v-show="s_day!='N/A'"  ></vSelect-->
                        </section>
                    </dd>
                </dl>
            </section>

            <div class="myo_condition_search2">
                <button type="reset" class="reset_btn" onclick="location.href='/MyBenefitList';">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>

        </form>
    </article>

    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.Commission.txt1 }}</p>
                    <p>{{ i18n.myoffice.myofficeAlert.CarryoverBonusAlert }}</p>
                </div>
            </div>
        </div>
    </section>

    <div id="printArea">
        <div class="cont_wrap">

            <div style="height:30px;"></div>

          <div id="divPrint" style="width:100%;" >

                <!-- Commission Statement -->
                <div style="text-align: center; margin:10px 0 15px 0;"><strong class="gray06" style="font-weight:bold; font-size:28px;">{{ i18n.myoffice.Commission.commissionStatement }}</strong></div>
                <button v-if="member_info.sa_state?member_info.sa_state.trim():''!= ''" class="p-2 border-2 border-gray-300 bg-white rounded-md" @click="go_print">{{i18n.myoffice.Benefit.paymentNotice}}</button>
                <div class="position_relate_box2">
                    <span class="table_notice_left">{{ username }} {{ i18n.myoffice.myofficeAlert.YourBonusListAlert }}</span>
                    <abbr style="display:none;" :title="i18n.myoffice.myofficeAlert.CanTablescroll2" rel="tooltip" class="position_ques_icon">
                      {{ username }} {{ i18n.myoffice.myoSearchForm.scroll }}<i class="fal fa-question-circle" aria-hidden="true"></i></abbr>
                </div><!-- 님 수고 많으셨습니다. 회원님의 수당내역은 다음과 같습니다. -->

                <div>
                  <h2 class="text-xl font-semibold mb-3">{{ i18n.myoffice.Commission.commissionStatement }}</h2>
                  <div class="flex flex-row w-full mb-6">
                    <div class="w-full border bg-white rounded-xl p-5">
                      <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                        <table class="border-separate border-spacing-2 table-auto w-full">
                            <colgroup>
                              <col style="min-width: 294px; width:33%"/>
                              <col/>
                            </colgroup>
                            <tbody class="tbody_print_C" v-if="member_info" >
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.paymentDate }}</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.paydate }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.MemberId }}</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.userid2 }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.memberName }}</th>
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.username }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.sumofPayment }}</th><!-- 지급합계 支給合計 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.totalamt,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">消費税</th><!-- 공제합계 控除合計 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.tax1amt,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">源泉所得税</th><!-- 수당환수금 ボーナス還収金 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.tax2amt,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                    <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">振込手数料</th><!-- 수당환수금 ボーナス還収金 -->
                                    <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.bankamt,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.refund.otherRefunds }}</th><!-- 기타환수금 その他還収金 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.amtbprecv,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.paymentAmount }}</th><!-- 지급액 支給額 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.trueamt,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.refund.lastweekAmount }}</th><!-- 전주이월금액 前週繰越金額 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.totalbe,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.refund.lowLastWeekAmount }}</th><!-- 익주이월금액 翌週繰越金額 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(member_info.totalre,0) }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.bankName }}</th> <!-- 振込金融機関名 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.bankname }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.accountNumber }}</th> <!-- 口座番号 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.account }}</td>
                                </tr>
                                <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800">
                                  <th class="text-center p-2 min-w-[100px] bg-[#172e59] text-white">{{ i18n.myoffice.Commission.bankHolder }}</th> <!-- 口座名義 -->
                                  <td class="text-center p-2 min-w-[100px]">{{ member_info.depositor }}</td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Allowance/ deduction -->
                <div>
                  <h2 class="text-xl font-semibold mb-3">{{ i18n.myoffice.Commission.BonusTaxList }}</h2><!-- 수당공제내역 -->
                  <div class="flex flex-row w-full mb-6">
                    <div class="w-full border bg-white rounded-xl p-5">
                      <div class="overflow-x-scroll w-full block xl:scrollbar-hide lg:scrollbar-hide sm:overflow-hidden">
                        <table class="border-separate border-spacing-2 table-auto w-full">
                          <thead>
                              <tr class="bg-[#172e59]">
                                  <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.type }}</th>
                                  <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.article }}</th>
                                  <th class="p-2 text-white break-keep">{{ i18n.myoffice.Commission.price }}</th>
                              </tr>
                          </thead>
                          <tbody class="tbody_print_A" >
                              <tr class="bg-[#f0f4ff] text-center border-2 border-white text-slate-800" v-for="Value in info_list">
                                  <td class="text-center p-2 min-w-[100px]">{{ Value.gubun }}</td>
                                  <td class="text-center p-2 min-w-[100px]">{{ Value.payname }}</td>
                                  <td class="text-center p-2 min-w-[100px]">{{ formatPrice(Value.amt,2) }}</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

            </div> <!-- print -->
        </div>


    </div>
    <div v-show="is_print" class="myo_condition_search2">
        <button type="button" class="search_btn"  @click="benefitListReport">{{ i18n.myoffice.Commission.Printing }}</button>
    </div>

</template>

<script>
import vSelect from "vue-select";
import Popper from "vue3-popper";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import printJS from 'print-js'
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyBenefitList",
    components : {
        vSelect,
        Loading,
        printJS
    },
    data: () => ({
        i18n        : window.i18n,
        working_nation : working_nation,
        userid : window.Laravel.user,
        username    : window.Laravel.username,
        paydate     : "",
        member_info : [],
        info_list   : [],
        is_print    : false,
        months : [{title : "01", value : i18n.myoffice.jan},
            {title : "02", value : i18n.myoffice.feb},
            {title : "03", value : i18n.myoffice.mar},
            {title : "04", value : i18n.myoffice.apr},
            {title : "05", value : i18n.myoffice.may},
            {title : "06", value : i18n.myoffice.jun},
            {title : "07", value : i18n.myoffice.jul},
            {title : "08", value : i18n.myoffice.aug},
            {title : "09", value : i18n.myoffice.sep},
            {title : "10", value : i18n.myoffice.oct},
            {title : "11", value : i18n.myoffice.nov},
            {title : "12", value : i18n.myoffice.dec}],
        s_year : new Date().getFullYear(),
        s_month : new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1,
        s_day : "",
        years : [],
        days : [],
        loading : false,

    }),
    watch : {
        s_year : function(){
            this.getGiveDay();
        },
        s_month : function(){
            this.getGiveDay();
        }
    },
    created : function (){
        var year = new Date().getFullYear();
        for(year ; year >= 2023 ; year--){
            this.years.push(
                {value: year}
            );
        }
        // this.getGiveDay();
      this.submitForm();
    },
    methods : {
        getGiveDay(){
            axios.post(window.Laravel.back_url+'/api/getGiveDay',{
                // month : this.s_year+""+this.s_month
                month : this.s_year+""+this.s_month
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{
                this.s_day = response.data.data[0].value;
                this.days  = response.data.data;
                this.submitForm();
            });
        },
        submitForm : function (){
            this.loading = true;
            if(this.s_day === 'N/A') {
              this.member_info = [];
              this.info_list   = [];
              this.loading     = false;
              return;
            }
            axios.post(window.Laravel.back_url+'/api/MyBenefitList',{
                s_year  : this.s_year,
                s_month : this.s_month,
                // s_day   : this.s_day == '無' ? '' : this.day,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(!response.data) {
                  this.loading     = false;
                  return;
                }
                if(response.data.member_info === "-1") {
                  this.loading     = false;
                  return;
                }
                if(response.data.info_list === "-1") {
                  this.loading     = false;
                  return;
                }

                this.is_print    = true;
                this.member_info = response.data.member_info;
                this.info_list   = response.data.info_list;
                this.loading     = false;

            });
        },
        formatPrice(value,digit) {
            if(!value) {
              return '';
            }
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        benefitListReport(){
            printJS({'printable' : 'printArea', 'type' : 'html','css':['/myoffice/css/my_benefit_list.css'],'scanStyles':false});
          //printJS({'printable' : 'printArea', 'type' : 'html', 'scanStyles':false});
        },
      go_print : function (){
/*        axios.post(window.Laravel.back_url+'/api/MyBenefitListXls',{
          // month : this.s_year+""+this.s_month
          s_year : this.s_year+""+this.s_month,
          days : this.days,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response =>{

        });*/

        var link = window.Laravel.back_url+'/api/MyBenefitListXls?userid='+this.userid + '&s_year=' + this.s_year+ '&s_month='+this.s_month;
        window.open(link);
      },

    }
}
</script>

<style scoped>

</style>
