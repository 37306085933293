<template>
  <!-- 메인 Bottom 배너 슬라이드 -->
  <section class="mx-auto mb-4">
    <div class="main_visual mx-auto w-full bg-white">
      <carousel v-if="banners.length !== 0" :settings=" settings " ref="myCarousel">
        <slide v-for=" (item) in banners " :key=" item.seq_si ">
          <div class="carousel__item">
            <!-- 임시로 비주얼 배너 불러오도록 처리 - 신규 배너와 연동되는 기능 추가 필요 -->
            <div @click="goBannerLink(item.link_url)" :class="[item.link_url ? 'onCursor' : '']">
              <img :src="item.href" v-bind:alt=" item.title "> <!--<img src="/images/test_2000x500.png" alt=""/>-->
            </div>
          </div>
        </slide >
        <template #addons>
          <Pagination />
          <Navigation>
          </Navigation>
        </template>
      </carousel>
    </div>
  </section>


</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

/* javascript numberFormat library */
let numeral = require("numeral");

export default {
  name: "MainBtBanners",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },setup() {
    // const { isMobile } = useMobileDetection();   // 모바일 여부 체크
    // return { isMobile };
  },
  data: () => ({
    // carousel settings
    settings: {
      wrapAround: true,
      snapAlign: 'end',

      mouseDrag: false,
      touchDrag: false
    },
    seen: false,
    banners: [],
  }),
  created() {
    // 배너
    axios.post(window.Laravel.back_url+'/api/banner', {
      bz_cd: window.Laravel.bz_cd,
      kind_cd : 'V',
      limit: 8
    }).then(response => {
      response.data.forEach((value, index) => {
        this.banners.push({ seq_si:value.seq_si, link_url: value.linkurl, href: 'https://cdnus.gcoop.me/shop' + value.path_name + value.file_name, current: false, title: value.title });
      });
    });
  },
  methods: {
    goBannerLink(link) {
      if(link) {
        location.href = link;
      }
    }
  }
}
</script>

<style scoped>
.onCursor { cursor: pointer; }
</style>
