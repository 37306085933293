<template>
    <div class="quick-menu">
        <ul v-if=" isShowMenu ">
            <!-- 로그인 -->
            <li v-if=" !isLoggedIn ">
                <a href="#" @click="login">
                    <i class="far fa-user" aria-hidden="true"></i>
                    <span class="blind">{{ i18n.shop.login.login }}</span>
                </a>
            </li>
            <!-- 장바구니 -->
            <li>
                <a href="/cart">
                    <i class="fal fa-shopping-cart" aria-hidden="true"></i>
                    <span>{{ i18n.shop.mypage.ShoppingCart }}</span>
                </a>
            </li>
            <!-- 마이오피스 - 로그인 시에만 노출 -->
            <li v-if=" isLoggedIn && user && (rank_cd !== '00') ">
                <a href="/MyofficeMain">
                    <i class="fal fa-user-chart" aria-hidden="true"></i>
                    <span>{{ i18n.shop.mypage.MyOffice }}</span>
                </a>
            </li>
            <!-- 마이페이지 - 로그인 시에만 노출 - 컨슈머일 때만 노출 -->
            <li v-if=" isLoggedIn  ">
                <a href="/Mypage">
                    <i class="fal fa-user-chart" aria-hidden="true"></i>
                    <span>{{ i18n.shop.category.MyPage }}</span>
                </a>
            </li>
            <!-- 국가별 제품보기  - 임시 주석처리 -->
            <!--
            <li>
                <a href="/displayinfo">
                    <i class="fas fa-globe" aria-hidden="true"></i>
                    <span style="font-size:13px;"><?=__("menu.menu.countrymenu2")?></span>
                </a>
            </li>
            -->
            <!-- 지쿱 아카데미 - 임시 주석처리 -->
<!--            <li>
                <a href="/MypageAcademy">
                    <i class="fal fa-graduation-cap" aria-hidden="true"></i>
                    <span style="font-size:13px;">{{ i18n.shop.mypage.gcacademy }}</span>
                </a>
            </li>-->
            <!-- 탑버튼 -->
            <li class="top" id="go_top">
                <a href="#app" role="button" class="">
                    <i class="fas fa-chevron-up"></i>
                    <span>TOP</span>
                </a>
            </li>
        </ul>
        <!-- show hide 버튼 -->
        <ul id="quick-menu2">
            <li id="quick_flag2">
                <a @click=" isShowMenu = !isShowMenu ">
                    <i class="fal fa-lightbulb-exclamation" aria-hidden="true" v-bind:class="{ color_white: !isShowMenu }"></i>
                    <span class="blind" style="color:#fff" v-if=" isShowMenu ">{{ i18n.shop.mypage.menuoffbtn }}</span>
                    <span class="blind" style="color:#fff" v-if=" !isShowMenu ">{{ i18n.shop.mypage.menuonbtn }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "LayerQuick",

    data: () => ({
        isShowMenu: true,
        isLoggedIn: false,
        i18n: window.i18n,
        user: '',
        rank_cd: '',
    }),
    mounted() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true;
            this.user = window.Laravel.user;
            this.rank_cd = window.Laravel.rank_cd;
            this.user_kind = window.Laravel.user_kind;
        }
    },
    methods: {
        login: function(event) {location.href = "/sso/login?bz_cd="+window.Laravel.bz_cd;},
    }
}
</script>

<style scoped>
    .color_white {
        color: #ffffff
    }
</style>
