<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="false"
           :is-full-page="true">
  </loading>
  <dl id="admin_page">
    <article class="myo_search_area">
      <form name='form_search' style="clear:both;"  v-on:submit.prevent="submitForm('1')">
        <section class="myo_condition_box">
          <dl class="myo_search_list">
            <dt>{{ i18n.search.find }}</dt><!-- 검색 -->
            <dd class="">
              <section class="flex_box">
                <vSelect :options="cate_list" label="label" :reduce="value => value.value"  :clearable="false" v-model="cate" class="xl:w-32 lg:w-32 w-full"></vSelect>
                <div class="myo_search_box">
                  <input type="text" class="myo_search_input" name="search_text" v-model="search_text">
                </div>
              </section>
            </dd>
          </dl>
        </section><!-- // myo_condition_box -->

        <div class="myo_condition_search2">
          <button type="reset" onclick="location.replace('')" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
          <button type="submit" class="search_btn btn-eft-none">{{ i18n.myoffice.Search }}</button>
        </div>
      </form>
    </article>

    <div class="w-full flex mt-2 mb-2">
      <button @click="getList('manual')"  :class="cate=='manual' ? 'rounded-t-md bg-[#0000001a] text-white' : ''"  class="border-r border-[#e0e4e7] focus:rounded-t-md hover:rounded-t-md focus:bg-[#0000001a] hover:bg-[#0000001a] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.BusinessTool.manual }}</button>
      <button @click="getList('catalog')" :class="cate=='catalog' ? 'rounded-t-md bg-[#0000001a] text-white' : ''" class="border-r border-[#e0e4e7] focus:rounded-t-md hover:rounded-t-md focus:bg-[#0000001a] hover:bg-[#0000001a] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.BusinessTool.leaflet }}</button>
      <button @click="getList('goods')"   :class="cate=='goods' ? 'rounded-t-md bg-[#0000001a] text-white' : ''" class="border-r border-[#e0e4e7] focus:rounded-t-md hover:rounded-t-md focus:bg-[#0000001a] hover:bg-[#0000001a] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.BusinessTool.product_image }}</button>
      <button @click="getList('leaflet')" :class="cate=='leaflet' ? 'rounded-t-md bg-[#0000001a] text-white' : ''" class="focus:rounded-t-md hover:rounded-t-md focus:bg-[#0000001a] hover:bg-[#0000001a] text-[#0e1b35] focus:text-white hover:text-white xl:text-base lg:text-base text-sm xl:px-6 lg:px-6 px-2 xl:py-4 lg:py-4 py-2 xl:w-1/4 lg:w-1/4 w-1/3 break-keep">{{ i18n.myoffice.BusinessTool.etc }}</button>
    </div>

    <div class="list-table-wrap">
      <table class="list-table">
        <colgroup>
          <col width="10%" />
          <col width="15%" />
          <col/>
          <col width="20%" />
        </colgroup>
        <thead>
        <tr>
          <th data-priority="4">NO</th>
          <th data-priority="4">{{ i18n.myoffice.BusinessTool.kind }}</th>
          <th data-priority="4">{{ i18n.myoffice.BusinessTool.title }}</th>
          <th data-priority="6">{{ i18n.myoffice.BusinessTool.regdate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="List.length > 0" v-for="(Value,index) in List" class="list_hover" >
          <td align="center">{{ total_count - ((page-1) * page_size + index) }}</td>
          <td align="center">{{  Value.b_flag  }}</td>
          <td class="overflow-hidden whitespace-no-wrap text-ellipsis ">
            <a @click="viewAddFile(Value.b_seq)">
              <span class="cursor-pointer">{{ Value.b_subject }}</span>
              <!--span><i v-show="Value.b_atta_file1" class="far fa-file-alt attach" aria-hidden="true"></i></span-->
            </a>
            <div v-if="b_seq === Value.b_seq" class="view-add-file ">
              <ul class="">
                <li class="text_content mx-2 my-2"> {{ Value.b_contents }} </li>
                <li class="file_down">
                  <div class="mx-[5%] my-1 px-[5%] bg-[#0000001a] flex items-baseline">
                    <span class="border-r divide-solid border-[#858585] pr-[5%]" v-if="!isMobile()">DOWMLOAD</span>
                    <a v-on:click="goDownloadLink(Value.b_atta_file1)" class="!inline-block ml-[5%] overflow-hidden whitespace-no-wrap text-ellipsis cursor-pointer">
                      {{ Value.b_atta_file1 ? Value.b_subject + '.' + Value.b_atta_file1.substring(Value.b_atta_file1.lastIndexOf('.') + 1) : '' }}
                    </a>
                    <span ><img src="/images/icon_file_down.png" class="!inline-block" alt="download file" /></span>
                  </div>
                </li>
              </ul>
            </div>
          </td>
          <td align="center">{{ Value.b_reg_date }}</td>
        </tr>
        <tr v-else>
          <td align="center" colspan="4">{{  list_msg  }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
      <v-pagination
        v-model="page"
        :pages=total_page
        :range-size="1"
        active-color="#DCEDFF">
      </v-pagination>
    </div>
  </dl>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";
import { useMobileDetection } from "vue3-mobile-detection";

export default {
  name: "BusinessTool",
  components : {
    vSelect,
    VPagination,
    Loading
  },
  el: '#tabs',
  setup() {
    const { isMobile } = useMobileDetection();
    return { isMobile };
  },
  data: () => ({
    i18n        : window.i18n,
    page        : 1,
    total_page  : 1,
    page_size   : 20,
    total_count : 0,
    List        : [],
    cate        : "manual", // 상단 탭
    loading     : false,
    list_msg    : "検索ボタンを押して照会可能です。",
    b_seq       : '', // 번호가 있으면 해당 다운로드 링크 오픈
    search_text : '',
    cate_list   : [{label : i18n.myoffice.BusinessTool.manual, value : 'manual'},
                   {label : i18n.myoffice.BusinessTool.leaflet, value : 'catalog'},
                   {label : i18n.myoffice.BusinessTool.product_image, value : 'goods'},
                   {label : i18n.myoffice.BusinessTool.etc, value : 'leaflet'}],
  }),
  watch : {
    page : function () {
      this.submitForm();
    },
    cate : function () {
      this.getList(this.cate);
      this.submitForm(1);
    }
  },
  created : function () {
    this.submitForm();
  },
  methods : {
    submitForm : function (page = 0) {
      if(page) {
        this.page = parseInt(page);
      }
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getBusinessTool',{
        type      : this.cate,
        search    : this.search_text,
        page_size : this.page_size,
        page      : this.page,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if(response.data.total_count == 0) {
          VueSimpleAlert.alert("検索結果がありません。","","",{confirmButtonColor: '#0abab5',confirmButtonText:"確認"});
          this.list_msg = "データがありません。";
        }
        this.total_count = response.data.total_count;
        this.List        = response.data.list.data;
        this.total_page  = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
        this.loading     = false;
      });
    },
    getList : function (type) {
      //manual : 제품메뉴얼 , catalog : 리플릿, goods : 제품이미지, leaflet : 기타
      this.cate = type;
    },
    viewAddFile : function (b_seq) {
      this.b_seq = b_seq;
    },
    goDownloadLink : function (file) {
      VueSimpleAlert.confirm(
  "本資料は、(株)GCOOP JAPANの資産であり、無断での修正、編集、加工は禁止しております。これに違反した場合、(株)GCOOP JAPAN運営ポリシーに則り、処罰されることがあります。同意しますか？",
     "","", {allowOutsideClick : false, confirmButtonText:"確認", cancelButtonText:"中止"})
          .then((result) => {
          window.open("https://cdn.gcoop.me/_upload/bbs/" + this.cate + "/" + file);
      });
    },
  }

}
</script>

<style scoped>
</style>
