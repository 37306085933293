<!--  현재 페이지의 메뉴 : hover와 동일하게 적용한 상태 -->
<style>
   #back_office_left_menu > li > ul.on > li > a { background: #4b5460; }
</style>
<template>
    <div id="sidebar-menu" class=" ">
        <div class="navi">
            <div class="tit">マイオフィス</div>
            <nav>
                <transition name="fade">
                    <ul id="back_office_left_menu"  class="">
                        <li v-for="Value in navigations" v-on:click="NavChange(Value.no)" :class="{'on' : Value.url === thisPage}" :data-id="Value.no">
                            <a v-show="(Value.no != 4 && Value.no != 9) || (Value.no == 4 && today >= 20230922)" :href="Value.url">
                                <i :class="Value.icon"></i><span>{{ Value.title }}</span><i v-if="Value.child" class="fal fa-angle-down" aria-hidden="true"></i>
                            </a>
                            <a :href="(Value.url==='') ? null : Value.url" v-show="Value.no == 9">
                                <i ></i>
                                <img src="/images/autoship_icon_pink.png" class="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-500">
                                <span>{{ Value.title }}</span><i v-if="Value.child" class=" fal fa-angle-down" aria-hidden="true"></i>
                            </a>

                            <ul v-for="sub in Value.child" v-if="Value.child" :class="{'on' : (sub.url === thisPage)}" :data-id="sub.no">
                                <li v-show="navId == Value.no  && sub.rank_cd  && rank_cd >= 60">
                                    <a :href="sub.url">{{ sub.title }}</a>
                                </li>
                                <li v-show="navId == Value.no && !sub.rank_cd"  v-if="sub.gourl" style="display: none;">
                                    <a :href="sub.url" @click="goAutoLogin(sub.urlLink, 'tools/main.php')">{{ sub.title }}</a>
                                </li>
                                <li v-show="navId == Value.no  && !sub.rank_cd"  v-else style="display: none;">
                                  <a :href="sub.url">{{ sub.title }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </transition>

              <div v-show="today >= 20230923" id="" class="overflow-hidden mt-[20px]">
                <ul>
                  <li class="center-flex"><a href="https://lin.ee/81WhKvJ" target="_blank"><img src="/images/sns_line_banner.png" class="w-[232px] h-[72px] rounded cursor-pointer" alt="友だち追加" /></a></li>
                  <li class="center-flex mt-[15px]"><a href="/dirInfo" style="cursor: pointer;"><img src="/images/dir_info.png" class="w-[232px] h-[72px] rounded cursor-pointer"  alt="" /></a></li>
                </ul>
              </div>
            </nav>
        </div>
    </div>
</template>
<script>
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyofficeNav",
    // props: ['today', 'my_office_menu'],
    props: ['today'],
    data: () => ({
        product_categorys: [],
        isLoggedIn: false,
        i18n: window.i18n,
        rank_cd : window.Laravel.rank_cd,
        isVcMember : window.Laravel.isVcMember,
        thisPage : document.location.pathname,
        // navigations : [],
        navigations : [],
        all_navigations :
          [
            {
              "url": "/MyofficeMain",
              "no": 1,
              title: i18n.menu.MyofficeMain,
              icon: "fal fa-home-lg-alt",
              isVcmShow : true,
            },
            {
              "url": '#',
              "no": 7,
              title: i18n.menu.MyRegister,
              icon: "fal fa-user-plus",
              gourl: 'Y',
              urlLink: "register",
              isVcmShow : false,
            },
            {
              "no": 8,
              title: i18n.menu.MyPersonInfo,
              icon: "fal fa-user",
              isVcmShow : true,
              child: [{
                "url": "/MyPersonInfo",
                "no": 8,
                title: i18n.menu.MyPersonInfo,
                isVcmShow : true,
              },
                {
                  "url": "/MyPoint",
                  "no": 8,
                  title: i18n.menu.MyPoint,
                  isVcmShow : false,
                },
                {
                  "url": "/MyMemberUpChange",
                  "no": 8,
                  title: i18n.menu.MyMemberUpChange,
                  isVcmShow : false
                },
              ]
            },

            {
              "no": 2,
              title: i18n.menu.MyPersonInfos,
              icon: "fal fa-user-cog",
              isVcmShow : false,
              child: [{
                "url": "/MyMemberFavorites",
                "no": 2,
                isVcmShow : false,
                title: i18n.menu.MyMemberFavorites
              },
                {
                  "url": "/MyMember",
                  "no": 2,
                  isVcmShow : false,
                  title: i18n.menu.MyMember
                },
                {
                  "url": "/MyMemberLegBox",
                  "no": 2,
                  isVcmShow : false,
                  title: i18n.menu.MyMemberLegBox
                },
                {
                  "url": "/MyMemberLegLine",
                  "no": 2,
                  isVcmShow : false,
                  title: i18n.menu.MyMemberLegLine
                },
                {
                  "url": "/MyMemberLegBoxCompression",
                  "no": 2,
                  isVcmShow : false,
                  title: i18n.menu.MyMemberLegBoxCompression
                },
              ]
            },
            {
              "no": 3,
              title: i18n.menu.MyOrders,
              icon: "fal fa-tasks",
              isVcmShow : true,
              child: [{
                "url": "/MyOrderList",
                "no": 3,
                isVcmShow : true,
                title: i18n.menu.MyOrderList
              },
                {
                  "url": "/MyOrderDown",
                  "no": 3,
                  isVcmShow : false,
                  title: i18n.menu.MyOrderDown
                },
                {
                  "url": "/MyOrderLegs",
                  "no": 3,
                  isVcmShow : false,
                  title: i18n.menu.MyOrderLegs
                },
                {
                  "url": "/MyOrderCancel",
                  "no": 3,
                  isVcmShow : true,
                  title: i18n.menu.MyOrderCancel
                },
                {
                  "url": "/MyOrderRefund",
                  "no": 3,
                  isVcmShow : true,
                  title: i18n.menu.MyOrderRefund
                },
              ]
            },
            {
              "no": 9,
              title: i18n.menu.autoshiplist,
              icon: "fa fa-user-circle",
              isVcmShow : false,
              child: [{
                "url": "/MyAutoshipCardChange",
                "no": 9,
                isVcmShow : false,
                title: i18n.menu.MyAutoshipCardChange
              },
                {
                  "url": "/MyAutoshipEdit",
                  "no": 9,
                  isVcmShow : false,
                  title: i18n.menu.MyAutoshipEdit
                },
                {
                  "url": "/MyAutoshipWithdrawal",
                  "no": 9,
                  isVcmShow : false,
                  title: i18n.menu.MyAutoshipWithdrawal
                },
              ]
            },

            // {"no" : 4,title : i18n.menu.MyBenefit,icon:"fal fa-money-check",
            //     child:[
            //         {"url" : "/MyBenefitSearch","no" : 4,title : i18n.menu.MyBenefitSearch},
            //         {"url" : "/MyBenefitDetailHistory","no" : 4,title : i18n.menu.MyBenefitDetailHistory},
            //         {"url" : "/MyBenefitList","no" : 4,title : i18n.menu.MyBenefitList}, // Commission Statement
            //         {"url" : "/MyBenefitTranslHistory","no" : 4,title : i18n.menu.MyBenefitTranslHistory},
            //         {"url" : "/MyBenefitCalendar","no" : 4,title : i18n.menu.MyBenefitCalendar},
            //         {"url" : "/MyBenefitTaxBreakHistory","no" : 4,title : i18n.menu.MyBenefitTaxBreakHistory}
            //     ]},
            {
              "no": 4,
              title: i18n.menu.MyBenefit,
              icon: "fal fa-money-check",
              isVcmShow : true,
              child: [
                {
                  "url": "/MyBenefitSearch",
                  "no": 4,
                  isVcmShow : true,
                  title: i18n.menu.MyBenefitSearch
                },
                {
                  "url": "/MyBenefitDetailHistory",
                  "no": 4,
                  isVcmShow : true,
                  title: i18n.menu.MyBenefitDetailHistory
                },
                {
                  "url": "/MyBenefitList",
                  "no": 4,
                  isVcmShow : true,
                  title: i18n.menu.MyBenefitList
                }, // Commission Statement
                {
                  "url": "/MyBenefitTranslHistory",
                  "no": 4,
                  isVcmShow : true,
                  title: i18n.menu.MyBenefitTranslHistory
                },
                // {"url" : "/MyBenefitCalendar","no" : 4,title : i18n.menu.MyBenefitCalendar},
                {
                  "url": "/MyBenefitTaxBreakHistory",
                  "no": 4,
                  isVcmShow : true,
                  title: i18n.menu.MyBenefitTaxBreakHistory
                },
                {
                  "url": "/MyBenefitAllowanceReturn",
                  "no": 4,
                  isVcmShow : true,
                  title: i18n.menu.MyBenefitAllowanceReturn
                }
              ]
            },
            {
              "no": 6,
              title: i18n.menu.MyCommunity,
              icon: "fal fa-comments",
              isVcmShow : true,
              child: [{
                "url": "/MyInQuriy",
                "no": 6,
                isVcmShow : true,
                title: i18n.menu.MyInQuriy
              },
                {
                  "url": "/MyNotice",
                  "no": 6,
                  isVcmShow : true,
                  title: i18n.menu.MyNotice
                },
                {
                  "url": "/BusinessTool",
                  "no": 6,
                  isVcmShow : true,
                  title: i18n.menu.BusinessTool
                },
                {
                  "url": "/MyData",
                  "no": 6,
                  isVcmShow : true,
                  title: i18n.menu.MyData
                },
                /*{"url" : "#","no" : 6,title : i18n.menu.MyEducation, gourl:'Y',urlLink : "brand"},*/
                {
                  "url": "/MyFAQ",
                  "no": 6,
                  isVcmShow : false,
                  title: i18n.menu.MyFAQ
                },
                {
                  "url": "/MyVPData",
                  "no": 6,
                  isVcmShow : false,
                  title: i18n.menu.MyVPData,
                  rank_cd: 60
                },
              ]
            },
            {
              "url": "/",
              "no": 0,
              title: i18n.menu.Shopping,
              isVcmShow : true,
              icon: "fal fa-shopping-cart"
            },
            // {"url" : "/BusinessGuide","no" : 0,title : i18n.menu.BusinessGuide,icon : "fa fa-book"}


          ],
        // navigations : [
        //   {"url" : "/MyofficeMain","no" : 1,title : i18n.menu.MyofficeMain,icon:"fal fa-home-lg-alt",
        //   },
        //     {"url" :'#',"no" : 7, title : i18n.menu.MyRegister,icon:"fal fa-user-plus", gourl:'Y',urlLink : "register"},
        //     {"no" : 8,title : i18n.menu.MyPersonInfo,icon:"fal fa-user",
        //       child:[
        //         {"url" : "/MyPersonInfo","no" : 8,title : i18n.menu.MyPersonInfo,},
        //         {"url" : "/MyPoint","no" : 8,title : i18n.menu.MyPoint},
        //         {"url" : "/MyMemberUpChange","no" : 8,title : i18n.menu.MyMemberUpChange},
        //      ]
        //     },
        //
        //     {"no" : 2,title : i18n.menu.MyPersonInfos,icon:"fal fa-user-cog",
        //         child:[
        //             {"url" : "/MyMemberFavorites","no" : 2,title : i18n.menu.MyMemberFavorites},
        //             {"url" : "/MyMember","no" : 2,title : i18n.menu.MyMember},
        //             {"url" : "/MyMemberLegBox","no" : 2,title : i18n.menu.MyMemberLegBox},
        //              {"url" : "/MyMemberLegLine","no" : 2,title : i18n.menu.MyMemberLegLine},
        //             {"url" : "/MyMemberLegBoxCompression","no" : 2,title : i18n.menu.MyMemberLegBoxCompression},
        //         ]},
        //     {"no" : 3,title : i18n.menu.MyOrders,icon:"fal fa-tasks",
        //         child:[
        //             {"url" : "/MyOrderList","no" : 3,title : i18n.menu.MyOrderList},
        //             {"url" : "/MyOrderDown","no" : 3,title : i18n.menu.MyOrderDown},
        //             {"url" : "/MyOrderLegs","no" : 3,title : i18n.menu.MyOrderLegs},
        //             {"url" : "/MyOrderCancel","no" : 3,title : i18n.menu.MyOrderCancel},
        //             {"url" : "/MyOrderRefund","no" : 3,title : i18n.menu.MyOrderRefund},
        //         ]},
        //     {"no" : 9,title : i18n.menu.autoshiplist,icon:"fa fa-user-circle",
        //         child:[
        //             {"url" : "/MyAutoshipCardChange","no" : 9,title : i18n.menu.MyAutoshipCardChange},
        //             {"url" : "/MyAutoshipEdit","no" : 9,title : i18n.menu.MyAutoshipEdit},
        //             {"url" : "/MyAutoshipWithdrawal","no" : 9,title : i18n.menu.MyAutoshipWithdrawal},
        //         ]},
        //
        //     // {"no" : 4,title : i18n.menu.MyBenefit,icon:"fal fa-money-check",
        //     //     child:[
        //     //         {"url" : "/MyBenefitSearch","no" : 4,title : i18n.menu.MyBenefitSearch},
        //     //         {"url" : "/MyBenefitDetailHistory","no" : 4,title : i18n.menu.MyBenefitDetailHistory},
        //     //         {"url" : "/MyBenefitList","no" : 4,title : i18n.menu.MyBenefitList}, // Commission Statement
        //     //         {"url" : "/MyBenefitTranslHistory","no" : 4,title : i18n.menu.MyBenefitTranslHistory},
        //     //         {"url" : "/MyBenefitCalendar","no" : 4,title : i18n.menu.MyBenefitCalendar},
        //     //         {"url" : "/MyBenefitTaxBreakHistory","no" : 4,title : i18n.menu.MyBenefitTaxBreakHistory}
        //     //     ]},
        //     {"no" : 4,title : i18n.menu.MyBenefit,icon:"fal fa-money-check",
        //         child:[
        //             {"url" : "/MyBenefitSearch","no" : 4,title : i18n.menu.MyBenefitSearch},
        //             {"url" : "/MyBenefitDetailHistory","no" : 4,title : i18n.menu.MyBenefitDetailHistory},
        //             {"url" : "/MyBenefitList","no" : 4,title : i18n.menu.MyBenefitList}, // Commission Statement
        //             {"url" : "/MyBenefitTranslHistory","no" : 4,title : i18n.menu.MyBenefitTranslHistory},
        //             // {"url" : "/MyBenefitCalendar","no" : 4,title : i18n.menu.MyBenefitCalendar},
        //             {"url" : "/MyBenefitTaxBreakHistory","no" : 4,title : i18n.menu.MyBenefitTaxBreakHistory},
        //             {"url" : "/MyBenefitAllowanceReturn","no" : 4,title : i18n.menu.MyBenefitAllowanceReturn}
        //         ]},
        //     {"no" : 6,title : i18n.menu.MyCommunity,icon:"fal fa-comments",
        //         child:[
        //             {"url" : "/MyInQuriy","no" : 6,title : i18n.menu.MyInQuriy},
        //             {"url" : "/MyNotice","no" : 6,title : i18n.menu.MyNotice},
        //             {"url" : "/MyData","no" : 6,title : i18n.menu.MyData},
        //             /*{"url" : "#","no" : 6,title : i18n.menu.MyEducation, gourl:'Y',urlLink : "brand"},*/
        //             {"url" : "/MyFAQ","no" : 6,title : i18n.menu.MyFAQ},
        //             {"url" : "/MyVPData","no" : 6,title : i18n.menu.MyVPData , rank_cd : 60},
        //         ]},
        //     {"url" : "/","no" : 0,title : i18n.menu.Shopping,icon:"fal fa-shopping-cart"},
        //     // {"url" : "/BusinessGuide","no" : 0,title : i18n.menu.BusinessGuide,icon : "fa fa-book"}
        //
        //
        // ],
        navId : 1,
        commition_check  : false,
    }),
    beforeCreate() {
        // 카테고리 메뉴
        // axios.post(window.Laravel.back_url+'/api/getMyOfficeMenu', {
        //     bz_cd: window.Laravel.bz_cd
        // }).then(response => {
        //     ///this.navigations = response.data;
        // }).catch(function (error) {console.log(error);});
      axios.post(window.Laravel.back_url + '/api/getjoininfoDocu',{ bz_cd : window.Laravel.bz_cd }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.commition_check = response.data.commition_check;
        //dylee 20230324 미주 최치원팀장 요청
        // 수당 발생시 소메뉴 오픈
        if(this.commition_check && this.isVcMember === false){
          this.navigations[2].child[3] = {"url" : "/MyforinerUpload","no" : 8,title : i18n.menu.MyBankInfo};
        }
      });
    },
    created() {
      this.navigations =
        (this.isVcMember === true) ? this.filterNavigations(this.all_navigations) : this.all_navigations;
    },
    mounted() {

        if (window.Laravel.isLoggedin) {

            this.isLoggedIn = true;
            this.user = window.Laravel.user;
            this.rank_cd = window.Laravel.rank_cd;

            if(this.isVcMember === false && this.rank_cd == '00'){//Comsumer 메뉴 삭제
              this.navigations.splice(1,1);//Member registration
              this.navigations[1].child.splice(2,1);
              this.navigations.splice(2,1);//Team managment
              this.navigations[2].child.splice(1,1);
              this.navigations.splice(3,2);//Commission Management,Consumer
              this.navigations[3].child.splice(2,1);//Library
              this.navigations[2].child[0].url = '/MyOrderList';
            }
        }
      // 좌측 메뉴 현재 페이지 열리도록
        var navOn       = document.querySelector("#back_office_left_menu .on");
        if(navOn) {
          this.navId    = navOn.getAttribute('data-id');
        } else { // 임시적으로 추가
            let getPath = this.thisPage.split('/');
            getPath     =  (getPath.length > 1) ? getPath[1] : getPath[0];
            if(getPath == 'MyOrderView') { getPath = 'MyOrderDown'; }
            if(getPath == 'MyInQuiryModify') { getPath = 'MyInQuriy'; }
            this.navigations.forEach((navi, key) => {
              if(navi.child) {
                navi.child.forEach((sub, key) => {
                  if(getPath == sub.url.split('/')[1]) {
                    this.navId       = sub.no;
                    let naviOnTarget = document.querySelectorAll('#back_office_left_menu > li[data-id="'+this.navId+'"] > ul > li:not([style="display:none;"]) > a[href="'+sub.url+'"]');
                    if(naviOnTarget) {
                      naviOnTarget[1].parentElement.parentElement.classList.add('on');
                    }
                  }
                });
              }
            });
        }
    },
    methods: {
        filterNavigations(navigations) {
          return navigations.reduce((acc, item) => {
            let newItem = {...item};
            if (newItem.isVcmShow) {
              if (newItem.child) {
                newItem.child = this.filterNavigations(newItem.child);
              }
              acc.push(newItem);
            }
            return acc;
          }, []);
        },
        NavChange(id){
            if(id == 7){
              this.goAutoLogin('register','');
            }
            /*
            if(this.navId == id){
                id = 0;
            }
            */
            this.navId = id;
        },
        goAutoLogin(mode, url) {
          if(mode == 'register'){
            if(this.rank_cd != '00'){
                location.href="/Register";
            }else{
              VueSimpleAlert.alert("不可能","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
            }
          }else{
            let config = {
              headers: {
                Authorization: 'Bearer ' + window.Laravel.access_token
              }
            };

            axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
              mode: mode,
              url: url,
              bz_cd: window.Laravel.bz_cd,
            },config).then(response => {
              if(response.data.href_url != ""){
                window.open(response.data.href_url);
              }
            }).catch(e => {
            });
          }
        }
    },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
#back_office_left_menu > li {
  cursor: pointer;
}

.center-flex{
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  //align-items: center; /* 수직 중앙 정렬 */
  //height: 200px; /* 또는 원하는 높이 */
}
</style>
