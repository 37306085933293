<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="this.nt_cd"/>
  </modalView>
  <!-- S : 은행및 지점 코드 입력 -->
  <modalView class="z-50" v-if="bank_search_open" @close-modal="bank_search_open = false"   >
    <bank_search @set_bank="set_bank" @close_bank_search="close_bank_search" :bank_type="this.bank_type" :bank_cd="this.business.bankCd" />
  </modalView>
  <!-- E : 은행및 지점 코드 입력 -->
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 xl:px-6 lg:px-6 px-4 lg:max-w-7xl bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <div class="w-full bg-white lg:p-2" id="infomodify" >
                    <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">法人仮登録</h2>
                    <p class="w-fil text-right text-sm"><span class="text-red-500">*</span> 必須入力</p>
                    <!-- S : 법인명(한자) -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>法人会員名(漢字)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="lastName" id="lastName" class=" add-input1 h-10 w-full xl:w-80 lg:w-80 placeholder:text-sm" v-model="business.lastName" type="text"
                               maxlength="20" placeholder="例）株式会社○○○○／○○○○有限会社"> <br>
                      </div>
                    </div>
                    <!-- E : 법인명(한자) -->
                    <!-- S : 법인명(영문) -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>法人会員名(英字)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="lastRomaName" id="lastRomaName" class=" add-input1 h-10 w-full xl:w-80 lg:w-80 placeholder:text-sm" v-model="business.lastRomaName" type="text"
                               maxlength="20" placeholder="例）○○○○Co.,Ltd／○○○○Inc."> <br>
                      </div>
                    </div>
                    <!-- E : 법인명(영문) -->
                    <!-- S : 법인명(가타카나) -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>法人会員名(カタカナ)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="lastHuriName" id="lastHuriName" class=" add-input1 h-10 w-full xl:w-80 lg:w-80 placeholder:text-[11px]" v-model="business.lastHuriName" type="text"
                               maxlength="20" placeholder="例）カブシキガイシャ○○○○／○○○○ユウゲンガイシャ" @input="funcUserName"> <br>
                      </div>
                    </div>
                    <!-- E : 법인명(가타카나) -->
                    <!-- S : 대표자명(한자) -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>代表者名(漢字)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="ownerLastName" id="ownerLastName" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="business.ownerLastName" type="text"
                               maxlength="20"> <br>
                      </div>
                    </div>
                    <!-- E : 대표자명(한자) -->
                    <!-- S : 대표자명(로마자) -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>代表者名(ローマ字)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="ownerLastRomaName" id="ownerLastRomaName" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="business.ownerLastRomaName" type="text"
                               maxlength="20"> <br>
                      </div>
                    </div>
                    <!-- E : 대표자명(로마자) -->
                    <!-- S : 대표자명(가타카나) -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>代表者名(カタカナ)</div>
                      <div class="w-full lg:w-5/6">
                        <input name="ownerLastHuriName" id="ownerLastHuriName" class=" add-input1 h-10 w-full xl:w-80 lg:w-80" v-model="business.ownerLastHuriName" type="text"
                               maxlength="20" @input="funcUserName"> <br>
                      </div>
                    </div>
                    <!-- E : 대표자명(가타카나) -->
                    <!-- S : 성별 -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>性別</div>
                      <div class="w-full lg:w-5/6 flex flex-row items-center gap-4">
                        <div class="flex items-center cursor-pointer">
                          <input type="radio" value="M" id="man" v-model="business.selectSex" checked><label for="man" class="cursor-pointer">&nbsp;男</label>
                        </div>
                        <div class="flex items-center cursor-pointer">
                          <input type="radio"  value="F" id="woman" v-model="business.selectSex"><label for="woman" class="cursor-pointer">&nbsp;女</label>
                        </div>
                      </div>
                    </div>
                    <!-- E : 성별 -->
                    <!--START : 생년월일-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>生年月日</div>
                      <div class="w-full lg:w-5/6">
                        <div class="w-full lg:w-5/6 flex gap-2">
                          <select name="strtYearSch" id="YearSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model="strtYearSch">
                            <option value="" selected>年</option>
                            <option v-for="(item, index) in years" :key="item" :value="item">{{item}}</option>
                          </select>
                          <select name="strtMonthSch" id="MonthSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model="strtMonthSch">
                            <option value="" selected>月</option>
                            <option v-for="(item, index) in months" :key="item" :value="item">{{ item }} </option>
                          </select>
                          <select name="strtDaySch" id="DaySch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model="strtDaySch">
                            <option value="" selected>日</option>
                            <option v-for="(item, index) in days" :key="item" :value="item">{{item}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!--END : 생년월일-->
                    <!--START : 이메일-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>メールアドレス</div>
                      <div class="w-full lg:w-5/6 ">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                          <div class="flex flex-row items-center gap-2">
                            <input name="email1" id="email1" class="type_email h-10 w-1/2 xl:w-60 lg:w-60"  v-model="business.email1" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true" :class="[is_email_chk ? 'disabled' : '']">
                            @
                            <input name="email2" id="email2" class="type_email h-10 w-1/2 xl:w-60 lg:w-60"  v-model="business.email2" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true" :class="[is_email_chk ? 'disabled' : '']">
                          </div>
                          <input type="button" v-if="emailChkBtn" @click="checkEmail"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] cursor-pointer mt-2 ml-2 xl:ml-2 lg:mt-0 xl:mt-0" value="Check"/>
                        </div>
                        <div id="emailMsg" class="oran text-red-700 text-sm">{{this.emailMsg}}</div>
                      </div>
                    </div>
                    <!--END : 이메일-->
                    <!--START : 비밀번호-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.Password }}</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                            <input @blur="checkPassword"  v-if="!show" name="passwd" id="passwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="business.passwd" style="ime-mode:disabled;" type="password" @input="funcUserName">
                            <input @blur="checkPassword"  v-if="show" name="passwd" id="passwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="business.passwd" style="ime-mode:disabled;" type="text" @input="funcUserName">
                            <input type="button" v-if='!show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="見る"/>
                            <input type="button" v-if='show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="隠す"/>
                          </div>
                          <div class="text-red-500 text-sm" id="passMsg">英数字を混ぜて8文字以上で入力してください。(大文字と小文字は区分します)</div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-3">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>{{ i18n.myoffice.Passwordcheck }}</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col">
                          <input @blur="checkPassword" v-if="!show_confirm" name="confirmPasswd" id="confirmPasswd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="business.confirmPasswd" style="ime-mode:disabled;" type="password" @input="funcUserName">
                          <input @blur="checkPassword" v-if="show_confirm" name="confirmPasswd" id="confirmPasswd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="business.confirmPasswd" style="ime-mode:disabled;" type="text" @input="funcUserName">
                          <input type="button" v-if='!show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="見る"/>
                          <input type="button" v-if='show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="隠す"/>
                        </div>
                        <div id="passConfirmMsg" class="oran text-red-700 text-sm"></div>
                      </div>
                    </div>
                    <!--END : 비밀번호-->
                    <!-- START : 은행정보 -->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">金融機関コード</div>
                      <div class="w-full lg:w-5/6">
                        <input class="type_number h-10 xl:w-60 lg:w-60 w-full"  type="text" v-model="business.bankCodeTxt" @click="search_bank('bank')" readonly>
                        <input type="button" @click="goLink('http://zengin.ajtw.net')" class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="銀行コード検索"/>
                        <div class="text-red-500 text-sm">銀行と支店コードを検索してから入力してください。</div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">支店コード</div>
                      <div class="w-full lg:w-5/6">
                        <input class="type_number h-10 xl:w-60 lg:w-60 w-full" type="text" v-model="business.bankBranchCodeTxt" @click="search_bank('branch')" readonly>
                        <div class="text-red-500 text-sm">銀行と支店コードを検索してから入力してください。</div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">口座番号</div>
                      <div class="w-full lg:w-5/6">
                        <input name="account" class="type_number h-10 xl:w-60 lg:w-60 w-full" type="text" v-model="business.account" maxlength="7" @input="business.account.replace(/\D/gi, '')">
                        <div class="text-red-500 text-sm">法人名義の口座番号を入力してください。</div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>口座名義</div>
                      <div class="w-full lg:w-5/6">
                        <input name="depositor" class="type_number h-10 xl:w-60 lg:w-60 w-full" type="text" v-model="business.depositor" readonly>
                      </div>
                    </div>
                    <!-- END : 은행정보 -->
                    <!--START : 휴대전화-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>携帯電話</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                          <div class="flex flex-row gap-2">
                            <input type="text" name="mobile1" v-model="business.mobile1" :readonly="is_mobile_chk == true" :class="[is_mobile_chk ? 'disabled' : '']" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" name="mobile2" v-model="business.mobile2" :readonly="is_mobile_chk == true" :class="[is_mobile_chk ? 'disabled' : '']" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" name="mobile3" v-model="business.mobile3" :readonly="is_mobile_chk == true" :class="[is_mobile_chk ? 'disabled' : '']" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          </div>
                          <input type="button" v-if='duplChkBtn' @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="確認"/>
                        </div>
                        <div class="flex flex-col">
                          <div id="mobileMsg" class="oran text-red-500 text-sm">{{this.mobileMsg}}</div>
                        </div>
                      </div>
                    </div>
                    <!--END : 휴대전화-->
                    <!--START : 집전화-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>電話番号</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                          <div class="flex flex-row gap-2">
                            <input type="text" name="tel1" v-model="business.tel1" maxlength="4" :readonly="is_tel_chk" :class="[is_tel_chk ? 'disabled' : '']" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" name="tel2" v-model="business.tel2" maxlength="4" :readonly="is_tel_chk" :class="[is_tel_chk ? 'disabled' : '']" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" name="tel3" v-model="business.tel3" maxlength="4" :readonly="is_tel_chk" :class="[is_tel_chk ? 'disabled' : '']" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          </div>
                          <input type="button" v-if='duplChkTelBtn' @click="checkTel"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="確認"/>
                        </div>
                        <div class="flex flex-col">
                          <div id="telMsg" class="oran text-red-500 text-sm">{{this.telMsg}}</div>
                        </div>
                      </div>
                    </div>
                    <!--END : 집전화-->
                    <!--START : 팩스 번호-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">ファックス番号</div>
                      <div class="w-full lg:w-5/6">
                        <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                          <div class="flex flex-row gap-2">
                            <input type="text" name="fax1" v-model="business.fax1" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" name="fax2" v-model="business.fax2" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                            <input type="text" name="fax3" v-model="business.fax3" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END : 팩스 번호-->
                    <!--START : 주소-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>郵便番号</div>
                      <div class="w-full lg:w-5/6">
                        <input name="post" id="post" class="type_number h-10 xl:w-60 lg:w-60 w-full" @click="search_address" v-model="business.post" maxlength='5' type="text" readonly>
                        <input type="button" @click="search_address"  class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="検索"/>
                        <input type="button" @click="goLink('https://www.post.japanpost.jp/zipcode')"  class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="郵便番号を調べる"/>
                        <div class="text-red-500 text-sm"></div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>ご住所</div>
                      <div class="w-full lg:w-5/6">
                        <input type='text' name="addr1" id="addr1" v-model='business.addr1' @click="search_address" class="h-10 xl:w-9/12 lg:w-9/12 w-full" readonly>
                      </div>
                    </div>
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>詳細住所</div>
                      <div class="w-full lg:w-5/6">
                        <input name="addr2" id="addr2" class=" add-input1 h-10 xl:w-9/12 lg:w-9/12 w-full" v-model="business.addr2" type="text"> <br>
                        <div class="text-red-500 text-sm">必ず屋号、部屋番号まで全て入力してください。</div>
                      </div>
                    </div>
                    <!--END : 주소-->
                    <!--START : 추천인-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:pt-2 lg:items-center mb-4 pb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>推薦者会員番号</div>
                      <input type='text' name="recommUserId" id="recommUserId" v-model='business.recommUserId' readonly  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                    </div>
                    <!--END : 추천인-->
                    <!--START : 아카데미-->
                    <!--                          <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4 pb-4">-->
                    <!--                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>所属アカデミー</div>-->
                    <!--                            <div class="w-full lg:w-5/6">-->
                    <!--                              <div class="w-full lg:w-5/6 flex gap-2">-->
                    <!--                                <select name="selectCenter" class="xl:w-60 lg:w-60 w-full text-sm md:text-base" v-model='business.selectCenter'>-->
                    <!--                                  <option v-for="(item, index) in academy" :key="item" :value="item.id">{{ item.value }}</option>-->
                    <!--                                </select>-->
                    <!--                              </div>-->
                    <!--                              <div class="text-red-500 text-sm">-->
                    <!--                                <p>1)アカデミーの変更は1回のみ可能です。</p>-->
                    <!--                                <p>2)所属するアカデミーがない場合は、東京支社を選択してください。(アカデミーについては、推薦者にご確認ください。)</p>-->
                    <!--                              </div>-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--END : 아카데미-->

                  </div><!-- // -->

                  <div class="w-full bg-white lg:p-2" id="optionInfo" >
                    <!--START : 기존 아이디-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">既存のメンバーID</div>
                      <input type='text' name="origin_user_id" id="origin_user_id" v-model='business.origin_user_id' class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.trim();">
                    </div>
                    <!--END : 기존 아이디-->
                    <!--START : 기존 아이디-->
                    <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                      <div class="w-full lg:w-1/6 text-base font-semibold mb-2">既存の連絡先</div>
                      <input type='text' name="origin_user_mobile" id="origin_user_mobile" v-model='business.origin_user_mobile' class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
                    </div>
                    <!--END : 기존 아이디-->
                  </div>

                  <div class="flex gap-2 mt-4">
                    <button type="button" v-on:click="send_agree" class="my-2 py-2 px-4 leading-6 bg-[#d5e7f6] hover:bg-[#cbcfdd] text-[#2c7fc3] xl:w-16 lg:w-16 w-1/2 reset_btn rounded-md">前のページに戻る</button>
                    <button type="button" v-on:click="submitForm"  v-if="submitFormBtn" class="my-2 py-2 px-4 leading-6 text-white bg-[#2c80c4] hover:bg-[#24679f] xl:w-16 lg:w-16 w-1/2 search_btn rounded-md">{{ i18n.myoffice.save }}</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block lg:hidden xl:hidden mt-4">
        <SideMenu></SideMenu>
      </div>
    </div>

  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";

export default {
  name: "MyRegisterBusiness",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    showMessage: true,

    i18n: window.i18n,
    laravel: window.Laravel,

    isLoggedIn: false,
    loading : false,
    mem: "",

    /* START : 파라미터 변수 선언 */
    business : [],
    strtDaySch:"",
    strtMonthSch:"",
    strtYearSch:"",
    bank_type        : "",

    rId : "",
    rrId : "",
    rrName : "",

    /* END : 파라미터 변수 선언 */

    /* 버튼 Show hide 변수*/
    smsChkBtn : false,
    smsChkPrimaryBtn : false,
    sendBtn : false,

    duplChkBtn : true,
    duplChkTelBtn : true,

    sendPrimaryBtn : true,
    emailChkBtn : true,
    businessChkBtn : true,
    ssnChkBtn : true,
    submitFormBtn : true,
    bank_search_open : false,

    mobile_sms_chk : "",//sms 코드 인증
    mobile_primary_sms_chk : "",//primary sms 코드 인증
    TimeCounter : 300, //sms 인증 제한 초
    TimerStr : "",//초 -> 분 형식변경

    /* 유효성 검사 변수 */
    is_pwd_chk : false,
    /*
      2023-02-14
      MYOFFICE 에서 ITIM / SSN 을 별도로 받기로 하여 유효성검사 is_jumin_chk 강제 true 변경함
    */
    is_jumin_chk : false,
    is_email_chk : false,
    is_mobile_chk : false,
    is_tel_chk    : false,
    is_age_chk : false,
    is_business_num_chk : false,

    isCheckAll : false,

    fullPage : true,
    state_list : "",
    city_list : "",
    street_list : "",
    address_search_open:false,
    isMatchStateCity:false,

    /* 기본 선언 변수 */
    days:['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    years:[],
    months:[],

    is_login: true,

    /*문구*/

    emailMsg : "",
    mobileMsg : "",
    telMsg    : "代表電話を入力してください。",
    ceoEmailMsg : "",
    mobilePrimaryMsg : "",
    businessMsg : "",
    juminMsg : "",

    //비밀번호 보이기감추기
    show : false,
    show_confirm : false,

  }),

  beforeCreate() {


  },
  created() {

    let year = new Date().getFullYear();
    for(let i=(year-18); i>=(year-100); i--){
      this.years.push(
        i
      );
    }
    for(let m = 1; m <= 12; m++){ /* 생년월일의 월 셀렉트 박스 */
      if(m.toString().length < 2) {
        this.months.push('0' + m);
      } else {
        this.months.push('' + m);
      }
    }

    // 필수입력란 변수 선언
    const inputValues = [ 'lastName', 'lastRomaName', 'lastHuriName', 'ownerLastName', 'ownerLastRomaName', 'ownerLastHuriName', 'selectSex'
                        , 'strtYearSch', 'strtMonthSch', 'strtDaySch', 'email1', 'email2', 'passwd', 'confirmPasswd'
                        , 'account', 'depositor', 'mobile1', 'mobile2', 'mobile3', 'tel1', 'tel2', 'tel3'
                        , 'post', 'addr1', 'addr2', 'recommUserId'
                        , 'bankCd', 'bankName', 'bankKataCode', 'bankHiraCode', 'bankBranchCode', 'bankBranchNameCode', 'bankBranchKataCode', 'bankBranchHiraCode'];
    for(const el of inputValues) {
      if (typeof this.business[el[0]] === "undefined") {
        switch (el) {
          case 'selectSex':
            this.business.selectSex = 'M';
            break;
          default:
            this.business[el] = "";
            break;
        }
      }
    }

  },
  watch : {

    //생년월일 체크
    strtDaySch : function(){
      this.chkAge(this.strtYearSch, this.strtMonthSch, this.strtDaySch);
    },
    strtMonthSch : function(){
      this.chkAge(this.strtYearSch, this.strtMonthSch, this.strtDaySch);
    },
    strtYearSch : function(){
      this.chkAge(this.strtYearSch, this.strtMonthSch, this.strtDaySch);
    },
    //연도 데이터 호출
    s_month : function(){
      var total_day = dayjs(this.s_year+this.s_month).daysInMonth();
      var day = 1;
      for(day ; day <= total_day ; day++){
        this.days.push(
          {value: day}
        );
      }
    },
  },
  mounted() {
    if (window.Laravel.registerRid) {
      this.isLoggedIn              = true;
      this.business.loginId        = window.Laravel.user;
      this.business.recommUserId   = window.Laravel.registerRid;
      this.business.recommUserName = window.Laravel.registerRname;
      this.business.work_user_id   = window.Laravel.user;
    }else{
      location.href='/';
    }
  },
  methods:{
    showPwd : function(select){
      //비밀번호 감추기 보이기
      if(select == 'show'){
        this.show = !this.show;
      }else if(select == 'show_confirm'){
        this.show_confirm = !this.show_confirm;
      }

    },
    updateArray (key) {
      this.$set(this.sa_data, 'key', 'value')
    },
    send_agree : function (){//agree 페이지로 이동
      location.href= './Register';
    },
    validationCheck : function () {
        /* S : 필수 변수 널체크 */
        const inputValueList = [
          ['lastName', '法人会員名(漢字)'], ['lastRomaName', '法人会員名(英字)'], ['lastHuriName', '法人会員名(カタカナ)'],
          ['ownerLastName', '代表者名(漢字)'], ['ownerLastRomaName', '代表者名(ローマ字)'], ['ownerLastHuriName', '代表者名(カタカナ)'],
          ['strtYearSch', '生年月日'], ['strtMonthSch', '生年月日'], ['strtDaySch', '生年月日'],
          ['depositor', '口座名義'], ['mobile1', '携帯電話'], ['mobile2', '携帯電話'], ['mobile3', '携帯電話'], ['email1', 'メールアドレス'], ['email2', 'メールアドレス'],
          ['tel1', '電話番号'], ['tel2', '電話番号'], ['tel3', '電話番号'], ['post', '郵便番号'], ['addr1', 'ご住所'], ['addr2', '詳細住所'], ['recommUserId', '推薦者会員番号']
        ];
        for(const el of inputValueList) {
          if(!this.business[el[0]]) {
            document.getElementById(el[0]).focus();
            VueSimpleAlert.alert(el[1] + "を入力してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return false;
          }
        }
        /* E : 필수 변수 널체크 */
        /* S : 중복확인 완료했는지 체크 */
        if(!this.is_email_chk) { // 이메일 중복 확인을 해주세요.
          VueSimpleAlert.alert("メールアドレスの重複確認をしてください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
          return false;
        }
        if(!this.is_mobile_chk) { // 휴대전화 중복 확인을 해주세요.
          VueSimpleAlert.alert("携帯電話番号の重複確認をしてください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
          return false;
        }
        if(!this.is_tel_chk) { // 전화번호 중복 확인을 해주세요.
          VueSimpleAlert.alert("電話番号の重複確認をしてください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
          return false;
        }
        /* E : 중복확인 완료했는지 체크 */
        if(!this.is_pwd_chk) { // 비밀번호 체크
          document.getElementById('passwd').focus();
          VueSimpleAlert.alert("パスワードを確認してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
          return false;
        }
        if(!this.is_age_chk) { // 미성년자 체크
          VueSimpleAlert.alert("年齢が満18歳未満の者は会員の入会ができます。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
          return false;
        }
        return true;
    },
    submitForm: function () {
        this.loading =true;

        if(!this.validationCheck()) { /* 필수 입력값 체크 및 유효성 체크 */
          this.loading = false;
          return;
        }

        this.submitFormBtn = false;
        // 입력하신 정보로 회원가입이 진행됩니다.
        VueSimpleAlert.confirm("入力した情報で会員入会します。", "", "", {allowOutsideClick : false,confirmButtonText:"確認", cancelButtonText:"キャンセル"}).then((result) => {
          this.business.rankCd     = "01";
          this.business.memberKind = "1";
          let formData             = new FormData();

          Object.entries(this.business).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append('selectCenter', '0401');

          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization : 'Bearer ' + window.Laravel.access_token
            }
          }
          /* S : 저장 */
          axios.post(window.Laravel.back_url+'/api/insertSaveBusinessMemberUs',
            formData
            , config).then(response => {
            if(response.data.status == "1"){
              VueSimpleAlert.alert("会員登録が完了しました。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => { //회원가입이 완료 되었습니다.
                if(result){
                  location.replace("/RegisterOk?userid="+response.data.SP_KEYVALUE+"&xid="+response.data.xid);
                }
              });
            } else if (response.data.status != "1") {
              this.loading       = false;
              this.submitFormBtn = true;
              VueSimpleAlert.alert(response.data.message, "", "", {allowOutsideClick: false,confirmButtonText:"確認"}).then((result) => {})
            }
          });
          /* E : 저장 */
        }).catch(error => {
          this.loading       = false;
          this.submitFormBtn = true;
        })// confirm end
    },

    checkBusinessNum(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/chkBusinessNumber', {
        businessNumber : (this.BusinessNumber1) +  (this.BusinessNumber2 == ""?"":"-"+ this.BusinessNumber2),
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if (response.data.cnt > 0) {
          //중복 사업자 존재
          this.businessMsg = i18n.join.join.businessduplicate;/* 중복된 사업자 번호가 있습니다.*/
          this.is_email_chk = false;
        } else {
          //중복 사업자 없음
          this.businessMsg = "Confirmed";/* 중복된 사업자 번호가 없습니다. */
          this.is_business_num_chk = true;
          this.businessChkBtn = false;//버튼 가리기
        }
        this.loading = false;
      })

    },
    checkEmail(){
      this.loading = true;
      //이메일 미입력시
      if(this.business.email1.length < 1 || this.business.email2.length < 1) {
        // this.emailMsg = i18n.join.twalert.emailId;
        this.emailMsg = "メールアドレスを入力してください。"; // 이메일 주소를 입력하세요.
        $("#email_1").focus();
        this.loading = false;
        return;
      }else{
        this.emailMsg = "";
      }
      //이메일 유효성체크
      this.email = this.business.email1.trim() + '@' + this.business.email2.trim();

      let regex =/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/;
      if(regex.test(this.email) == false){
        // this.emailMsg = i18n.join.twalert.emailId2;
        this.emailMsg = "メール形式に合わない。"; // 이메일 형식에 맞지 않습니다.
        this.loading  = false;
        return false;
      }else{
        this.emailMsg = "";
      }

      axios.post(window.Laravel.back_url+'/api/getComparisonEmail', {
        email     : this.email,
        join_type : 'business'
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if(response.data.chk.checker == "N"){
          //중복 이메일 존재
          // this.emailMsg = i18n.join.join.alert_emailchk1;/* 이미 등록된 이메일 주소입니다. */
          this.emailMsg     = "既に登録済みのメールアドレスです。";
          this.is_email_chk = false;
        }else{
          //중복 이메일 없음
          // this.emailMsg = i18n.join.join.alert_emailchk4;/* 사용 가능한 이메일 주소입니다. */
          document.getElementById('emailMsg').style.color = 'skyblue';
          this.emailMsg     = "利用可能なメールアドレスです。";
          VueSimpleAlert.alert(this.emailMsg,"","",{allowOutsideClick : false}).then((result) => {})
          this.is_email_chk = true;
          this.emailChkBtn  = false; // 이메일 버튼 hide
        }
        this.loading = false;
      });
    },
    //모바일 유효성 검사
    checkMobile(){
      this.loading   = true;
      this.mobileMsg = "";

      //모바일 미입력시
      if(this.business.mobile1.length < 3 || this.business.mobile2.length < 1 || this.business.mobile3.length < 1  ) {
        this.mobileMsg = "携帯電話を入力してください。"; // 휴대전화를 입력하세요.
        this.loading   = false;
        return;
      }
      axios.post(process.env.MIX_BACK_URL+'/api/getComparisonMobile', {
        mobile    : this.business.mobile1.trim()+"-"+this.business.mobile2.trim()+"-"+this.business.mobile3.trim(),
        join_type : 'business'
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if (response.data.chk.checker == "0") { // 사용 가능한 휴대전화번호입니다.
          this.mobileMsg = "利用可能な携帯電話番号です。";
          document.getElementById('mobileMsg').style.color = 'skyblue';
          VueSimpleAlert.alert(this.mobileMsg,"","",{allowOutsideClick : false}).then((result) => {})

          this.is_mobile_chk   = true;
          this.duplChkBtn      = false;
          this.ressetMblChkBtn = true;
        } else {
          this.is_mobile_chk   = false;
          this.mobileMsg       = "既に使用中の 携帯電話番号です。"; // 이미 사용중인 휴대 전화 번호입니다.
        }
        this.loading = false;
      });
    },
    //전화번호 유효성 검사
    checkTel(){
      this.loading   = true;
      this.telMsg = "";

      //전화번호 미입력시
      if(this.business.tel1.length < 2 || this.business.tel2.length < 1 || this.business.tel3.length < 1  ) {
        this.telMsg    = "電話番号を入力してください。"; // 전화번호를 입력하세요.
        this.loading   = false;
        return;
      }
      axios.post(process.env.MIX_BACK_URL+'/api/getComparisonTel', {
        tel : this.business.tel1.trim()+"-"+this.business.tel2.trim()+"-"+this.business.tel3.trim(),
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if (response.data.chk.checker == "0") { // 사용 가능한 전화번호입니다.
          this.telMsg = "利用可能な電話番号です。";
          document.getElementById('telMsg').style.color = 'skyblue';
          VueSimpleAlert.alert(this.telMsg,"","",{allowOutsideClick : false}).then((result) => {})
          this.is_tel_chk    = true;
          this.duplChkTelBtn = false;
        } else {
          this.is_tel_chk   = false;
          this.telMsg       = "既に使用中の 電話番号です。"; // 이미 사용중인 전화 번호입니다.
        }
        this.loading = false;
      });
    },
    //비밀번호, 비밀번호 확인 동일 확인
    checkPassword : function(e){
      //비밀번호 8자리 미만
      if(this.business.passwd.length < 8) {
        //$("#passConfirmMsg").text(i18n.join.twalert.passchk3);//영문 및 숫자를 조합하여, 8자리로 입력 해 주시기 바랍니다
        $("#passMsg").text("英文と数字を組み合わせて、8桁で入力してください。");
        this.is_pwd_chk = false;
        return;
      } else {
        $("#passMsg").text("");
      }

      if(this.business.confirmPasswd.length < 8) {
        $("#passConfirmMsg").text("英文と数字を組み合わせて、8桁で入力してください。");
        this.is_pwd_chk = false;
        return;
      } else {
        $("#passConfirmMsg").text("");
      }

      //비밀번호 확인 미입력시
      if(this.business.passwd.length < 1 || this.business.confirmPasswd.length < 1) {
        // $("#passConfirmMsg").text(i18n.join.twalert.password1);//영문 및 숫자를 조합하여, 8자리로 입력 해 주시기 바랍니다
        $("#passConfirmMsg").text("英文と数字を組み合わせて、8桁で入力してください。");
        this.is_pwd_chk = false;
        return;
      }

      //비밀번호, 비밀번호 확인 일치여부
      if(this.business.passwd != this.business.confirmPasswd) { // 비밀번호 확인이 일치하지 않습니다.
        // $("#passMsg").text(i18n.join.twalert.password2);
        $("#passConfirmMsg").text("パスワードの確認が一致しません。");
        this.is_pwd_chk = false;
        return;
      }else{ // 비밀번호가 일치합니다 통과
        $("#passConfirmMsg").text("");
        this.is_pwd_chk = true;
      }

    },

    chkJumin(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/businessChkjumin', {
        ssn : this.sa_data[0].sa_ssn,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if( response.data.data.cnt == "0" ){ // 가입가능
          this.sa_data[0].is_jumin_chk = "Y";
          this.juminMsg = "Available";
          this.ssnChkBtn = false;
        }else {
          this.sa_data[0].is_jumin_chk = "N";
          this.juminMsg = i18n.join.join.ssn_notice;
        }
        this.loading = false;
      });

    },
    chkAge(year,mon,day){
      this.business.strtYearSch  = year;
      this.business.strtMonthSch = mon;
      this.business.strtDaySch   = day;
      this.is_age_chk            = false;

      // 미국 미성년자 기준은 만 18세
      // 기준확인을 하기 위한 참고한 사이트 : http://m.blog.naver.com/woongjooseok/220711033579

      if(year == "" || mon == "" || day == ""){
        return;
      }
      //비교를위해 숫자타입으로 변경.
      year = Number(year);
      mon  = Number(mon);
      day  = Number(day);

      //성년기준 나이
      let cut = 18;

      //체크용 날짜형식
      let set = new Date();
      let setYear = Number(set.getFullYear());
      let setMon  = Number((set.getMonth() + 1));
      let setDay  = Number(set.getDate());

      // 현재나이
      let age = (setYear-1) - year;

      // 자신의 생일이 지난 만18세가 되면 허용
      if(age >= cut){
        this.is_age_chk = true;
        return;
      }
      // 월 체크
      if(setMon > mon){
        this.is_age_chk = true;
        return;
      }
      // 일 체크
      if(setDay > day){
        this.is_age_chk = true;
      }
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ selectState }) {
              component.$el.classList.toggle(
                'drop-up',
                selectState.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    // 숫자 포멧
    funcNumber(type,val) {
      let value = val;
      // 숫자만 남김
      var pattern_kor = /[^0-9]/gi;

      if(type =='jumin_1'){
        this.jumin_1 = val.replace(pattern_kor, "");
      }else if(type =='jumin_2'){
        this.jumin_2 = val.replace(pattern_kor, "");
      }else if(type =='jumin_3'){
        this.jumin_3 = val.replace(pattern_kor, "");
      }

    },
    /* S : 주소검색 */
    search_address(){
      this.address_search_open = true;
    },
    set_address(value){
      this.address_search_open = false;
      this.business.post         = value.postcode;
      this.business.addr1        = value.address + " " + value.address2 + " " + value.address3;
      this.business.city         = value.address;
    },
    close_address_search(){
      this.address_search_open = false;
    },
    /* E : 주소검색 */
    /* S : 은행검색 */
    search_bank(bank_type){
      console.log(this.business.bankCd);
      if(bank_type === 'branch' && !this.business.bankCd) { // 금융 기관 코드를 먼저 입력해주세요.
        VueSimpleAlert.alert("金融機関コードを最初に入力してください。","","",{allowOutsideClick : false}).then((result) => {})
        return;
      }
      this.bank_type        = bank_type;
      this.bank_search_open = true;
    },
    set_bank(value){
      this.close_bank_search();
      console.log(value);
      if(value.bank_type === 'bank') {
        this.business.bankCodeTxt       = value.code + " " + value.name + "(" + value.kana + ")";
        this.business.bankCd            = value.code ?? '';
        this.business.bankName          = value.name ?? '';
        this.business.bankKataCode      = value.kana ?? '';
        this.business.bankHiraCode      = value.hira ?? '';
      }
      if(value.bank_type === 'branch') {
        this.business.bankBranchCodeTxt  = value.code + " " + value.name + "(" + value.kana + ")";
        this.business.bankBranchCode     = value.code ?? '';
        this.business.bankBranchNameCode = value.name ?? '';
        this.business.bankBranchKataCode = value.kana ?? '';
        this.business.bankBranchHiraCode = value.hira ?? '';
      }
    },
    close_bank_search(){
      this.bank_search_open = false;
    },
    /* E : 은행검색 */
    /* 이름 특수문자 자동 삭제 */
    funcUserName(e) {
      var tmp         = e.target.value;
      var regExp      = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;

      if(regExp.test(tmp)){
        var t = tmp.replace(regExp, "");
        tmp   = t;
        this.business[e.target.id] = tmp;
      }
      // 会員名(カタカナ) 해당하는 이름은 추가 체크
      if(['lastHuriName', 'ownerLastHuriName'].indexOf(e.target.id) != -1) {
        this.onlyJapaneseName(e, 'askh');
        /* S : 예금주 입력란 채우기 */
        this.business.depositor = this.business.lastHuriName;
        /* E : 예금주 입력란 채우기 */
      }
    },
    onlyJapaneseName(e, option) { /* 전각/반각 처리 */
      var tmp         = e.target.value;
      axios.post(window.Laravel.back_url+'/api/convert_kana', {
        val    : tmp,
        option : option
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.business[e.target.id] = tmp;
      });
    },
    goLink(url) {
      window.open(url);
    },

  },

}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
.disabled { background-color: #e0e0de; }
</style>
