<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <section class="container mx-auto py-12">
        <div class="bg-white">
            <div class="max-w-8xl mx-auto py-8 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="w-full">
                    <div class="flex justify-start item-start space-y-2 flex-col">
                        <h1 class="text-2xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800  mb-2 lg:mb-6">注文が完了しました。</h1>
                        <p class="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">ご注文ありがとうございます。<br>
                            以下からご注文情報をご確認いただけます。</p>
                        <p v-show="pay_kind_cd == 'A'">
                        <div class="t03">
                            次の口座番号に指定日までに振込が必要となっています。<br>
                            その後、バーチャル口座(仮想口座)は削除されます。<br>
                             下記の情報を金融機関ATMにてご入力頂き、お振込み下さい。<br>
                            （こちらの内容をご入力頂いたメールアドレスに自動送信しております。）<br>
                            <br> <!-- 다음 계좌로 지정일까지 입금해주셔야 하며, 이후에는 가상계좌가 삭제됩니다. -->
                            お客様番号 : {{pay_data.account_name}}<br>
                            確認番号  :{{pay_data.account_no}}<br>
                            収納機関番号 : {{pay_data.account_owner}}<br><!-- 계좌정보 -->
                            決済金額 : {{ formatPrice(pay_data.pay_amount)}} 円<br> <!-- 입금금액   원 -->
                            入金満了日 : {{pay_data.deposit_deadline}} <!-- 입금만료일 -->
                            <div  style="color:red;margin-top:20px;">
                                ※Pay-easyでの決済後、キャンセル・ご返金は出来ませんのでご注意ください。<br>
                                ※金融機関ATMに限りご利用頂けます。(コンビニ設置の共有ATMは除く)<br>
                                ※Pay-easyの利用方法やご利用頂ける金融機関につきましては、下記のURLよりご確認下さい。<br>
                                <span style="cursor:pointer;color:cornflowerblue;text-decoration: underline;">
                            <a href="https://www.pay-easy.jp/where/list_atm.php#tab2">https://www.pay-easy.jp/where/list_atm.php#tab2</a>
                        </span>
                            </div>
                        </div>
                        </p>
                        <p v-show="pay_kind_cd == '7'">
                        <div class="t03">
                            下記のURLより支払いを完了させて下さい。<br>
                            コンビニ決済の利用方法やご案内等も下記URLよりご確認下さい。<br>
                            （こちらの内容をご入力頂いたメールアドレスに自動送信しております。）
                            <br> <!-- 편의점 결제에 관한 설명은 아래 URL을 확인해 주십시오. (이 내용을 입력하신 메일주소로 자동송신하고 있습니다.) -->
                            受付番号   :{{pay_data.account_owner}}<br>
                            払込票 URL : <a target="_blank" :href="pay_url">{{ pay_url }}</a><br>

                            決済金額 : {{formatPrice(pay_data.pay_amount)}} 円<br> <!-- 입금금액   원 -->
                            入金満了日 : {{pay_data.deposit_deadline}} <!-- 입금만료일 -->
                            <div style="color:red;margin-top:20px;">
                                ※コンビニ決済後は、キャンセル・ご返金は出来ません。<br>
                                内容をご確認の上、お支払い頂きますようお願い致します。
                                お支払いは、現金のみです。ご注意下さい。
                            </div>
                        </div>
                        </p>
                    </div>
                    <div class="w-full flex justify-start my-6 py-2 bg-gray-100">
                        <h2 class="md:text-xl dark:text-white lg:text-2xl w-80 font-semibold leading-7 lg:leading-9 text-gray-800">注文NO. {{ order_list.ord_no }}</h2>
                        <button v-on:click="popup_invoice(order_list.ord_no);" class="px-2 m-0 text-sm text-white bg-gray-500 rounded">購入契約書照会</button>
                    </div>
                    <div class="w-full px-4 lg:flex lg:justify-between">
                        <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
                            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">承認番号</p>
                            <div v-for="(pay, i) in order_pay_list">
                                <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">{{ pay.pg_cd }}<br>{{ pay.approval_no }}</p>
                            </div>

                        </div>
                        <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
                            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">配送情報</p>
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                                {{ order_info_list.d_firstname }} {{ order_info_list.d_lastname }}<br>{{ order_info_list.d_mobile }}<br>
                                {{ order_info_list.d_addr1 }} {{ order_info_list.d_addr2 }}</p>
                        </div>
                        <div class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8" v-show="false">
                            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">Billing Address</p>
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">
                                {{ order_info_list.o_firstname }} {{ order_info_list.o_lastname }}<br>{{ order_info_list.o_mobile }}<br>
                                {{ order_info_list.o_addr2 }} {{ order_info_list.o_addr1 }}</p>
                        </div>

                        <div  class="flex justify-start items-start flex-col space-y-4 mt-4 lg:mt-8">
                            <p class="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">お問合わせ</p>
                            <p class="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-left text-sm leading-5 text-gray-600">jp.info@gcoop.com</p>
                        </div>
                    </div>

                    <div class="w-full lg:flex lg:justify-start">
                        <div class="flex flex-col justify-start items-start w-full lg:w-2/3 space-y-4 md:space-y-6 xl:space-y-8 px-2">
                            <div class="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full mt-8">
                                <p class="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800 mb-6">注文内訳</p>
                                <div v-for="(product, i) in order_product_list" :key="product.seq_pd" class="flex w-full border-b border-gray-200 mb-2">
                                    <div class="relative w-1/2 py-2">
                                        <img v-if="product.images.length > 0" class="w-full hidden md:block" v-bind:src="'https://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename "  alt="{{ product.product.pdt_name }}" />
                                        <img v-if="product.images.length > 0" class="w-full md:hidden" v-bind:src="'https://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="{{ product.product.pdt_name }}" />
                                        <!--                    <div class="sold-out-box2" v-if="product.product.status == 2">-->
                                        <!--                      <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />-->
                                        <!--                    </div>-->
                                        <!--                    <div class="sold-out-box2" v-if="product.product.status == 3">-->
                                        <!--                      <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />-->
                                        <!--                    </div>-->
                                        <!--                    <div class="coming_box" v-if="product.product.status == 4">-->
                                        <!--                      <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />-->
                                        <!--                    </div>-->
                                        <!--                    <div class="badge_box" v-if="product.badges.image_link != ''">-->
                                        <!--                      <img :src="product.badges.image_link" alt="sold out" />-->
                                        <!--                    </div>-->

                                    </div>
                                    <div class="flex-col flex justify-between items-start w-full pt-2 pb-8 space-y-4 md:space-y-0 pl-4">
                                        <div class="w-full flex flex-col justify-start items-start space-y-8">
                                            <h3 class="dark:text-white xl:text-xl font-semibold leading-6 text-gray-800">{{ product.product.pdt_name }}</h3>
                                        </div>
                                        <div class="text-red-500 text-xs md:text-lg" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                        <div class=" w-full">
                                            <p class="text-sm dark:text-white xl:text-lg">{{ formatPrice(product.amt,2) }}円
                                              <div v-if="isDirect===false" style="display: inline">
                                              / <span class="text-red-500">PV {{ formatPrice(product.pv,2) }} </span>
                                              </div>
                                            </p>
                                            <p class="text-sm dark:text-white xl:text-lg text-gray-800">Qty : {{ product.qty }}</p>
                                            <p class="text-sm dark:text-white xl:text-lg font-semibold text-gray-800">{{ formatPrice(product.amt * product.qty,2) }}円
                                              <div v-if="isDirect===false" style="display: inline">
                                                / <span class="text-red-500">PV {{ formatPrice(product.pv * product.qty,2) }} </span>
                                              </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 lg:mt-8 px-2">
                            <div class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                                <div class="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                                    <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">決済情報</h3>
                                    <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                                        <div class="flex justify-between w-full">
                                            <p class="text-base dark:text-white leading-4 text-gray-800">決済金額</p>
                                            <p class="text-right text-base dark:text-gray-300 leading-4 text-gray-600">{{ formatPrice(sum_item.amt) }}円 <p v-if="isDirect===false" class="text-red-300 text-right mt-2">PV {{ formatPrice(sum_item.bv,2) }} </p></p>
                                        </div>
                                        <div class="flex justify-between w-full">
                                            <p class="text-base dark:text-white leading-4 text-gray-800">送料</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800 text-right">
                                                {{ formatPrice(parseFloat(shipping_selected)) }}円
                                            </p>
                                        </div>
                                        <div v-if="isDirect===false" class="flex justify-between w-full">
                                            <p class="text-base dark:text-white leading-4 text-gray-800">ポイント</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800 text-right">
                                                {{ formatPrice(parseFloat(use_point)) }}円
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center w-full">
                                        <p class="text-base dark:text-white font-semibold leading-4 text-gray-800">決済金額</p>
                                        <p class="text-right text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">{{  formatPrice(sum_item.amt) }}円 <p v-if="isDirect===false" class="text-red-300 text-right mt-2">PV {{ formatPrice(sum_item.bv,2) }} </p></p>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full" v-show="false">
                                <div class="flex justify-start w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                                    <div class="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                                        <h3 class="text-xl dark:text-white font-semibold leading-5 text-gray-800">Shipping Method</h3>
                                        <div class="flex justify-between items-start w-full">
                                            <div class="flex justify-center items-center space-x-4">
                                                <div v-if="order_info_list.deli_kind !='1'">
                                                    <div class="w-8 h-8">
                                                        <img class="w-full h-full" alt="logo" src="/images/jp_deli_logo_yamato02.png?ver=1.0" />
                                                    </div>
                                                    <div v-show="false" class="flex flex-col justify-start items-center">
                                                        <p class="text-lg leading-6 dark:text-white font-semibold text-gray-800">
                                                            UPS {{ this.order_info_list.deli_kind == "3" ? "Signature" : "Ground" }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div v-if="order_info_list.deli_kind =='1'">
                                                    <div class="flex flex-col justify-start items-center">
                                                        <p class="text-lg leading-6 dark:text-white font-semibold text-gray-800">Pickup</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../modalViewWechat";

export default {
    name: "OrderSummary",
    props: ['order'],
    components : {
        Loading,
    },
    data: () => ({
        isDirect : (window.isDirect === true),
        title: [],
        order_list: [],
        order_info_list: [],
        order_pay_list: [],
        order_product_list: [],
        sum_item: [],
        // fee_shipping: [],
        shipping_selected: 0,
        // fee_tax: [],
        // tax_selected: 0,
        total_cost: 0,
        insurance_amt : 0,
        gel_price : 0,
        pay_data : [],
        pay_kind_cd : "",
        pay_url : "",
        use_point : 0,
        // csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        //orderer: orderer
        onCancel:false,
    }),
    created() {
        this.loading = true;
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        this.shipping_selected = 0;
        this.getOrder(window.Laravel.bz_cd);
    },
    methods: {
        getOrder(bz_cd) {
            axios.post(process.env.MIX_BACK_URL + '/api/getOrder', {
                order: this.order,
                bz_cd: bz_cd,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    console.log(response.data);
                    this.order_list = response.data;
                    this.order_info_list= response.data.info;
                    this.order_info_list.d_addr2 = this.order_info_list.d_addr2.replaceAll("|@|", ", ");
                    this.order_info_list.d_addr1 = this.order_info_list.d_addr1.replaceAll("|@|", ", ");
                    this.order_info_list.o_addr1 = this.order_info_list.o_addr1.replaceAll("|@|", ", ");
                    this.order_info_list.o_addr2 = this.order_info_list.o_addr2.replaceAll("|@|", ", ");
                    this.order_info_list.deli_kind = this.order_info_list.deli_kind;
                    this.insurance_amt = response.data.insurance_amt ?? 0;
                    this.order_pay_list= response.data.pay;
                    this.pay_url = response.data.url;
                    response.data.pay.forEach((value, index) => {
                        if(value.kind_cd == "7"){
                            this.pay_kind_cd = "7";
                            this.pay_data = value;
                        }
                        if(value.kind_cd == "A"){
                            this.pay_kind_cd = "A";
                            this.pay_data = value;
                        }
                        if(value.kind_cd == "9"){
                            this.pay_kind_cd = "9";
                            this.pay_data = value;
                            this.use_point = value.pay_amount;
                        }

                    });

                    this.order_product_list= response.data.product;
                    this.sum_item.price = this.order_list.ord_price;
                    this.sum_item.vat = this.order_list.ord_vat;
                    this.sum_item.amt = this.order_list.ord_amt;
                    this.sum_item.bv = this.order_list.ord_pv;
                    this.shipping_selected = this.order_list.ord_deli_amt;
                    this.gel_price = response.data.gel_price;
                    this.totalCost();
                    // this.totalCost(this.order_product_list);  //  결제상품 총액 / 배송비 포함 실결제액
                    // this.title = response.data[0].cate_name;
                    this.loading = false;

                })
                .catch(e => {
                    // window.history.go(-1);
                });
        },
        // 합산 금액 합산
        totalCost(){  // 토탈 금액 합산 기능
            this.total_cost = this.sum_item.amt;
            if(this.shipping_selected) this.total_cost += parseFloat(this.shipping_selected);
            if(this.tax_selected) this.total_cost += parseFloat(this.tax_selected);
        },
        popup_invoice(ord_no) {
            window.open(window.Laravel.back_url+"/api/getInvoice?ordno="+ord_no);
        },
        // 숫자 포멧
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    }
}
</script>

<style>

</style>
