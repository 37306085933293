<template>
    <div class="modal" v-if="open_modal" >
        <div class="fixed w-full h-full bg-black/80 z-[10] top-0 left-0 " v-show="overay"></div>
        <div class="fixed top-2/4 left-2/4 z-[11] min-h-[500px] translate-x-[-50%] translate-y-[-50%] bg-white p-5 w-full max-w-xl h-2/5 rounded-md shadow-zinc-500/40 shadow-xl overflow-x-scroll sm:overflow-hidden">
          <div class="w-full p-1 justify-between ">
            <div class="text-right"><span @click="open_modal = false"  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
            <h2 class="w-full text-lg xl:text-xl lg:text-xl font-semibold text-gray-800 border-l-[10px] border-[#4493d5] pl-3">
              シリアルコード
            </h2>
          </div>
          <section class="container mx-auto lg:py-6 md:p-0">
              <div class="pt-5 pb-4 bg-[#f5f5f5] text-center">
                <div class="mb-6"><img src="/images/img_footer_logo.png" alt="Gcoop" class="block ml-auto mr-auto w-1/2"></div>
                <p class="desc"></p>
                <form name="uni_form" method="post" action="">
                  <div class="">
                      <div style="margin-bottom:10px;">シリアルコードを入力してください。</div>
                      <input type="text" id="input_serialCode1" v-model="input_serialCode1" style="width:100px;" @keyup="cardKeyup('1')" maxlength="4">&nbsp;-&nbsp;
                      <input type="text" id="input_serialCode2" v-model="input_serialCode2" style="width:100px;" @keyup="cardKeyup('2')" maxlength="4">&nbsp;-&nbsp;
                      <input type="text" id="input_serialCode3" v-model="input_serialCode3" style="width:100px;" maxlength="4">
                  </div>
                </form>
              </div>
              <div class="flex xl:flex-row lg:flex-row flex-col" v-show="submit_button">
                <button type="button" v-on:click="submitForm" class="mt-5 w-full h-10 p-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] rounded-md flex flex-col justify-center items-center">入力</button>
              </div>
          </section>
        </div>
    </div>
</template>

<script>
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "SerialCode",
    props : ['serial_overay'],
  components : {
    VueSimpleAlert,
  },
  data: () => ({
    input_serialCode1 : "",
    input_serialCode2 : "",
    input_serialCode3 : "",
    submit_button     : true,
    open_modal        : true,
    overay            : true, // 다른 팝업있으면 검은 배경 레이어 처리 안 함
  }),
  mounted() {
      if(this.serial_overay) {
        this.overay = false;
      }
  },
  methods: {
    cardKeyup : function (pos) {
      if (pos == '1') {
        if ($("#input_serialCode1").val().length == 4) {
          $("#input_serialCode2").focus();
        }
      } else if (pos == '2') {
        if ($("#input_serialCode2").val().length == 4) {
          $("#input_serialCode3").focus();
        }
      }
    },
    submitForm : function() {
        if(!this.input_serialCode1.trim() || !this.input_serialCode2.trim() || !this.input_serialCode3.trim()) {
          // 시리얼코드를 정확히 입력해주세요.
          VueSimpleAlert.alert("シリアルコードを正確に入力してください。", "", "", {allowOutsideClick: false}).then((result) => {});
          return;
        }
        this.submit_button = false;

        let formData = new FormData();
        formData.append('serialCode1', this.input_serialCode1.trim());
        formData.append('serialCode2', this.input_serialCode2.trim());
        formData.append('serialCode3', this.input_serialCode3.trim());
        formData.append('bz_cd'      , 'jp');
        formData.append('userid'     , window.Laravel.user);

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + window.Laravel.access_token
          }
        }
        axios.post(window.Laravel.back_url+'/api/insertSerialCode',
        formData
        , config).then(response => {
            if(response.data.ret == "-1") { // 등록 실패
              VueSimpleAlert.alert(response.data.message, "", "", {allowOutsideClick: false}).then((result) => {});
              this.submit_button = true;
              return;
            }
            VueSimpleAlert.alert(response.data.message, "", "", {allowOutsideClick: false}).then((result) => { // 등록 성공
              location.replace("/");
            });
        });
    }, // submit end
  }
}
</script>

<style scoped>
/* Modal */
.modal,
.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}
.modal{z-index: 998;}

.overlay {
  opacity: 0.8;
  background-color: black;
}
</style>
