<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>

  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 px-4 px-6 lg:max-w-7xl lg:px-8 bg-white">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <div class="w-full">
            <div class="max-w-8xl mx-auto sm:pl-6 lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">

                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">{{ i18n.menu.MyOfficeUserDel }}</h1>
                  </div>
                  <div id ="noti" style="display: none">
                    <div class="ml-2 mb-5">
                      <div class="text-red-500 mb-1 before:content-['*'] text-sm">
                        {{ i18n.shop.mypage.userdel_noti1}}
                      </div>
                      <p></p>
                      <div class="text-red-500 before:content-['*'] text-sm">
                        {{ i18n.shop.mypage.userdel_noti2}}
                      </div>
                    </div>
                    <div style="width:100%; height:2px;" class="mt-5 mb-5 bg-gray-200"></div>
                    <div class="flex space-x-2">
                      <button type="button" onclick="location.replace('/Mypage');" class="my-2 py-2 px-4 leading-6 text-white bg-gray-500 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.myoffice.Goback }}</button>
                      <button type="button" @click="apro_userDel" class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.myoffice.Withdraw2 }}</button>
                    </div>
                  </div>
                  <div id="del">
                    <div class="border-y-2 border-black py-2 lg:p-2 xl:p-2">
                      <div class="text-red-500 mb-2 before:content-['*'] text-sm">
                        {{ i18n.shop.mypage.memberidpwchk}}
                      </div>
                      <div class="lg:flex xl:flex mb-1 py-2">
                        <div class="w-full lg:w-1/6 text-black mb-1 font-semibold">{{ i18n.myoffice.MemberId }}</div>
                        <div class="text-gray-500"><input type='text' v-model="userid" class="h-10 w-full xl:w-80 lg:w-80" readonly>  </div>
                      </div>
                      <div class="lg:flex xl:flex mb-1 py-2">
                        <div class="w-full lg:w-1/6  text-black mb-1 font-semibold">{{ i18n.myoffice.Password }}</div>
                        <div class="text-gray-500"><input type='password' v-model="Password" autocomplete="off" class="h-10 w-full xl:w-80 lg:w-80"> </div>
                      </div>
                    </div>
                    <div class="flex space-x-2">
                      <button type="button" onclick="location.replace('/Mypage');" class="my-2 py-2 px-4 leading-6 text-white bg-gray-500 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">{{ i18n.myoffice.Goback }}</button>
                      <button type="button" v-on:click="idpwchk" class="my-2 py-2 px-4 leading-6 text-white bg-gray-900 w-24 text-center w-1/2 xl:1/5 lg:w-1/5 rounded">Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import numeral from 'numeral';
  import 'numeral/locales';
  import VueSimpleAlert from "vue3-simple-alert";
  import {  ShoppingCartIcon} from '@heroicons/vue/outline'
  import MypageTop from '../MypageTop.vue';
  import SideMenu from '../SideMenu.vue';
  import Loading from 'vue3-loading-overlay';



  export default {
    name: "userdel",
    components : {
      VueSimpleAlert,
      ShoppingCartIcon,
      MypageTop,
      SideMenu,
      Loading,
    },
    data: () => ({
      i18n: window.i18n,
      laravel: window.Laravel,
      no_search_messsage: '',
      isLoggedIn: false,

      loading : false,
      mem: "",
      userid:login_userid,
      Password:"",
      idpwchk_flag:"N",
    }),
    beforeCreate() {
      axios.post(window.Laravel.back_url + '/api/memberInfo', {}, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.mem = response.data;
        console.log(this.mem);
      });
    },
    created() {

    },
    mounted() {
      if (window.Laravel.isLoggedin) {
        this.isLoggedIn = true;
        this.user = window.Laravel.user;
      }
    },
    methods:{
      apro_userDel() {
        axios.post(window.Laravel.back_url + '/api/mypage_userDel',
          { "flag" : this.idpwchk_flag},
          {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {

          if(response.data.output=="1") {
            alert(i18n.resetpw.userDel.alert1);
            location.href = "/sso/logout";
            return;
          }
          if(response.data.output=="-5") {
            alert(i18n.myoffice.jsALert.CheckIDPW);
            location.href="/Mypage/userDel";
            return;
          }
        });
      },
      idpwchk() {
        axios.post(window.Laravel.back_url + '/api/mypage_idpwchk',
          { "userid" : this.userid, "Password" : this.Password},
          {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          if(response.data.output=="Y") {
            $("#noti").show();
            $("#del").hide();
            this.idpwchk_flag='Y';
            return;
          }
          if(response.data.output=="N") {
            alert(i18n.myoffice.jsALert.CheckIDPW);
            return;
          }
        });
      },
    },

  }
</script>

<style scoped>

</style>
