<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">【プライバシーポリシー】</h2>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        プライバシーポリシー<br><br>
        1.個人情報保護方針<br>
        株式会社 GCOOP JAPAN(以下、GCOOP)では、個人情報保護法およびその他の規範を遵守し、会員の個人情報を厳<br>
        重な管理体制のもとで正確に取り扱い、会員からの信頼に応えるため、以下の通り個人情報保護方針を定め、これを<br>
        実行致します。なお、この内容については、必要に応じて適宜見直しを行い、その改善と向上に努めます。<br><br>
        2.個人情報利用目的<br>
        GCOOP では、会員の個人情報は下記の限られた範囲で利用させていただいております。個人情報保護に関する法令<br>
        により認められる場合を除き、利用目的を超えて会員の個人情報を利用する場合はあらかじめ本人の同意を得るもの<br>
        とします。<br>
        ① 商品の販売、発送ならびに代金等の授受をするため<br>
        ② GCOOP からの文書等を送付するため<br>
        ③ GCOOP での会員管理およびお問い合わせに対する回答等の対応のため<br>
        ④ ビジネス活動をサポートするための登録・購買実績などの情報の提供<br>
        ⑤ ビジネス活動をサポートするために必要であると、GCOOP が判断する媒体への掲載や掲示<br>
        ⑥当該メンバーの紹介者や上位系列のメンバーに対して請求者以下の紹介者もしくはグループの登録状況を確認する<br>
        ため（第三者提供）<br><br>
        3.第三者への提供<br>
        GCOOP は、会員の個人データを当該メンバーの紹介者や上位系列のメンバーに対して請求者以下の紹介者もしくは<br>
        グループの登録状況を確認するため会員 ID・氏名・都道府県・購入履歴を組織系列図として発行を希望するメンバー<br>
        に Web にて提供します。これらは全て業務遂行上必須であり、ご理解頂けない場合、登録することができません。<br>
        また、次のいずれかに該当する場合は、事前に本人の同意を得ることなく利用いたします。<br>
        ①人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき。<br>
        ②国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必<br>
        要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。<br>
        ③法令に基づく場合。<br><br>
        4.安全管理及び委託先の監督<br>
        GCOOP は、会員の個人情報の漏洩、滅失または棄損の防止その他安全管理のため、個人情報の保護に関する社内規<br>
        程・社内体制を整備し、これらを適切に運用するなど、必要かつ適切な措置を講じます。GCOOP は、業務を委託す<br>
        るために会員の個人情報を委託先に預託しています。その場合は、GCOOP が定める基準に合致した委託先であるか<br>
        などを調査し、書面により契約を締結して法令上必要な措置を講じることとします。<br><br>
        5.個人情報の開示・訂正利用停止等<br>
        GCOOP は、会員ご本人から自己の情報について、開示、変更、追加または削除、利用停止または消去、第三者提供<br>
        の停止の求めがあった時は、ご本人確認ののち、遅滞なく対応します。ただし、会員管理に必要だと GCOOP が判断<br>
        する個人情報の削除はできません。 なお、個人情報を開示するにあたり、手数料をいただく場合があります。<br><br>
        6.変更について<br>
        この個人情報保護の内容は、必要に応じて見直し・改善することがあります。<br>
    </div>
</template>

<script>

export default {
    name: "privatePoricyText",
    components : {
        // address_book,
    },
    props:{
        openlayer: Boolean
    },
    data: () => ({
    }),
    methods:{
        close_address_search(){
            // this.openlayer = false;
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
