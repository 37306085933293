<template>
  <loading :active="loading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage">
  </loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" />
  </modalView>

  <div id="mem_info" class="myo-modi-mem">
<!--    <section class="accordion_notice_box">-->
<!--      <div class="accordion_notice_item">-->
<!--        <a class="accordion_notice_tit" href="#n">-->
<!--          <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>-->
<!--          {{ i18n.myoffice.myoSearchForm.notice }}-->
<!--          <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-up" aria-hidden="true"></i></span></div>-->
<!--        </a>-->
<!--        <div class="accordion_notice_cont">-->
<!--          <div class="myo_search_notice">-->
<!--            <p><span class="signiture remark1">&#42;</span>&nbsp;<span>{{ i18n.join.join.required }}</span></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <form name="uni_form" method="post" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
      <h2 class="myo_table_tit">{{ i18n.menu.MyAutoshipEdit }}</h2>
      <ul id="myo_form" class="mgb_30">
        <!-- 회원명-->
        <li class="item table_form top-li">
          <span class="item_title">{{ i18n.myoffice.Order.Reciever }}</span>
          <span class="item_content"><input type="text" class="" v-model="autoship.r_name"></span>
        </li>
        <!-- 휴대전화 -->
        <li class="item table_form top-li">
          <span class="item_title">{{ i18n.myoffice.handphone }}</span>
          <span class="item_content m_contents">
              <input type="text" v-model="autoship.r_mobile" class="" maxlength="11" oninput="this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
              <div id="mobileCheckTxt" class="mgt_8 mgb_8"></div>
          </span>
        </li>
        <!-- 주소-->
        <li class="item table_form relative z-1 border-b-0 bg-white">
          <span class="item_title title_5 !border-b-0">{{ i18n.myoffice.Form.address }}</span>
          <div class="w-full">
              <span class="item_content m_contents">
                <div class="flex xl:flex-row lg:flex-row flex-col">
                    <input name="post" id="post" class="relative z-1" @click="search_address" v-model="autoship.r_post" type="text" readonly>
                    <span class="bk_submit_btn">
                      <input type="button" @click="search_address"  class="btn smschBtn osMem_btn relative z-1 ml-2" value="郵便番号検索"/><br>
                    </span>
                </div>
              </span>
          </div>
          <div class="flex xl:flex-row lg:flex-row flex-col">
             <span class="item_content m_contents max-w-none w-full">
                 <div class="flex xl:flex-row lg:flex-row flex-col w-full">
                  <input name="addr1" id="addr1" class="!max-w-[350px] add-input1" @click="search_address" v-model="autoship.r_addr1" type="text" readonly>
                </div>
             </span>
          </div>
          <div class="flex xl:flex-row lg:flex-row flex-col ">
             <span class="item_content m_contents max-w-none w-full">
                 <div class="flex xl:flex-row lg:flex-row flex-col w-full">
                  <input name="addr2" id="addr2" class="!max-w-[350px] add-input1" v-model="autoship.r_addr2" type="text">
                </div>
             </span>
          </div>
        </li>
      </ul>
      <div class="myo_condition_search2">
        <button type="submit" class="search_btn">{{ i18n.myoffice.save }}</button>
      </div>
    </form>
  </div>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import modalView from "../../modalView";
import vSelect from "vue-select";

export default {
  name: "AutoShipModify",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    Loading,
    modalView,
    vSelect,
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    address_search_open:false,
    autoship : [],
  }),
  beforeCreate() {

  },
  created() {
    this.loading = true;
    axios.post(process.env.MIX_BACK_URL+'/api/getNormalAsMst',{
      bz_cd: window.Laravel.bz_cd,
    },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
      .then(response => {
        this.autoship = response.data;
        if(response.data == ""){
          VueSimpleAlert.alert("定期購入を登録してください。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {
            if(result){
              location.replace("/MyofficeMain");
            }
          });
        }
        this.loading = false;   // this.getCartList(response.data);
      })

  },
  mounted() {
  },
  methods:{
    submitForm: function (event) {
      let formData = new FormData();
      if(!this.autoship.r_addr1){
        alert.alert('配送先を入力してください。');
        $("#addr1").focus();
        return;
      }
      if(!this.autoship.r_addr2){
        alert.alert('配送先を入力してください。');
        $("#addr2").focus();
        return;
      }
      formData.append('r_name'  , this.autoship.r_name   ? this.autoship.r_name.trim() : '');
      formData.append('r_mobile', this.autoship.r_mobile ? this.autoship.r_mobile.trim() : '');
      formData.append('r_addr1' , this.autoship.r_addr1  ? this.autoship.r_addr1.trim() : '');
      formData.append('r_addr2' , this.autoship.r_addr2  ? this.autoship.r_addr2.trim() : '');
      formData.append('r_post'  , this.autoship.r_post   ? this.autoship.r_post.trim() : '');

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + window.Laravel.access_token
        }
      }

      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/autoShipAddressModify',
        formData
        , config).then(response => {
        if(response.data.result == "1"){
          VueSimpleAlert.alert(response.data.Message,"","",{allowOutsideClick : false}).then((result) => {
            if(result){
              location.replace("/MyAutoshipEdit");
            }
          });
        }
        this.loading = false;
      });

    },
    search_address(){
      this.address_search_open = true;
    },
    set_address(value){
      this.autoship.r_post     = value.postcode;
      this.autoship.r_addr1    = value.address + value.address2 + value.address3;
      this.address_search_open = false;
    },
    close_address_search(){
      this.address_search_open = false;
    },
  },

}

</script>

<style scoped>

</style>
