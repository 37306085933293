<template>
  <div v-if="this.isLoggedIn === true">
    <h2 class="mt-3 mb-3 font-medium text-lg">会員情報</h2>
    <div>
      <table>
        <colgroup>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <thead>
        </thead>
        <tbody>
        <tr>
          <td colspan="3">
            <p class="my-info-area">
              <span class="font-bold">{{ username }}</span> (<span>{{ userId }}</span>)
            </p>
            <p class="my-info-area">
              <span style="">マイポイント</span>
              <span class=" font-bold text-blue-500" style="">{{ heartPoint }}</span>
            </p>
            <p class="my-info-area">
              <span style="">私の現在PV</span>
              <span class="font-bold text-blue-500" style="">{{ sumPv }}</span>
            </p>
          </td>
        </tr>
        <tr class="text-center">
          <td class="padding-x" v-for="(data, i) in menu_icon.slice(0, 3)" :key="i">
            <div >
              <a :href="data.href">
                <i class="fal" :class="data.icon"></i>
                <span>{{  data.label }}</span>
              </a>
            </div>
          </td>
        </tr>
        <tr class="text-center">
          <td class="padding-x" v-for="(data, i) in menu_icon.slice(3, menu_icon.length)" :key="i">
            <div >
              <a :href="data.href">
                <i class="fal" :class="data.icon"></i>
                <span>{{  data.label }}</span>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";

export default {
  name: "LoginMenu",
  props: ['left_menu_icon'],
  setup() {
    return {
      // navigation,
    }
  },
  data: () => ({
    userId: window.login_userid,
    username: '',
    isLoggedIn: false,
    sumPv: 0,
    heartPoint: 0,
    user: {},
    menu_icon : [],
    // menu_icon : this.left_menu_icon,
    // menu: [
    //   {
    //     href: '/MyofficeMain',
    //     icon: 'fa-chart-line',
    //     label: 'マイオフィス'
    //   },
    //   {
    //     href: '/MyAutoshipCardChange',
    //     icon: 'fa-gifts',
    //     label: '定期購入'
    //   },
    //   {
    //     href: '/MyPersonInfo',
    //     icon: 'fa-user',
    //     label: '会員情報'
    //   },
    //   {
    //     href: '/MyOrderList',
    //     icon: 'fa-truck',
    //     label: '注文照会'
    //   },
    //   {
    //     href: '/Register',
    //     icon: 'fa-sign-in-alt',
    //     label: '会員入会'
    //   },
    //   {
    //     href: '/MyInQuriy',
    //     icon: 'fa-exclamation-square',
    //     label: '1:1 お問い合わせ'
    //   },
    // ]
  }),
  created() {

    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
      this.username = window.Laravel.username;
      this.menu_icon = this.left_menu_icon;
    }

    if (this.isLoggedIn === true) {
      this.getLoginInfo();
    }
  },
  mounted() {
  },
  methods: {
    getLoginInfo() {
      const apiPath = '/api/loginMenu';
      axios.get(process.env.MIX_BACK_URL + apiPath, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}, {})
        .then(response => {
          this.sumPv = numeral(response.data.sumPv).format('0,000');
          this.heartPoint = numeral(response.data.heart).format('0,000');
        });
    },
  }
}
</script>

<style lang="scss" scoped>
table {
  //border-collapse: collapse;
  //border-collapse : unset;
  table-layout: fixed;
  width: 100%;
  //background-color: #efefef;
  //background-color: rgb(249 249 249 / 1);
  background-color: rgb(249 249 249);
  //border-bottom: 5px solid rgb(0 0 0 / 0.2);
  //background-color: #000000;
  //border: 0;
  //border: 1px solid #E2E8F0;
  border-radius: 10px;

  padding: 10px;

  colgroup {
    col {
      width: 25%;
    }
  }
}

th,
td {
  //border: 1px solid #aaa;
  background-clip: padding-box;
  scroll-snap-align: start;
  padding: 0.8rem;
  text-align: center;
  margin: 0;
  font-size: 11px;

  &.padding-x {
    padding-left:0.3rem;
    padding-right:0.3rem;
    &:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  p.my-info-area {

    font-size: 1rem;
    line-height: 1.5rem;

    &:first-child {
      padding: 15px 0;
      border-bottom: 1px solid rgb(23 46 89);
    }
    &:nth-child(2){
      padding-bottom:15px;
      margin-top: 15px;

    }

    &:last-child{
      padding-bottom: 10px;
      border-bottom: 1px dotted black;
    }

    &:not(:first-child) {
      span {
        &:first-child {
          display: inline-block;
          width: 60%;
        }

        &:nth-child(2) {
          display: inline-block;
          text-align: right;
          width: 40%;
        }
      }
    }
  }

  div {
    //text-align: center;

    span {
      font-weight: 700;
    }

    i {
      font-size: 25px;
      color: #000;
      display: block;
      margin-bottom: 10px;

    }
  }
}

tbody tr {
  &:last-child {
    height: 95px;
    vertical-align: top;
  }

  &:first-child {
    td:first-child {
      text-align: left;
    }
  }
}

//tbody tr:last-child td {
tbody tr td {


}
</style>
