<template>
    <section class="container max-w-8xl lg:max-w-7xl mx-auto pt-6 pb-3 px-4">
        <div class="mt-10">
            <ul class="flex border border-gray-300 shadow-lg shadow-gray-300/40">
                <li class="w-1/2 w-full border-r border-gray-300  flex hover:duration-300 hover:bg-slate-400 " :class="{'bg-slate-400/20' : menucd == '007'}">
                    <a href="msTerms" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Terms &amp; Conditions</a>
                </li>
                <li class="w-1/2 border-r border-gray-300 flex hover:duration-300 hover:bg-slate-400 " :class="{'bg-slate-400/20' : menucd == '008'}">
                    <a href="msPrivacyPolicy" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Privacy Policy</a>
                </li>
<!--                <li class="w-1/4 border-r border-gray-300 flex hover:duration-300 hover:bg-slate-400" :class="{'bg-slate-400/20' : menucd == '014'}">
                    <a href="msShippingPolicy" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Shipping Policy</a>
                </li>
                <li class="w-1/4 hover:duration-300 flex hover:bg-slate-400" :class="{'bg-slate-400/20' : menucd == '018'}">
                    <a href="msReturnPolicy" class="flex items-center justify-center p-2.5 block text-center lg:text-base text-sm hover:text-white w-full">Return Policy</a>
                </li>-->
            </ul>
        </div>
        <!-- 신규 미국 약관 콘텐츠 -->
          <div>
            <h2 v-if="menucd == '007'" class="mt-8 text-xl lg:text-2xl">Terms &amp; Conditions</h2>
            <h2 v-if="menucd == '008'" class="mt-8 text-xl lg:text-2xl">Privacy Policy</h2>
            <div class="break-words text-sm lg:text-base" v-html="data">
          </div>
        </div>
    </section>
</template>

<script>
import numeral from "numeral";

export default {
    name: "Term",
    props : ["menucd"],
    data: () => ({
        data: ""
    }),
    created() {
        // 배너
        axios.post(window.Laravel.back_url+'/api/getTerm', {
            menucd: this.menucd,
            bz_cd: window.Laravel.bz_cd,
        }).then(response => {
            this.data = response.data;
        });
    },
}
</script>

<style scoped>

</style>
