<template>
  <div class="bg-[#f2f5fc] py-4 my-6">
    <section class="max-w-8xl mx-auto  lg:max-w-7xl xl:px-8 lg:px-8 px-5">
      <!-- 스와이프 배너 추가 반응형 : pc버전 3개씩 / 모바일 : 1개씩 노출 -->
      <div class="xl:flex lg:flex xl:flex-row lg:flex-row block xl:gap-6 lg:gap-6 gap-2 w-full xl:pt-6 lg:pt-6 pt-2 xl:pb-6 lg:pb-6 pb-3 relative">
        <carousel v-if="banners.length !== 0" :settings=" settings " ref="myCarousel" :wrap-around="true" class="flex flex-col-reverse" :breakpoints="breakpoints">

          <slide v-for=" (item) in banners " :key=" item.seq_si ">

            <div class="xl:pr-4 lg:pr-4 pr-0">
              <div class="carousel__item">
                <!-- 임시로 비주얼 배너 불러오도록 처리 - 신규 배너와 연동되는 기능 추가 필요 -->
                <div @click="goBannerLink(item.link_url)" :class="[item.link_url ? 'onCursor' : '']">
                  <div class="relative overflow-hidden bg-cover bg-no-repeat">
                    <img :src="item.href" v-bind:alt=" item.title ">
                    <div class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed transition duration-300 ease-in-out"></div>
                  </div>
                  <!-- 배너 설명 모바일 사이즈에서는 미노출 처리-->
                  <div class="">
                    <div class="xl:mt-6 lg:mt-6 mt-2 xl:text-xl lg:text-xl text-sm font-semibold ellipsis text-left">{{ item.banner_desc }}</div>
                    <div class="mt-2 xl:text-sm lg:text-sm text-xs text-gray-600 text-left">{{ (item.reg_date).substring(5, 7) }}.{{ (item.reg_date).substring(8, 10) }}.{{ (item.reg_date).substring(0, 4) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </slide >
          <template #addons>
            <navigation class="hi hover:bg-black/20">
              <template #prev>
                <span><svg xmlns="http://www.w3.org/2000/svg" class="fill-slate-700 w-2" viewBox="0 0 320 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/></svg></span>
              </template>
              <template #next>
                <span><svg xmlns="http://www.w3.org/2000/svg" class="fill-slate-700 w-2" viewBox="0 0 320 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg></span>
              </template>
            </navigation>
          </template>
        </carousel>

      </div>
    </section>
  </div>


</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

/* javascript numberFormat library */
let numeral = require("numeral");

export default{
  name: "MainMdBanners",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },setup() {
    // const { isMobile } = useMobileDetection();   // 모바일 여부 체크
    // return { isMobile };
  },
  data: () => ({
    // carousel settings
    settings: {
      wrapAround: true,
      snapAlign: 'start',
      //autoplay: '5000',
      mouseDrag: true,
      touchDrag: true
    },
    seen: false,
    banners: [],
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 2.5,
        snapAlign: 'start',
      },
    },
  }),
  //비주얼 배너 이미지 사용하기 위해 임시 선언 - 삭제 필요
  created() {
    // 배너
    axios.post(window.Laravel.back_url+'/api/banner', {
      bz_cd   : window.Laravel.bz_cd,
      kind_cd : 'U',
      limit   : 8
    }).then(response => {
      response.data.forEach((value, index) => {
        this.banners.push({ seq_si:value.seq_si, link_url: value.linkurl, href: 'https://cdnus.gcoop.me/shop' + value.path_name + value.file_name, current: false, title: value.title, banner_desc : value.banner_desc, reg_date : value.reg_date });
      });
    });
  },
  methods: {
    goBannerLink(link) {
      if(link) {
        location.href = link;
      }
    }
  }
}

</script>

<style>
.carousel__prev.hi{
  background-color: #fff;
  border-radius: 0;
  /*position: static !important;*/
  /*  top: -20px;
    left: 0;*/
  border:1px solid #adafb4;
  color:#000;
  width:55px;
  height:55px;
  /*transform: none;*/

  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.carousel__prev.hi:hover{
  background:rgba(0,0,0,0.2)
}
.carousel__next.hi{
  background-color: #fff;
  border-radius: 0;
  /*position: static !important;*/
  /*  top: -20px;
    right: 0 !important;*/
  border:1px solid #adafb4;
  color:#000;
  width:55px;
  height:55px;
  /*transform: none;*/
}
.carousel__next.hi:hover{
  background:rgba(0,0,0,0.2)
}
.text-6xl{font-size: 3.75rem; /* 60px */ line-height: 1;}
@media (max-width: 700px) {
  .carousel__prev.hi{width: 20px; height: 55px; border:1px solid #ddd; background:rgba(255,255,255,0.5)}
  .carousel__next.hi{width: 20px; height: 55px; border:1px solid #ddd; background:rgba(255,255,255,0.5)}
  .carousel__prev.hi:hover{
    background:rgba(0,0,0,0.2)
  }
  .carousel__next.hi:hover{
    background:rgba(0,0,0,0.2)
  }
}
.onCursor { cursor: pointer; }
</style>
