<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>

  <!-- 메인 페이지에서만 노출 -->
<!--  <div v-show="main == 1"  class="flex flex justify-start items-start flex-col space-y-4">임시</div>-->
  <!-- 마이페이지 메인 아닐 경우 -->
<!--  <div v-show="main != 1" class="hidden lg:flex lg:justify-start lg:items-start lg:flex-col lg:space-y-4 xl:flex xl:justify-start xl:items-start xl:flex-col xl:space-y-4">임시</div>-->

</template>

<script>
export default {
    name: "MypageTop",
    props:['laravel','main'],
    data: () => ({
      user : '',
      username : '',
      myorder_cnt : 0,
    }),
    mounted() {
      this.getOrderList();
      if (this.laravel.isLoggedin) {
        this.isLoggedIn = true;
        this.user = this.laravel.user;
        this.username = this.laravel.username;
      }
    },
  methods:{
    getOrderList(){
        this.loading = true;
        this.userid = window.Laravel.user;
        axios.post(window.Laravel.back_url+'/api/getMyOrderList',{
          s_date : this.s_date,
          e_date : this.e_date,
          page : this.page,
          sort: this.sort,
          ord_kind: this.ord_kind ?? '1',
          bz_cd: this.bz_cd,
          userkind_type: this.userkind_type,
          kind_cd: this.kind_cd,
          rcpt_yns: this.rcpt_yns,
          keyword1 : this.keyword1,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
          this.myorder_cnt = response.data.total_count;
        });

    },
  }
}
</script>

<style scoped>

</style>
