<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.Order.type }}</dt>
        <dd>
            <vSelect :options="order_cds" :reduce="value => value.id" v-model="kind_cd"   ></vSelect>
        </dd>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "ordeType",
    components : {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        kind_cd: "",
        order_cds : [
            { "id": "", "label": i18n.myoffice.all },
            { "id": 1, "label": i18n.myoffice.Order.orderNomal2 },
            // { "id": 2, "label": i18n.myoffice.Order.orderCustomer },
            // { "id": 3, "label": i18n.myoffice.Order.orderAutoship },
            // { "id": 4, "label": i18n.myoffice.Order.GcooperMallPurchase },
            // { "id": 5, "label": i18n.myoffice.Order.DirectPayment },
            // { "id": 6, "label": i18n.myoffice.Order.GcooperProgram },
            // { "id": 7, "label": i18n.myoffice.Order.GFesta },
            // { "id": 8, "label": i18n.myoffice.Order.jp_dir }
        ]

    }),
    props:{
        bz_cd :""
    },
    watch : {
        kind_cd(kind_cd){
            this.set_kind_cd(kind_cd);
        }
    },
    methods : {
        set_kind_cd(kind_cd){
            this.$emit("set_kind_cd",kind_cd);
        }
    }
}
</script>

<style scoped>

</style>