'use strict'
import {absoluteUrlPath, allowApiList} from '@/assets/api/index'

/**
 * get axios 호출
 * @param apiKey
 * @param accessToken
 * @param params
 * @param headers
 * @param options
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getApiCall = (apiKey, params = {}, headers = {}, options = {}) => {


  const accessToken = window.Laravel.access_token;

  let params_ = {
    ...params,
  }

  return axios.get(
    absoluteUrlPath() + allowApiList[apiKey].path,
    {
      params: params_,
      headers: {
        Authorization: 'Bearer ' + accessToken,
        ...headers,
        ...options
      }
    }).catch(function (thrown) {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled', thrown.message);
    } else {
      // 에러 핸들링
    }
  })
}

/**
 * post axios 호출
 * @param apiKey
 * @param accessToken
 * @param params
 * @param headers
 * @param options
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const postApiCall = (apiKey, params = {}, headers = {}, options = {}) => {

  // const params_ = {
  //     ...params,
  // }
  const accessToken = window.Laravel.access_token;

  return axios.post(
    absoluteUrlPath() + allowApiList[apiKey].path,
    params,
    {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        ...headers,
        ...options
      }
    }
  ).catch(function (thrown) {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled', thrown.message);
    } else {
      // 에러 핸들링
    }
  })
}
