<template>
  <div>
    <swiper
      :loop="true"
      :spaceBetween="20"
      :slidesPerView="4"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }"
      :pagination="{
        clickable: true,
      }"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :modules="modules"
      :autoHeight="true"
      :breakpoints="{
        // 화면 너비가 640px 이하일 때
        0: {
          slidesPerView: 2,
        },
        // 화면 너비가 768px 이하일 때
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }"
      class="my-swiper container mx-auto xl:py-8 lg:py-8 py-4"
    >

      <swiper-slide v-for="product in products" :key="product.id" class="my-swiper-wrapper" >

        <div class=" group relative bg-gray-100 border-black m-1.5 rounded-lg border-slate-900 xl:py-2 lg:py-2 py-2 xl:px-3 lg:px-3 px-2 transition duration-300 ease-in-out hover:bg-black/20">
          <div class="w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden aspect-square relative">
            <!-- 카테고리 뱃지 추가 - 품절일 경우에는 미노출 -->
            <!--<div class="absolute top-1 left-1">
              <span class="text-[#2196f3] border border-[#2196f3] block px-2 py-1 text-[11px] bg-white">Living</span>
            </div>-->
            <!---->
            <a :href="product.href" class="block w-full">
              <img :src="product.imageSrc" :alt="product.imageAlt" class="w-full object-center object-cover max-w-none"
                   onerror="this.src='/images/error/noImage.png'"/>
              <div class="sold-out-box2" v-if="product.status == 2">
                <img src="/images/badge/jp/pc_temp_sold_out.png" alt="sold out"/>
              </div>
              <div class="sold-out-box2" v-if="product.status == 3">
                <img src="/images/badge/jp/pc_sold_out.png" alt="sold out"/>
              </div>
              <div class="coming_box" v-if="product.status == 4">
                <img src="/images/badge/jp/pc_coming_soon.png" alt="sold out"/>
              </div>
              <div class="badge_box" v-if="product.badge != ''">
                <img :src="product.badge" alt="sold out"/>
              </div>
            </a>
          </div>

          <div class="xl:mt-8 lg:mt-8 mt-3 justify-between">
            <!-- 카테고리 뱃지 추가 - 품절일 경우에는 미노출 -->
            <div class="">
              <!--<span class="text-[#899cc1] block py-1 xl:text-[11px] lg:text-[11px] text-[10px]">Living > CareCellve</span>-->
              <span
                class="text-[#899cc1] block py-1 xl:text-[11px] lg:text-[11px] text-[10px]">{{ product.parent_cate_name }} > {{
                  product.cate_name
                }}</span>
            </div>
            <div class="h-10 lg:h-14 md:h-14 sm:h-12 mb-2.5">
              <h3
                class="text-[#0e293e] text-sm lg:text-base sm:text-sm text-ellipsis overflow-hidden line-clamp-2 font-semibold ellipsis">
                <a :href="product.href" class="block w-full leading-snug">
                  {{ product.name }}
                </a>
              </h3>
            </div>
            <div class="w-full xl:mb-5 lg:mb-5 mb-3 font-semibold" v-if="product.status != '4'">
                <ConsumerPrice :isLoggedIn="isLoggedIn" :price="product.set1_amt" :toBeSold="product.status == '4' ? true : false" type="list"></ConsumerPrice>
                <div v-if=" isLoggedIn ">
              <div v-if="isDirect===true">
                    <p class="mt-1 text-blue-500 xl:text-lg lg:text-lg text-sm">{{ i18n.shop.shopping.GPMemberPrice }} : {{ product.price }}</p>
              </div>
              <div v-else>
                    <p class="font-medium text-gray-900 xl:text-lg lg:text-lg text-sm">{{ i18n.shop.shopping.GPMemberPrice }} : {{ product.price }}</p>
                <p class="mt-1 text-blue-500 xl:text-lg lg:text-lg text-sm">{{ product.bv }}</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </swiper-slide>
      <!-- 커스텀 네비게이션 버튼 -->
      <div class="swiper-navigation">
        <button class="swiper-button-prev">
<!--          <svg class="carousel__icon" viewBox="0 0 24 24" role="img" ariaLabel="arrowLeft"><title>arrowLeft</title><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>-->
        </button>
        <button class="swiper-button-next">
<!--          <svg class="carousel__icon" viewBox="0 0 24 24" role="img" ariaLabel="arrowRight"><title>arrowRight</title><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>-->
        </button>
      </div>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import ConsumerPrice from '@/components/shop/ConsumerPrice';

export default {
  name: 'ListSwiper',
  props: ['products', 'isDirect', 'isLoggedIn'],

  components: {
    Swiper,
    SwiperSlide,
    ConsumerPrice
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    };
  },
  data : () =>({
    i18n : window.i18n
  })
};
</script>
<style lang="scss" scoped>

.my-swiper{
  padding-left:20px;
  padding-right:20px;
}

.my-swiper-wrapper{
  padding-bottom:50px;
  //border: 1px solid #ff0000;
}

.swiper-button-prev, .swiper-button-next {
  /* 버튼 스타일링 */
  transform: translateY(-50%);
  //border-radius:30px;
  //width:30px;
  //height:30px;
  //text-align: center;
  //font-size:20px;
  //padding:0;
  //color:#ffffff;
  //background-color: #9ca4ac;
  //display:flex;
  //align-items: center;
  //position: absolute;
  //cursor: pointer;
  //border: 3px solid #ffffff;
  //opacity: 0.6;
  //box-sizing: content-box;

  &:after{
    //font-size:25px;
    font-size:1.5em;
    font-weight: 700;
    opacity: 0.4;

  }
}
//.swiper-button-next{
//  /* 버튼 스타일링 */
//  transform: translateY(-50%);
//  &:after{
//    font-size:30px;
//  }
//}

.swiper-container {
  //--swiper-navigation-size: 20; /* 네비게이션 버튼의 크기를 30px로 조정 */
}

</style>
