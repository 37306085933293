<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>

    <modalView class="z-50" v-if="address_book_open" @close-modal="address_book_open = false" :harf="true"  >
        <address_book @get_info_address_book="get_info_address_book" @close_address_book="close_address_book" />
    </modalView>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <!--    <div>-->
    <!--      <address_book @close_address_book="close_address_book" @get_info_address_book="get_info_address_book" v-if="address_book_open" />-->
    <!--  </div>-->
    <section class="bg-[#f0f2f4]">
        <div class="container mx-auto py-6">
            <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="flex flex-wrap">
                    <div class="autoship_box mb-3 w-full lg:mr-4">
                        <div class="autoship_notice_box">
                            <dl class="autoship_notice_cont">
                                <dt>定期購入システム</dt>
                                <dd style="font-size: 16px; color:#fff;">次回の定期購入決済日は [&nbsp;<span style="color:#ffeb3b; font-size:20px;">{{ admits_day }}</span>&nbsp;] です。</dd>
                                <dd>1. 定期購入は定期購入専用の買い物かごに設定した製品を毎月、指定日に自動決済し、お届けするシステムです。</dd>
<!--                                <dd>2. 1日(2次決済：6日)、11日(2次決済：16日)、21日(2次決済：26日)から決済日を指定し、指定した決済日の午前11時に自動決済されます。 残高不足や限度額超過などの理由で決済が失敗した場合は、決済日から5日後の午前11時に、1回に限り追加の自動決済(2次決済)が行われます。</dd>-->

                                <dd>
                                  2. 1日(2次決済：6日)、11日(2次決済：16日)、21日(2次決済：26日)から決済日を指定し、指定した決済日の午前11時に自動決済されます。
                                  <br/>&nbsp; 残高不足や限度額超過などの理由で決済が失敗した場合は、決済日から5日後の午前11時に、1回に限り追加の自動決済(2次決済)が行われます。
                                </dd>

                                <dd>3. 定期購入は100PV以上で決済が可能です。(送料無料)</dd>
                                <dd>4. 定期購入の構成品は決済前に限り、ご自由に変更が可能です。</dd>
                                <dd>5. 定期購入専用の買い物かごに製品が追加されていない(100PV未満)場合、自動決済は失敗となります。</dd>
                                <dd>6. 定期購入を連続決済(月一回)した場合、特典が付きます。
                                    <br>&nbsp; 3か月連続決済時　CKミスト贈呈
                                    <br>&nbsp;&nbsp;6か月連続決済時　イントロダムGHゲル
                                    <br>&nbsp;&nbsp;9か月連続決済時　CKバランスパワー(5EA)贈呈
                                    <br>&nbsp;&nbsp;12か月連続決済時　イベント招待
<!--                                    <br>&nbsp;&nbsp;連続決済時の特典は在庫状況などにより、変更になる場合があります-->
                                    <br/>&nbsp;&nbsp;<span style="color:red;">※</span> 連続決済時の特典は在庫状況などにより、変更になる場合があります。
                                </dd>
                            </dl>
                            <div class="autoship_process_box">
                                <dl>
                                    <dt>
                                    <span style="position: relative; width: 40px; height: 40px; display: inline-block; border-radius: 50%;">
                                        <i class="fas fa-desktop" style="font-size: 0.8em; position: absolute; top: 5px; left: 0;"></i><i class="fas fa-mobile-alt" style="position: absolute; top: 15px; right: 3px; transform: rotate(35deg); font-size: 0.8em;"></i>
                                    </span>
                                    </dt>
                                    <dd>毎月、決済設定をせずに、定期的に自動決済が可能</dd>
                                </dl>
                                <dl>
                                    <dt><span style="width: 40px; height: 40px;"><i class="fas fa-shipping-fast"></i></span></dt>
                                    <dd><span style="font-size:16px; font-weight:500; color: #fff59b;">100PV</span> <br>以上購入時、送料無料</dd>
                                </dl>
                                <dl>
                                    <dt><span style="width: 40px; height: 40px;"><i class="fas fa-hand-holding-heart"></i></span></dt>
                                    <dd><span style="font-size:16px; font-weight:500; color: #fff59b;">{{ autoship_conitue }}</span> <br>連続購入回数</dd>
                                </dl>
                            </div>
                        </div>
                    </div><!-- // autoship_box -->
                    <div class="w-full sm:w-3/4">
                        <div class="flex justify-between pb-8 max-w-8xl lg:max-w-7xl mx-auto" v-show="true">
                            <button @click="link('/cart')" class="bg-white font-semibold border hover:bg-gray-300 py-3 text-sm text-black uppercase w-1/2 max-w-2xl"><a >買い物かご({{ other_products.length }})</a></button>
                            <button @click="link('/AsCart')" class="bg-[#2c80c4] font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-1/2 max-w-2xl"><a >定期購入({{ products.length }})</a></button>
                        </div>

                        <div class="flex justify-between mb-2 lg:mb-6">
                            <h1 class="font-semibold text-2xl">定期購入カート</h1>
                            <h2 class="font-semibold text-lg md:text-xl">{{ products.length }} 件</h2>
                        </div>
                        <div class=" pb-2 mb-1 ">
                            <button class="float-left mt-1 p-2 xl:text-sm lg:text-sm text-xs text-[#4f65ae] bg-[#d1d5de] hover:bg-[#cbcfdd] rounded" @click="allSelect">全選択</button>
                            <button class="float-right mt-1 p-2 xl:text-sm lg:text-sm text-xs text-[#4f65ae] bg-[#d1d5de] hover:bg-[#cbcfdd] rounded" @click="removeSelect">選択削除</button>
                        </div>

                        <section class="clear-both	mt-14 bg-white shadow-zinc-500/40 shadow-md rounded-md xl:p-6 lg:p-6 p-3">

                            <div class="hidden lg:flex w-full" >
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-2/12 w-full p-2 border">製品情報</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-5/12 w-full p-2 border">数量</h3>
                                <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-5/12 w-full p-2 border">製品金額</h3>
                            </div>
                            <!--// 장바구니 제품 목록-->
                            <div v-for="(product, i) in products" :key="product.seq_pd" v-if="isEnterProduct" class="sm:flex sm:items-center border-b border-slate-200 last:border-b-0 xl:py-5 lg:py-5 py-0 w-full">
                                <div class="xl:flex lg:flex w-full xl:w-7/12 lg:w-7/12 "> <!-- product -->
                                    <span class="p-2 flex items-center justify-between">
                                      <input type="checkbox" :name="product.pdt_cd" :value="product.pdt_cd" v-model="selectedItems" class="cursor-pointer text-[#4e64ad]">
                                      <a href="#" v-on:click.prevent="removeItem($event,i)" class="w-5 font-semibold hover:text-red-500 text-gray-500 text-xs xl:border lg:border border-slate-400 inline-block p-1 xl:hidden lg:hidden block">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                      </a>
                                    </span>
                                    <div class="flex w-full xl:px-0 lg:px-0 px-2">
                                        <a :href="'/product/item/'+product.seq_pd" class="inline-block">
                                            <div class="relative xl:w-24 lg:w-24 xl:h-24 lg:h-24 w-20 h-20 aspect-w-1 aspect-h-1 border rounded-md overflow-hidden">
                                                <img class="w-24 h-24 max-w-none	" v-bind:src="'http://cdn.gcoop.me/public' + product.product.images[0].path + '/' + product.product.images[0].filename" v-bind:alt=" product.product.images[0].filename " alt="" onerror="this.src='/images/error/noImage.png'">

                                                <div class="sold-out-box2" v-if="product.product.use_yn == 'N'">
                                                    <img src="/images/badge/jp/pc_temp_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="sold-out-box2" v-if="product.product.status == 2">
                                                    <img src="/images/badge/jp/pc_temp_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="sold-out-box2" v-if="product.product.status == 3">
                                                    <img src="/images/badge/jp/pc_temp_sold_out.png" alt="sold out" />
                                                </div>
                                                <div class="coming_box" v-if="product.product.status == 4">
                                                    <img src="/images/badge/jp/pc_coming_soon.png" alt="sold out" />
                                                </div>
                                                <div class="badge_box" v-if="product.product.badges.image_link != ''">
                                                    <img :src="product.product.badges.image_link" alt="sold out" />
                                                </div>
                                            </div>
                                        </a>
                                        <div class="flex flex-col justify-between flex-grow relative px-2">
                                            <a :href="'/product/item/'+product.seq_pd" class="block h-full">
                                                <div class=" xl:text-base lg:text-base text-sm font-semibold leading-6 text-gray-800 mb-2">{{ product.product.pdt_name }}</div>
                                                <div class="text-red-500 text-xs lg:text-sm" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                                <p class="text-xs ">
                                                    {{ formatPrice(product.product.prices.amt) }}円 /
                                                    <span class="text-blue-500">PV {{ formatPrice(product.product.prices.pv,2) }}</span>
                                                </p>
                                                <div class="text-right absolute bottom-0 right-0 z-[5]">
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="xl:flex lg:flex hidden justify-center xl:w-2/12 lg:w-2/12 p-2">
                                    <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                        <button @click="decrement($event,i)" class="py-2 sm:py-1">
                                            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                        </button>
                                        <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)" class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                        <button @click="increment($event,i)" class="py-2 sm:py-1">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-center xl:w-2/12 lg:w-2/12 font-semibold text-md p-2 hidden xl:block lg:block">
                                    <p class="text-sm xl:text-md lg:text-md ">{{ formatPrice(product.product.prices.amt * product.qty) }}円</p>
                                    <p class="text-sm xl:text-md lg:text-md text-blue-500">PV {{ formatPrice(product.product.prices.pv * product.qty,2) }}</p>
                                </div>

                                <div class="text-center xl:w-1/12 lg:w-1/12 p-2 flex justify-center">
                                    <a href="#" v-on:click.prevent="removeItem($event,i)" class="border border-gray-300 xl:flex lg:flex hidden w-8 h-8  items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="hover:text-red-500 text-gray-500 text-xs w-4 h-4"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                                    </a>
                                </div>

                                <!-- 모바일 카운트 토탈 -->
                                <div class="xl:hidden lg:hidden flex xl:justify-around lg:justify-around justify-between">
                                    <div class="flex justify-center xl:w-1/2 lg:w-1/2 w-full p-2 ">
                                        <div class="inline-flex items-center px-4 sm:px-3 font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                            <button @click="decrement($event,i)" class="py-2 sm:py-1">
                                                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                            </button>
                                            <input type="number" name="qty" v-model="product.qty" @input="changeQty($event,i)" class="w-12 m-0 xl:px-2 xl:py-2 lg:px-2 lg:py-2 p-1 text-center border-0 focus:ring-transparent focus:outline-none rounded-md" id="custom-input-number">
                                            <button @click="increment($event,i)" class="py-2 sm:py-1">
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-[#2c7fc3]"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="text-center xl:w-1/2 lg:w-1/2 w-full font-semibold text-md p-2 ">
                                        <p class="text-sm xl:text-md lg:text-md ">{{ formatPrice(product.product.prices.amt * product.qty) }}円</p>
                                        <p class="text-sm xl:text-md lg:text-md text-blue-500">{{ formatPrice(product.product.prices.pv * product.qty,2) }}PV</p>
                                    </div>
                                </div>

                            </div>


                            <div class="flex justify-center my-4">
                                <a href="/product/list/all" v-on:click.prevent="prevUrl" class="font-semibold text-blue-500 text-sm">
                                    追加注文
                                </a>
                            </div>
                        </section>


                        <div class="w-full text-center mb-12 pt-3 " v-if="isEnterProduct==false">
                            <button @click="isEnterProduct = !isEnterProduct" class="bg-[#2c80c4] text-white px-10 py-2 rounded-xl">View Product Details</button>
                        </div>


                        <div class="w-full text-center mb-12 mt-3">
                            <button v-if="false && !isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-[#2c80c4] text-white px-10 py-2 rounded-xl">Enter shipping information</button>
                            <button v-if="false && isEnterShipping" @click="isEnterShipping = !isEnterShipping" class="bg-gray-200 text-white px-10 py-2 rounded-xl">Close shipping information</button>
                        </div>
                        <div class="mb-12 bg-white shadow-zinc-500/40 shadow-md rounded-md p-6" v-if="isEnterShipping">
                            <div class="flex justify-between border-b pb-8">
                                <h1 class="text-lg xl:text-xl lg:text-xl font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">配送地情報</h1>
                            </div>
                            <form name="shipping_info" class="justify-center w-full mx-auto" method="post">
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="order_seq">
                                <input type="hidden" name="shipping_fee">
                                <input type="hidden" name="tax_fee">
                                <input type="hidden" name="pay_total_amt">
                                <input type="hidden" name="postcode" :value='this.shipping_info["d_post"]'>
                                <input type="hidden" name="city" :value='this.shipping_info["d_city"]'>
                                <input type="hidden" name="state" :value='this.shipping_info["d_state"]'>
                                <input type="hidden" name="address2_1" :value='this.shipping_info["d_address"]'>
                                <input type="hidden" name="address2_2" :value='this.shipping_info["d_address2"]'>
                                <div class="mt-10">

                                    <div class="space-x-0 sm:flex lg:space-x-4">
                                        <div class="w-full flex sm:w-1/4" >
                                            <input name="order-info" id="order-info1" ref="order-info1" type="radio" class="mt-0.5" value="1" @click="change_shipping('Same')" checked>
                                            <label for="order-info1" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                注文者情報と同じ </label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4">
                                            <input name="order-info" id="order-info2" type="radio" class="mt-0.5" value="2" @click="change_shipping('Enroll')" >
                                            <label for="order-info2" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                新しい配送地</label>
                                        </div>
                                        <div class="w-full flex sm:w-1/4" >
                                            <input name="order-info" id="order-info3" type="radio" class="mt-0.5" value="3" @click="change_shipping('Manage')">
                                            <label for="order-info3" class="block ml-3 mb-3 text-sm font-semibold text-gray-500">
                                                配送地管理</label>
                                        </div>
                                    </div>

                                    <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box w-full lg:w-1/2 mt-6">
                                            <input name="d_firstName" id="d_firstName" ref="d_firstname" type="text" placeholder=" " v-model='this.shipping_info["d_name"]'
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <label for="d_firstName" class="md-label block mb-6 text-sm font-semibold text-gray-500">名前</label>
                                        </div>
                                        <div class="md-input-box w-full lg:w-1/2 mt-6 ">
                                            <input name="mobile" id="mobile" ref="d_mobile" type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder=" " v-model='this.shipping_info["d_mobile"]'
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                                            <label for="mobile" class="md-label block mb-3 text-sm font-semibold text-gray-500">携帯電話</label>
                                        </div>
                                    </div>

                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box relative z-[11] mt-6 w-full lg:w-1/2">
                                            <input name="postcode" id="postcode" ref="postcode" type="text" placeholder=" " v-model='this.shipping_info["d_post"]' readonly @click="search_address('M')"
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="40">
                                            <label for="postcode" class="md-label block mb-6 text-sm font-semibold text-gray-500">郵便番号</label>
                                        </div>
                                        <div class="md-input-box relative z-[11] mt-6 w-full lg:w-1/2" v-show="deli_kind !='1'">
                                            <input type="button" @click="search_address('M')" value="住所検索" class="bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full cursor-pointer">
                                        </div>
                                    </div>

                                    <div v-show="deli_kind !='1'" class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                        <div class="md-input-box relative z-[13] mt-6 w-full lg:w-1/2 ">
                                            <input name="city" id="city" ref="d_city" type="text" placeholder=" " v-model='this.shipping_info["d_addr1"]' readonly @click="search_address('M')"
                                                   class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="40">
                                            <label for="city" class="md-label block mb-6 text-sm font-semibold text-gray-500">住所</label>
                                        </div>

                                        <div class="md-input-box w-full lg:w-1/2 mt-6">
                                            <input name="address" id="address" ref="d_address" type="text" placeholder=" " :readonly="deli_kind =='1'" v-model='this.shipping_info["d_addr2"]'
                                                   @keyup="regText('d_address')"  class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="40">
                                            <label for="address" class="md-label block mb-6 text-sm font-semibold text-gray-500">番地(建物名・部屋番号まで入力してください)</label>
                                            <div class="text-xs text-red-400 pl-1">例: 1-28-12 VORT麻布 maxim 4F</div>
                                        </div>
                                    </div>

                                    <!-- S : 비고란 -->
                                    <div class="mt-6 space-x-0 lg:flex lg:space-x-4">
                                      <div class="md-input-box relative z-[13] mt-6 w-full ">
                                        <input name="r_memo" id="r_memo" type="text" placeholder=" " v-model='this.shipping_info["r_memo"]'
                                               class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="100">
                                        <label for="r_memo" class="md-label block mb-6 text-sm font-semibold text-gray-500">{{ i18n.shop.shopping.ShippingInstructions }}</label>
                                        <div class="text-xs text-red-400 pl-1">
                                          例 : 14-16時指定、納品書不要等 <br/>
                                          ※置き配、配達日指定はできません。
                                        </div>
                                      </div>
                                    </div>
                                    <!-- S : 비고란 -->
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="summary" class="w-full sm:w-1/4 xl:px-4 lg:px-4 px-0">
                        <div class="px-6 py-6 bg-white shadow-zinc-500/40 shadow-md rounded-md">
                            <h1 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">決済金額</h1>
                            <div class="flex justify-between mt-10 mb-5">
                                <label class="font-semibold uppercase py-1 w-1/3">注文製品 ({{ products.length }})</label>
                                <div class="font-semibold w-2/3">
                                    <p class="hidden text-base text-right">{{ formatPrice(sum_item.amt) }}円</p>
                                    <p class="hidden text-blue-500 text-base text-right">{{ formatPrice(sum_item.vat,1) }}円</p>
                                    <p class="text-base text-right">{{ formatPrice(sum_item.amt) }}円</p>
                                    <p v-show="false" class="text-gray-700 text-sm text-right">{{ formatPrice(total_weight,3) }} lbs</p>
                                </div>
                            </div>
                            <div class="flex justify-between mt-10 mb-5">
                                <label class="font-semibold uppercase py-1 w-1/3">PV</label>
                                <div class="font-semibold w-2/3">
                                    <p class="text-blue-500 text-base text-right">{{ formatPrice(sum_item.bv,2) }}</p>
                                </div>
                            </div>
                            <div class="flex justify-between mt-10 mb-5">
                                <label class="font-semibold uppercase py-1 w-1/3">送料</label>
                                <div class="font-semibold w-2/3">
                                    <p class="text-lg text-right">{{ formatPrice(shipping_selected) }}円</p>
                                </div>
                            </div>
                            <div v-if="false" class="border-t mt-8" >
                                <div class="flex justify-between py-6">
                                    <label class="font-semibold uppercase py-1 w-1/3">積立ポイント</label>
                                    <div class="font-semibold w-2/3">
                                        <p class="text-lg text-right">{{ formatPrice(point,2) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button @click="order" v-show="is_payment" class="bg-[#2c80c4] hover:bg-[#2c80c4] font-semibold py-3 text-sm text-white uppercase w-full mt-4">決済</button>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "../modalView";
import VueSimpleAlert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Swal from 'sweetalert2'


export default {
    name: "AsCart",
    components : {
        // address_book,
        vSelect,
        alert,
        modalView,
        Loading,
        Swal
    },
    props: ['orderer','fail_message','mem_center'],
    setup() {
        return {
            // products,
        }
    },
    watch: {
        shipping_info : function(){

        }
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        max_qty: 99,
        sum_item: [],
        total_cost: 0,
        total_weight: 0,
        fee_shipping: [],
        shipping_selected: 0,
        fee_tax: [],
        tax_selected: 0,
        isEnterShipping: true,
        isEnterProduct: true,
        shipping_info: [],
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        selectedItems: [],
        state_list: [],
        city_list: [],
        street_list: [],
        post_list: [],
        center_list: [],
        cnt_cd : "",
        msg_post:'',
        is_academi_order : false,
        address_book_open: Boolean,  // 주소록 레이어 오픈
        loading:false,
        address_search_open:false,
        isMatchStateCity:false,
        is_order_possible : window.Laravel.is_order_possible,
        is_payment : false,
        is_first : false,
        is_manual : false,
        other_products : [],
        point : 0,
        admits_day : "",
        autoship_conitue : 0
    }),
    created() {
        this.cnt_cd = this.mem_center != "0600" ? this.mem_center : "Select Center" ;
        this.address_book_open = false;
        this.change_shipping('');
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        // 장바구니 가져오기
        this.getCart();
        this.getCheck4WeekOrder();
        if(this.fail_message != ""){
            alert.alert(this.fail_message,"","",{returnFocus : false,  didClose: () => {
                    this.$refs.d_name.focus();
                }, didDestroy: ()=>{
                    this.$refs.d_name.focus();
                }
            }).then((result) => {
                if(result){
                    this.$refs.d_name.focus();
                }
            });
        }

    },
    methods:{
        regText(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace( reg, "");
            this.shipping_info[colum] = text;
        },
        regNumber(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace(/[^0-9]/g,'');
            this.shipping_info[colum] = text;
        },
        // 장바구니 가져오기
        getCart(){
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/getAsCartList',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.products = response.data;
                    if(this.products != []) { // 상품이 있으면 배송비, 택스 처리
                        this.totalCost(this.products);  //  결제상품 총액 / 배송비 포함 실결제액
                        //this.getWeight(window.Laravel.bz_cd);
                    }
                    this.sum_item.price = this.sumPrice(this.products);
                    this.sum_item.vat = this.sumVat(this.products);
                    // this.point = this.sum_item.price * 0.05;
                    this.sum_item.amt = this.sumAmt(this.products);
                    this.sum_item.bv = this.sumBV(this.products);
                    this.point = Math.floor(this.sum_item.bv * 0.05);
                    this.getShippingFee();

                    this.loading = false;   // this.getCartList(response.data);
                })
                .catch(e => {
                });
            axios.post(process.env.MIX_BACK_URL+'/api/cart',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.other_products = response.data.cart_info;
                    this.loading = false;   // this.getCartList(response.data);
                })
                .catch(e => {
                });
            axios.post(process.env.MIX_BACK_URL+'/api/getNormalAsMst',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {

                    // this.admits_day = response.data.admits_day;
                    this.autoship_conitue = response.data.start_week;

                    if ( response.data.admits_day !== undefined){
                        this.admits_day = response.data.admits_day.toString().replace(/(\d{4})(\d{2})(\d{2})/, '$1年$2月$3日');
                    }
                })
                .catch(e => {
                });


        },
        getCenterList(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCenter',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.center_list = response.data;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });

        },
        getCheck4WeekOrder(){
            axios.post(process.env.MIX_BACK_URL+'/api/getCheck4WeekOrder',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // this.shipping_info["d_state"] = value;
                    this.is_payment = response.data.is_payment;
                    this.is_first = response.data.is_first;
                    this.is_manual = response.data.is_manual;

                    // console.log(this.state_list);
                })
                .catch(e => {
                });

        },
        get_info_address_book(id)
        {
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
                seq:id
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(this.address_kind == "B"){
                        this.o_firstname = response.data.r_firstname;
                        this.o_lastname = response.data.r_lastname;
                        // this.o_state = (response.data.r_addr1).split("|@|")[1];
                        this.o_city = (response.data.r_addr1).split("|@|")[0];
                        this.o_post = response.data.r_post;
                        this.o_mobile = response.data.r_mobile;
                        this.o_addr2_1 = (response.data.r_addr1).split("|@|")[0];
                        this.o_addr2_2 = (response.data.r_addr2).split("|@|")[0];
                        // this.o_country = response.data.r_country;
                        this.address_book_open = false;
                        this.loading = false;
                    }else{
                        this.shipping_info["d_name"] = response.data.r_firstname + ' ' + response.data.r_lastname;
                        // this.shipping_info["d_state"] = (response.data.r_addr1).split("|@|")[1];
                        this.shipping_info["d_city"] = (response.data.r_addr1).split("|@|")[0];
                        this.shipping_info["d_post"] = response.data.r_post;
                        this.shipping_info["d_telephone"] = response.data.r_tel;
                        this.shipping_info["d_mobile"] = response.data.r_mobile;
                        this.shipping_info["d_addr1"] = (response.data.r_addr1).split("|@|")[0];
                        this.shipping_info["d_addr2"] = (response.data.r_addr2).split("|@|")[0];
                        // this.shipping_info["country"] = response.data.r_country;

                        this.address_book_open = false;
                        this.loading = false;

                        //this.getShippingFee();
                    }
                })
        },
        // 수량 변경
        decrement(e,i){
            if(this.products[i].qty > 1) {
                this.products[i].qty--;
                this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty);
                this.totalCost(this.products);
                this.getWeight(window.Laravel.bz_cd);
            }
        },
        increment(e,i){
            if(this.products[i].qty < this.max_qty) {
                this.products[i].qty++;
                this.totalCost(this.products);
                this.getWeight(window.Laravel.bz_cd);
                if(this.sum_item.bv > 500){
                    this.products[i].qty--;
                    this.totalCost(this.products);
                    this.getWeight(window.Laravel.bz_cd);
                    alert.alert("Autoship allows to purchase by only 500BV");
                }else{
                    this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty);
                }
            }
        },
        changeQty(e,i){
            if(this.products[i].qty < 1) {
                this.products[i].qty = 1;
                this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty);
                this.totalCost(this.products);
                this.getWeight(window.Laravel.bz_cd);
            }
            else if(this.products[i].qty > this.max_qty) {
                this.products[i].qty = this.max_qty;
                this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty);
                this.totalCost(this.products);
                this.getWeight(window.Laravel.bz_cd);
            }{
                this.setChangeQty(this.products[i].pdt_cd,this.products[i].qty);
                this.totalCost(this.products);
                this.getWeight(window.Laravel.bz_cd);

            }
        },
        setChangeQty(pdt_cd, qty){
            axios.post(process.env.MIX_BACK_URL+'/api/insertAsCartPdt',{
                pdt_cd: pdt_cd,
                qty: qty,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.emitter.emit('getCountAutoShipItems', '')
                });
        },
        // 장바구니 아이템 삭제
        removeItem(e,i){

            this.loading = true;
            axios.post(process.env.MIX_BACK_URL+'/api/removeProduct',{
                item: this.products[i].pdt_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.getCart(window.Laravel.bz_cd);
                });
        },
        // 선택 아이템 삭제
        removeSelect(){
            if(this.selectedItems.length === 0){
                alert.alert("製品を選択してください");
                return false;
            }
            this.loading = true;
            // axios.post(process.env.MIX_BACK_URL+'/api/remove_cart',{
            axios.post(process.env.MIX_BACK_URL+'/api/removeProduct',{
                item: this.selectedItems,
                type : 'select'
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.selectedItems = [];
                    this.getCart(window.Laravel.bz_cd);
                });
        },
        // 전체선택
        allSelect() {
          if(this.selectedItems.length < 1) {
            this.products.forEach((value, index) => {
              this.selectedItems.push(value.pdt_cd);
            });
          } else {
            this.selectedItems = [];
          }
        },
        // 쇼핑 계속하기
        prevUrl(){
            // console.log(window.history);
            window.location.href = '/';
            // this.$router.go（-1）;
        },
        // 주문하기
        async order() {

            if (!this.isEnterShipping) {
                this.isEnterShipping = true;
                this.isEnterProduct = false;

                alert.alert('Please enter your shipping information', "", "", {
                    returnFocus: false, focusConfirm: false,
                    didOpen: () => {
                    },
                    didDestroy: (toast) => {
                        this.$refs.d_firstname.focus();
                    }
                }).then((result) => {
                });
                return true;
            }

            let orderValidation = '';

            for (const product of this.products) {
                if ( product.product.use_yn === 'N' || product.product.status === '2' || product.product.status === '3') {
                    orderValidation = 'soldout';
                    break;
                } else if (product.product.status == "4") {
                    orderValidation = 'comingsoon';
                    break;
                }
            }

            if ( orderValidation === 'soldout'){
                alert.alert(this.i18n.shop.autoshipse2.soldoutAlert, "", "", {
                    returnFocus: false, focusConfirm: false,
                    didOpen: () => {
                    },
                    didDestroy: (toast) => {
                    }
                }).then((result) => {
                });
                return
            }else if ( orderValidation === 'comingsoon'){
                return ;
            }


            if (this.sum_item.amt == 0) {
                alert.alert('買い物かごに製品がありません。');
                return false;
            }
            if (this.sum_item.bv < 100) {
                alert.alert('定期購入のカートの構成が100PV未満です。決済するには100PV以上の構成が必要です。');
                return false;
            }

            if (!this.shipping_info["d_name"]) {
                alert.alert('受取人の名前を記入してください。');
                return false;
            }
            if (!this.shipping_info["d_mobile"]) {
                alert.alert('受取人の連絡先(電話または携帯電話)を正確に記入してください。');
                return false;
            }
            if (!this.shipping_info["d_addr1"]) {
                alert.alert('郵便番号を利用して配送先の住所を正確に記入してください。');
                return false;
            }
            if (!this.shipping_info["d_addr2"]) {
                alert.alert('郵便番号を利用して配送先の住所を正確に記入してください。');
                return false;
            }


            let formdata = new FormData();
            formdata.append("_token", this.csrf);
            formdata.append("bz_cd", window.Laravel.bz_cd);
            formdata.append("client_ip", window.Laravel.clientIp);
            formdata.append("orderHost", location.host);
            formdata.append("orderNation", window.Laravel.bz_cd);
            formdata.append("orderPath", '일반주문');
            formdata.append("sumPrice", this.sum_item.price);
            formdata.append("sumVat", this.sum_item.vat);
            formdata.append("sumAmt", this.sum_item.amt);
            formdata.append("sumBv", this.sum_item.bv);
            formdata.append("shippingFee", this.shipping_selected);
            formdata.append("taxFee", this.tax_selected);
            formdata.append("name", this.shipping_info.d_name);
            formdata.append("mobile", this.shipping_info.d_mobile);
            formdata.append("addr1", this.shipping_info.d_addr1);
            formdata.append("addr2", this.shipping_info.d_addr2);
            formdata.append("post", this.shipping_info.d_post);
            formdata.append("note", "");
            formdata.append("deli_kind", this.deli_kind);
            formdata.append("note", this.shipping_info.r_memo ?? ''); // 비고란

            axios.post(process.env.MIX_BACK_URL + '/api/asorder', formdata,
                {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.loading = false;

                    // console.log(response.data);
                    if (response.data.msg) {
                        alert.alert(response.data.msg);
                    }
                    if (response.data.result == 'Success') {
                        let form = document.shipping_info;
                        form.action = '/asorder';
                        form.order_seq.value = response.data.order_seq;
                        form.shipping_fee.value = this.shipping_selected;
                        form.tax_fee.value = this.tax_selected;
                        form.pay_total_amt.value = this.total_cost;
                        form.address2_1.value = this.shipping_info["d_addr1"];
                        form.address2_2.value = this.shipping_info["d_addr2"];

                        form.submit();
                    }
                    // this.getCart(window.Laravel.bz_cd);
                });

            // this.$router.go（-1）;
        },
        getStateCity(itme) {
            axios.post(process.env.MIX_BACK_URL + '/api/getStateCity', {
                bz_cd: window.Laravel.bz_cd,
                postcode: itme,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    if (response.data) {
                        let list = response.data;
                        list.forEach(this.matchStateCity);
                    }
                });
        },
        matchStateCity(item) {
            let state = item.state;
            let city = item.district;
            if ((this.shipping_info["d_address"].toUpperCase().match(state.toUpperCase())) && (this.shipping_info["d_address"].toUpperCase().match(city.toUpperCase()))) {
                alert.alert('Please enter State/City/Postcode only in Search Address.');
                this.isMatchStateCity = true;
                return false;
            }
        }, sleep(ms) {
            return new Promise((r) => setTimeout(r, ms));
        },
        getWeight(bz_cd) {

            // 사용하지 않는 api 로 확인됨 ( 호출 시 에러 발생 )
            return ;

            // axios.post(process.env.MIX_BACK_URL+'/api/getWeight',{
            //     bz_cd: bz_cd,
            // },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            //     .then(response => {
            //         this.total_weight = response.data.result;
            //         this.getShippingFee();
            //     });
        },
        getShippingFee(){
            var totalAmt = this.sum_item.amt;
            var post = this.shipping_info.d_post;
            var is_jp_gfeata = 0;
            this.shipping_selected = 0;
            // if(parseInt(post) >= 9000000 && parseInt(post) < "9099999"){
            //     if(parseInt(totalAmt) < 10000){
            //         this.shipping_selected = "1400";
            //     }else{
            //         this.shipping_selected = "2000";
            //     }
            // }else{
            //     if(parseInt(totalAmt) < 10000){
            //         this.shipping_selected = "600";
            //
            //     }else{
            //         this.shipping_selected = "0";
            //     }
            // }
        },

        // 장바구니 금액 합산
        totalCost(data){  // 토탈 금액 합산 기능

            this.sum_item.price = this.sumPrice(data);
            this.sum_item.vat = this.sumVat(data);
            this.sum_item.amt = this.sumAmt(data);
            this.sum_item.bv = this.sumBV(data);
            this.total_cost = this.sum_item.amt;
            if (this.tax_selected) this.total_cost += parseFloat(this.tax_selected);

        },
        sumPrice(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.price*el.qty), 0);
        },
        sumVat(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.vat*el.qty), 0);
        },
        sumAmt(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.amt*el.qty), 0);
        },
        sumBV(data) { // 상품 금액 * 수량 합산
            return [].concat.apply([],
                data.map(el => el))
                .reduce((acc, el) => acc + +(el.product.prices.pv*el.qty), 0);
        },
        change_shipping_fee(){  // 배송비 합산
            this.totalCost(this.products);
            // this.total_cost = this.add_shipping_fee();
        },
        change_tax_fee(){
            this.totalCost(this.products);
        },
        change_shipping(data){  // 배송지 변경
            this.msg_post = "";
            if(data=='Same' || data == '') {
                this.shipping_info["d_name"] = this.orderer["o_name"];
                this.shipping_info["d_addr2"] = this.orderer["o_addr2"];
                this.shipping_info["d_addr1"] = this.orderer["o_addr1"];
                this.shipping_info["d_post"] = this.orderer["o_post"];
                this.shipping_info["d_telephone"] = this.orderer["o_telephone"];
                this.shipping_info["d_mobile"] = this.orderer["o_mobile"];
                // this.shipping_info["country"] = this.orderer["o_country"] ?? "USA";
                this.deli_kind = "2";
                if(data == 'Same'){
                    //this.getShippingFee()
                }
            }else{
                this.shipping_info["d_name"] = '';
                this.shipping_info["d_post"] = '';
                this.shipping_info["d_addr2"] = '';
                this.shipping_info["d_addr1"] = '';
                this.shipping_info["d_telephone"] = '';
                this.shipping_info["d_mobile"] = '';
                // this.shipping_info["country"] = '';
                this.deli_kind = "2";
            }

            if (data=='Manage'){
                this.address_kind = "M";
                this.address_book_open = true;
            }else{
                // this.address_book_open = false;
            }
        },
        // 주소록 레이어 오픈
        close_address_book(){
            this.address_book_open = false;
        },
        // 숫자 포멧
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        search_address(){
            if(this.is_academi_order == false){
                this.address_search_open = true;
            }
        },
        set_address(value){
            if(this.address_kind == "B"){
                this.address_search_open = false;
                this.o_addr1   = value.address + " " + value.address2 + " " + value.address3;
                this.o_post    = value.postcode;
                this.o_country = value.country;
            }else{
                this.address_search_open      = false;
                this.shipping_info["d_addr1"] = value.address + " " + value.address2 + " " + value.address3;
                this.shipping_info["d_city"]  = value.address;
                this.shipping_info["d_post"]  = value.postcode;
                //this.getShippingFee();
            }
        },

        close_address_search(){
            this.address_search_open = false;
        },
        center_sel(value){
            console.log(this.cnt_cd);
            this.center_list.forEach((value, index) => {

                if(value.cnt_cd == this.cnt_cd){
                    this.shipping_info["d_post"] = value.post;
                    this.shipping_info["d_state"] = value.state;
                    this.shipping_info["d_city"] = value.city;
                    this.shipping_info["d_street"] = value.street;
                    this.shipping_info["d_address"] = value.address;
                    this.shipping_info["d_address2"] = value.address1;

                }
            });

        },
        link(value){
            location.href = value;
        }
    }
}
</script>
<style>
.vs__search {
    font-size: 1.5em;
    font-weight: 500;
    padding: 0.05rem;
    border: none;
    /* margin-top: 35px; */
}
.badge_box{position: absolute; top: 0; right: 0; width: 100%; }
.badge_box>img {width: 25% !important; top:8px; right: 8px; position: absolute; z-index: 1; background:transparent !important;}

.coming_box{position: absolute; top: 0; right: 0; width: 100%; }
.coming_box>img{width:25% !important;  position: absolute; right:8px; top: 8px;  z-index: 1; background:transparent !important;}

.sold-out-box2{position: absolute; top: 0; right: 0; width: 100%; }
.sold-out-box2>img {width: 100% !important; top:0px; right: 0px; position: absolute;  z-index: 1; background:transparent !important;}


.autoship_box{border-radius:20px; box-shadow: 0px 2px 6px 2px #9e9e9e;
    background: rgb(37,84,175);
    background: linear-gradient(144deg, rgba(37,84,175,1) 0%, rgba(19,46,98,1) 24%, rgba(14,34,74,1) 51%, rgba(3,15,38,1) 75%);
}
.autoship_box .autoship_notice_box{border-radius:20px; overflow: hidden; background:rgba(0,0,0,0.2)}
/*background:url(/images/new_autoshop/autoship_bg_1000x1500.png);*/
.autoship_box .autoship_notice_box:before{background-color:rgba(0,0,0,0.2); width:100%; height:100%;}
.autoship_notice_cont{width: 100%; padding:20px 20px 40px 20px; border-radius: 20px 0 0 20px;  box-sizing: border-box; display:flex; flex-direction: column;}
.autoship_notice_cont dt{font-size:22px; padding: 30px 0 20px 10px; color: #fff; font-weight: 500; }
.autoship_notice_cont dd{font-size:14px; padding: 4px 0 4px 10px; color: #f7f7f7; line-height:1.7; word-break:break-word;}
.autoship_notice_cont button{ align-self: flex-end; padding: 10px 25px; display:inline-block; border: 1px solid #fff;  color:#fff; font-size:15px;  /*background:#7855e3; */     background:rgba(255,255,255,0.2); transition: 0.3s; margin-top:18px;}
.autoship_notice_cont button:hover, .autoship_notice_cont button:focus{background:rgba(255,255,255,0.2); transition: 0.3s;}
.autoship_progressbar_box{border-radius:0 20px  20px 0; /*background:#f9f9f9;*/ box-sizing: border-box; margin-top: 25px; margin-bottom:10px;}

/*정기구독 css 추가*/
.autoship_process_box{width:100%; display: flex; background: rgb(2 12 30 / 80%); border-radius: 0 0 20px 20px; padding:10px 0;}
.autoship_process_box dl{width:calc(100% / 3); display: flex; justify-content: space-evenly; flex-direction: column; align-items: center; border-right:1px dashed #fff; padding:10px; }
.autoship_process_box dl:last-child{border-right:0px dashed transparent;}
.autoship_process_box dl dt{font-size:25px; color: #8585a1; display:flex; color:#fff; padding:5px 0;}
.autoship_process_box dl dd{font-size:12px; text-align:center; padding: 5px 0; color:#fff;}

/*무료배송 progress bar */
.progress_bg_bar{ background:#c7c7c7; position: relative; overflow:hidden; border-radius:20px; height:14px;}
.progress_bar{width:100%; height:100%; top:-2px; left:0; transform:translateX(-100%); overflow:hidden; display: inline-block; position: relative;
    background: rgb(106,152,238);
    background: linear-gradient(135deg, rgba(106,152,238,1) 0%, rgba(37,65,116,1) 45%, rgba(19,40,78,1) 79%, rgba(3,15,38,1) 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    -webkit-animation-duration: 5.25s;
    animation-duration: 5.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
    z-index:0; transition:background 300ms, color 300ms;}
.message{font-weight:200;}
.message.freeship{color:#ffa0bf;}
.progress_bg_bar.freeship{background: rgb(106,152,238); background: linear-gradient(135deg, rgba(106,152,238,1) 0%, rgba(37,65,116,1) 45%, rgba(19,40,78,1) 79%, rgba(3,15,38,1) 100%);}
.progress_bar.stop:after{display:none; -webkit-animation-play-state: paused;  animation-play-state: paused;}
.progress_bar:after { content: ''; left: -100%;
    background-image: -webkit-linear-gradient(left,hsla(0,0%,100%,0),#fff 50%,hsla(0,0%,100%,0));
    background-image: linear-gradient(90deg,hsla(0,0%,100%,0) 0,#fff 50%,hsla(0,0%,100%,0));
    -webkit-animation: wow 2s ease 1s infinite;
    animation: wow 2s ease 1s infinite;
    -webkit-animation-delay: 2s; /* Safari 4.0 - 8.0 */
    animation-delay: 2s; opacity: .35; position: absolute; top: 0; width: 100%; height: 100%;}
.progress_bar.stop.freeship{background: none !important;}
.message{text-align:center; position:relative; z-index:2; color:white; }
#free_shipping_message{height:35px; position: relative; font-size:15px; color:#000; font-weight: 400;}
.total_count{color: #295eb5; font-size:18px; font-weight:500;}
/*하트 progress bar */
.progress_bg_bar2{ background:#c7c7c7; position: relative; overflow:hidden; border-radius:20px; height:14px;}
.progress_bar2{width:100%; height:100%; top:-2px; left:0; transform:translateX(-100%); overflow:hidden; display: inline-block; position: relative;
    background: rgb(252,221,209);
    background: linear-gradient(135deg, rgba(252,221,209,1) 0%, rgba(250,201,216,1) 14%, rgba(254,198,216,1) 32%, rgba(255,131,170,1) 60%, rgba(255,108,155,1) 89%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    -webkit-animation-duration: 5.25s;
    animation-duration: 5.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
    z-index:0; transition:background 300ms, color 300ms;
}
.progress_bar2.stop2.freeship2{background: none !important;}
.message2{font-weight:200;}
.progress_message2.freeship2{color:#ffa0bf;}
.progress_bg_bar2.freeship2{background: rgb(252,221,209); background: linear-gradient(135deg, rgba(252,221,209,1) 0%, rgba(250,201,216,1) 14%, rgba(254,198,216,1) 32%, rgba(255,131,170,1) 60%, rgba(255,108,155,1) 89%);}

.progress_bar2.stop2:after{display:none; -webkit-animation-play-state: paused;  animation-play-state: paused;}

.progress_bar2:after { content: ''; position: absolute; top: 0; width: 100%; height: 100%; left: -100%;
    background-image: -webkit-linear-gradient(left,hsla(0,0%,100%,0),#fff 50%,hsla(0,0%,100%,0));
    background-image: linear-gradient(90deg,hsla(0,0%,100%,0) 0,#fff 50%,hsla(0,0%,100%,0));
    -webkit-animation: wow2 2s ease 1s infinite;
    animation: wow2 2s ease 1s infinite;
    -webkit-animation-delay: 2s; /* Safari 4.0 - 8.0 */
    animation-delay: 2s; opacity: .35;
}
.progress_bar2.stop2.freeship2{background: none !important;}
.message2{text-align:center; position:relative; z-index:2; color:#fff; }
#free_shipping_message2{height:35px; position: relative; font-size:15px; color:#000;     font-weight: 400;}
.total_count2{color: #ff647f; font-size:18px; font-weight:500;}

</style>
