<template>
    <div class="member-search">
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dd class="myo_search_list">
                        <input type="hidden" name="order" id="order">
                        <input type="hidden" name="sort" id="sort">
                        <section class="myo_condition_box">
                            <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" :start_plusday="1"></dateForm>
                        </section>
                    </dd>
                    <dd v-if="false">
                        <input type="checkbox"  id="checkCondition" v-model="checkCondition" >
                        <label for="checkCondition">
                            <i class="far fa-square" aria-hidden="true" v-show="checkCondition == false" style=" margin-right: 5px;"></i>
                            <i class='fas fa-check-square' aria-hidden='true' v-show="checkCondition == true"></i>
                            {{ i18n.myoffice.Commission.Classfication }} : {{ i18n.myoffice.Commission.checktext1 }} <!-- 조회구분 :  수당이 발생한 일자만 조회합니다 -->
                        </label>

                    </dd>
                </dl>
                <div class="myo_condition_search">
                    <button type="reset" onclick="" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                    <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
                </div>

            </section>
        </form>
    </div>

    <div class="summary" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

  <!-- <p class="p_alert">{{ i18n.myoffice.myofficeAlert.ClickStarButtonNotice }}</p>--><!-- 아래 리스트 중 회원번호 옆 별을 클릭(터치)하여 회원을 즐겨찾기에 등록 및 삭제가 가능합니다. -->

    <input type=hidden name=exec_mode value=''>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table id="member_list" class="w-full relative overflow-x-auto shadow-md sm:rounded-lg" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                <th class="px-6 py-3 whitespace-nowrap">No</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Commission.DateofBalance }}</th><!-- 즐겨찾기 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Commission.Position }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Commission.OrderBV }}</th>
                <th class="px-6 py-3 whitespace-nowrap" v-for="Value in name_list">{{ Value.pay_name }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Commission.TotalAmount }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in List" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th class="px-6 py-3 whitespace-nowrap">{{ total_count - ((page-1)*20 + index) }}</th><!-- 무실적경과일 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ Value.pay_date }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ Value.rank_new_name }}</th>

                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.ord_pv,1) }}</th>  <!-- 注文PV(주문PV) //-->
                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt1,2) }}</th>    <!-- 販売ボーナス(판매수당) //-->

                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt5,2) }}</th>    <!-- 購入ボーナス(구매수당) //-->
                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt2,2) }}</th>    <!-- 管理ボーナス(관리수당) //-->

                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt3,2) }}</th>    <!-- 職級ボーナス(직급수당) //-->


                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt4,2) }}</th>    <!-- 職級共有ボーナス(직급공유수당) //-->
                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt6,2) }}</th> <!-- 2p 수당 2P 売上共有ボーナス //-->

<!--                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.amt7,2) }}</th>-->
                <th class="px-6 py-3 whitespace-nowrap">{{ formatPrice(Value.total_amt,2) }}</th>
            </tr>
            <tr class="h-2" v-if="total_count == 0">
              <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>
</template>

<script>

import dateForm from '../../searchForm/dateFormMonth.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyBenefitSearch",
    components: {
        dateForm,
        VPagination,
        Loading
    },setup() {
        //
    },
    data: () => ({
        i18n: window.i18n,
        title: [],
        products: [],
        page : 1,
        total_page : 1,
        total_count : 0,
        s_date: date_month_search,
        e_date: date_month_search,
        checkCondition : false,
        name_list : [],
        List : [],
        loading : false,
        list_msg : "検索ボタンを押して照会可能です。",
    }),
    created() {
        // 배너
        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/getNameList',{
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
            this.name_list = response.data;
            this.loading = false;
        });
        this.submitForm();

    },
    methods:{
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getBenefitSearch',{
                s_date : this.s_date,
                e_date : this.e_date,
                checkCondition : this.checkCondition,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.total_count == 0){
                  VueSimpleAlert.alert("検索結果がありません","","",{confirmButtonColor: '#0abab5',confirmButtonText:"確認"});
                  this.list_msg = "データがありません。";
                }
                this.List = response.data.list.data;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            });
        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        formatPrice(value,digit) {
          let val = (value/1).toFixed(digit).replace('.', '.')
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

    }
}
</script>

<style scoped>

</style>
