<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage">
    </loading>

    <article class="myo_search_area">
        <form name='form_search' v-on:submit.prevent="submitForm(1)">
            <section class="myo_condition_box">
                <dl class="myo_search_list">
                    <dt>{{ i18n.myoffice.Searchcontent }}</dt>
                    <dd>
                        <input type="text" name="keyword1" v-model="keyword1"  class="myo_search_input2">
                    </dd>
                </dl>
                <div class="myo_condition_search2">
                    <button type="reset" class="reset_btn" onclick="location.replace('/{{ $working_nation }}/{{ $mid }}')">{{ i18n.myoffice.Refresh }}</button>
                    <button type="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
                </div>
            </section>
        </form>
    </article>

    <section class="accordion_notice_box">
        <div class="accordion_notice_item">
            <a class="accordion_notice_tit" href="#n">
                <span class="exc_icon"><i class="fal fa-exclamation-circle" aria-hidden="true"></i></span>
                {{ i18n.myoffice.myoSearchForm.notice }}
                <div class="bmenu x7">
                    <span class="btop">
                        <i class="fas fa-chevron-up" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <div class="accordion_notice_cont">
                <div class="myo_search_notice">
                    <p>{{ i18n.myoffice.myofficeAlert.ClickCancleAlert }}</p><!-- *버튼 클릭 시 즐겨찾기 목록에서 탈퇴회원이 삭제됩니다. -->
                    <p>{{ i18n.myoffice.myofficeAlert.favoritesNotice }}</p><!-- 회원 즐겨찾기는 [하위회원 관리] 메뉴에서 선택 및 삭제가 가능합니다 -->
                </div>
            </div>
        </div>
    </section>

    <div class="summary position_relate_box">
        <strong>Total</strong> <em>{{ total_count }}</em>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <input type=hidden name=exec_mode value=''>
        <table id="member_list" class="relative overflow-x-auto shadow-md sm:rounded-lg" cellspacing="0" width="100%">
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                <th class="px-6 py-3 w-3 whitespace-nowrap">NO</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.MemberRank.Favorite }}</th><!-- 즐겨찾기 -->
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.MemberId }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.memberName }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.RegistrationDate }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.MemberStatus }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">最終購入履歴</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.Rank }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">達成職級</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ i18n.myoffice.EnrollmentSponsor }}</th>
                <th class="px-6 py-3 whitespace-nowrap">Maintenance Status</th>

            </tr>
                </thead>
                <tbody>
                <tr v-for="(Value,index) in list" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td class="px-6 py-3 w-3 whitespace-nowrap">{{ total_count - ((page-1)*page_size + index) }}</td>
                    <th class="px-6 py-3 w-3 whitespace-nowrap">
                        <span class='m_favorites' title='Member Favorites' v-show="Value.fav_cnt != 0" v-on:click="favorChk(index,Value.fav_cnt,Value.c_id)" ><i class="fas fa-star fav_on" aria-hidden="true"></i></span>
                        <span class='m_favorites' title='Member Favorites' v-show="Value.fav_cnt == 0" v-on:click="favorChk(index,Value.fav_cnt,Value.c_id)"><i class="far fa-star fav_off" aria-hidden="true"></i></span>
                    </th><!-- 즐겨찾기 -->
                <td class="px-6 py-3 w-3 whitespace-nowrap user_menu" :data-enc_id="Value.enc_id" :data-userid="Value.c_id">{{ Value.c_id }} <i class="fal fa-info-circle"></i></td>
                <td class="px-6 py-3 w-3 whitespace-nowrap">
                    <span v-show="Value.status == 1">{{ Value.c_name }}</span>
                    <span v-show="Value.status != 1" class="delete_member">{{ i18n.myoffice.Withdraw }}</span>
                </td>
                <td class="px-6 py-3 w-3 whitespace-nowrap">
                    <span >{{ Value.reg_date }}</span>
                </td>
                <td class="px-6 py-3 w-3 whitespace-nowrap">
                    <span v-show="Value.status == 1">{{ i18n.myoffice.normality }}</span>
                    <span v-show="Value.status != 1" class="delete_member">{{ i18n.myoffice.Withdraw }}</span>
                </td>
                <td class="px-6 py-3 w-3 whitespace-nowrap">
                    <span >{{ Value.ord_date }}</span>
                </td>

                <td class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.rank_name }}</td>
                <td class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.rank_name }}</td>

                <td class="px-6 py-3 w-3 whitespace-nowrap">{{Value.r_names}}</td>
                <th class="px-6 py-3 whitespace-nowrap">{{ Value.act_yn }}</th><!--20230224 DYLEE 마감이후유지여부추후변경-->
            </tr>
            <tr class="h-2" v-if="total_count == 0">
                <td class="text-left md:text-center pl-5 py-5" colspan="12">データがありません。</td>
            </tr>

            </tbody>
        </table>
    </div>
    <div class="myo_condition_search mgt_20">
        <button type="button" @click="erase_clear" class="w-full max-w-fit bg-[#e07424] mem_clear_btn">{{ i18n.myoffice.MyFavorites.arrangement }}</button>
    </div>

    <div style="display: flex; align-items: center; justify-content: center;" class="mt-4">
        <v-pagination
            v-model="page"
            :pages=total_page
            :range-size="1"
            active-color="#DCEDFF">
        </v-pagination>
    </div>

</template>

<script>
import VueSimpleAlert from "vue3-simple-alert";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import dateForm from "../../searchForm/dateForm.vue";
import Loading from "vue3-loading-overlay";

export default {
    name: "MyMemberFavorites",
    components: {
      VPagination,
      Loading
    },
    data: () => ({
        i18n: window.i18n,
        keyword1 : "",
        list : [],
        total_count : "",
        total_page : 1,
        page : "1",
        page_size  : 10,
        fullPage : true,
        loading : "false",

    }),
    created : function (){
        this.submitForm();
    },
    watch : {
      page: function(){
        this.submitForm();
      }
    },
    methods : {
        submitForm: function (page = 0) {
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getMemberFavor', {
                keyword1: this.keyword1,
                page : (page != 0) ? page : this.page,
                page_size : this.page_size,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                this.list = response.data.list.data;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
                this.loading = false;
                if(response.data.total_count == 0){
                    VueSimpleAlert.alert("検索結果がありません","","",{confirmButtonText:"確認"});
                    this.sid_name = "";
                }

            });

        },
        erase_clear(){
            axios.post(window.Laravel.back_url+'/api/favorite_erase_clear', {
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                VueSimpleAlert.alert(i18n.myoffice.myofficeAlert.CancelCompleteAlert,"","",{confirmButtonText:"確認"});
            });

        },
        favorChk(index,fav_cnt,userid){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/setMemberFavorites',{
                fid : userid,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.ret == 1){
                    if(fav_cnt == 0){
                        this.list[index].fav_cnt = 1;
                    }
                    if(fav_cnt == 1){
                        this.list[index].fav_cnt = 0;
                    }
                }
                VueSimpleAlert.alert(response.data.msg,"","",{allowOutsideClick : false,confirmButtonText:"確認"});
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>
