<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <div>
    <div class="cbs-product-visual">
      <img src="/images/dir_img1.jpg" alt="">
    </div>
    <div class="inner cbs-box">
      <div class="cbs-page-tit">
        <h2>韓国メーカー直売ページについて</h2>
      </div>
      <div class="cbs-notice">
        <div>
<!--          <p>※韓国メーカー直売は5万円以上のご注文でご利用いただけます。<br></p>-->
          <p>※韓国メーカー直売は、5万円以上のご注文からご利用いただけます。<br></p>
          <p>※化粧品を除く、その他全ての製品に15％の値引きが適用されます。(化粧品のみ5％)<br></p>
<!--          <p>※一度にご注文頂けるのは、送料含め15万円までです。（送料5000円）<br></p>-->
<!--          <p>※海外発送商品の返品・交換は不可となります（クーリングオフの対象外になります）。<br></p>-->

<!--          <p>※一度にご注文頂けるのは、送料含め199,900円までです。(送料5,000円)</p>-->
          <p>※一度にご注文頂けるのは、199,900円までです。</p>
          <p>※海外発送商品の返品・交換は不可となります。(クーリングオフの対象外になります)</p>

          <p>韓国メーカー直売に関する弊社業務は注文代行であり、商品内容に関する責任は注文者自身に帰属します。<br></p>
          <p>但し、弊社の手違いによりご注文と異なる商品をお届けした場合に限り、無料で交換いたします。<br></p>

          <p style="margin:10px 0; color:#f00; font-weight: bold;">
<!--            ※コロナの影響により配送に遅延が発生したり、配送可能エリアが再度制限される場合もございますので、予めご了承ください。-->
            ※韓国からの発送になる為、通関手続きに時間を要する場合がございます。<br/>
            　また、配送業者の事情により配達に遅れが生じる場合もございますので、予めご了承ください。
          </p>
        </div>
      </div>
      <div id="cbs-contentTxt">
        下記のリンクよりGeneral Bio Co., Ltd.が提供する韓国メーカー直売のウェブページにつながります。当ページではGeneral
        Bio Co., Ltd.の一部製品をご本人様の希望でお求め頂けます。 当サービスはGeneral Bio Co., Ltd.
        が提供するサービスの為、別途のアカウントが必要になります。 当ページから行われる韓国メーカー直売のサービスは全て会員ご本人様の自己責任で行ってください。
        General Bio Co.,
        Ltd.は指定薬物や輸入禁止・規制品目を事前に確認し、極力そのような製品は取り扱わないよう心がけておりますが、それに伴うリスクの安全を保証しているものではありません。何かトラブルが生じても一切責任を負わず、全て購入者の責任とされます。
        <br><br>
        【特記】<br>
        商品の誤配送や故障などに関するお問い合わせは、GCOOP JAPAN(jp.info@gcoop.com)までご連絡ください。
      </div><!--//contentTxt-->


      <div v-if="this.loading===false" class="cbs-btn-box">
        <ul>
          <li v-if="is_member===false"><span @click="form_href('/dirAgree')">アカウント作成</span></li>
          <!--동의페이지로 이동 -->
<!--          <li><span @click="form_href('/dirJoin/model?mode=login')">専用ページへ</span></li>&lt;!&ndash;정기구매 등록 &ndash;&gt;-->
          <li v-if="is_member===true"><span @click="form_href('product/list/114')">専用ページへ</span></li><!--정기구매 등록 -->


        </ul>
      </div><!--//btn-box-->
    </div><!--//inner cbs-box-->
  </div>
</template>
<script>

import {getApiCall} from "../../../assets/api/rest";
import Loading from 'vue3-loading-overlay';

export default {
  name: "direct_info",
  // props: ['is_member'],
  data: () => ({
    loading: true,
    is_member: true,
  }),
  components: {
    Loading
  },
  methods: {
    form_href(url) {
      location.href = url;
    },
    isDirectMember() {
      getApiCall('isDirectMember').then(response => {
        if (response.data.resultCode === 0) {
          this.is_member = response.data.isDirectMember;
          this.loading = false;
        }
      })
    }
  },
  mounted() {
  },
  created() {
    this.isDirectMember();
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  width: 100%;
  margin: 0 auto;
}

body {
  font-family: "Noto Sans JP";
  font-size: 0.88em;
  color: #666;
  font-weight: 300;
  line-height: 1.56;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  word-break: keep-all;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  font-size: 1em;
  font-weight: normal;
}

/* font-size:100%;  기본 폰트사이즈 가지지 않도록 선언 필요*/
ul, ol, li {
  list-style: none
}

a, a:hover, a:active, a:visited {
  text-decoration: none;
  color: #000;
}

img {
  border: 0;
  vertical-align: top;
  width: 100%;
  min-height:190px;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  color: #666;
  vertical-align: middle;
  margin-top: -2px;
}

textarea {
  resize: none;
  width: 100%;
  height: 248px;
  padding: 5px;
  vertical-align: top;
  font-size: 0.88em;
  font-family: inherit;
  color: #666
}

em, address {
  font-style: normal
}

table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  color: transparent
}

strong {
  font-weight: 500;
  color: #222
}

label {
  vertical-align: middle;
  cursor: pointer
}

.fa, .fas, .fal {
  vertical-align: middle;
}

input {
  font-size: 0.88em;
  vertical-align: middle;
}

img {
  vertical-align: middle;
}

/*페이지 css*/
/*작업 시 페이지에 적용 시키고 작업 후 추후 css 파일로 옮기겠음*/
.inner {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.cbs-box {
  word-break: break-all;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px
}

.cbs-page-tit {
  margin: 20px 0 30px 0;
}

.cbs-page-tit > h2 {
  font-size: 1.13em;
}

.cbs-cont-tit {
  text-align: center;
  font-size: 1.13em;
  font-weight: bold;
  margin: 15px 0;
}

.cbs-notice {
  font-size: 1.13em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.cbs-notice p {
  text-indent: 0px;
}

#cbs-contentTxt {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #d4d4d4;
  background: #ffffff;
  height: 200px;
  overflow: auto;
  color: #000;
  padding: 10px 15px;
  font-size:0.88em;
}

.agree_check {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.cbs-btn-box {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 80px;
}

.cbs-btn-box ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 5px;
}

.cbs-btn-box ul li {
  display: table-cell;
}

.cbs-btn-box ul li span {
  display: block;
  height: 62px;
  line-height: 62px;
  font-size: 1.13em;
  color: #222;
  border: 1px solid #0abab5;
  cursor: pointer;
  text-align: center;
}

.cbs-btn-box ul li span:last-child {
  color: #fff;
  border: 1px solid #0abab5;
  background: #0abab5;
}
</style>
