<template>
  <section class="bg-[#f0f2f4]">
    <div class="container mx-auto lg:py-6 md:p-0">
      <div class="max-w-8xl mx-auto py-4 px-6 lg:max-w-7xl lg:px-8">
        <MypageTop :laravel="laravel"></MypageTop>
        <div class="w-full lg:flex lg:justify-start">
          <div class="hidden lg:block xl:block">
            <SideMenu></SideMenu>
          </div>
          <!-- 마이페이지 본문 -->
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start space-y-2 flex-col">
                  <div class="flex justify-between">
                    <h1 class="text-lg dark:text-white lg:text-xl font-semibold leading-7 lg:leading-9 text-gray-800 mb-2">
                      Shipping Address List
                      <span v-show="address_list.length > 0">( {{this.total_count}} )</span>
                    </h1>
                  </div>


                  <div class="w-full border-t-2 border-black lg:p-2">
                    <div class="py-2">

                      <div class="relative overflow-x-auto mt-4">
                        <!-- 모바일 사이즈 -->
                        <div class="w-full relative overflow-x-auto">
                          <ul class="text-sm dark:bg-gray-700 dark:text-gray-400 font-bold bg-[#d1d5de] hidden lx:flex lg:flex items-center">
                            <li class="px-6 py-3 basis-1/5 text-center text-base">Receiver</li>
                            <li class="px-6 py-3 basis-2/5 text-center text-base">Address</li>
                            <li class="px-6 py-3 basis-1/5 text-center text-base">Phone Number</li>
                            <li class="px-6 py-3 basis-1/5 text-center text-base"></li>
                          </ul>
                          <ul v-show="address_list.length > 0" v-for="(Value,index) in address_list" class="rounded-md lg:rounded-none xl:rounded-none bg-white dark:bg-gray-200 dark:border-gray-100 font-thin block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent border-t border-x md:border-x-0 rounded-md py-3">
                            <li class="w-full lg:basis-1/5 text-md lg:text-md xl:text-md">
                                <div class=" xl:px-1 lg:px-1 px-3 py-1 xl:block lg:block hidden text-center">
                                  <button class="bg-rose-600 cursor-default text-white w-14 mr-1 text-xs text-white py-1" v-show="Value.default_yn == 'Y'">Basic</button>
                                </div>
                                <div class="xl:py-3 lg:py-3 py-1 px-3 text-[#591734] font-medium xl:hidden lg:hidden block">
                                  <button class="bg-rose-600 cursor-default text-white w-16 mr-1 xl:text-sm lg:text-sm text-xs text-white py-1 block mb-3" v-show="Value.default_yn == 'Y'">Basic</button>
                                  Receiver
                                </div>
                                <div class="xl:py-3 lg:py-3 py-1 xl:px-1 lg:px-1 px-3 font-normal w-full xl:text-center lg:text-center text-left ">{{ Value.r_name }}</div>
                            </li>
                            <li class="w-full lg:basis-2/5 text-md lg:text-md xl:text-md">
                              <div class="xl:py-3 lg:py-3 py-1 px-3 text-[#591734] font-medium xl:hidden lg:hidden block ">Address</div>
                              <div class="xl:py-3 lg:py-3 py-1 px-3 font-normal w-full lg:text-left">{{ (Value.r_addr2).replace(/\|@\|/g, ", ") }}, {{ (Value.r_addr1).replace(/\|@\|/g, ", ") }}</div>
                            </li>
                            <li class="w-full lg:basis-1/5 text-md lg:text-md xl:text-md">
                              <div class="xl:a px-3 text-[#591734] font-medium xl:hidden lg:hidden block ">Phone Number</div>
                              <div class="xl:py-3 lg:py-3 px-3 font-normal w-full lg:text-left">{{ Value.r_mobile }}</div>
                            </li>
                            <li class="w-full lg:basis-1/5 text-md lg:text-md xl:text-md flex lg:block xl:block">
                              <div class="text-center w-full flex px-2">
                                <button type="button" class="my-2 py-2 px-2 bg-[#d1d5de] hover:bg-[#cbcfdd] text-[#591734] text-center text-sm w-full mr-2 text-md" @click="delete_item(Value.seq_oab)">Delete</button>
                                <button type="button" class="my-2 py-2 px-2 bg-[#2c7fc3] hover:bg-[#24679f] text-white text-center w-full text-sm" @click="list_url(Value.seq_oab)">Edit</button>
                              </div>
                            </li>
                          </ul>
                          <div class="rounded-md lg:rounded-none xl:rounded-none border dark:bg-gray-200 dark:border-gray-100 block items-center xl:flex lg:flex mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 lg:shadow-transparent xl:shadow-transparent" v-if="this.total_count == 0">
                            <div class="w-full text-center px-2 py-3 text-black bg-white ">There is no registered shipping address.<br>
                              Please click the 'register' button below to register your shipping address.
                            </div>
                          </div>
                        </div>

                      </div><!-- // -->
                    </div>
                  </div>
                  <!--                    <div class="flex justify-end space-x-4"><button class="my-2 py-1 px-2 leading-6 text-sm text-white bg-gray-900 rounded">제품 구매계약서</button> <button class="my-2 py-1 px-2 leading-6 text-sm text-white bg-gray-900 rounded">청약철회 신청서</button></div>-->
                  <div class="flex"><button class="my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#24679f] w-full md:w-1/6 search_btn rounded-md" @click="list_url(0)">Register</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {MinusSmIcon, PlusSmIcon} from '@heroicons/vue/outline';
import MypageTop from '../MypageTop.vue';
import SideMenu from '../SideMenu.vue';
import moment from 'moment';
import alert from "vue3-simple-alert";

export default {
  name: "AddressList",
  components : {
    VueSimpleAlert,
    MinusSmIcon,
    PlusSmIcon,
    MypageTop,
    SideMenu,
    moment
  },
  props: ['order_no'],
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
    limit: 4,
    products: [],
    no_search_messsage: '',
    isLoggedIn: false,
    row : '',
    max_qty : 99,
    VPagination: [1],
    shipping_fee : 0,
    address_list: [],
    total_count : 0,
  }),
  created() {
    //this.submitForm();
    this.getAddressBook();
  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
    }
  },
  methods:{
    submitForm: function (event) {
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/getMyOrderView', {
        ord_no: this.order_no
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        this.loading = false;
        this.row = response.data.row;
        this.row.ord_date2 = moment(this.row.ord_date2).format('DD-MM-YYYY');
        this.isOwnDelivery = response.data.isOwnDelivery;
        this.pdt_list = response.data.pdt_list;
        this.ord_deli = response.data.ord_deli;
        this.rcpt_list = response.data.rcpt_list;
        this.deli_mst = response.data.deli_mst;
      });

    },
    getAddressBook(){
      axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook',{
        bz_cd: window.Laravel.bz_cd,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.address_list = response.data;
          this.total_count = this.address_list.length;
          console.log(this.total_count);
        })
    },
    list_url(link){
      location.href="/Mypage/AddressList/"+link;
    },
    delete_item(seq){
      alert.confirm("Are you sure you want to delete this address?").then(() => {
        axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_delete',{
          bz_cd: window.Laravel.bz_cd,
          seq: seq,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
          .then(response => {
            this.address_list = response.data;
          })
      })
    },

  }
}
</script>

<style scoped>
.contents-active{
  display:block;
}

.contents-active-none{
  display:none;
}
</style>
