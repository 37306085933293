<template>
    <!-- 직급 현황 -->
    <div class="rounded-xl bg-white mb-6 shadow-zinc-500/40 shadow-md p-5 w-full flex flex-col">
        <div class="py-3 flex flex-row justify-between">
            <h3 class="font-semibold text-[#172e59] text-lg mb-4 min-h-1/3">{{
                    i18n.myoffice.Commission.statusOfJobClass
                }}
                <span class="text-sm font-normal xl:block lg:block hidden"><br>&nbsp;</span>
            </h3> <!--승급 진행상황-->
        </div>
        <!-- 여기에 프로그래스 바 추가 -->
        <div class=" flex flex-col justify-around xl:mt-12 lg:mt-12" v-if="dash_d_val.RANK_NEW">
            <div class="flex flex-row items-center w-full">
                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">1</span></div>
                        <div v-if="rank_name == 'Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">1</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'Manager'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'Manager'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'General Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">2</span></div>
                        <div v-if="rank_name == 'General Manager'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">2</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'General Manager'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'General Manager'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'Director'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">3</span></div>
                        <div v-if="rank_name == 'Director'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">3</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'Director'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'Director'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>


                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-row justify-center items-center">
                        <div v-if="rank_name != 'Vice President'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">4</span></div>
                        <div v-if="rank_name == 'Vice President'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">4</span></div>
                    </div>
                </div>
                <div class="w-1-9 align-center items-center align-middle content-center flex">
                    <div class="w-full bg-grey-light rounded items-center align-middle align-center">
                        <div v-if="rank_name == 'Vice President'"
                             class="bg-red-500 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                        <div v-if="rank_name != 'Vice President'"
                             class="bg-gray-400 text-xs leading-none xl:py-1 lg:py-1 py-0.5 text-center text-grey-darkest rounded"></div>
                    </div>
                </div>

                <div class="w-1-9 text-center lg:text-base mx-1">
                    <div class="flex flex-col justify-center items-center">
                        <div v-if="rank_name != 'President'"
                             class="xl:border-4 lg:border-4 border-2 border-gray-400 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-gray-400">5</span></div>
                        <div v-if="rank_name == 'President'"
                             class="xl:border-4 lg:border-4 border-2 border-red-500 rounded-full xl:w-16 xl:h-16 lg:h-16 lg:h-16 w-10 h-10 flex justify-center items-center">
                            <span class="text-lg text-red-500">5</span></div>
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-center w-full">
                <!--                                    <p v-if="rank_name != 'Branch Manager'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1">Branch Manager</p>-->
                <!--                                    <p v-if="rank_name == 'Branch Manager'" class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-7 break-keep mx-1 text-red-500 font-semibold">Branch Manager</p>-->

                <p v-if="rank_name != 'Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">Manager</p>
                <p v-if="rank_name == 'Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1 text-red-500 font-semibold">
                    Manager</p>

                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">&nbsp;</p>

                <p v-if="rank_name != 'General Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">General Manager</p>
                <p v-if="rank_name == 'General Manager'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1 text-red-500 font-semibold">
                    General Manager</p>

                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">&nbsp;</p>

                <p v-if="rank_name != 'Director'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">Director</p>
                <p v-if="rank_name == 'Director'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1 text-red-500 font-semibold">
                    Director</p>
                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">&nbsp;</p>
                <p v-if="rank_name != 'Vice President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">Vice President</p>
                <p v-if="rank_name == 'Vice President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1 text-red-500 font-semibold">
                    Vice President</p>
                <p class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">&nbsp;</p>
                <p v-if="rank_name != 'President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1">President</p>
                <p v-if="rank_name == 'President'"
                   class="text-center xl:text-sm lg:text-sm text-[10px] mt-2 w-1-9 break-keep mx-1 text-red-500 font-semibold">
                    President</p>
            </div>
            <!--현재 직급은 이며,
            President 승급을 위해 직추천산하 총 3명의 Vice President가 필요하며,
            현재 1명 달성했습니다.-->

            <div v-if="memberData[dash_d_val.RANK_NEW]['type'] === 'A'" class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3 break-word">
                現在、あなたの資格は<span
                class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                <span class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{
                        dash_d_val.RANK_NEXT
                    }}</span>を達成する為には、1回の注文で{{ numberFormat(memberData[dash_d_val.RANK_NEW]['entireDownlinePv']) }}PV以上購入されるか、<br>
                購入累積が{{ numberFormat(memberData[dash_d_val.RANK_NEW]['minimumSalesPv']) }}PV以上必要です。
            </div>
            <div v-else-if="memberData[dash_d_val.RANK_NEW]['type'] === 'B'"
                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3 break-word">
                現在、あなたの資格は<span
                class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>
                <span class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{
                        dash_d_val.RANK_NEXT
                    }}</span>を達成するためには、本人傘下全体の売上が{{ numberFormat(memberData[dash_d_val.RANK_NEW]['entireDownlinePv']) }}PV以上且つ直推薦傘下が3レグ必要であり、<br>
                少なくとも2つのレグで各{{ numberFormat(memberData[dash_d_val.RANK_NEW]['minimumSalesPv']) }}PV以上の売上が必要です。
            </div>
            <div v-else-if="memberData[dash_d_val.RANK_NEW]['type'] === 'C'"
                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3 break-word">
                現在、あなたの職級は<span class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br/>
                最高職級の達成おめでとうございます。

<!--                です。最高職級である-->
<!--                <span class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>-->
<!--                達成、おめでとうございます。<br>-->
<!--                スペシャル職級である<span class="text-red-500 font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>達成には、本人傘下最少2レグで、各1名のPresident達成者が必要です。-->
            </div>


<!--            <div v-if="dash_d_val.RANK_NEXT == 'Member' "-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの資格は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、1回の注文で1,000PV以上購入されるか、<br>-->
<!--                購入累積が2,000PV以上必要です。-->
<!--            </div>-->
<!--            <div v-if="dash_d_val.RANK_NEXT == 'Bronze' "-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの資格は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、1回の注文で2,000PV以上購入されるか、<br>-->
<!--                購入累積が5,000PV以上必要です。-->
<!--            </div>-->
<!--            <div v-if="dash_d_val.RANK_NEXT == 'Silver'"-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの資格は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、1回の注文で3,000PV以上購入されるか、<br>-->
<!--                購入累積が10,000PV以上必要です。-->
<!--            </div>-->
<!--            <div v-if="dash_d_val.RANK_NEXT == 'Gold' "-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの資格は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、本人傘下全体の売上が10,000PV以上且つ直推薦傘下が3レグ必要であり、<br>-->
<!--                少なくとも2つのレグで各3,000PV以上の売上が必要です。-->
<!--            </div>-->

<!--            <div v-if="dash_d_val.RANK_NEXT == 'Branch Manager'"-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの資格は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、-->
<!--                直推薦1代で1回のご注文3,000PV以上購入された方が3名必要です。-->
<!--            </div>-->

<!--            <div v-if="dash_d_val.RANK_NEXT == 'Manager'"-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの職級は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、本人傘下全体の売上が20,000PV以上且つ直推薦傘下が3レグ必要であり、-->
<!--                少なくとも2つのレグで各6,000PV以上の売上が必要です。-->
<!--            </div>-->

<!--            <div v-if="dash_d_val.RANK_NEXT == 'Genal Manager'"-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの職級は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>を達成するためには、本人傘下全体の売上が50,000PV以上且つ直推薦傘下が3レグ必要であり、-->
<!--                少なくとも2つのレグで各10,000PV以上の売上が必要です。-->
<!--            </div>-->

<!--            <div v-if="dash_d_val.RANK_NEXT == 'Director'"-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの職級は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>-->
<!--                を達成するためには、本人傘下全体の売上が200,000PV以上且つ直推薦傘下が3レグ必要であり、<br>-->
<!--                少なくとも2つのレグで各50,000PV以上の売上が必要です。-->
<!--            </div>-->
<!--            <div v-if="dash_d_val.RANK_NEXT == 'Vice President'"-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--                現在、あなたの職級は<span-->
<!--                class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>-->
<!--                を達成するためには、本人傘下全体の売上が500,000PV以上且つ直推薦傘下が3レグ必要であり、<br>-->
<!--                少なくとも2つのレグで各150,000PV以上の売上が必要です。-->
<!--            </div>-->
<!--            <div v-if="dash_d_val.RANK_NEXT == 'President' "-->
<!--                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">-->
<!--&lt;!&ndash;                現在、あなたの職級は&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>です。<br>&ndash;&gt;-->

<!--                です。最高職級である-->
<!--                <span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{-->
<!--                        dash_d_val.RANK_NEXT-->
<!--                    }}</span>-->
<!--                達成、おめでとうございます。<br>-->
<!--                スペシャル職級である<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">{{ dash_d_val.RANK_NEW }}</span>達成には、本人傘下最少2レグで、各1名のPresident達成者が必要です。-->
<!--            </div>-->

            <div v-if="rank_name == '99'"
                 class="text-center py-5 px-3 xl:text-base lg:text-base text-sm xl:mt-5 lg:mt-3">
                現在のあなたの職級はPresidentです。<br>
                最高職級の<span class="text-[#AB1C30] font-semibold xl:text-lg lg:text-lg text-base">President</span>達成おめでとうございます。
            </div>
        </div>
    </div><!--// -->
</template>

<script>


import numeral from "numeral";

export default {
    name: "PromotionStatus",
    props: ['dash_d_val', 'rank_name', 'rank_ord_pv'],
    components: {},
    data: () => ({
        i18n: window.i18n,
        memberData: {
            'Member': {
                type: 'A',
                entireDownlinePv: 1000,
                minimumSalesPv: 2000,
            },
            'Bronze': {
                type: 'A',
                entireDownlinePv: 2000,
                minimumSalesPv: 5000,
            },
            'Silver': {
                type: 'A',
                entireDownlinePv: 3000,
                minimumSalesPv: 10000,
            },
            'Gold': {
                type: 'B',
                entireDownlinePv: 10000,
                minimumSalesPv: 3000,
            },
            'Manager': {
                type: 'B',
                entireDownlinePv: 20000,
                minimumSalesPv: 6000,
            },
            'General Manager': {
                type: 'B',
                entireDownlinePv: 50000,
                minimumSalesPv: 10000
            },
            'Director': {
                type: 'B',
                entireDownlinePv: 200000,
                minimumSalesPv: 50000
            },
            'Vice President': {
                type: 'B',
                entireDownlinePv: 500000,
                minimumSalesPv: 150000
            },
            'President': {
                type: 'C'
            },
        },
    }),
    created() {
    },
    mounted() {
    },
    methods: {
        numberFormat(val, type) {
            if (type === 'dollar') {
                return numeral(val).format('$0,0.00')
            } else if (type === 'decimalPoint') {
                return numeral(val).format('0,0.00')
            }
            return String(val).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        },
    },
}
</script>

<style scoped>
.w-1-7 {
    width: 14.28%;
}

.w-1-9 {
    width: 11.11%;
}

.break-word {
    word-wrap: break-word;
}
</style>
