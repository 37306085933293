<template>
    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage">
        </loading>
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm('1')">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">
                <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date"></dateForm>
            </section>
            <div class="myo_condition_search">
                <button type="reset" onclick="" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert" v-if="page_type == 'orderCancel'">配送先の変更やご注文のキャンセルは、注文当日のみ可能です。<br>
        翌日以降の注文キャンセルは返品扱いとなり、事務手数料や往復送料等が発生する場合があります。<br>
        また、決済方法によっては注文当日中であってもキャンセルができない場合もあります。
    </p><!-- 아래 리스트 중 회원번호 옆 별을 클릭(터치)하여 회원을 즐겨찾기에 등록 및 삭제가 가능합니다. -->


    <p class="p_alert" v-if="page_type == 'orderRefund' && Laravel.today >= 20240628" v-html="i18n.myoffice.refund.listTextNew3"></p>
    <p class="p_alert" v-if="page_type == 'orderRefund' && Laravel.today < 20240628" v-html="i18n.myoffice.refund.listText3"></p>


    <p class="p_alert" v-if="page_type == 'orderRefund'" v-html="i18n.myoffice.refund.listText2"></p>

    <div class="summary position_relate_box mt-6" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

        <input type=hidden name=exec_mode value=''>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table id="order_list" class="relative overflow-x-auto shadow-md sm:rounded-lg w-full" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
              <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.refund.apply }}</th><!--배송여부 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.orderDate }}</th><!-- 주문일 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.shop.shopping.orderInfomation }}</th><!-- {{ i18n.shop.shopping.OrderNum") -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.depositdivision }}</th><!-- 구분 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.type }}</th><!-- 구분 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.shop.etc.OrderStatus }}</th><!-- 구분 -->
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.Order.depositdate }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.refund.Elapseddays }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in orderList" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th class="px-6 py-3 w-3 whitespace-nowrap" v-if="isNotConsumer(Value.r_type)" >
                <button class="search_btn_us" v-on:click="ViewModal(Value.ord_no,Value.r_type,Value.kind_cd)">
                  {{ Value.r_type.length > 2 ? (Value.r_status + ' : ' + Value.r_type) :Value.btn_name }}
                </button>
                <!-- <span v-on:click="ViewModal(Value.ord_no,Value.r_type)">{{ Value.r_type.length > 2 ? (Value.r_status + ' : ' + Value.r_type) :Value.btn_name }}</span>-->
              </th>
              <th class="px-6 py-3 w-3 whitespace-nowrap" v-else></th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.ord_date }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap text-left">
                    <div><strong>[{{ i18n.shop.shopping.OrderNum }}]</strong>&nbsp;<span class="ord-no bold high-lighter" >{{ Value.ord_no}}</span></div>
                    <div><strong>[{{ i18n.shop.shopping.Price }}]</strong>&nbsp;<span class="ord-pay bold">{{ formatPrice(Value.ord_amt, 0) }} {{ i18n.currency.jp }}</span></div>
                    <div v-if="Value.isDirect===false">
                      <strong>[PV]</strong>&nbsp;<span class="ord-bv bold">{{ formatPrice(Value.ord_pv, 1) }}</span>
                    </div>
                </th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.money_kinds ??'' }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.kind_name }}</th>
<!--                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.deli_finish =='배송완료'?'配送完了':Value.deli_finish }}</th>-->
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ delifinish_str[Value.deli_finish] ?? '' }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.acc_date }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.diff_days }}</th>
            </tr>
            <tr class="h-2" v-if="total_count == 0">
                <td class="text-left md:text-center pl-5 py-5" colspan="13">{{ this.list_msg }}</td>
            </tr>

            </tbody>
        </table>
        </div>
  <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
    <v-pagination
      v-model="page"
      :pages=total_page
      :range-size="1"
      active-color="#DCEDFF">
    </v-pagination>
  </div>
    <modalView v-if="showRModal" @close-modal="showRModal = false" :harf="false" >
        <MyViewOrderRefund :ord_no="ord_no" :r_type="r_type" :isAutoShip="isAutoShip" ref="MyViewOrderRefund"></MyViewOrderRefund>
    </modalView>
    <modalView v-if="showModal" @close-modal="showModal = false"  :harf="false" >
        <MyViewOrderCancel :ord_no="ord_no" :r_type="r_type" ref="MyViewOrderCancel"></MyViewOrderCancel>
    </modalView>

</template>
<script>
import dateForm from '../../searchForm/dateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../../modalView.vue";
import MyViewOrderCancel from './MyViewOrderCancel.vue'
import MyViewOrderRefund from './MyViewOrderRefund.vue'
import VueSimpleAlert from "vue3-simple-alert";


export default {
    name: "MyViewOrder",
    components : {
        dateForm,
        VPagination,
        Loading,
        modalView,
        MyViewOrderCancel,
        MyViewOrderRefund,
    },
    data: () => ({
        i18n: window.i18n,
        Laravel: window.Laravel,
        rank_cd : "",
        orderList : [],
        total_count : 0,
        page : 1,
        total_page : 1,
        page_size : 10,
        loading : false,
        fullPage : true,
        size: '45px',
        order : 'level',
        sort: 'desc',
        s_date: date_month,
        e_date: date_today,
        ord_no : '',
        showModal : false,
        showRModal : false,
        r_type : '',
        isAutoShip : false,
        list_msg : "検索ボタンを押して照会可能です。",
        delifinish_str : {'배송완료' : '配送完了', '일부배송' : '一部配送'}
    }),
    computed : {

    },
    props:['level','search_id','sid_name','diff','down_name','reg_type','gpstatus','end_date', 'page_type'],
    watch : {
        page: function(){
            // $("#submit").click();
          this.submitForm();
        }
    },
    created : function (){
        this.submitForm();
    },
    methods : {
        submitForm : function (page = ''){
            if(page) {
              this.page = parseInt(page);
            }
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getOrderCRList',{
                s_date : this.s_date,
                e_date : this.e_date,
                page   : this.page,
                page_size : this.page_size,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
              if(response.data.list.data.length != 0){
                //this.orderList = response.data.list.data;
                // this.total_count = response.data.total_count;
                // this.total_page = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
                /** [교환 반품 취소가 가능한 리스트만 노출(r_type 있는 애들만)]
                * 1. 교환 반품 : 모두 가능
                * 2. 취소 : 카드, 포인트만 가능
                */
                this.orderList = [];
                response.data.list.data.forEach((value, index) => {
                  if(this.page_type == 'orderCancel') {
                      if(value.r_type == 'C' && (value.money_kind == '3' || value.money_kind == '9')) {
                        this.orderList.push(value);
                      }
                  }
                  if(this.page_type == 'orderRefund') {
                      if(value.r_type == 'R' || value.r_type == 'R2') {
                        this.orderList.push(value);
                      }
                  }
                });
                this.total_count = this.orderList.length;
                this.total_page  = parseInt(this.total_count/this.page_size)+parseInt(this.total_count%this.page_size > 0 ? 1 : 0);
                this.loading     = false;
              }else{
                this.total_count = response.data.total_count;
                VueSimpleAlert.alert("検索結果がありません","","",{confirmButtonText:"確認"});
                this.list_msg = "データがありません。";
                // this.sid_name = "";
              }
              this.loading = false;
            });
        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        updatePageHandler(page){
            //this.page = page;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        reSortArray(id){
            this.order = id;
            this.sort = this.sort == "asc" ? "desc" : "asc";
            $("#submit").click();

        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        ViewModal(ord_no,r_type,kind_cd){
            this.ord_no = ord_no;
            this.r_type = r_type;

            this.isAutoShip = (kind_cd === '6') ? true : false;
            if(r_type == "C"){
                this.showModal = true;
            }
            if(r_type == "R" || r_type == "R2"){
                this.showRModal = true;
            }
        },
        isNotConsumer(r_type) { // 소비자면 false : 소비자 삭제 불가
            if(window.Laravel.rank_cd === "00") {
              return false;
            }
          return !(!r_type);
        },
      formatPrice(value,digit) {
        let val = (value/1).toFixed(digit).replace('.', '.')
        // return parseFloat(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

    }
}

$(document).ready( function () {
});
</script>
