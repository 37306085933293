<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.Order.depositcheck }}</dt>
        <dd>
            <vSelect :options="list" :reduce="value => value.id" v-bind="rcpt_yns" @option:selected="set_rcpt_yns"  ></vSelect>
        </dd>
    </dl>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "transactionStatus",
    components : {
        vSelect
    },
    data: () => ({
        i18n: window.i18n,
        rcpt_yns: "",
        list : [
            { "id": "", "label": i18n.myoffice.all },
            { "id": "Y", "label": i18n.myoffice.Order.depositcomplete },
            { "id": "N", "label": "未決済" },
            { "id": "C", "label": i18n.myoffice.CancleComplete }]
    }),
    props : {
        rcpt_yns : ""
    },
    watch : {
        rcpt_yns(rcpt_yns){
            console.log(rcpt_yns);
            this.set_rcpt_yns(rcpt_yns);
        }
    },
    methods : {
        set_rcpt_yns(rcpt_yns){
            this.$emit("set_rcpt_yns",rcpt_yns.id);
        }
    }
}
</script>

<style scoped>

</style>