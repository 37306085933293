<template>
    <div class="modal" v-if="open_modal">
        <div class="overlay" @click="open_modal = false"></div>
        <div class="modal-harf-card2">
            <popup @set_modal_flag="set_modal_flag" />
            <div class="popup-btn" v-if="list_y">
                <button  v-on:click="set_close_cookie" class="popup-close-btn1 bg-gray-100 border-r text-[#172e59]">{{ i18n.shop.popup.noOpen }}</button> <!--하루동안 안보기-->
                <button v-on:click=" close " class="popup-close-btn2 popup-close-btn bg-gray-500 text-white">{{ i18n.shop.popup.close }}</button> <!--닫기-->
            </div>
        </div>
    </div>
</template>

<script>

import popup from './popup';
import VueCookies from "vue-cookies";

export default {
    name: "modalViewPopup",
    props : ['harf'],
    data: () => ({
        open_modal :true,
        i18n: window.i18n,
        list_y: false,

    }),
    components : {
        popup,
        VueCookies
    },
    methods : {
        set_close_cookie(){
            $cookies.set("popup_timer", "1","1d");
            this.open_modal = false;

        },
        close(){
            this.open_modal = false;
        },
        set_modal_flag(open_modal){
            console.log(open_modal);
            this.list_y = open_modal;
            this.open_modal = open_modal;//3
        },
    }
}
</script>

<style >
/* Modal */
.modal,
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.overlay {
  opacity: 0.8;
  background-color: black;
}
.modal-card {
  position: relative;
  max-width: 90%;
  max-height: 650px;
  width: 100%;
  padding: 20px;
  background-color: white;
  z-index: 999;
  opacity: 1;
  overflow-y: auto;
  border-radius: 20px;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.modal-harf-card {
  /*position: relative;*/
  max-width: 530px;
  /*min-width: 320px;*/
  width:100%;
  /* margin: auto;*/
  /* margin-top: 30px;*/
  padding: 20px;
  background-color: white;
  /* min-height: 600px;*/
  z-index: 999;
  opacity: 1;
  overflow-y: auto;
  /* max-height: 800px;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-harf-card2 {
  position: relative;
  max-width: 530px;
  /*min-width: 320px;*/
  width:100%;
  /* margin: auto;*/
  /* margin-top: 30px;*/
  padding: 20px;
  /* min-height: 600px;*/
  z-index: 999;
  opacity: 1;
  overflow-y: auto;
  /* max-height: 800px;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-btn{display: table; table-layout: fixed; width:100%; max-width:500px; margin-top:-4px; margin:0 auto;/* background:rgb(243,244,246);*/}
.popup-btn button{ display:inline-block; width:50%; padding:15px 0; font-size:1.0em;}
.carousel__pagination{align-items:center;}
.slick-play-btn2{position:absolute; bottom:90px; right:10px; display:none;}
.carousel__prev, .carousel__next{background-color:#9ca4ac; box-sizing: content-box; border: 2px solid white; opacity: 0.6;}
.carousel__pagination-button{background-color:rgb(243,244,246); opacity: 0.6;}
.carousel__pagination-button--active{background-color:rgb(243,244,246);}



@media all and (max-width:500px) {
  .popup-btn button{ font-size:0.8em;}
}
</style>
