<template>
  <section>
    <img class="img-banner" src="/images/dir_img2.jpg" alt="direct banner">
  </section>
</template>

<script>
export default {
  name: 'direct_banner',
};
</script>

<style scoped>
.img-banner {
  width: 100%;
  height: auto;
  min-height:190px;
}
</style>
