<template>
  <div class="w-full p-1 justify-between ">
    <div class="text-right"><span @click="close_halloffame_modal"  class="cursor-pointer font-semibold text-gray-800"><svg xmlns="http://www.w3.org/2000/svg" class="xl:w-7 lg:w-7 w-6 xl:h-7 lg:h-7 h-6 inline-block  fill-[#929292]" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg></span></div>
    <div class="text-center w-full mt-5">
      <img v-bind:src="'http://cdnus.gcoop.me/' + img_url_selected" alt="GC hall of fame" class="object-cover w-full" />
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "HallofFameModal",
    components : {
        // address_book,
        vSelect,
    },
    props:['img_url_selected'],
    data: () => ({

    }),
    methods:{
      close_halloffame_modal(){
            this.$emit("close_halloffame_modal");
        },

    }
}
</script>

<style scoped>

</style>
