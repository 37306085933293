<template>
  <div class="xl:min-h-[600px]">
    <div class="main-content">
      <div  v-for="(v, k) in this.dataList" class="mb-20 border-l-8 border-[#EDD185] pt-6 pb-5 pl-5 pr-5 bg-black rounded-md">
          <div class="mb-5">
            <h2 class="mb-2.5 xl:text-4xl lg:text-4xl text-3xl font-semibold text-white">{{k}}</h2>
            <h3 class="flex items-center">
              <span class=""><svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-[#EDD185]" height="1em" viewBox="0 0 640 512"><path d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"/></svg></span>
              <span class="text-[#dddad2] xl:text-base lg:text-base text-sm ml-1">Hall of Fame {{k.replace(/ /gi, "")}}</span>
            </h3>
          </div>
          <div class="grid gap-2 mt-3 xl:grid-cols-6 lg:grid-cols-6 grid-cols-3">
            <div v-for="(v2,k2) in v" class="border border-[#a5915d] text-[#EDD185] bg-[#161103] hover:bg-gradient-to-r from-[#f3cf62] to-[#d7a81a] focus:bg-gradient-to-r from-[#f3cf62] to-[#d7a81a] transition duration-300 ease-in-out px-3 py-3 xl:text-lg lg:text-lg text-base rounded-md text-center cursor-pointer animate_date">
              <div @click="list_url(k.replace(/ /gi, '')+((this.monthName.indexOf(v2)+1).toString().length < 2 ? '0' + (this.monthName.indexOf(v2)+1) : (this.monthName.indexOf(v2)+1)))">
                <span>{{ v2 }}</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import dateForm from "../../searchForm/dateForm";
import memberForm from "../../searchForm/memberForm";
import stepForm from "../../searchForm/stepForm";
import divisionForm from "../../searchForm/divisionForm";
import countryForm from "../../searchForm/countryForm";
import orderClassification from "../../searchForm/orderClassification";
import rankForm from "../../searchForm/rankForm";
import ordeType from "../../searchForm/ordeType";
import transactionStatus from "../../searchForm/transactionStatus";
import VPagination from "@hennge/vue3-pagination";
import Loading from "vue3-loading-overlay";
import datepicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "../../modalView.vue";
import Swal from "sweetalert2";
import {Tooltip} from "floating-vue";

export default {
  name: "HallofFame",
  components : {
    vSelect,
    modalView,
    Loading,
    VTooltip : Tooltip
  },
  props: [],
  watch: {

  },
  data: () => ({
    i18n: window.i18n,
    exec_mode : "list",
    yearOnlyList : [],
    dataList : [],
    yearMonth : '202404',
    monthName : ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec',],
  }),
  created() {
    this.loading = true;
    this.getHallOfFame();
  },
  mounted() {

  },
  methods:{
    getHallOfFame(){
      axios.post(process.env.MIX_BACK_URL+'/api/getHallOfFame',{
        bz_cd: window.Laravel.bz_cd,
        exec_mode: this.exec_mode,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.dataList = response.data;
        })
        .catch(e => {
        });

    },
    list_url(yearMonth){
      location.href="/HallofFameView/"+yearMonth;
     },
  }
}
</script>

<style scoped>
  .animate_date {
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
  }
  .animate_date:hover, .animate_date:focus{
  }
  .animate_date span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  .animate_date span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
    /*color:#534110;*/
    color:#fff;
  }
  .animate_date:hover span {
    padding-right: 25px;
    color:#534110;
    /*color:#fff;*/
  }
  .animate_date:hover span:after {
    opacity: 1;
    right: 0;
    color:#534110;
    /*color:#fff;*/
  }
  /*.bgbg{
    background-image: linear-gradient(to right, #f3cf62, #d7a81a);
  }*/

  </style>
