<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <div>
        <div align="center" style="margin:10px 0;">
            <span class="btn"><input type="button" onclick="history.back();" :value="i18n.myoffice.Golist"></span>
        </div>
        <div id="order_left">
            <div class="edit-info">
                <h1 class="edit-info-caption">{{ i18n.myoffice.Order.orderInformation }}</h1>
                <ul>
                    <li>
                        <div class="edit-info-subject">{{ i18n.myoffice.Order.ordno }}</div>
                        <div class="edit-info-content">
                            <strong>{{ row.ord_no }}</strong>
                            <span v-if="row.ord_kind2">[{{row.ord_kind2}}]</span>
                            <span v-html="row.acc_dates"></span>
                        </div>
                    </li>
                    <li v-if="bp_row.ord_no">
                        <div class="edit-info-subject">{{ i18n.myoffice.Order.basicOrderno }}</div><!-- 원(原) 구매번호 -->
                        <div class="edit-info-content"><strong onclick="view_ord_info('{{ bp_row.ord_no }}');">{{ bp_row.ord_no }}</strong> &nbsp;
                            <div v-if="bp_row.rcpt_yn == 'Y'">
                                {{ i18n.myoffice.Order.Paymentdate }} : {{ row.acc_date }}
                            </div>
                            <div v-else>
                                {{ i18n.myoffice.Order.undeposit }}
                            </div>
                        </div> <!-- 결제일 --><!-- 미결제 입니다. -->
                    </li>
                    <li>
                        <div class="edit-info-subject">{{ i18n.myoffice.Order.type }}</div>
                        <div class="edit-info-content">{{ row.kind_cd == "1" ? "Order" : "Auto Order" }}</div>
                    </li>
                    <li>
                        <div class="edit-info-subject">{{ i18n.myoffice.Order.amount }}</div>
                        <div class="edit-info-content">
                          <span id="item_customer">{{ formatPrice(row.ord_amt,0) }}{{ i18n.myoffice.currency }}</span>
                          <p v-if="isDirect===false" class="inline">
                            ,&nbsp;<span id="item_bv">{{ formatPrice(row.ord_pv,1) }} PV</span>
                          </p>
                        </div> <!-- 원 몰수당 원 -->
                    </li>
                    <li>
                        <div class="edit-info-subject">{{ i18n.myoffice.Order.depositamount }}</div>
                        <div class="edit-info-content"><span id="item_customer">{{ formatPrice(row.rcpt_total,0) }}{{ i18n.myoffice.currency }}</span> ,&nbsp;&nbsp; [{{ row.rcpt_yn=='Y' ? (row.ord_kind == "0" ?  i18n.myoffice.CancleComplete : i18n.myoffice.paymentComplete ) : i18n.myoffice.Order.undeposit }}] </div><!-- 취소완료 : '결제완료 : 미입금 -->
                    </li>

                    <li>
                        <div class="edit-info-subject">{{ i18n.myoffice.Order.invoicenumber }}</div>
                        <div class="edit-info-content">
                            <div v-if="deli_mst.send_date">
                                {{ deli_mst.deli_no }}, &nbsp;<b> {{ deli_mst.deli_kinds }} &nbsp;</b>
                            </div>
                            <div v-else>
                                {{ i18n.myoffice.Order.UnRelease }}
                            </div>
<!--                                    @if(deli_mst.deli_kind == 1)-->
<!--                                        {{ i18n.myoffice.Order.Cdirectreceipt }}-->
<!--                                    @elseif(deli_mst.deli_kind == 3)-->
<!--                                        {{ i18n.myoffice.Order.FreightCollect }}-->
<!--                                    @else-->
<!--                                        {{ i18n.myoffice.Order.FreightPrepaid }}-->
<!--                                    @endif-->
                        </div><!--출고일:  미출고 / --><!-- 출고일 -->
                    </li>
                </ul>

                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table id="pdt-list" class="relative border-collapse	border border-slate-400 overflow-x-auto shadow-md sm:rounded-lg" cellspacing="0" width="100%">
                        <thead>
                        <tr>
                            <th class="px-6 py-3 whitespace-nowrap border border-slate-300">No</th>
                            <th class="px-6 py-3 whitespace-nowrap border border-slate-300">{{ i18n.shop.autoshipse2.pdt_code }}</th>
                            <th class="px-6 py-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.pdtName }}</th>
                            <th class="px-6 py-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.qty }}</th>
                            <th class="px-6 py-3 whitespace-nowrap border border-slate-300">PV</th>
                            <th class="px-6 py-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.amount }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, index) in pdt_list">
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ index+1 }}</td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">
                                <a href="#" onclick="winBomList('reg_bom_list_'+value.pdt_cd, this);return false;">{{ value.pdt_cd }}</a>
                            </td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ value.pdt_name }}</td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ value.qty }}</td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">
                              <span v-if="isDirect">-</span>
                              <span v-else>{{ formatPrice(value.pv * value.qty,1) }}</span>
                            </td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ formatPrice(value.amt * value.qty,0) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div v-if="row.ord_kind != 0 && row.kind_cd != 7 && row.rcpt_yn == 'Y' && isOwnDelivery" class="info_alert_div" style="margin-top: 15px">
                <span class='btn info'><input type='button' v-on:click="popup_invoice(row.ord_no);" :value="i18n.myoffice.Order.purchaseform"></span><!-- 상품구매계약서 -->
            </div>

        </div>

        <div id="order_right">
            <div class="edit-info">
                <h1 class="edit-info-caption">{{ i18n.myoffice.Order.PaymentDetails }}</h1>
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table id="rcpt-list" class="relative border-collapse	border border-slate-400 overflow-x-auto shadow-md sm:rounded-lg" cellspacing="0" width="100%">
                        <thead>
                        <tr>
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">No</th>
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.PaymentType }}</th><!-- 결제유형 -->
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">回数</th><!-- 결제유형 -->
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.Approvalnumber }}</th><!-- 승인번호 -->
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.amount }}</th><!-- 총금액 -->
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.dashboard.date }}</th><!-- 날짜 -->
                            <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.remarks }}</th><!-- 비고 -->
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value,index) in rcpt_list">
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ index+1 }}</td>
                            <!-- 지페스타 상품은 전표버튼 노출안되게 수정 -->
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ value.kinds }}<a v-show="value.url" class="!text-red-500" :href="value.url" >(払込票)</a></td><!-- 전표 -->
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ value.card_inst }}</td><!-- 전표 -->
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ value.card_app_no }}</td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-right">{{ formatPrice(value.amt,0) }}</td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300 text-center">{{ value.reg_date }}</td>
                            <td class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ value.remark }} <span v-if="row.ord_kind == '0'" class="text-red-600">( {{ i18n.myoffice.Order.Cancel }} )</span></td><!-- [취소] -->
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="row.kind_Cd !=7 && isOwnDelivery" class="logis edit-info" id="logis_right">
            <h1 class="edit-info-caption">{{ i18n.myoffice.Order.shippinginformation }}</h1>
            <ul>
                <li>
                    <div class="edit-info-subject">{{ i18n.myoffice.Order.Deliverymethod }}</div>
                    <div class="edit-info-content doc-link-box">
                       {{ ord_deli.deli_kinds }}
                    </div>
                </li>
                <li>
                    <div class="edit-info-subject">{{ i18n.myoffice.Address }}</div>
                    <div class="edit-info-content">{{ ord_deli.r_addr1 }} {{ ord_deli.r_addr2 }}</div>
                </li>
                <li>
                    <div class="edit-info-subject">{{ i18n.myoffice.Order.Recipient }}</div>
                    <div class="edit-info-content">{{ ord_deli.r_name }}&nbsp;</div>
                </li>
                <li>
                    <div class="edit-info-subject">{{ i18n.myoffice.Order.Contact }}</div>
                    <div class="edit-info-content">{{ ord_deli.r_mobile }}&nbsp;</div>
                </li>
                <li>
                    <div class="edit-info-subject">{{ i18n.myoffice.Order.Shippingnotes  }}</div><!-- 배송메모 -->
                    <div class="edit-info-content">{{ ord_deli.r_memo }}&nbsp;</div>
                </li>
            </ul>
        </div>

        <div v-for="( value,index ) in deli_mst"  v-if="row.kind_Cd !=7 && isOwnDelivery" class="logis edit-info" id="logis_right">
            <ul>
                <li>
                    <div class="edit-info-subject">{{ i18n.myoffice.Order.Issuenumber }}</div>
                    <div class="edit-info-content doc-link-box">
                        <b>{{ value.deli_no }}</b>&nbsp;
                        <span v-if="value.send_date == '' || value.send_date == null">{{ i18n.myoffice.Order.UnRelease }}</span>
                        <span v-if="value.i_list"> {{ i18n.myoffice.Order.shippingnumber }}:
                            <span class="ml-1 separator" v-for="( ivalue,iindex ) in value.i_list" style="color:#09C; font-weight: bold;">
                              {{ ivalue }}
                            </span>
                            <span class="btn info ml-1">
                                  <a href="#" v-on:click='viewTraking(ivalue)'>{{ i18n.myoffice.Order.traking }}</a>
                              </span>
                        </span>
                    </div>
                </li>
            </ul>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table  id="deli_pdt-list-{{value.deli_no}}" class="relative border-collapse	border border-slate-400 overflow-x-auto shadow-md sm:rounded-lg" cellspacing="0" width="100%">
                    <thead>
                    <tr>
                        <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">No</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">PID</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.pdtName}}</th><!-- 제품명 -->
                        <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.qty}}</th><!-- 수량 -->
                        <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">PV</th>
                        <th class="px-6 py-3 w-3 whitespace-nowrap border border-slate-300">{{ i18n.myoffice.Order.amount}}</th><!-- 총금액 -->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="( pdt_val,pdt_index ) in value.deli_pdt_list">
                        <td class="px-6 py-3 w-3 whitespace-nowrap text-center border border-slate-300">{{ pdt_index+1 }}</td>
                        <td class="px-6 py-3 w-3 whitespace-nowrap text-center border border-slate-300">{{ pdt_val.pdt_cd }}</td>
                        <td class="px-6 py-3 w-3 whitespace-nowrap text-left border border-slate-300">{{ pdt_val.pdt_name }}</td>
                        <td class="px-6 py-3 w-3 whitespace-nowrap text-right border border-slate-300">{{ pdt_val.qty }}</td>
                        <td class="px-6 py-3 w-3 whitespace-nowrap text-right border border-slate-300">{{ formatPrice(pdt_val.pv * pdt_val.qty,1) }}</td>
                        <td class="px-6 py-3 w-3 whitespace-nowrap text-right border border-slate-300">{{ formatPrice(pdt_val.amt * pdt_val.qty,0) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="clear:both;"></div>
        <br><br>
        <div align="center" style="margin:10px 0;">
            <span class="btn"><input type="button" onclick="history.back();" :value="i18n.myoffice.Golist"></span><!-- 목록으로 -->
        </div>
    </div>
    <modalView v-if="showModal" @close-modal="showModal = false" :harf="false">
        <invoice :deli_no="deli_no" ref="invoice"></invoice>
    </modalView>

</template>

<script>
import modalView from "../../modalView.vue";
import invoice from "../../invoice/invoice";

export default {
    name: "MyOrderView",
    components : {
        modalView,
        invoice
    },
    props: ['no'],
    data: () => ({
      i18n : window.i18n,
        isDirect : false,
        row : "",
        bp_row : "",
        deli_mst : "",
        rcpt_list : "",
        isOwnDelivery : false,
        mst : "",
        pdt_list : "",
        ord_deli : "",
        loading : false,
        fullPage : true,
        showModal: false,
        deli_no : "",
    }),
    created : function (){
        this.submitForm();
    },
    methods : {
        submitForm: function (event) {
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getMyOrderView', {
                ord_no: this.no
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
                this.loading = false;
                this.row = response.data.row;
                this.isOwnDelivery = response.data.isOwnDelivery;
                this.pdt_list = response.data.pdt_list;
                this.ord_deli = response.data.ord_deli;
                this.rcpt_list = response.data.rcpt_list;
                this.deli_mst = response.data.deli_mst;


                this.isDirect = this.row.isDirect;
            });

        },
        popup_invoice(ord_no) {
            window.open(window.Laravel.back_url+"/api/getInvoice?ordno="+ord_no);
        },
        viewTraking(invoice_no){
            //this.deli_no = deli_no;
            //this.showModal = true;
            // window.open("https://www.trackingmore.com/taqbin-jp-tracking/ja.html?number="+invoice_no);
          window.open("https://toi.kuronekoyamato.co.jp/cgi-bin/tneko");
        },
        formatPrice(value,digit) {

            if ( value === undefined){
              return "-";
            }
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

    }

}
</script>

<style scoped>
  .separator:not(:first-child)::before { content: " / "; }
</style>
