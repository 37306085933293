<template>


  <!-- 공지사항 -->
  <div class="flex flex-row rounded-md overflow-hidden w-full mb-6 shadow-zinc-500/40 shadow-md items-center bg-[#272c33] xl:px-5 lg:px-5 px-2 py-2 xl:scrollbar-hide lg:scrollbar-hide">
    <div class="rounded-full xl:p-2 lg:p-2 p-1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-4 h-4 fill-white"><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
    </div>
    <ul class="xl:px-3 lg:px-3 p-1 py-1 w-full overflow-hidden">
      <li class="px-1 py-1 hover:bg-[#dfe3ea]/50 cursor-pointer">
          <a v-bind:href="notice_no" class="w-full text-md block text-white truncate overflow-hidden">
            {{ board_title }}
          </a>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
    name: "NoticeTitle",
    data: () => ({
        title : "",
        dataCnt : 0,
        chkCnt : 0,
        dataArr : 0,
        notice_no : "",
        board_title : ""
    }),
    beforeCreate() {
      axios.post(window.Laravel.back_url+'/api/noticeTopTitle',{
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        this.dataCnt = response.data.length;
        this.dataArr = response.data;
        setInterval(this.dataRolling,2300);
      });

    },
    mounted(){


    },
    methods:{
      dataRolling(){
        if(this.chkCnt == (this.dataCnt - 1)){
          this.chkCnt = 0;
        }else{
          this.chkCnt += 1;
        }
        if(this.dataArr[this.chkCnt]) {
          this.notice_no   = this.dataArr[this.chkCnt].url;
          this.board_title = this.dataArr[this.chkCnt].title;
        }

      },

    },
}
</script>

<style scoped>

</style>
