<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
    <address_search @set_address="set_address" @close_address_search="close_address_search" :nt_cd="this.nt_cd"/>
  </modalView>
  <!-- S : 은행및 지점 코드 입력 -->
  <modalView class="z-50" v-if="bank_search_open" @close-modal="bank_search_open = false"   >
    <bank_search @set_bank="set_bank" @close_bank_search="close_bank_search" :bank_type="this.bank_type" :bank_cd="this.member.bankCd" />
  </modalView>
  <!-- E : 은행및 지점 코드 입력 -->
  <section class="container mx-auto lg:py-6 md:p-0">
    <div class="bg-white">
      <div class="max-w-8xl mx-auto py-4 xl:px-6 lg:px-6 px-4 lg:max-w-7xl bg-white">
        <div class="w-full lg:flex lg:justify-start">
          <div class="w-full">
            <div class="max-w-8xl mx-auto lg:max-w-7xl lg:pl-8 lg:pr-0">
              <div class="w-full pb-10">
                <div class="flex justify-start item-start flex-col">
                  <!-- form start -->
                  <div class="w-full bg-white lg:p-2" id="infomodify" >
                    <form name="register_form" id="register_form">
                      <div class="py-2">
                        <div class="flex justify-between text-lg mb-4">
                          <h2 class="xl:text-2xl lg:text-xl text-lg mt-3 mb-6 font-semibold">メンバー仮登録</h2>
                          <div class="flex justify-end">
                            <MinusSmIcon class="h-6 w-6" aria-hidden="true" />
                            <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                          </div>
                        </div>
                        <p class="w-fil text-right text-sm"><span class="text-red-500">*</span> 必須入力</p>
                        <!--START : 국가선택-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>国籍</div>
                          <div class="w-full lg:w-5/6 flex flex-row flex-wrap	 items-center gap-4">
                            <div class="flex items-center cursor-pointer">
                              <input type="radio" value="A" id="japan" v-model="member.foreinger_chk"><label for="japan" class="cursor-pointer">&nbsp;日本国籍</label>
                            </div>
                            <div class="flex items-center cursor-pointer">
                              <input type="radio" value="F" id="foreign" v-model="member.foreinger_chk"><label for="foreign" class="cursor-pointer">&nbsp;外国籍</label>
                            </div>
                            <div class="w-fil text-sm text-red-500">在留カードをお持ちの方は、外国を選択してください。</div>
                          </div>
                        </div>
                        <!--END : 국가선택-->
                        <!--END : 외국일 때 - 재류자격-->
                        <div v-if="member.foreinger_chk === 'F'" class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>在留資格</div>
                          <div class="w-full lg:w-5/6">
                            <select name="jp_cd_name" id="jp_cd_name" class="xl:w-60 lg:w-60 w-full text-sm md:text-base" v-model="member.jp_cd_name">
                              <option value="" selected>在留資格</option>
                              <option v-for="(item, index) in jp_cd_name_list" :key="item" :value="index">{{item}}</option>
                            </select>
                          </div>
                        </div>
                        <!--END : 외국일 때 - 재류자격-->
                        <!--START : 이름-->
                        <div v-if="member.foreinger_chk === 'A'" class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>会員名(漢字)</div>
                          <div class="w-full lg:w-5/6 flex-wrap flex-col gap-2">
                            <input type='text' id="lastName" v-model="member.lastName" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="姓" @input="funcUserName">
                            <input type='text' id="firstName" v-model="member.firstName" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="名" @input="funcUserName">
                            <div class="w-fil text-sm text-red-500">外国籍の方：在留カードに記載されている本名を入力してください。</div>
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>会員名(ローマ字)</div>
                          <div class="w-full lg:w-5/6 flex-wrap flex-col gap-2gap-2">
                            <input type='text' id="lastRomaName" v-model="member.lastRomaName"  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="Surname(姓)" @input="funcUserName">
                            <input type='text' id="firstRomaName" v-model="member.firstRomaName"  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="Given name(名)" @input="funcUserName">
                            <div class="w-fil text-sm text-red-500">在留カードに書いているお名前を入力してください。</div>
                          </div>
                        </div>
                        <!-- S : 일본인일 때 -->
                        <div v-if="member.foreinger_chk === 'A'" class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>会員名(カタカナ)</div>
                          <div class="w-full lg:w-5/6 flex-wrap flex-col gap-2">
                            <input type='text' id="lastHuriName" v-model="member.lastHuriName" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="セイ" @input="funcUserName">
                            <input type='text' id="firstHuriName" v-model="member.firstHuriName" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="メイ" @input="funcUserName">
                            <div class="w-fil text-sm text-red-500"></div>
                          </div>
                        </div>
                        <!-- E : 일본인일 때 -->
                        <!-- S : 외국일 때 -->
                        <div v-if="member.foreinger_chk === 'F'" class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>会員名(カタカナ)</div>
                          <div class="w-full lg:w-5/6 flex-wrap flex-col gap-2">
                            <input type='text' id="lastKataName" v-model="member.lastKataName" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="セイ" @input="funcUserName">
                            <input type='text' id="firstKataName" v-model="member.firstKataName" maxlength="40" class="h-10 w-full xl:w-60 lg:w-60 mr-2" placeholder="メイ" @input="funcUserName">
                            <div class="w-fil text-sm text-red-500">外国籍の方：通称名で通帳をお作りの方は通称名を入力してください。</div>
                          </div>
                        </div>
                        <!-- E : 외국일 때 -->
                        <!--END : 이름-->
                        <!--START : 성별-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>性別</div>
                          <div class="w-full lg:w-5/6 flex flex-row items-center gap-4">
                            <div class="flex items-center cursor-pointer">
                              <input type="radio" value="M" id="man" v-model="member.selectSex" checked><label for="man" class="cursor-pointer">&nbsp;男</label>
                            </div>
                            <div class="flex items-center cursor-pointer">
                              <input type="radio"  value="F" id="woman" v-model="member.selectSex"><label for="woman" class="cursor-pointer">&nbsp;女</label>
                            </div>
                          </div>
                        </div>
                        <!--END : 국가선택-->
                        <!--START : 생년월일-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>生年月日</div>
                          <div class="w-full lg:w-5/6">
                            <div class="w-full lg:w-5/6 flex gap-2">
                              <select name="strtYearSch" id="YearSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model="strtYearSch">
                                <option value="" selected>年</option>
                                <option v-for="(item, index) in years" :key="item" :value="item">{{item}}</option>
                              </select>
                              <select name="strtMonthSch" id="MonthSch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model="strtMonthSch">
                                <option value="" selected>月</option>
                                <option v-for="(item, index) in months" :key="item" :value="item">{{ item }} </option>
                              </select>
                              <select name="strtDaySch" id="DaySch" class="xl:w-40 lg:w-40 w-full text-sm md:text-base" v-model="strtDaySch">
                                <option value="" selected>日</option>
                                <option v-for="(item, index) in days" :key="item" :value="item">{{item}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!--END : 생년월일-->
                        <!--START : 비밀번호-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>パスワード</div>
                          <div class="w-full lg:w-5/6">
                            <div class="flex xl:flex-row lg:flex-row flex-col">
                              <input v-if="!show" @blur="checkPassword" name="passwd" id="passwd" v-model="member.passwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3" style="ime-mode:disabled;" type="password" @input="funcUserName">
                              <input v-if="show" @blur="checkPassword" name="passwd" id="passwd"  v-model="member.passwd" class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  style="ime-mode:disabled;" type="text"  @input="funcUserName">
                              <input type="button" v-if='!show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="見る"/>
                              <input type="button" v-if='show' @click="showPwd('show')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="隠す"/>
                            </div>
                            <div class="text-red-500 text-sm" id="passMsg">英数字を混ぜて8文字以上で入力してください。(大文字と小文字は区分します)</div>
                          </div>
                        </div>

                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-3">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>パスワード確認</div>
                          <div class="w-full lg:w-5/6">
                            <div class="flex xl:flex-row lg:flex-row flex-col">
                              <input v-if="!show_confirm" @blur="checkPassword" name="confirmPasswd" id="confirmPasswd"  class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="member.confirmPasswd" style="ime-mode:disabled;" type="password"  @input="funcUserName">
                              <input v-if="show_confirm" @blur="checkPassword" name="confirmPasswd" id="confirmPasswd"   class="type_pwd h-10 w-full xl:w-60 lg:w-60 pr-3"  v-model="member.confirmPasswd" style="ime-mode:disabled;" type="text"  @input="funcUserName">
                              <input type="button" v-if='!show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="見る"/>
                              <input type="button" v-if='show_confirm' @click="showPwd('show_confirm')"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 ml-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="隠す"/>
                            </div>
                            <div id="passConfirmMsg" class="oran text-red-700 text-sm"></div>
                          </div>
                        </div>
                        <!--END : 비밀번호-->

                        <!-- START : 은행정보 -->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">金融機関コード</div>
                          <div class="w-full lg:w-5/6">
                            <input class="type_number h-10 xl:w-60 lg:w-60 w-full"  type="text" v-model="member.bankCodeTxt" @click="search_bank('bank')" readonly>
                            <input type="button" @click="goLink('http://zengin.ajtw.net')" class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="銀行コード検索"/>
                            <div class="text-red-500 text-sm">銀行と支店コードを検索してから入力してください。</div>
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">支店コード</div>
                          <div class="w-full lg:w-5/6">
                            <input class="type_number h-10 xl:w-60 lg:w-60 w-full" type="text" v-model="member.bankBranchCodeTxt" @click="search_bank('branch')" readonly>
                            <div class="text-red-500 text-sm">銀行と支店コードを検索してから入力してください。</div>
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">口座番号</div>
                          <div class="w-full lg:w-5/6">
                            <input name="account" class="type_number h-10 xl:w-60 lg:w-60 w-full" type="text" v-model="member.account" maxlength="7">
                          </div>
                        </div>
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>口座名義</div>
                          <div class="w-full lg:w-5/6">
                            <input name="depositor" class="type_number h-10 xl:w-60 lg:w-60 w-full" type="text" v-model="member.depositor" readonly>
                          </div>
                        </div>
                        <!-- END : 은행정보 -->

                        <!--START : 휴대전화-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>携帯電話</div>
                            <div class="w-full lg:w-5/6">
                              <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                                <div class="flex flex-row gap-2">
                                  <input type="text" name="mobile1" v-model="member.mobile1" :readonly="is_mobile_chk == true" :class="[is_mobile_chk ? 'disabled' : '']" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                  <input type="text" name="mobile2" v-model="member.mobile2" :readonly="is_mobile_chk == true" :class="[is_mobile_chk ? 'disabled' : '']" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                  <input type="text" name="mobile3" v-model="member.mobile3" :readonly="is_mobile_chk == true" :class="[is_mobile_chk ? 'disabled' : '']" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                </div>
  <!--                              <input type="button" v-if='sendBtn' @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0 btn_mobileChk" value="送信"/>-->
                                <input type="button" v-if='duplChkBtn' @click="checkMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="確認"/>
  <!--                              <input type="button" v-if='ressetMblChkBtn' @click="resetMobile"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 lg:mt-0 xl:mt-0" value="初期化"/>-->
                              </div>
                              <div class="flex flex-col">
  <!--                              <div class="flex xl:flex-row lg:flex-row flex-col gap-2">-->
  <!--                                <input type="text" v-if="smsChkBtn" v-model="mobile_sms_chk"   maxlength="6" class="type_number h-10 w-full xl:w-60 lg:w-60 mt-4"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">-->
  <!--                                <input type="button" v-if="smsChkBtn" @click="checkConfirmMobile"  class="mt-4 h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-4 btn_mobileChk" value="確認"/>-->
  <!--                              </div>-->
                                <div id="mobileMsg" class="oran text-red-500 text-sm">{{this.mobileMsg}}</div>
  <!--                            <div v-if="smsChkBtn" class="border-0 text-red-500 h-10 w-20 xl:w-30 lg:w-30">{{this.TimerStr}}</div>-->
                            </div>
                          </div>
                        </div>
                        <!--END : 휴대전화-->
                        <!--START : 집전화-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">電話番号</div>
                          <div class="w-full lg:w-5/6">
                            <div class="flex xl:flex-row lg:flex-row flex-col gap-2">
                              <div class="flex flex-row gap-2">
                                <input type="text" name="tel1" v-model="member.tel1" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                <input type="text" name="tel2" v-model="member.tel2" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                <input type="text" name="tel3" v-model="member.tel3" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--END : 집전화-->
                        <!--START : 팩스 번호-->
<!--                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">-->
<!--                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2">ファックス番号</div>-->
<!--                          <div class="w-full lg:w-5/6">-->
<!--                            <div class="flex xl:flex-row lg:flex-row flex-col gap-2">-->
<!--                              <div class="flex flex-row gap-2">-->
<!--                                <input type="text" name="fax1" v-model="member.fax1" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">-->
<!--                                <input type="text" name="fax2" v-model="member.fax2" maxlength="3" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">-->
<!--                                <input type="text" name="fax3" v-model="member.fax3" maxlength="4" class="type_number h-10 w-1/3 xl:w-40 lg:w-40"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
                        <!--END : 팩스 번호-->
                        <!--START : 이메일-->
                        <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                          <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>メールアドレス</div>
                          <div class="w-full lg:w-5/6 ">
                            <div class="flex xl:flex-row lg:flex-row flex-col">
                                <div class="flex flex-row items-center gap-2">
                                  <input name="email1" id="email1" class="type_email h-10 w-full xl:w-60 lg:w-60"  v-model="member.email1" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true" :class="[is_email_chk ? 'disabled' : '']" placeholder="メールアドレス">
                                  @
                                  <input name="email2" id="email2" class="type_email h-10 w-full xl:w-60 lg:w-60"  v-model="member.email2" style="ime-mode:disabled;" type="text" :readonly="is_email_chk == true" :class="[is_email_chk ? 'disabled' : '']" placeholder="ex)google.com">
                                </div>
                                <input type="button" v-if="emailChkBtn" @click="checkEmail"  class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="確認"/>
                            </div>
                            <div id="emailMsg" class="oran text-red-500 text-sm">{{this.emailMsg}}</div>
                          </div>
                        </div>
                        <!--END : 이메일-->

                          </div>

                          <!--START : 주소-->
                          <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>郵便番号</div>
                            <div class="w-full lg:w-5/6">
                              <input name="post" id="post" class="type_number h-10 xl:w-60 lg:w-60 w-full" @click="search_address" v-model="member.post" maxlength='5' type="text" readonly>
                              <input type="button" @click="search_address"  class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="検索"/>
                              <input type="button" @click="goLink('https://www.post.japanpost.jp/zipcode')"  class="h-10 xl:w-36 lg:w-36 w-full leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="郵便番号を調べる"/>
                            </div>
                          </div>
                          <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>ご住所</div>
                            <div class="w-full lg:w-5/6">
                              <input type='text' name="addr1" id="addr1" v-model='member.addr1' @click="search_address" class="h-10 xl:w-9/12 lg:w-9/12 w-full" readonly>
                            </div>
                          </div>
                          <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>詳細住所</div>
                            <div class="w-full lg:w-5/6">
                              <input name="addr2" id="addr2" class=" add-input1 h-10 xl:w-9/12 lg:w-9/12 w-full" v-model="member.addr2" type="text"> <br>
                              <div class="text-red-500 text-sm">必ず屋号、部屋番号まで全て入力してください。</div>
                            </div>
                          </div>
                          <!--END : 주소-->

                          <!--START : 추천인-->
                          <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:pt-2 lg:items-center mb-4 pb-4">
                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>推薦者会員番号</div>
                            <input type='text' name="recommUserId" id="recommUserId" v-model='member.recommUserId' readonly  maxlength="40" class="h-10 w-full xl:w-60 lg:w-60">
                          </div>
                          <!--END : 추천인-->
                          <!--START : 후원인-->
<!--                          <div class="w-full bg-white lg:p-2" id="optionInfo" >-->
<!--                            <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">-->
<!--                              <div class="w-full lg:w-1/6 text-base font-semibold mb-2">後援者会員番号</div>-->
<!--                              <input type='text' name="parentUserId" id="parentUserId"   maxlength="10"  class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.replace(/[^a-z0-9]/gi,'');">-->
<!--                              <input type="button" class="h-10 w-full xl:w-20 lg:w-20 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 cursor-pointer mt-2 xl:ml-2 lg:mt-0 xl:mt-0" value="検索">-->
<!--                            </div>-->
<!--                          </div>-->
                          <!--END : 후원인-->
                          <!--START : 아카데미-->
<!--                          <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4 pb-4">-->
<!--                            <div class="w-full lg:w-1/6 text-base font-semibold mb-2"><span class="text-red-500">*</span>所属アカデミー</div>-->
<!--                            <div class="w-full lg:w-5/6">-->
<!--                              <div class="w-full lg:w-5/6 flex gap-2">-->
<!--                                <select name="selectCenter" class="xl:w-60 lg:w-60 w-full text-sm md:text-base" v-model='member.selectCenter'>-->
<!--                                  <option v-for="(item, index) in academy" :key="item" :value="item.id">{{ item.value }}</option>-->
<!--                                </select>-->
<!--                              </div>-->
<!--                              <div class="text-red-500 text-sm">-->
<!--                                <p>1)アカデミーの変更は1回のみ可能です。</p>-->
<!--                                <p>2)所属するアカデミーがない場合は、東京支社を選択してください。(アカデミーについては、推薦者にご確認ください。)</p>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
                          <!--END : 아카데미-->
                          <div class="w-full bg-white lg:p-2" id="optionInfo" >
                            <!--START : 기존 아이디-->
                            <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                              <div class="w-full lg:w-1/6 text-base font-semibold mb-2">既存のメンバーID</div>
                              <input type='text' name="origin_user_id" id="origin_user_id" v-model='member.origin_user_id' class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.trim();">
                            </div>
                            <!--END : 기존 아이디-->
                            <!--START : 기존 아이디-->
                            <div class="w-full flex flex-col lg:flex-row justify-start lg:space-x-4 lg:py-2 lg:items-center mb-4">
                              <div class="w-full lg:w-1/6 text-base font-semibold mb-2">既存の連絡先</div>
                              <input type='text' name="origin_user_mobile" id="origin_user_mobile" v-model='member.origin_user_mobile' class="h-10 w-full xl:w-60 lg:w-60" oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
                            </div>
                            <!--END : 기존 아이디-->
                          </div>
                      </form>
                    </div>
                  <!-- form end -->
                    <div class="flex gap-2">
                      <button type="button" v-on:click="send_agree" class="my-2 py-2 px-4 leading-6 bg-[#d5e7f6] hover:bg-[#cbcfdd] transition duration-200 text-[#2c7fc3] xl:w-16 lg:w-16 w-1/2 reset_btn rounded-md">前のページに戻る</button>
                      <button type="button" v-on:click="submitForm" v-if="submitFormBtn"  class="my-2 py-2 px-4 leading-6 text-white bg-[#172e59] hover:bg-[#0e1b35] transition duration-200 xl:w-16 lg:w-16 w-1/2 search_btn rounded-md">確認</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block lg:hidden xl:hidden mt-4">
          <SideMenu></SideMenu>
        </div>
      </div>

  </section>
</template>

<script>

import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {  ShoppingCartIcon} from '@heroicons/vue/outline'
import MypageTop from './mypage/MypageTop.vue';
import SideMenu from './mypage/SideMenu.vue';
import Loading from 'vue3-loading-overlay';
import alert from "vue3-simple-alert";
import {createPopper} from "@popperjs/core";
import modalView from "./modalView";
import vSelect from "vue-select";
import dayjs from "dayjs";

export default {
  name: "MyRegisterMember",
  components : {
    VueSimpleAlert,
    ShoppingCartIcon,
    MypageTop,
    SideMenu,
    Loading,
    modalView,
    vSelect
  },
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,

    no_search_messsage: '',
    isLoggedIn: false,
    loading : false,
    mem: "",
    myconfig:"",

    userid : "",
    /* 파라미터 변수 선언 */
    member : [],
    jp_cd_name_list : { 1 : '永住者', 3 : '定住者', 4 : '日本人の配偶者等', 5 : '永住者の配偶者等', 6 : '芸術', 7 : '宗教', 8 : '報道', 9 : '法律会計業務', 10 : '医療',
                        11 : '研究', 12 : '技術', 13 : '人文知識', 14 : '国際業務', 15 : '企業内転勤', 16 : '家族滞在'},  // 재류자격 셀렉트박스
    strtDaySch:"",
    strtMonthSch:"",
    strtYearSch:"",
    mobile_sms_chk : "", //sns 코드
    TimeCounter : 300, //sms 인증 제한 초
    TimerStr : "",//초 -> 분 형식변경

    recommUserId : "",
    recommUserName : "",
    origin_user_id : "",
    origin_user_mobile : "",

    rankCd : "01",
    memberKind : "1",

    academy : [], /* 아카데미 셀렉트박스 */

    bank_search_open : false,
    bank_type        : "",

    /* 유효성 검사 변수 */
    is_pwd_chk : false,
    /*
      2023-02-14
      MYOFFICE 에서 ITIM / SSN 을 별도로 받기로 하여 유효성검사 is_jumin_chk 강제 true 변경함
    */
    is_jumin_chk : true,
    is_email_chk : false,
    is_mobile_chk : false,
    is_age_chk : false,

    /* 버튼 Show hide 변수*/
    smsChkBtn : false,
    sendBtn : false,

    duplChkBtn : true,
    ressetMblChkBtn : false,

    emailChkBtn : true,
    submitFormBtn : true,

    selectCity : "",
    fullPage : true,
    state_list : "",
    city_list : "",
    street_list : "",
    address_search_open:false,
    isMatchStateCity:false,

    /* 기본 선언 변수 */
    days:['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    years:[],
    months:[],

    is_login: true,

    /*문구*/
    juminMsg : "",
    emailMsg : "",
    mobileMsg : "",

    show : false,
    show_confirm : false,
  }),
  created() {
    let year = new Date().getFullYear();
    for(let i=(year-18); i>=(year-100); i--){
      this.years.push(
        i
      );
    }
    for(let m = 1; m <= 12; m++){ /* 생년월일의 월 셀렉트 박스 */
      if(m.toString().length < 2) {
        this.months.push('0' + m);
      } else {
        this.months.push('' + m);
      }
    }
    this.getAcademy();

    const inputValues = [ 'foreinger_chk', 'lastName', 'firstName', 'lastRomaName', 'firstRomaName', 'lastHuriName',
      'firstHuriName', 'strtYearSch', 'strtMonthSch',
      'strtDaySch', 'passwd', 'confirmPasswd', 'bankCodeTxt',
      'bankBranchCodeTxt', 'account', 'depositor', 'mobile1', 'mobile2',
      'mobile3', 'tel1', 'tel2', 'tel3', 'fax1', 'fax2', 'fax3', 'selectSex',
      'email1', 'email2', 'post', 'addr1', 'addr2', 'recommUserId', 'jp_cd_name', 'loginId',
      'bankCd', 'bankName', 'bankKataCode', 'bankHiraCode', 'bankBranchCode', 'bankBranchNameCode', 'bankBranchKataCode', 'bankBranchHiraCode'];
    for(const el of inputValues) {
      if (typeof this.member[el[0]] === "undefined") {
        switch (el) {
          case 'foreinger_chk':
            this.member.foreinger_chk = 'A';
            break;
          case 'selectSex':
            this.member.selectSex = 'M';
            break;
          // case 'selectCenter':
          //   this.member.selectCenter = '0401';
          //   break;
          default:
            this.member[el] = "";
            break;
        }
      }
    }
  },
  watch : {
    //생년월일 체크
    strtDaySch : function(){
      this.chkAge(this.strtYearSch, this.strtMonthSch, this.strtDaySch);
    },
    strtMonthSch : function(){
      this.chkAge(this.strtYearSch, this.strtMonthSch, this.strtDaySch);
    },
    strtYearSch : function(){
      this.chkAge(this.strtYearSch, this.strtMonthSch, this.strtDaySch);
    },
    //연도 데이터 호출
    s_month : function(){
      var total_day = dayjs(this.s_year+this.s_month).daysInMonth();
      var day = 1;
      for(day ; day <= total_day ; day++){
        this.days.push(
          {value: day}
        );
      }
    },
  },
  mounted() {
    if (window.Laravel.registerRid) {
      this.isLoggedIn           = true;
      this.member.loginId        = window.Laravel.user;
      this.member.recommUserId   = window.Laravel.registerRid;
      this.member.recommUserName = window.Laravel.registerRname;
    }else{
      location.href='/';
    }
  },
  methods:{
    showPwd : function(select){
      if(select == 'show'){
        this.show = !this.show;
      }else if(select == 'show_confirm'){
        this.show_confirm = !this.show_confirm;
      }

    },
    send_agree : function (){//agree 페이지로 이동
      location.href= './Register';
    },
    validationCheck : function () {
       /* 필수 변수 널체크 */
      /* S : 공통 체크*/
      const inputValueList = [
            ['foreinger_chk', '国籍'], ['selectSex', '性別'], ['passwd', 'パスワード'], ['confirmPasswd', 'パスワード確認'],
            ['depositor', '口座名義'], ['mobile1', '携帯電話'], ['mobile2', '携帯電話'], ['mobile3', '携帯電話'], ['email1', 'メールアドレス'], ['email2', 'メールアドレス'],
            ['post', '郵便番号'], ['addr1', 'ご住所'], ['addr2', '詳細住所'], ['recommUserId', '推薦者会員番号']
          ];
      for(const el of inputValueList) {
          if(!this.member[el[0]]) {
            VueSimpleAlert.alert(el[1] + "を入力してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return false;
          }
      }
      if(!this.member.firstRomaName || !this.member.lastRomaName) { // 로마자를 입력해주세요
        VueSimpleAlert.alert("名前（ローマ字）を入力してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
        return false;
      }
      /* E : 공통 체크*/
      /* S : 일본인 체크*/
      if(this.member.foreinger_chk === "A") { // 일본인일 때만
          if(!this.member.firstName || !this.member.lastName) { // 한자이름을 입력해주세요
              VueSimpleAlert.alert("名前（漢字）を入力してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
              return false;
          }
          if(!this.member.firstHuriName || !this.member.lastHuriName) { // 가타카나를 입력해주세요
            VueSimpleAlert.alert("名前（カタカナ）を入力してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return false;
          }
      }
      /* E : 일본인 체크*/
      /* S : 외국인 체크*/
      if(this.member.foreinger_chk === "F") { // 외국인일 때만
          if(!this.member.firstKataName || !this.member.lastKataName) { // 가타카나를 입력해주세요
            VueSimpleAlert.alert("名前（カタカナ）を入力してください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return false;
          }
          if(!this.member.jp_cd_name) { // 체류 자격을 선택하십시오
            VueSimpleAlert.alert("滞留資格をお選びください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return false;
          }
      }
      /* E : 외국인 체크*/
      /* S : 중복확인 완료했는지 체크 */
      if(!this.is_email_chk) { // 이메일 중복 확인을 해주세요.
        VueSimpleAlert.alert("メールの重複確認をしてください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
        return false;
      }
      if(!this.is_mobile_chk) { // 휴대전화 중복 확인을 해주세요.
        VueSimpleAlert.alert("携帯電話番号の重複確認をしてください。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
        return false;
      }
      /* E : 중복확인 완료했는지 체크 */
      if(!this.is_age_chk) { // 미성년자 체크
        VueSimpleAlert.alert("満18歳以上から入会できます。", "", "",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {})
        return false;
      }
      return true;
    },
    submitForm: function (event) {
          this.loading =true;

          if(!this.validationCheck()) { /* 필수 입력값 체크 및 유효성 체크 */
            this.loading = false;
            return;
          }

          this.submitFormBtn = false;
          // 입력하신 정보로 회원가입이 진행됩니다.
          VueSimpleAlert.confirm("入力した情報で会員入会します。", "", "", {allowOutsideClick : false,confirmButtonText:"確認", cancelButtonText:"キャンセル"}).then((result) => {
              this.member.rankCd     = this.rankCd;
              this.member.memberKind = this.memberKind;
              let formData           = new FormData();

              Object.entries(this.member).forEach(([key, value]) => {
                formData.append(key, value);
              });
              formData.append('selectCenter', '0401');
              formData.append('agreeMail', 'Y'); // 강제로 체크 rec_mail
              formData.append('agreeSms', 'Y'); // 강제로 체크 rec_sms

              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + window.Laravel.access_token
                }
              }
                /* S : 저장 */
              axios.post(window.Laravel.back_url+'/api/insertSaveMemberUs',
                  formData
                  , config).then(response => {
                  if(response.data.status == "1"){
                      VueSimpleAlert.alert("会員登録が完了しました。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => { //회원가입이 완료 되었습니다.
                        if(result){
                          location.replace("/RegisterOk?userid="+response.data.SP_KEYVALUE+"&xid="+response.data.xid);
                        }
                      });
                  } else if (response.data.result != "1") {
                      this.loading         = false;
                      this.is_email_chk    = false;
                      this.is_mobile_chk   = false;
                      this.submitFormBtn   = true;
                      this.emailChkBtn     = true;
                      this.duplChkBtn      = true;
                      this.emailMsg        = '';
                      this.mobileMsg       = '';
                      VueSimpleAlert.alert(response.data.message, "", "", {allowOutsideClick: false,confirmButtonText:"確認"}).then((result) => {})
                  }
              });
             /* E : 저장 */
          }).catch(error => {
            this.loading         = false;
            this.is_email_chk    = false;
            this.is_mobile_chk   = false;
            this.submitFormBtn   = true;
            this.emailChkBtn     = true;
            this.duplChkBtn      = true;
            this.emailMsg        = '';
            this.mobileMsg       = '';
          })// confirm end

      },

    checkEmail(){
      this.loading = true;
      //이메일 미입력시
      if(this.member.email1.length < 1 || this.member.email2.length < 1) {
        // this.emailMsg = i18n.join.twalert.emailId;
        this.emailMsg = "メールアドレスを入力してください。"; // 이메일 주소를 입력하세요.
        $("#email_1").focus();
        this.loading = false;
        return;
      }else{
        this.emailMsg = "";
      }
      //이메일 유효성체크
      this.email = this.member.email1.trim() + '@' + this.member.email2.trim();

      let regex =/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/;
      if(regex.test(this.email) == false){
        // this.emailMsg = i18n.join.twalert.emailId2;
        this.emailMsg = "メール形式に合わない。"; // 이메일 형식에 맞지 않습니다.
        this.loading  = false;
        return false;
      }else{
        this.emailMsg = "";
      }

      axios.post(window.Laravel.back_url+'/api/getComparisonEmail', {
        email : this.email,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if(response.data.chk.checker == "N"){
          //중복 이메일 존재
          // this.emailMsg = i18n.join.join.alert_emailchk1;/* 이미 등록된 이메일 주소입니다. */
            document.getElementById('emailMsg').style.color = 'red';
            this.emailMsg     = "既に登録済みのメールアドレスです。";
            this.is_email_chk = false;
        }else{
          //중복 이메일 없음
          // this.emailMsg = i18n.join.join.alert_emailchk4;/* 사용 가능한 이메일 주소입니다. */
            document.getElementById('emailMsg').style.color = 'skyblue';
            this.emailMsg     = "利用可能なメールアドレスです。";
            VueSimpleAlert.alert(this.emailMsg,"","",{allowOutsideClick : false}).then((result) => {})
            this.is_email_chk = true;
            this.emailChkBtn  = false; // 이메일 버튼 hide
        }
        this.loading = false;


      });
    },

    resetMobile(){
      this.loading = true;
      this.member.mobile1 = "";
      this.member.mobile2 = "";
      this.member.mobile3 = "";

      this.is_mobile_chk   = false;
      this.duplChkBtn      = true;
      this.ressetMblChkBtn = false;

      this.loading = false;
    },

    checkMobile(){
      this.loading        = true;
      this.mobile_sms_chk = "";
      //this.smsChkBtn = true;
      this.mobileMsg      = "";

      //모바일 미입력시
      if(this.member.mobile1.length < 3 || this.member.mobile2.length < 1 || this.member.mobile3.length < 1  ) {
        // this.mobileMsg = i18n.join.twalert.enPhone;
        this.mobileMsg = "携帯電話を入力してください。"; // 휴대전화를 입력하세요.
        this.loading   = false;
        return;
      }

      //모바일 유효성 검사(중복)
      axios.post(window.Laravel.back_url+'/api/getComparisonMobile', {
        mobile : this.member.mobile1+"-"+this.member.mobile2+"-"+this.member.mobile3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
        if (response.data.chk.checker == "0") { // 사용 가능한 휴대전화번호입니다.
          this.mobileMsg = "利用可能な携帯電話番号です。";
          document.getElementById('mobileMsg').style.color = 'skyblue';
          VueSimpleAlert.alert(this.mobileMsg,"","",{allowOutsideClick : false}).then((result) => {})
          //문자인증 비활성화 20230425 최치원 팀장 요청
          /*this.timerStart();//인증문자 제한시간 시작
          //모바일 인증코드 발송
          axios.post(process.env.MIX_BACK_URL+'/api/sendRegisterAproval', {
            nt_cd : this.nt_cd,
            mobile : this.mobile1+"-"+this.mobile2+"-"+this.mobile3,
          }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
          });*/
          this.is_mobile_chk   = true;
          this.duplChkBtn      = false;
          this.ressetMblChkBtn = true;
        } else {
          this.is_mobile_chk   = false;
          // this.mobileMsg       = i18n.join.myalert.notAvailPhoneNotice1; /!* 사용할 수 없는 핸드폰 번호입니다. *!/
          document.getElementById('mobileMsg').style.color = 'red';
          this.mobileMsg       = "既に使用中の 携帯電話番号です。"; // 이미 사용중인 휴대 전화 번호입니다.
        }
        this.loading = false;
      });
    },
    //문자 확인코드
    checkConfirmMobile(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/sendRegisterAprovalCode', {
        code : this.mobile_sms_chk,
        nt_cd : this.nt_cd,
        mobile : this.mobile1+"-"+this.mobile2+"-"+this.mobile3,
      }, {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

          if(response.data == 1 ){//성공
            this.mobileMsg = "Confirmed";

            this.is_mobile_chk = true;
            this.sendBtn =false;
            this.smsChkBtn = false;
          }else if(response.data == 1){
            this.mobileMsg = "Code is not available. Please retry again";
            this.is_mobile_chk = false;
            this.smsChkBtn = true;
          }else{
            this.mobileMsg = "Please retry again";
            this.is_mobile_chk = false;
            this.smsChkBtn = true;
          }
        this.loading = false;
      });

    },
    /* START : SMS 인증 시간 함수*/
    timerStart: function() {
      // SMS 인증 1초에 한번씩 start 호출
      this.TimeCounter = 300;
      var interval = setInterval(() => {
        if(this.TimeCounter > 0){
          this.TimeCounter--; //1초씩 감소
          this.TimerStr = this.prettyTime();
          if (this.TimeCounter <= 0) this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },
    prettyTime: function() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        secondes.toString().padStart(2, "0")
      );
    },
    timerStop: function(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
      if(!this.is_mobile_chk){
        this.mobileMsg = "Code has expired";
      }
      this.smsChkBtn = false;
    },
    /* END : SMS 인증 시간 함수*/
    //비밀번호, 비밀번호 확인 동일 확인
    checkPassword : function(e){
      //비밀번호 8자리 미만
      if(this.member.passwd.length < 8) {
        //$("#passConfirmMsg").text(i18n.join.twalert.passchk3);//영문 및 숫자를 조합하여, 8자리로 입력 해 주시기 바랍니다
        $("#passMsg").text("英文と数字を組み合わせて、8桁で入力してください。");
        this.is_pwd_chk = false;
        return;
      } else {
        $("#passMsg").text("");
      }

      if(this.member.confirmPasswd.length < 8) {
        $("#passConfirmMsg").text("英文と数字を組み合わせて、8桁で入力してください。");
        this.is_pwd_chk = false;
        return;
      } else {
        $("#passConfirmMsg").text("");
      }

      //비밀번호 확인 미입력시
      if(this.member.passwd.length < 1 || this.member.confirmPasswd.length < 1) {
        // $("#passConfirmMsg").text(i18n.join.twalert.password1);//영문 및 숫자를 조합하여, 8자리로 입력 해 주시기 바랍니다
        $("#passConfirmMsg").text("英文と数字を組み合わせて、8桁で入力してください。");
        this.is_pwd_chk = false;
        return;
      }

      //비밀번호, 비밀번호 확인 일치여부
      if(this.member.passwd != this.member.confirmPasswd) { // 비밀번호 확인이 일치하지 않습니다.
        // $("#passMsg").text(i18n.join.twalert.password2);
        $("#passConfirmMsg").text("パスワードの確認が一致しません。");
        this.is_pwd_chk = false;
        return;
      }else{ // 비밀번호가 일치합니다 통과
        $("#passConfirmMsg").text("");
        this.is_pwd_chk = true;
      }

    },

    chkJumin(){
      this.loading = true;
      axios.post(window.Laravel.back_url+'/api/chkjumin', {
        jumin_no : this.jumin_1+this.jumin_2+this.jumin_3,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        if( response.data == 0 ){ // 가입가능
            this.is_jumin_chk = true;
            this.juminMsg = "Available";
        }else {
          this.is_jumin_chk = false;
          this.juminMsg = i18n.join.join.ssn_notice;
        }
        this.loading = false;
      });

    },
    chkAge(year,mon,day){
        this.member.strtYearSch  = year;
        this.member.strtMonthSch = mon;
        this.member.strtDaySch   = day;
        this.is_age_chk          = false;

        // 미국 미성년자 기준은 만 18세
        // 기준확인을 하기 위한 참고한 사이트 : http://m.blog.naver.com/woongjooseok/220711033579

        if(year == "" || mon == "" || day == ""){
          return;
        }
        //비교를위해 숫자타입으로 변경.
        year = Number(year);
        mon  = Number(mon);
        day  = Number(day);

        console.log(year, mon, day);

        //성년기준 나이
        let cut = 18;

        //체크용 날짜형식
        let set = new Date();
        let setYear = Number(set.getFullYear());
        let setMon  = Number((set.getMonth() + 1));
        let setDay  = Number(set.getDate());

        // 현재나이
        let age = (setYear-1) - year;

        // 자신의 생일이 지난 만18세가 되면 허용
        if(age >= cut){
          this.is_age_chk = true;
          return;
        }
        // 월 체크
        if(setMon > mon){
          this.is_age_chk = true;
          return;
        }
        // 일 체크
        if(setDay > day){
          this.is_age_chk = true;
        }

    },
    /* S : 아카데미(center) 가져오기 */
    getAcademy() {
        this.loading = true;
        axios.post(window.Laravel.back_url+'/api/getCenter', {
          bz_cd : window.Laravel.bz_cd,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
          response.data.forEach((value, index) => {
              this.academy.push({
                id    : value.cnt_cd,
                value : value.cnt_name
              });
          });
          this.loading = false;
        });
    },
    /* E : 아카데미(center) 가져오기 */
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    // 숫자 포멧
    formatPrice(value,digit) {
      let val = (value/1).toFixed(digit).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    // 숫자 포멧
    funcNumber(type,val) {
      let value = val;
      // 숫자만 남김
      var pattern_kor = /[^0-9]/gi;

      if(type =='jumin_1'){
        this.jumin_1 = val.replace(pattern_kor, "");
      }else if(type =='jumin_2'){
        this.jumin_2 = val.replace(pattern_kor, "");
      }else if(type =='jumin_3'){
        this.jumin_3 = val.replace(pattern_kor, "");
      }

    },
    /* S : 주소검색 */
    search_address(){
      this.address_search_open = true;
    },
    set_address(value){
      this.address_search_open = false;
      this.member.post         = value.postcode;
      this.member.addr1        = value.address + " " + value.address2 + " " + value.address3;
      this.member.city         = value.address;
    },
    close_address_search(){
      this.address_search_open = false;
    },
    /* E : 주소검색 */
    /* S : 은행검색 */
    search_bank(bank_type){
      if(bank_type === 'branch' && !this.member.bankCd) { // 금융 기관 코드를 먼저 입력해주세요.
          VueSimpleAlert.alert("金融機関コードを最初に入力してください。","","",{allowOutsideClick : false}).then((result) => {})
          return;
      }
      this.bank_type        = bank_type;
      this.bank_search_open = true;
    },
    set_bank(value){
      this.close_bank_search();
      console.log(value);
      if(value.bank_type === 'bank') {
        this.member.bankCodeTxt       = value.code + " " + value.name + "(" + value.kana + ")";
        this.member.bankCd            = value.code ?? '';
        this.member.bankName          = value.name ?? '';
        this.member.bankKataCode      = value.kana ?? '';
        this.member.bankHiraCode      = value.hira ?? '';
      }
      if(value.bank_type === 'branch') {
        this.member.bankBranchCodeTxt  = value.code + " " + value.name + "(" + value.kana + ")";
        this.member.bankBranchCode     = value.code ?? '';
        this.member.bankBranchNameCode = value.name ?? '';
        this.member.bankBranchKataCode = value.kana ?? '';
        this.member.bankBranchHiraCode = value.hira ?? '';
      }
    },
    close_bank_search(){
      this.bank_search_open = false;
    },
    /* E : 은행검색 */
    /* 이름 특수문자 자동 삭제 */
    funcUserName(e) {
      var tmp         = e.target.value;
      var regExp      = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;

      if(regExp.test(tmp)){
        var t = tmp.replace(regExp, "");
        tmp   = t;
        this.member[e.target.id] = tmp;
      }
      // 会員名(カタカナ) 해당하는 이름은 추가 체크
      if(['firstKataName', 'lastKataName', 'firstHuriName', 'lastHuriName'].indexOf(e.target.id) != -1) {
        this.onlyJapaneseName(e, 'askh');
        /* S : 예금주 입력란 채우기 */
        if(this.member.foreinger_chk === "A") { // 일본인 예금주
          this.member.depositor = this.member.lastHuriName + " " + this.member.firstHuriName;
        } else { // 외국인 예금주
          this.member.depositor = this.member.lastKataName + " " + this.member.firstKataName;
        }
        /* E : 예금주 입력란 채우기 */
      }
    },
    onlyJapaneseName(e, option) { /* 전각/반각 처리 */
      var tmp         = e.target.value;
      // var japaneseExp = /[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z0-9]/gi;
      // if(japaneseExp.test(tmp)){
      //   var t = tmp.replace(japaneseExp, "");
      //   tmp   = t;
      //   this.member[e.target.id] = tmp;
      // }
        axios.post(window.Laravel.back_url+'/api/convert_kana', {
          val    : tmp,
          option : option
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.member[e.target.id] = tmp;
        });
    },
    goLink(url) {
      window.open(url);
    },

  },

}


</script>

<style >
.v-select{ min-width: 100px; z-index:0 }
.m_menu{display:none;}
.disabled { background-color: #e0e0de; }
</style>
