<template>
    <div class="rounded-xl mb-6 shadow-zinc-500/40 shadow-md p-5 xl:w-2/6 lg:w-3/6 w-full bg-[#172e59] text-white">
        <div class="py-3 flex flex-row justify-between">
            <h3 class="font-semibold text-white text-lg mb-4">
                Consumer Sales Status <!--옴니트리션 달성현황-->
                <br style=""/>
                <!--                                    <div class="text-sm font-normal">({{ cs_omni_list.ord_mon }})</div>-->
                <span class="text-sm font-normal">
<!--                                        <span> &lt;&lt; </span>-->
                                        <span>{{ cs_omni_list.ord_mon }}</span>
<!--                                        <span> &gt;&gt; </span>-->
                                    </span>
            </h3>
            <a href="/MyOmnitrition" class="text-white" v-if="false">View More</a>
        </div>
        <div>
            <div class="flex xl:flex-row lg:flex-row flex-col border border-gray-500 w-full p-3 bg-white mb-2 " v-if="cs_omni_list.ord_mon">
                <div class="xl:w-2/3 lg:w-2/3 w-full flex-center" >
                    <!-- 도넛 그래프 추가 -->
                    <apexcharts type="radialBar" :options="chartOptions" :series="series"></apexcharts>
                </div>
                <div class="flex xl:flex-col lg:flex-col flex-row xl:w-1/3 lg:w-1/3 w-full justify-around xl:mt-0 lg:mt-0 mt-5 xl:border-l lg:border-l border-l-0">
                    <div class="flex flex-col xl:w-full lg:w-full w-1/2 xl:border-0 lg:border-0 border-r">
                        <div class="text-[#8899ae] xl:text-lg lg:text-lg text-sm text-center py-1">Order<br>Amount</div>
                        <div class="text-[#2e384d] xl:text-xl lg:text-xl text-lg text-center font-semibold py-1">$ {{ formatPrice(cs_omni_list.r_ord_amt,2) }}</div>
                    </div>
                    <div class="flex flex-col xl:w-full lg:w-full w-1/2">
                        <div class="text-[#8899ae] xl:text-lg lg:text-lg text-sm text-center py-1">Consumer Sales<br>Amount</div>
                        <div class="text-[#2e384d] xl:text-xl lg:text-xl text-lg text-center font-semibold py-1">$ {{ formatPrice(cs_omni_list.cs_amt,2) }}</div>
                    </div>
                </div>
            </div>
            <div>
                * Directions for Consumer Sales Registration can be found by:
                Customer Service Center > Library > How to Register Consumer Sales
            </div>
        </div>
    </div>
</template>

<script>

import apexcharts from "vue3-apexcharts";
export default {
    name: "ConsumerSalesStatus",
    props: ['cs_omni_list', 'series'],
    components : {
        apexcharts,

    },
    data: () => ({
        chartOptions: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '70%',
                    }
                },
            },
            labels: ['Achievement Rate'],
        },
    }),
    created() {
    },
    mounted() {
    },
    methods:{
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
}
</script>

<style scoped>

</style>
