<template>
    <direct_auth v-if="isDirect===true"></direct_auth>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <modalView class="z-50" v-if="weachat_open" @close-modal="weachat_open = false" :harf="true" :request="request" :MIX_BACK_URL="MIX_BACK_URL">
        <iframe id="resultIframe" name="resultIframe"></iframe>
    </modalView>
    <modalTooltip class="z-10" v-if="showModal" @close-modal="showModal = false" :harf="true" :request="request" :MIX_BACK_URL="MIX_BACK_URL">
            <div>
                <div class="w-full flex justify-end"><button  @click="showModal = false">X</button></div>
                <ul class="text-xs">
                    <li>■ ポイントとは？：本人の購入(一般購入・定期購入)PVの5％をポイントとして支給されます。1ポイント＝100円で計算され、使用可能ポイントと保留ポイントには5％分のポイント×100をした数字が表記されています。</li>
                    <li>■ 適用対象：全てのジクッパー(コンシューマー会員含む)</li>
                    <li>■ 積立の条件</li>
                    <li class="ml-4">- 本人の購入(一般購入・定期購入) PVの5％が積立されます。</li>
                    <li class="ml-4">- ポイントは、購入日当日は保留ポイントとして反映され、翌日に使用可能ポイントに反映されます。</li>
                    <li>■ ポイントの使用方法</li>
                    <li class="ml-4">- 使用可能ポイントに表示されているポイントは、1ポイント=1円としてご使用頂けます。</li>
                    <li class="ml-4">- 2,000ポイント以上の積立でご使用いただけます。</li>
                    <li class="ml-4">- ポイント使用後に残ったポイントが2,000ポイント未満の場合はご使用いただけません。</li>
                    <li class="ml-4">- 一般購入にのみご使用頂けます。(定期購入の使用は不可)</li>
                    <li class="ml-4">- 送料もポイントのご使用が可能です。</li>
                    <li class="ml-4">- ポイントとカードの併用決済が可能です。 (複数のカードとポイントの併用は不可)</li>
                    <li class="ml-4">- ポイントを使用して決済する場合は、ポイントの一部使用はできません。保有しているポイントより決済金額が高い場合、差額はカード決済となります。</li>
                    <li class="ml-4">例）7,000ポイントが積立されている会員が、10,000円分の購入をする場合、7,000ポイントを全て使用し、残りの3,000円分はカード決済となります。</li>
                    <li class="ml-4">7,000ポイントのうち、5,000ポイントだけを使用することはできません。</li>
                    <li class="ml-4">- ポイントを使用してご購入された場合もポイントが支給されます。</li>
                    <li class="ml-4">- ポイント使用時は支給日の古い順に消費されます。</li>
                    <li class="ml-4">- ポイントの譲渡は出来ません。</li>
                    <li>■ ポイントの消滅</li>
                    <li class="ml-4">- 積立されたポイントには使用期限があります。</li>
                    <li class="ml-4">- 会員退会時、積立されたポイントは消滅します。</li>
                    <li class="ml-4">- 積立されたポイントは、積立日から24ヶ月経過すると自動消滅します。</li>
                    <li>■ 交換/返品</li>
                    <li class="ml-4">- ポイントで購入した後でも、ご注文日当日中であればご注文のキャンセルが可能です。(キャンセルする場合、ポイントは復元されます。)</li>
                    <li class="ml-4">- ポイントでご購入いただいた製品も返品が可能です。</li>
                    <li class="ml-4">ただし、返品時の際は通常の返品時と同様に購入金額の10％と往復送料が発生します。返品処理後にポイントが復元されます。</li>
                    <li class="ml-4">【ポイントのみを使用して購入した製品の返品】</li>
                    <li class="ml-4">[手数料+送料]を指定口座に入金 → 返品処理 → 使用ポイント復元</li>
                    <li class="ml-4">【ポイント+カードを使用して購入した製品の返品】</li>
                    <li class="ml-4">[手数料+送料]をカード決済 → 返品処理 → 使用ポイント復元</li>
                    <li class="ml-4">- ポイントでご購入いただいた製品の部分返品は出来ません。</li>
                    <li class="ml-4">- ポイントでご購入いただいた製品の交換は出来ません。全ての製品を一度返品されてから、再度ご購入ください。</li>
                    <li class="ml-4">(ただし、返品に掛かる事務手数料として購入金額の10％と往復送料が発生します。)</li>
                    <li>■ 送料</li>
                    <li class="ml-4">- 通常の一般購入と同一の条件となります。</li>
                    <li>■ ポイントの積立及び使用内訳の表示</li>
                    <li class="ml-4">- 積立されたポイントは、マイオフィスのメインページでご確認できます。</li>
                    <li class="ml-4">- 会員情報＞ポイント内訳でより詳細な情報をご確認いただけます。</li>
                </ul>
            </div>
    </modalTooltip>

    <section class="bg-[#f0f2f4]">
        <div class="container mx-auto py-6">
            <div class="max-w-8xl mx-auto px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="w-full">
                    <div class="flex justify-start item-start space-y-2 flex-col">
                        <h1 class="text-2xl  font-semibold leading-7 lg:leading-9 text-gray-800 mb-2 lg:mb-6">注文情報</h1>
                    </div>

                    <div class="w-full lg:flex lg:justify-start">

                        <div class="w-full lg:w-2/3 ">
                            <!-- 주문자 정보 -->
                            <div class="flex flex-col justify-start items-start w-full mb-6 shadow-zinc-500/40 shadow-md rounded-md xl:mt-0 lg:mt-0 mt-4">
                                <p class="text-lg xl:text-xl lg:text-xl  font-semibold leading-6 xl:leading-5 text-gray-800 flex items-center justify-between w-full cursor-pointer xl:px-6 lg:px-6 px-4 xl:py-6 lg:py-6 py-4 bg-white rounded-md" v-on:click="showMessage2 = !showMessage2">
                                    <span class="border-l-[10px] border-[#4493d5] pl-3 xl:pt-2 lg:pt-2 xl:pb-2 lg:pb-2">情報</span>
                                    <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path></svg>
                                </p>
                                <div class="w-full p-4 xl:grid lg:grid xl:grid-cols-2 lg:grid-cols-2 block bg-white rounded-bl-md rounded-br-md" v-if="showMessage2">
                                    <div class="flex justify-start items-start flex-col space-y-4 mt-2 border-b lg:border-0 xl:border-0 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">注文履歴</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">{{ order_info_list.o_name }} <br> {{ order_info_list.d_mobile }}</p>
                                    </div>
                                    <div class="flex justify-start items-start flex-col space-y-4 mt-2 border-b lg:border-0 xl:border-0 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">配送情報</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">
                                            {{ request["postcode"]}} {{ request["address2_1"]}} {{ request["address2_2"]}} </p>
                                    </div>
                                    <div class="flex justify-start items-start flex-col space-y-4 mt-2 border-b lg:border-0 xl:border-0 p-2" v-show="false">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">Billing Address</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">
                                            {{ order_info_list.o_name }}<br>{{ order_info_list.o_mobile }}<br>
                                            {{ order_info_list.o_post }} {{ order_info_list.o_addr2 }} {{ order_info_list.o_addr1 }}
                                        </p>
                                    </div>
                                    <div v-show="false" class="flex justify-start items-start flex-col space-y-4 mt-2 p-2">
                                        <p class="text-base  font-semibold leading-4 text-left text-[#2c7fc3] lg:text-lg">Contact Help</p>
                                        <p class="w-full dark:text-gray-300 text-left text-sm leading-5 text-[#0e293e]">info.us@gcoop.com</p>
                                    </div>
                                </div>
                            </div>
                            <!-- 제품 리스트 -->
                            <div class="flex flex-col justify-start items-start shadow-zinc-500/40 shadow-md rounded-md">
                                <div class="flex flex-col justify-start items-start w-full rounded-md">
                                    <p class="text-lg xl:text-xl lg:text-xl  font-semibold leading-6 xl:leading-5 text-gray-800 flex items-center justify-between w-full cursor-pointer xl:px-6 lg:px-6 px-4 xl:py-6 lg:py-6 py-4 bg-white rounded-md" v-on:click="showMessage = !showMessage">
                                        <span class="border-l-[10px] border-[#4493d5] pl-3 xl:pt-2 lg:pt-2 xl:pb-2 lg:pb-2">注文製品 <span class="">({{ order_product_list.length }})</span></span>
                                        <svg class="w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </p>
                                    <div class="bg-white xl:px-4 lg:px-4 xl:py-4 lg:py-4 rounded-bl-md rounded-br-md w-full" v-if="showMessage">
                                        <div v-for="(product, i) in order_product_list" :key="product.seq_pd" class="flex w-full border-b border-dotted border-gray-300 mb-2 p-3 last:border-b-0">
                                            <div class="relative xl:w-1/5 lg:w-1/5 w-1/3">
                                                <div v-if="product.images.length > 0" class="aspect-w-1 aspect-h-1 rounded-md overflow-hidden aspect-square border">
                                                    <img class="w-full hidden md:block" v-bind:src="'https://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename "  alt="{{ product.product.pdt_name }}" />
                                                    <img class="w-full md:hidden" v-bind:src="'https://cdn.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="{{ product.product.pdt_name }}" />
                                                    <div class="sold-out-box2" v-if="product.product.status == 2">
                                                        <img src="/images/badge/us/pc_temp_sold_out.png" alt="sold out" />
                                                    </div>
                                                    <div class="sold-out-box2" v-if="product.product.status == 3">
                                                        <img src="/images/badge/us/pc_sold_out.png" alt="sold out" />
                                                    </div>
                                                    <div class="coming_box" v-if="product.product.status == 4">
                                                        <img src="/images/badge/us/pc_coming_soon.png" alt="sold out" />
                                                    </div>
                                                    <div class="badge_box" v-if="product.product.badges.image_url">
                                                        <img v-bind:src="'https://cdn.gcoop.me/' + product.product.badges.image_url" alt="sold out" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-col flex justify-between items-start w-full pt-3 pb-2 space-y-4 md:space-y-0 pl-4">
                                                <div class="w-full flex flex-col justify-start items-start space-y-8 mb-2">
                                                    <h3 class=" xl:text-lg lg:text-lg text-md font-semibold leading-6 text-[#0e293e]">{{ product.product.pdt_name }}</h3>
                                                </div>
                                                <div class="text-red-500 text-md" v-if="product.product.deli_date2" v-html="product.product.deli_date2"></div>
                                                <div class=" w-full">
                                                    <p class="text-xs ">{{ formatPrice(product.price) }}円
                                                      <span v-if="isDirect === false">
                                                      / <span class="text-blue-500">PV {{ formatPrice(product.pv,2) }}</span>
                                                      </span>
                                                    </p>
                                                    <div class="flex justify-end flex-col">
                                                        <p class="xl:text-md lg:text-md  font-semibold text-gray-800 xl:text-right lg:text-right mb-1">数量 : {{ product.qty }}</p>
                                                        <p class="xl:text-md lg:text-md  font-semibold text-gray-800 xl:text-right lg:text-right">{{ formatPrice(product.amt * product.qty) }}円
                                                          <span v-if="isDirect === false">
                                                          / <span class="text-blue-500">PV {{ formatPrice(product.pv * product.qty,2) }} </span>
                                                          </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- // 제품 리스트 -->
                        </div>

                        <!-- payment 영역 -->
                        <div class="w-full lg:w-1/3 xl:mt-0 lg:mt-0 mt-8">
                            <!-- 결제유형 선택 -->
                            <div class="w-full" id="app">
                                <div class="flex justify-start w-full xl:px-4 lg:px-4 xl:pb-4 lg:pb-4 xl:mt-0 lg:mt-0 mt-4">
                                    <div class="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-6 w-full bg-white dark:bg-gray-800 space-y-2 shadow-zinc-500/40 shadow-md rounded-md">
                                        <h3 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">決済方法<br>
                                            <p class="text-[#2c7fc3] text-xs font-semibold">( 決済方法を選択してください )</p>
                                        </h3>
                                        <div v-if="isDirect===false">
                                          <div class="pt-1">マイポイント : {{ formatPrice(cur_point) }}</div>
                                          <div class="flex justify-start">
                                              <div class="cursor-pointer" @click="getTooltip()">
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                                  </svg>
                                              </div>
                                              <div class="pl-1">
                                                ポイントについて
                                              </div>
                                          </div>
                                        </div>
                                        <!-- 결제수단  -->
                                        <div class="w-full pay_box">
                                            <div class="w-full text-lg flex items-center" @click="key = 1">
                                                <input type="radio" id="paytype1" name="paytype" class="hidden" :checked="key == '1'">
                                                <label for="paytype1" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center w-full">
                                                <span class="flex">
                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-6"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M168 336C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360C96 346.7 106.7 336 120 336H168zM360 336C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360C224 346.7 234.7 336 248 336H360zM512 32C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H512zM512 80H64C55.16 80 48 87.16 48 96V128H528V96C528 87.16 520.8 80 512 80zM528 224H48V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V224z"/></svg>
                                                  &nbsp;Card
                                                </span>
                                                </label>
                                            </div>
                                            <div v-if="isDirect===false" class="grid grid-cols-2 gap-2 mt-2">
                                                <div class="w-full xl:text-base lg:text-base text-sm" @click="key = 2">
                                                    <input type="radio" id="paytype2" name="paytype" class="hidden">
                                                    <label for="paytype2" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                                        Pay-easy
                                                    </label>
                                                </div>
                                                <div class="w-full xl:text-base lg:text-base text-sm" @click="key = 3">
                                                    <input type="radio" id="paytype5" name="paytype" class="hidden">
                                                    <label for="paytype5" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                                        コンビニ決済
                                                    </label>
                                                </div>
                                            </div>
                                            <div v-if="isDirect===false" class="grid grid-cols-2 gap-2 mt-2">
                                                <div class="w-full xl:text-base lg:text-base text-sm hidden" @click="key = 6">
                                                    <input type="radio" id="paytype6" name="paytype" class="hidden">
                                                    <label for="paytype6" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                                        할부결제
                                                    </label>
                                                </div>

                                                <div v-if="isDirect===false" class="w-full xl:text-base lg:text-base text-sm"  v-show="usable_point >= 2000" @click="key = 4">
                                                    <input type="radio" id="paytype4" name="paytype" class="hidden">
                                                    <label for="paytype4" class="bg-white text-gray-800 border border-gray-300 font-semibold px-4 py-1 cursor-pointer rounded-md block text-center h-12 flex items-center justify-center">
                                                        ポイント
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 결제수단 콘텐츠 영역 -->
                                        <div v-show="visible_payment_area && (key == 1 || key == 4 || key == 6)"> <!--v-if="key == 1"-->
                                            <h5 class="font-bold before_cont text-[#2c7fc3]">Card</h5>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="card_no" id="card_no" ref="card_no" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="16"
                                                           v-model="card_no">
                                                    <label for="card_no" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">カード番号</label>
                                                </div>
                                            </div>
                                            <div class="mt-4 flex space-x-4 mt-4 ">
                                                <div class="md-input-box relative z-[10] bg-white w-1/2">
                                                    <input name="valid_date" id="valid_date" ref="valid_date" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="4"
                                                           v-model="valid_date">
                                                    <label for="valid_date" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">MM/YY</label>
                                                </div>
                                                <div class="md-input-box relative z-[10] bg-white w-1/2">
                                                    <input name="cvv" id="cvv" ref="cvv" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer" maxlength="4"
                                                           v-model="cvv">
                                                    <label for="cvv" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">CVV</label>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="e_mail" id="e_mail" ref="e_mail" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                           v-model="e_mail">
                                                    <label for="e_mail" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">E-mail</label>
                                                </div>
                                            </div>
                                            <div class="mt-4 hidden">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="e_mail" id="e_mail" ref="e_mail" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                           v-model="cardholder_name">
                                                    <label for="e_mail" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">cardholder_name</label>
                                                </div>
                                            </div>
                                            <h5 v-show="sum_item.amt >= 10000" class="mt-4 font-bold before_cont text-[#2c7fc3]">お支払い回数</h5>
                                            <div  v-show="sum_item.amt >= 10000" class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <vSelect :options="jpo_list" label="value" :reduce="value => value.id"  :clearable="false" v-model="jpo"  ></vSelect>
                                                </div>
                                            </div>

                                        </div>
                                        <div v-show="visible_payment_area && key == 2"> <!--v-if="key == 1"-->
                                            <h5 class="font-bold before_cont text-[#2c7fc3]">Pay-easy</h5>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">

                                                    <input name="valid_date" id="valid_date" ref="valid_date" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                           v-model="bankKana">
                                                    <label for="valid_date" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">カタカナ名</label>
                                                    <div style="font-size:.8rem; color:#ff5959; margin-bottom:8px; font-weight: bold; word-break: break-word;">
                                                        ※必ずカタカナでご入力下さい。
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">

                                                    <label for="valid_date" style="transform: translate(0px, -15px) scale(1);" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">決済サービスオプション</label>

                                                    <select name="price_open" id="price_open" class="mt-2 mb-2 w-full border border-gray-300 rounded text-sm md:text-base" v-model="payeasySelect">
                                                        <option value="netbank-pc" selected>ネットバンキング</option>
                                                        <option value="ATM">ATM</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="e_mail" id="e_mail" ref="e_mail" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                           v-model="e_mail">
                                                    <label for="e_mail" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">E-mail</label>
                                                </div>
                                                <div style="font-size:.8rem; color:#ff5959; margin-bottom:8px; font-weight: bold; word-break: break-word;">
                                                    ※ ご入力頂いたメールアドレスに確認用メールを送信しております。
                                                </div>

                                            </div>

                                        </div>
                                        <div v-show="visible_payment_area && key == 3"> <!--v-if="key == 1"-->
                                            <h5 class="font-bold before_cont text-[#2c7fc3]">コンビニ決済</h5>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">

                                                    <label for="valid_date" style="transform: translate(0px, -15px) scale(1);" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">決済サービスオプション</label>

                                                    <select name="price_open" id="price_open" class="mt-2 mb-2 w-full border border-gray-300 rounded text-sm md:text-base" v-model="cvsSelect">
                                                        <option value="econ" selected> イーコン（ローソン、ファミリーマート、ミニストップ、セイコーマート）</option>
                                                        <option value="other">その他(デイリーヤマザキ)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="md-input-box relative z-[10] w-full bg-white w-full">
                                                    <input name="e_mail" id="e_mail" ref="e_mail" type="text" placeholder=" "
                                                           class="md-input w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600  cursor-pointer"
                                                           v-model="e_mail">
                                                    <label for="e_mail" class="md-label block mb-3 text-sm font-semibold text-[#2c7fc3]">E-mail</label>
                                                </div>
                                                <div style="font-size:.8rem; color:#ff5959; margin-bottom:8px; font-weight: bold; word-break: break-word;">
                                                    ※ ご入力頂いたメールアドレスに確認用メールを送信しております。
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 총합계 -->
                            <div class="flex justify-center flex-col md:flex-row flex-col items-stretch w-full mt-4 xl:px-4 lg:px-4 xl:pb-4 lg:pb-4 ">
                                <div class="flex flex-col px-4 py-6 md:p-6 xl:p-6 w-full bg-white dark:bg-gray-800 space-y-6 shadow-zinc-500/40 shadow-md rounded-md">

                                    <h3 class="text-lg xl:text-xl lg:text-xl  font-semibold leading-5 text-gray-800 border-l-[10px] border-[#4493d5] pl-3">決済金額</h3>

                                    <div class="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                                        <div class="flex justify-between w-full">
                                            <p class="text-base  leading-4 text-gray-800">合計金額</p>
                                            <p class="text-base text-right dark:text-gray-300 leading-4 text-gray-800">
                                                {{ formatPrice(sum_item.amt - shipping_selected) }}円
                                                <span v-if="isDirect === false" style="display:block" class="text-blue-500 text-right mt-2">PV {{ formatPrice(sum_item.bv,2) }} </span>
                                            </p>
                                        </div>
                                        <div class="flex justify-between w-full">
                                            <p class="text-base  leading-4 text-gray-800">送料</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800 text-right">
                                                {{ formatPrice(parseFloat(shipping_selected)) }}円
                                            </p>
                                        </div>
                                        <div class="flex justify-between w-full" v-if="key == '4'">
                                            <p class="text-base  leading-4 text-gray-800">ポイント</p>
                                            <p class="text-base dark:text-gray-300 leading-4 text-gray-800 text-right">
                                                - {{ formatPrice(usable_point) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center w-full pb-4">
                                        <p class="text-base  font-semibold leading-4 text-gray-800">決済金額</p>
                                        <div class="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">
                                            <p class="text-right xl:text-2xl lg:text-2xl text-xl font-bold font-bold text-gray-900">{{  formatPrice(total_amt) }}円</p>
                                            <p v-if="isDirect === false" class="xl:text-2xl lg:text-2xl text-xl text-blue-500 text-right font-bold mt-2">
                                              PV {{ formatPrice(sum_item.bv,2) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-[15px]">
                              <div class="flex flex-row">
                                    <input type="checkbox" class="mt-0.5" v-model="pay_agree" true-value="Y" false_value="N" id="pay_agree">
                                    <label for="pay_agree" class="block ml-3 mb-3 text-sm font-semibold text-gray-500 cursor-pointer">上記の製品の購入条件を確認及び決済に同意</label>
                                </div>
                            </div>

                            <div class="w-full xl:px-4 lg:px-4 xl:py-4 lg:py-4 mt-2">
                                <button @click="go_pay" class="bg-[#2c80c4] font-semibold hover:bg-[#2468a0] py-3 text-sm text-white uppercase w-full">決済</button>
                            </div>

                        </div>
                        <!--// payment 영역 -->

                    </div>
                </div>
            </div>
        </div>
    </section>
    <form name="frmPayment">
        <input type="hidden" name="_token">
        <input type="hidden" id="token_api_key" value="8a42ac09-7266-47f5-b998-4a28f070a784">
        <input type="hidden" name="TransactionType" value="SALE">
        <input type="hidden" name="PymtMethod" value="ANY"> <!-- 정기구매 첫결제 : CC , 일반결제 any -->
        <input type="hidden" name="ServiceID" value="GPO">
        <input type="hidden" name="PaymentID">
        <input type="hidden" name="OrderNumber">
        <input type="hidden" name="PaymentDesc" value="GCOOP Malaysia">
        <input type="hidden" name="MerchantName" value="GCOOP Malaysia Sdn. Bhd.">
        <input type="hidden" name="MerchantReturnURL">
        <input type="hidden" name="MerchantCallbackURL">
        <input type="hidden" name="Amount">
        <input type="hidden" name="CurrencyCode" value="MYR">
        <input type="hidden" name="CustIP">
        <input type="hidden" name="CustName">
        <input type="hidden" name="CustEmail">
        <input type="hidden" name="CustPhone">
        <input type="hidden" name="MerchantTermsURL" value="https://cdn.gcoop.me/shop/storage/pdf/my/terms_mal.pdf">
        <input type="hidden" name="LanguageCode" value="en">
        <input type="hidden" name="PageTimeout" value="780">
        <input type="hidden" name="first_name">
        <input type="hidden" name="last_name">
        <input type="hidden" name="valid_date">
        <input type="hidden" name="cvv">
        <input type="hidden" name="card_no">
        <input type="hidden" name="tax">
        <input type="hidden" name="seq_os">
        <input type="hidden" name="userid">
    </form>
</template>

<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import modalView from "../modalViewWechat";
import modalTooltip from "../modalView";
import { useMobileDetection } from "vue3-mobile-detection";
import alert from "vue3-simple-alert";
import VueSimpleAlert from "vue3-simple-alert";
import vSelect from "vue-select";

let itemsToShow  = 1;

export default {
    name: "Order",
    props: ['request','hashdata'],
    setup() {
        const { isMobile } = useMobileDetection();   // 모바일 여부 체크
        if( isMobile())  itemsToShow  = 2;
        return { itemsToShow };
    },
    components : {
        Loading,
        modalView,
        modalTooltip,
        vSelect
    },

    data: () => ({
        isDirect : (window.isDirect === true),
        //show hide
        showMessage: true,
        showMessage2: true,
        showModal: false,
        title: [],
        order_list: [],
        order_info_list: [],
        order_pay_list: [],
        order_product_list: [],
        sum_item: [],
        // fee_shipping: [],
        shipping_selected: 0,
        csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
        is_order_possible : window.Laravel.is_order_possible,
        key : 1,
        loading : false,
        weachat_open :false,
        is_mobile : itemsToShow,
        card_no : "",
        valid_date : "",
        cvv : "",
        first_name : "",
        last_name : "",
        e_mail : "",
        deli_kind : "",
        deli_kinds : "",
        insurance_fee : "",
        gel_price : "",
        price_open : "",
        pay_agree : "",
        pay_agree2 : "",
        pay_agree2_show : true,
        cur_point : 0,
        usable_point : 0,
        visible_payment_area : true,
        cvsSelect : "econ",
        payeasySelect : "netbank-pc",
        bankKana : "",
        jpo : "10", //일본 할부 계월 선택
        jpo_list : [
            {id : "10", value : "一括"},
            {id : "61C03", value : "3回"},
            {id : "61C05", value : "5回"},
            {id : "61C06", value : "6回"},
            {id : "61C10", value : "10回"},
            {id : "61C12", value : "12回"},
            {id : "61C15", value : "15回"},
            {id : "61C18", value : "18回"},
            {id : "61C20", value : "20回"},
            {id : "61C24", value : "24回"}],
        cardholder_name : "",
    }),
    watch : {
        key : function(){

            if(this.cur_point <= 0 && this.key == "4"){
                VueSimpleAlert.alert("ポイントを利用できません","","",{confirmButtonText:"確認"});
                $("#paytype1").prop("checked", true);
                return;
            }else{
                this.calcTotal();
            }

        },
        card_no : function(val){
            return this.card_no = this.card_no.replace(/[^0-9]/g, '');
        },
        valid_date : function(val){
            return this.valid_date = this.valid_date.replace(/[^0-9]/g, '');
        },
        cvv : function(val){
            return this.cvv = this.cvv.replace(/[^0-9]/g, '');
        },
    },
    created() {
        this.loading = true;
        this.order = this.request["order_seq"];
        this.sum_item.price = 0;
        this.sum_item.vat = 0;
        this.sum_item.amt = 0;
        this.sum_item.bv = 0;
        this.shipping_selected = 0;
        this.getOrder(window.Laravel.bz_cd);
        this.MIX_BACK_URL = process.env.MIX_BACK_URL;
    },
    methods:{
        getOrder(bz_cd) {
            this.loading = true;
            axios.post(process.env.MIX_BACK_URL + '/api/getOrder', {
                order: this.order,
                bz_cd: bz_cd,
            }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                .then(response => {
                    this.order_list = response.data;
                    this.order_info_list= response.data.info;
                    // this.order_info_list.d_addr2 = this.order_info_list.d_addr2.replaceAll("|@|", ", ");
                    // this.order_info_list.d_addr1 = this.order_info_list.d_addr1.replaceAll("|@|", ", ");
                    // this.order_info_list.d_name = this.order_info_list.d_name;
                    // this.order_info_list.d_mobile = this.order_info_list.d_mobile;
                    // this.order_info_list.o_name = this.order_info_list.o_name;
                    // this.order_info_list.o_addr1 = this.order_info_list.o_addr1.replaceAll("|@|", ", ");
                    // this.order_info_list.o_addr2 = this.order_info_list.o_addr2.replaceAll("|@|", ", ");
                    this.order_pay_list= response.data.pay;
                    this.order_product_list= response.data.product;
                    this.sum_item.price = this.order_list.ord_price;
                    this.sum_item.vat = this.order_list.ord_vat;
                    this.sum_item.amt = this.order_list.ord_amt;
                    this.sum_item.bv = this.order_list.ord_pv;
                    this.shipping_selected = this.order_list.ord_deli_amt;
                    this.total_amt = this.sum_item.amt;
                    this.bankKana = response.data.bankKana;
                    this.e_mail = (response.data.e_mail === 'null' ) ? '' : response.data.e_mail;
                    this.loading = false;

                    //포인트
                    axios.post(process.env.MIX_BACK_URL + '/api/getPoint', {
                        bz_cd: bz_cd,
                    }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
                        .then(response => {
                            this.cur_point = response.data;
                            if(this.total_amt <= this.cur_point){
                                this.usable_point = this.total_amt;
                            }else{
                                this.usable_point = this.cur_point;
                            }

                            this.loading = false;
                        })
                        .catch(e => {
                        });

                })
                .catch(e => {
                });

        },
        prevUrl(){ // 쇼핑 계속하기
            window.history.go(-1);//
        },
        getTooltip() {//본인 산하 직추천 명단
            this.showModal = true;
        },
        calcTotal(){
            if(this.key == "4"){
                this.total_amt = this.sum_item.amt - this.usable_point;

                if(parseFloat(this.cur_point) >= parseFloat(this.sum_item.amt)){
                    this.visible_payment_area = false;
                }

            }else{
                this.total_amt = this.sum_item.amt;
                this.visible_payment_area = true;
            }
        },
        formatPrice(value,digit) { // 숫자 포멧

            if(value === undefined){
              return 0;
            }

            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        checkCardInvalid(){
            if(this.card_no == ""){
                VueSimpleAlert.alert("カード番号を入力してください","","",{confirmButtonText:"確認"});
                this.loading = false;
                return false;
            }
            if(this.valid_date == ""){
                VueSimpleAlert.alert("有効期限を入力してください","","",{confirmButtonText:"確認"});
                this.loading = false;
                return false;
            }

            if(this.cvv == ""){
                VueSimpleAlert.alert("CVVを入力してください","","",{confirmButtonText:"確認"});
                this.loading = false;
                return false;
            }
            return true;
        },
        go_pay(){
            this.loading = true;

            // console.log(this.key);
            if(!this.pay_agree){
                VueSimpleAlert.alert("購入条件に同意してください","","",{confirmButtonText:"確認"});
                this.loading = false;
                return;
            }


            if(this.key == "4"){
                if(parseFloat(this.cur_point) < parseFloat(this.sum_item.amt)){
                    if(this.checkCardInvalid() == false){
                        return;
                    }
                }
            }else if(this.key == "1"){
                if(this.checkCardInvalid() == false){
                    return;
                }
            }
            var pay_kind = 0;
            var token  = "";

            if(this.key == 1 || this.key == 6){
                var data = {};
                data.token_api_key = document.getElementById('token_api_key').value;
                if (this.card_no) {
                    data.card_number = this.card_no;
                }
                if (this.valid_date) {
                    data.card_expire = this.valid_date.substring(0,2)+"/"+this.valid_date.substring(2,4);
                }
                if (this.cvv) {
                    data.security_code = this.cvv;
                }

                data.lang = "ja";
                let url = "https://api.veritrans.co.jp/4gtoken";
                var card_no = this.card_no;
                var valid_date = this.valid_date;
                var cvv = this.cvv;
                var e_mail = this.e_mail;

                axios.post(url,
                    {
                        card_number : this.card_no,
                        card_expire : this.valid_date.substring(0,2)+"/"+this.valid_date.substring(2,4),
                        security_code : this.cvv,
                        lang : "ja",
                        token_api_key : document.getElementById('token_api_key').value,
                        //cardholder_name : this.cardholder_name,
                    },{})
                    .then(response => {
                        if(response.data.code == "success"){
                            token = response.data.token;
                            pay_kind = 3;
                            this.pay_card(pay_kind,token);

                        }else{
                            this.loading = false;
                            VueSimpleAlert.alert(response.data.message,"","",{confirmButtonText:"確認"});

                        }
                    })
                    .catch(error =>{
                        if( error.response ){
                            VueSimpleAlert.alert(error.response.data.message,"","",{confirmButtonText:"確認"});
                        }
                        this.loading = false;
                    });
            }
            if(this.key == 4){
                if(parseFloat(this.cur_point) < parseFloat(this.sum_item.amt)){
                    var data = {};
                    data.token_api_key = document.getElementById('token_api_key').value;
                    if (this.card_no) {
                        data.card_number = this.card_no;
                    }
                    if (this.valid_date) {
                        data.card_expire = this.valid_date.substring(0,2)+"/"+this.valid_date.substring(2,4);
                    }
                    if (this.cvv) {
                        data.security_code = this.cvv;
                    }

                    data.lang = "ja";
                    let url = "https://api.veritrans.co.jp/4gtoken";
                    var card_no = this.card_no;
                    var valid_date = this.valid_date;
                    var cvv = this.cvv;
                    var e_mail = this.e_mail;

                    axios.post(url,
                        {
                            card_number : this.card_no,
                            card_expire : this.valid_date.substring(0,2)+"/"+this.valid_date.substring(2,4),
                            security_code : this.cvv,
                            lang : "ja",
                            token_api_key : document.getElementById('token_api_key').value
                        },{})
                        .then(response => {
                            if(response.data.code == "success"){
                                token = response.data.token;
                                pay_kind = 9;
                                this.pay_card(pay_kind,token);

                            }else{
                                this.loading = false;
                                VueSimpleAlert.alert(response.data.message,"","",{confirmButtonText:"確認"});

                            }
                        })
                        .catch(error =>{
                            if( error.response ){
                                VueSimpleAlert.alert(error.response.data.message,"","",{confirmButtonText:"確認"});
                            }
                            this.loading = false;
                        });
                }else{
                    pay_kind = 9;
                    this.pay_card(pay_kind,token);
                }
            }
            if(this.key == 2){
                if(this.payeasySelect == "ATM"){
                    pay_kind = "A";
                }
                if(this.payeasySelect == "netbank-pc"){
                    pay_kind = 6;
                }
                this.pay_card(pay_kind,token);
            }
            if(this.key == 3){
                pay_kind = "7";
                this.pay_card(pay_kind,token);
            }
        },


        pay_card(pay_kind,token){
            this.loading = true;
            var url = "pay_card";
            if(pay_kind == "9"){
                var url = "pay_point";
            }
            if(this.key === 6){
                var url = "pay_halbu";
            }

            var halbu = this.key;

            axios.post(process.env.MIX_BACK_URL+'/api/'+url,
                {
                    isDirect : this.isDirect,
                    seq_os : this.request["order_seq"],
                    amount : this.total_amt,
                    card_no : this.card_no,
                    e_mail : this.e_mail,
                    valid_date : this.valid_date,
                    cvv : this.cvv,
                    price_open : this.price_open,
                    kind : pay_kind,
                    payeasySelect : this.payeasySelect,
                    cvsServiceOptionType : this.cvsSelect,
                    token : token,
                    bankKana : this.bankKana,
                    PaymentID : this.request["payment_id"],
                    jpo : this.jpo
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.loading = false;
                    if(response.data.ord_no && response.data.result == "1" && halbu != 6){
                      if(this.isDirect === true){
                        window.location.replace("/payResult?kind=success&order_no=" + response.data.ord_no + "&isDirect=1");
                      }else{
                        location.href="/payResult?kind=success&order_no=" + response.data.ord_no;
                      }
                    }else if(response.data.ord_no && response.data.result == "1"  && halbu === 6){
                        // location.href="/orderhalbu?kind=success&ResResponseContents=" + response.data.ResResponseContents;
                        window.location.replace("/orderhalbu?kind=success&ResResponseContents=" + response.data.ResResponseContents);
                    }else{
                        this.loading = false;
                        if(response.data.result == 6){
                            location.href=response.data.url;
                        }
                        else if(response.data.msg != ""){
                            this.loading = false;
                            VueSimpleAlert.alert(response.data.msg,"","",{confirmButtonText:"確認"});
                        }else{
                            alert("결제 실패");
                            this.loading = false;
                        }
                    }
                });
        }
    }
}
</script>

<style>
.pay_box input[type="radio"]:checked + span {
    display: block;
}

.pay_box input:checked + label {
    background-color: rgb(182 197 210 / 30%);
    border:2px solid #2c80c4;
    color:#0e293e;
}
.pay_box input:checked + label>span>svg {
    color:#184468;
}

.before_cont::before {
    content: "■ ";
    color:#dce0ef;
}

</style>

<style >
/* Modal */
.modal,
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.modal{z-index: 998;}

.overlay {
  opacity: 0.8;
  background-color: black;
}
</style>


