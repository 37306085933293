<template>
  <loading :active="loading"
           :can-cancel="true"
           :is-full-page="true"></loading>
  <direct_auth v-if="isDirect===true" join_check="nonMember"></direct_auth>
  <div>
    <div class="cbs-product-visual">
      <img src="/images/dir_img2.png?ver=1.0" alt="">
    </div>

    <div class="inner cbs-box">
      <div class="cbs-page-tit">
        <h2>韓国メーカー直売</h2>
      </div>

      <div class="cbs-cont-tit">
        <p>アカウント作成</p>
      </div>

      <div class="cbs-input">
        <input type="text" name="nickname" id="nickname" :value="userId" placeholder="ID"
               onkeyup="this.value=this.value.replace(/[^A-Za-z0-9]/g,'');" readonly>
      </div>
      <div class="cbs-input">
        <input type="password" name="passwd" id="passwd" value="" placeholder="パスワード">
      </div>
      <div class="cbs-input">
        <input type="password" name="rePasswd" id="rePasswd" value="" placeholder="パスワード確認">
      </div>
      <div class="cbs-input">
        <input type="text" name="e_mail" id="e_mail" :value="email" value="" placeholder="メールアカウント">
      </div>

      <div class="cbs-btn-box">
        <ul>
          <li><span @click="form_submit();">アカウント作成</span></li><!--정기구매 등록 -->
        </ul>
      </div><!--//btn-box-->

    </div>
  </div>
</template>


<script>
import {getApiCall, postApiCall} from "../../../assets/api/rest";
import Loading from "vue3-loading-overlay";
import Swal from 'sweetalert2'

export default {
  name: "direct_join",
  components: {Loading},
  props: ['is_direct'],
  data: () => ({
    loading: false,
    userId:'',
    email:''
  }),
  created() {
  },
  mounted() {
    this.getMemberInfo();
  },
  computed: {
    isDirect() {
      return this.is_direct;
    }
  },
  methods: {

    getMemberInfo() {
      getApiCall('getMemberInfo', {
        }).then(response => {
          if (response.data.resultCode === 0) {
            this.userId = response.data.userId;
            this.email = response.data.email;
          }
          this.loading = false;
        })

    },
    form_submit() {

      let isReturn = false;
      const validation = {
        'nickname': 'Dを入力してください',
        'passwd': 'パスワードを入力してください',
        'rePasswd': 'パスワード確認を入力してください',
        'e_mail': 'メールアカウントを入力してください'
      };

      if ($.trim($('#passwd').val()) !== $.trim($('#rePasswd').val())) {
        alert('パスワードとパスワード確認の入力値が違います');
        return;
      }
      for (const key in validation) {
        let value = validation[key];
        console.log($.trim($('#' + key).val()));
        if (!$.trim($('#' + key).val())) {
          alert(value);
          isReturn = true;
          $('#' + key).focus();
          return false;
        }
      }

      if (isReturn === false) {
        this.loading = true;
        postApiCall('directMemberJoin', {
          'nickname': $('#nickname').val(),
          'passwd': $('#passwd').val(),
          'eMail': $('#e_mail').val(),
        }).then(response => {
          if (response.data.resultCode === 0) {

            Swal.fire({
              title: '韓国メーカー直売',
              icon: 'success',
              // text: "加入おめでとうございます。",
              text: " ご登録ありがとうございます。",
              focusConfirm: true,
              confirmButtonText:
                'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.replace('/product/list/114');
              }
            })
          }
          this.loading = false;
        })
      }
    }
  }
}
</script>


<style scoped>
/*reset*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  width: 100%;
  margin: 0 auto;
}

body {
  font-family: "Noto Sans JP";
  font-size: 0.88em;
  color: #666;
  font-weight: 300;
  line-height: 1.56;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  word-break: keep-all;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  font-size: 1em;
  font-weight: normal;
}

/* font-size:100%;  기본 폰트사이즈 가지지 않도록 선언 필요*/
ul, ol, li {
  list-style: none
}

a, a:hover, a:active, a:visited {
  text-decoration: none;
  color: #000;
}

img {
  border: 0;
  vertical-align: top;
  width: 100%;
  min-height:190px;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  color: #666;
  vertical-align: middle;
  margin-top: -2px;
}

textarea {
  resize: none;
  width: 100%;
  height: 248px;
  padding: 5px;
  vertical-align: top;
  font-size: 0.88em;
  font-family: inherit;
  color: #666
}

em, address {
  font-style: normal
}

table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  color: transparent
}

strong {
  font-weight: 500;
  color: #222
}

label {
  vertical-align: middle;
  cursor: pointer
}

.fa, .fas, .fal {
  vertical-align: middle;
}

input {
  font-size: 0.88em;
  vertical-align: middle;
}

img {
  vertical-align: middle;
}

/*페이지 css*/
/*작업 시 페이지에 적용 시키고 작업 후 추후 css 파일로 옮기겠음*/
.inner {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.cbs-box {
  word-break: break-all;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px
}

.cbs-page-tit {
  margin: 20px 0 30px 0;
}

.cbs-page-tit > h2 {
  font-size: 1.13em;
}

.cbs-cont-tit {
  text-align: center;
  font-size: 1.13em;
  font-weight: bold;
  margin: 15px 0;
}

.cbs-notice {
  font-size: 1.13em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.cbs-notice p {
  text-indent: 0px;
}

#cbs-contentTxt {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #d4d4d4;
  background: #ffffff;
  height: 200px;
  overflow: auto;
  color: #000;
  padding: 10px 15px;
  font-size:0.88em;
}

.agree_check {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.cbs-btn-box {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 80px;
}

.cbs-btn-box ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 5px;
}

.cbs-btn-box ul li {
  display: table-cell;
}

.cbs-btn-box ul li span {
  display: block;
  height: 62px;
  line-height: 62px;
  font-size: 1.13em;
  color: #222;
  border: 1px solid #0abab5;
  cursor: pointer;
  text-align: center;
}

.cbs-btn-box ul li span:last-child {
  color: #fff;
  border: 1px solid #0abab5;
  background: #0abab5;
}

.cbs-input {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px auto;
  text-align: center;
}

.cbs-input > input {
  height: 40px;
  max-width: 300px;
  width: 100%;
  font-size: 1.2em;
  padding: 0 5px 0 5px;
}
</style>
