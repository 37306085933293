<template>
    <div v-if="main===true">
      <section class="container mx-auto">
        <div class="bg-slate-100 lg:bg-white min-h-[300] lg:min-h-[500] ">
          <div class="max-w-8xl mx-auto lg:max-w-7xl bg-white">
            <h2 class="xl:text-2xl lg:text-2xl text-lg font-extrabold tracking-normal text-gray-900 mt-1 mb-1">{{ title }}</h2>
            <div class="flex flex-wrap justify-start pt-2 text-neutral-500 text-sm lg:text-base">
              <div @click="list_url(category.seq_pdc)" v-for=" category in getCategoryPath "
                   class="cursor-pointer border-r border-black text-center hover:text-blue-400 last:border-0 mb-1" :class="{ active :(parseInt(no) == parseInt(category.seq_pdc))}"><span class="px-3">{{ category.cate_name }}</span>
              </div>
            </div>
            <h2>{{ no_search_messsage }}</h2>
            <div v-if="products.length">
              <list_swiper :products="products" :isDirect="isDirect" :isLoggedIn="isLoggedIn"></list_swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <direct_banner v-if="isDirect===true"></direct_banner>
      <direct_auth v-if="isDirect===true"></direct_auth>
      <section class="container mx-auto xl:py-8 lg:py-8 py-4">
          <div class="bg-slate-100 lg:bg-white min-h-[300] lg:min-h-[500]">
              <div class="max-w-8xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-white">
                  <h2 class="xl:text-2xl lg:text-2xl text-lg font-extrabold tracking-normal text-gray-900 mt-1 mb-1">{{ title }}</h2>
                  <div class="flex flex-wrap justify-start pt-2 text-neutral-500 text-sm lg:text-base">
                      <div @click="list_url(category.seq_pdc)" v-for=" category in getCategoryPath "
                           class="cursor-pointer border-r border-black text-center hover:text-blue-400 last:border-0 mb-1" :class="{ active :(parseInt(no) == parseInt(category.seq_pdc))}"><span class="px-3">{{ category.cate_name }}</span>
                      </div>
                  </div>
                  <h2>{{ no_search_messsage }}</h2>
                  <div v-if="products.length" class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 lg:gap-x-6 gap-x-1 gap-y-3">
                      <div v-for="product in products" :key="product.id" class="group relative bg-gray-100 border-black m-1.5 rounded-lg border-slate-900 xl:py-2 lg:py-2 py-2 xl:px-3 lg:px-3 px-2 transition duration-300 ease-in-out hover:bg-black/20">

                          <div class="w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden aspect-square relative">
                            <!-- 카테고리 뱃지 추가 - 품절일 경우에는 미노출 -->
                            <!--<div class="absolute top-1 left-1">
                              <span class="text-[#2196f3] border border-[#2196f3] block px-2 py-1 text-[11px] bg-white">Living</span>
                            </div>-->

                            <a :href="product.href" class="block w-full">
                              <img :src="product.imageSrc" :alt="product.imageAlt" class="w-full object-center object-cover max-w-none" onerror="this.src='/images/error/noImage.png'"/>
                              <div class="sold-out-box2" v-if="product.status == 2">
                                  <img src="/images/badge/jp/pc_temp_sold_out.png" alt="sold out" />
                              </div>
                              <div class="sold-out-box2" v-if="product.status == 3">
                                  <img src="/images/badge/jp/pc_sold_out.png" alt="sold out" />
                              </div>
                              <div class="coming_box" v-if="product.status == 4">
                                  <img src="/images/badge/jp/pc_coming_soon.png" alt="sold out" />
                              </div>
                              <div class="badge_box" v-if="product.badge != ''">
                                  <img :src="product.badge" alt="sold out" />
                              </div>
                            </a>
                          </div>

                          <div class="xl:mt-8 lg:mt-8 mt-3 justify-between">
                            <!-- 카테고리 뱃지 추가 - 품절일 경우에는 미노출 -->
                            <div class="">
                              <!--<span class="text-[#899cc1] block py-1 xl:text-[11px] lg:text-[11px] text-[10px]">Living > CareCellve</span>-->
                              <span class="text-[#899cc1] block py-1 xl:text-[11px] lg:text-[11px] text-[10px]">{{ product.parent_cate_name }} > {{ product.cate_name }}</span>
                            </div>
                              <div class="h-10 lg:h-14 md:h-14 sm:h-12 mb-2.5">
                                  <h3 class="text-[#0e293e] text-sm lg:text-base sm:text-sm text-ellipsis overflow-hidden line-clamp-2 font-semibold ellipsis">
                                      <a :href="product.href" class="block w-full leading-snug">
  <!--                                        <span aria-hidden="true" class="absolute inset-0" style="bottom: 100px;" />-->
                                          {{ product.name }}
                                      </a>
                                  </h3>
                              </div>
                              <div  class="w-full xl:mb-5 lg:mb-5 mb-3 font-semibold" v-if="product.status != '4'">
                                  <!-- 소비자가 -->
                                <ConsumerPrice :isLoggedIn="isLoggedIn" :price="product.set1_amt" :toBeSold="product.status == '4' ? true : false" type="list"></ConsumerPrice>
                                <div v-if=" isLoggedIn ">
                                    <div v-if="isDirect===true">
                                        <p class="mt-1 text-blue-500 xl:text-lg lg:text-lg text-sm">{{ i18n.shop.shopping.GPMemberPrice }} :
                                            {{
                                                product.price
                                            }}</p>
                                    </div>
                                    <div v-else>
                                        <p class="font-medium text-gray-900 xl:text-lg lg:text-lg text-sm">
                                            {{ i18n.shop.shopping.GPMemberPrice }} : {{ product.price }}</p>
                                        <p class="mt-1 text-blue-500 xl:text-lg lg:text-lg text-sm">{{ product.bv }}</p>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div v-if=" isLoggedIn && main != true && product.status == 1 && isVcMember === false " class="w-full xl:mb-5 lg:mb-5 mb-2 font-semibold">
                              <div class="inline-flex items-center font-semibold font-heading text-gray-500 border border-gray-300 focus:ring-blue-300 focus:border-blue-300 rounded-md bg-white">
                                  <button @click="decrement(product)" class="py-2  px-1.5 lg:px-3 md:px-2">
                                    <svg class="text-[#172e59]" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="1"><rect x="12" width="2" height="12" transform="rotate(90 12 0)" fill="currentColor"></rect></g></svg>
                                  </button>
                                  <input type="number" name="qty" v-model="product.qty" @input="changeQty(product)" class="w-8 m-0 px-0 py-2 text-center border-0 focus:ring-transparent focus:outline-none rounded-md lg:px-2 lg:py-2 lg:w-12" id="custom-input-number">
                                    <button @click="increment(product)" class="py-2 px-1.5 lg:px-3 md:px-2">
                                      <svg class="text-[#172e59]" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="1"><rect x="5" width="2" height="12" fill="currentColor"></rect><rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="currentColor"></rect></g></svg>
                                  </button>
                              </div>
                              <div v-if="product.status == 1"
                                   class="bg-white inline-flex items-end align-top float-right border-solid border border-gray-300 rounded-md flex items-center justify-between bg-white w-10 lg:w-12"  style="height: 42px;">
                                  <a class="block text-center py-2 uppercase transition duration-200 flex w-full justify-evenly p-0 cursor-pointer"  v-on:click.prevent="addCart(product)">
                                      <ShoppingCartIcon class="h-6 sm:h-8 w-5 lg:w-6 text-[#172e59]" aria-hidden="true" />
                                  </a>
                              </div>
                              <div v-if="product.status == 1 && product.set3_amt != 0"
                                   class="mr-1 bg-white inline-flex items-end align-top float-right border-solid border border-gray-300 rounded-md flex items-center justify-between bg-white pl-0 w-10 lg:w-12"  style="height: 42px;">
                                  <a class="block text-center py-2 uppercase transition duration-200 flex w-full justify-evenly p-0 cursor-pointer"  v-on:click.prevent="addAutoship(product)">
                                      <img src="/images/autoship_icon_black.png" class="h-[24px] w-[24px] max-w-[24px] text-[#172e59]">
                                  </a>
                              </div>
                          </div>

                      </div>
                  </div>
                  <!--            <button class="text-l mx-auto font-extrabold tracking-tight text-gray-900">더보기</button>-->
              </div>
          </div>
    </section>
  </div>
</template>
<script>
/* javascript numberFormat library */
import numeral from 'numeral';
import 'numeral/locales';
import VueSimpleAlert from "vue3-simple-alert";
import {ShoppingCartIcon} from '@heroicons/vue/outline'
import ConsumerPrice from '@/components/shop/ConsumerPrice';



export default {
    name: "ProductList",
    components : {
        VueSimpleAlert,
        ShoppingCartIcon,
        ConsumerPrice
    },
    props: ['no','limit','keyword','main'],
    setup() {
        return {
            // products,
        }
    },
    data: () => ({
        isDirect : (window.isDirect === true),
        gold_check : window.Laravel.gold_check,
        isVcMember : window.Laravel.isVcMember,
        title: '',
        products: [],
        getCategoryPath: [],
        isLoggedIn: false,
        star_check : window.Laravel.star_check,
        max_qty : 99,
        i18n : window.i18n
    }),
    created() {

        if(this.no) {
            switch (this.no) {
                case 'new':
                    // 신상품
                    axios.post(process.env.MIX_BACK_URL + '/api/product/new', {
                        bz_cd: window.Laravel.bz_cd,
                        limit: this.limit,
                        start_check : this.star_check
                    }).then(response => {
                        this.title = "新製品";
                        this.getProductList(response.data);
                    });
                    break;
                case 'best':
                    // 베스트
                    axios.post(process.env.MIX_BACK_URL + '/api/product/best', {
                        bz_cd: window.Laravel.bz_cd,
                        limit: this.limit,
                        start_check : this.star_check
                    }).then(response => {
                        this.title = "ベスト";
                        this.getProductList(response.data);
                    });
                    break;
                case 'all':
                    // 전체
                    axios.post(process.env.MIX_BACK_URL + '/api/product/all', {
                        bz_cd: window.Laravel.bz_cd,
                        gold_check : this.gold_check
                    }).then(response => {
                        this.title = "全製品";
                        // console.log(response.data);
                        this.getProductList(response.data);
                    });
                    break;
                default :
                    // 카테고리 메뉴
                    // 연관 카테고리
                    axios.post(process.env.MIX_BACK_URL + '/api/categoryPath', {
                        bz_cd: window.Laravel.bz_cd,
                        seq: this.no,
                    }).then(response => {
                        if (response.data.length) {
                            this.title = response.data[0].parent;
                            if(!this.limit) // 목록 수 제한이 없으면
                            {
                                let refseq = response.data[0].refseq;
                                this.getCategoryPath = response.data;
                                this.getCategoryPath.unshift({
                                    'parent': this.title,
                                    'seq_pdc': refseq,
                                    'regseq': refseq,
                                    'cate_name': '全製品'
                                });
                            }
                        }
                    });
                    if((this.no == 361 || this.no == 363 || this.no == 364 || this.no == 365) && this.star_check == false ){
                        VueSimpleAlert.alert("STARモールは500PV以上購入した会員のみ利用可能です", "", "", { confirmButtonText:"確認"}).then((result) => {
                            if (result) {
                                location.replace("/");
                            }
                        });
                    }

                    // 제품 리스트업
                    axios.post(process.env.MIX_BACK_URL + '/api/product/cate', {
                        bz_cd: window.Laravel.bz_cd,
                        cate: this.no,
                    }).then(response => {
                        // console.log(response.data);
                        this.getProductList(response.data);
                    });

            }
        }
        if(this.keyword){
            // 전체
            axios.post(process.env.MIX_BACK_URL+'/api/product/search', {
                bz_cd: window.Laravel.bz_cd,
                keyword: this.keyword,
                isDirect: this.isDirect,
                gold_check : this.gold_check
            }).then(response => {
                this.title = "\""+this.keyword + "\" 製品 検索結果";
                // console.log(response.data);
                this.getProductList(response.data);
            });
        }

    },
    mounted() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true;
            this.user = window.Laravel.user;
        }
    },
    methods:{
        getProductList($list) {

            // 직구 상품인 경우,
            const addLink = (this.isDirect===true) ? '/direct' : '';

            if($list.length < 1){
                this.no_search_messsage = "検索結果なし";
            }
            $list.forEach((value, index) => {

                numeral.defaultFormat('$0,0[.]00');
                // numeral.locale('ko-kr');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                // numeral.defaultFormat('0,0[.]00$');    // locale 'ko' 글로벌 기준, 'ko-kr' 한국 기준
                if((value.seq_pdc == 361 || value.seq_pdc == 363 || value.seq_pdc == 364 || value.seq_pdc == 365) && this.star_check == false ){

                }else{



                    this.products.push({
                        id               : value.seq_pd,
                        pdt_cd           : value.pdt_cd,
                        name             : value.pdt_name,
                        href             : '/product/item/' + value.seq_pd + addLink,
                        imageSrc         : 'https://cdn.gcoop.me/public' + value.images[1]?.path + '/' + value.images[1]?.filename,
                        imageAlt         : value.cate_name + '>' + value.pdt_name,
                        // price: numeral(value.prices.amt).format('$0,0.00'),
                        price            : '' + numeral(value.prices.amt).format('0,0')+'円',
                        set3_amt         : '' + numeral(value.prices.set3_amt).format('0'),
                        bv               : 'PV ' + numeral(value.prices.pv).format('0,0.00'),
                        qty              : 1,
                        status           : value.status,
                        badge            : value.badge != "" ? "https://cdn.gcoop.me/"+value.badges[0].image_url : "",
                        parent_cate_name : value.parent_cate_name,
                        cate_name        : value.cate_name,
                        // set1_amt         : (value.prices.set1_amt === '0.0000') ? '' : numeral(value.prices.set1_amt).format('0,0')+'円',
                        set1_amt         : numeral(value.prices.set1_amt).format('0,0')+'円',
                    });

                }
            });
        },
        list_url(link){
            location.href="/product/list/"+link;
        },
        addCart(product){

          const apiPath = (this.isDirect===true) ? '/api/add_dir_cart' : '/api/add_cart';
          // axios.post(process.env.MIX_BACK_URL+'/api/add_cart',{
          axios.post(process.env.MIX_BACK_URL + apiPath, {
            item: product.id,
            qty: product.qty,
            client_ip: window.Laravel.clientIp,
          }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
            .then(response => {
              if (response.data.msg) {
                if (response.data.result == 'Y') {

                  VueSimpleAlert.alert("", "", "", {
                    confirmButtonText: "確認",
                    background: 'transparent',
                    timer: 1000,
                    imageHeight: 300,
                    imageUrl: '/images/my_cart_icon_500x500.png',
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false
                  }).then((result) => {
                    if (result) {
                      //location.replace("/MyforinerUpload");
                    }
                  });

                    this.emitter.emit('getCountCartItems', '')

                } else {
                  VueSimpleAlert.alert(response.data.msg, "", "", {onfirmButtonText: "確認"}).then((result) => {
                    if (result) {
                      //location.replace("/MyforinerUpload");
                    }
                  });

                  //alert(response.data.msg);
                }
              }
              if (response.data.result == 'Y') {
                //window.location.reload()
              }
            });
        },
        addAutoship(product){
            console.log(product);
            axios.post(process.env.MIX_BACK_URL+'/api/insertAsCartPdt',{
                item  : product.id,
                pdt_cd: product.pdt_cd,
                qty   : product.qty,
                client_ip: window.Laravel.clientIp,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    if(response.data.msg){0
                        if(response.data.result == 'Y') {

                            VueSimpleAlert.alert("", "", "", { background:'transparent', timer:1000, imageHeight: 300, imageUrl: '/images/putin_cart_icon_jp.png', showCancelButton:false,  showCloseButton: false, showConfirmButton : false}).then((result) => {
                                if (result) {
                                    //location.replace("/MyforinerUpload");
                                }
                            });
                            this.emitter.emit('getCountAutoShipItems', '')

                        }else{
                            VueSimpleAlert.alert(response.data.msg, "", "", { }).then((result) => {
                                if (result) {
                                    //location.replace("/MyforinerUpload");
                                }
                            });

                            //alert(response.data.msg);
                        }
                    }
                    if(response.data.result == 'Y'){
                        //window.location.reload()
                    }
                });
        },
        decrement(product){
            product.qty = this.formatPrice(product.qty,0);

            if(product.qty > 1) {
                product.qty--;
            }
        },
        increment(product){
            product.qty = this.formatPrice(product.qty,0);
            if(product.qty < this.max_qty) {
                product.qty++;
            }
        },
        changeQty(product){
            product.qty = this.formatPrice(product.qty,0);
            if(product.qty < 1) {
                this.count = 1;
            }
            if(product.qty > this.max_qty) {
                product.qty = this.max_qty;
            }
        },
        formatPrice(value,digit) {
            let val = (value/1).toFixed(digit).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
}

$(window).scroll(function(e){
    if($(".container").length == 1){
        if($(document).scrollTop() > 200 ){
            $(".quick-menu").addClass("fix");
            $(".quick-menu").removeAttr("style");
        }else{
            $(".quick-menu").removeClass("fix");
            $(".quick-menu").css("top","200px");
        }
    }else if($(".container").length == 0){
        if($(document).scrollTop() > 155){
            $(".quick-menu").addClass("fix");
            $(".quick-menu").removeAttr("style");
        }else{
            $(".quick-menu").removeClass("fix");
            $(".quick-menu").css("top","155px");
        }
    }
});

</script>
<style>
.active {color:rgb(96,165,250); font-weight: bold}
.badge_box{position: absolute; top: 0; right: 0; width: 100%; }
.badge_box>img {width: 25% !important; top:8px; right: 8px; position: absolute; z-index: 1; background:transparent !important;}

.coming_box{position: absolute; top: 0; right: 0; width: 100%; }
.coming_box>img{width:25% !important;  position: absolute; right:8px; top: 8px;  z-index: 1; background:transparent !important;}

.sold-out-box2{position: absolute; top: 0; right: 0; width: 100%; }
.sold-out-box2>img {width: 100% !important; top:0px; right: 0px; position: absolute;  z-index: 1; background:transparent !important;}

.quick-menu{position: absolute; top:200px;}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


/**/
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.bg-slate-100{
  border-radius: 20px;
}
</style>
