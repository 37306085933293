<template>
    <div class="w-full p-1 justify-between ">
        <div class="text-right"><span @click=" close_address_search "  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
        <h2 class="w-full text-lg xl:text-xl lg:text-xl font-semibold text-gray-800 border-l-[10px] border-[#4493d5] pl-3">
          住所検索
          <div class="text-[#2c7fc3] text-xs font-semibold">( 郵便番号で検索してください。)</div>
        </h2>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        <ul class="flex py-2">
            <li class="flex-1 mr-2 items-center border-blue-500 py-2 px-4" @click="currentTab = 1" :class="currentTab === 1 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white border rounded' : 'border-white border-gray-200 text-gray-500 hover:bg-gray-200 border rounded'">
                <a class="text-center block xl:text-base lg:text-base text-sm" target='_blank' href="https://www.post.japanpost.jp/zipcode/">郵便番号を調べる</a>
            </li>
            <li class="flex-1 mr-2" v-show="false" @click=" currentTab = 2 ">
                <a class="text-center block border rounded py-2 px-4"
                   :class="currentTab === 2 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white' : 'border-white hover:border-gray-200 text-blue-500 hover:bg-gray-200'"  href="#">
                    스트리트로 검색하기
                </a>
            </li>
        </ul>

        <div class="" v-if="currentTab === 1">
          <div class="w-full px-2 py-4 justify-between border-b">
                  <label class="block mb-3 text-sm font-semibold text-gray-500">郵便番号</label>
                  <div class="flex">
                      <input type="text" v-model="post1" class="w-full lg:w-2/6 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="3" oninput="this.value = this.value.replace(' ','')">
                      <span class="text-center mx-1">-</span>
                      <input type="text" v-model="post2" class="w-full lg:w-2/6 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="4" oninput="this.value = this.value.replace(' ','')">
                      <input type="button" @click="post_Search" value="検索" class="bg-blue-500 font-semibold hover:bg-[#2468a0] px-4 py-3 text-sm text-white uppercase w-full lg:w-3/6 mx-1 cursor-pointer">
                  </div>
            </div>
            <div class="w-full p-4 overflow-auto	">
                <label class="block mb-3 text-sm font-semibold text-gray-500">{{ post_label }}</label>
                <table class="w-full">
                    <tr class="p-2 border border-gray-200 w-full hover:bg-slate-100	cursor-pointer" v-for="(value,index) in post_list">
                        <td class="p-2" @click="onChangePost(value.id)">{{ value.label }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "addressSearch",
    components : {
        // address_book,
        vSelect,
    },
    props:['nt_cd'],
    data: () => ({
        isDirect : (window.isDirect === true),
        title      : [],
        products   : [],
        currentTab : 1,
        post       : "", /* 우편번호 첫 번째 - 두 번째 : DB에 저장된 형식과 동일 */
        post1      : "", /* 우편번호 첫 번째 칸 */
        post2      : "", /* 우편번호 두 번째 칸 */
        post_list  : [],
        post_label : "",
    }),
    methods:{
        post_Search(){
          if(!this.post1 || !this.post2) { // 우편번호를 입력해주세요.
            VueSimpleAlert.alert("郵便番号を入力してください。","","",{onfirmButtonText:"確認",allowOutsideClick : false}).then((result) => {});
            return;
          }
          this.post = this.post1.trim() + '-' + this.post2.trim();
          axios.post(process.env.MIX_BACK_URL+'/api/getPostSearchList',{
              post: this.post,
              // 직구 모드 추가 add by hskim :: 2024-01-05 오후 2:53
              isDirect : this.isDirect
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
              .then(response => {
                  this.post_label = "";
                  if(response.data.length < 1){
                    this.post_label = "正しい郵便番号を入力してください。"; // 찾으신 주소가 없습니다.
                  }
                  this.post_list = [];

                  response.data.forEach((value, index) => {
                      this.post_list.push({
                          label : "[" + this.post + "] " + value.addr1+ " "+value.addr2 + " "+value.addr3,
                          id : {"postcode" : this.post, "address" : value.addr1, "address2" : value.addr2, "address3" : value.addr3}
                      });
                  });
              })
              .catch(e => {
              });

        },
        onChangePost(post_select){
            this.$emit("set_address",post_select);
        },
        close_address_search(){
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
