<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->

<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot :show="open" as="template">
      <Dialog as="div" class="relative z-40" @close="open = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full"  style="">
            <DialogPanel class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-8 shadow-xl scrollbar">
<!--              <div class="flex px-2 pt-3 pb-2 mt-1 mb-4 justify-end">-->
              <div class="flex px-2 pt-3 mt-1 mb-4 justify-end">
                <button class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-[#172e59]" type="button" @click="open = false">
                  <span class="sr-only">Close menu</span>
                  <XIcon aria-hidden="true" class="h-6 w-6 text-lg " />
                </button>
              </div>
              <!-- Links -->
<!--              <div class="mt-2 px-3">-->
              <div class="px-3">
                <login_menu :left_menu_icon="left_menu_icon"></login_menu>
                <h2 class="mt-4 font-medium text-lg">メニュー</h2>
                <div class="border-b border-gray-200" v-for="(category) in navigation.categories" :key="category.id" @click="addActiveIndex(category.id)">
                  <button :class="[activeTabIndex.includes(category.id) ? 'text-[#172e59] border-[#172e59] font-semibold' : 'text-[#0e293e] border-transparent', 'text-left w-full block whitespace-nowrap border-b-2 py-3 px-1 text-base font-normal flex justify-between']">
                    <span>{{ category.name }}</span>
                    <span>&#43;</span>
                  </button>
                  <div class="mb-2 bg-white" v-if="activeTabIndex.includes(category.id)"> <!--px-4 pt-4 pb-4-->
                    <div class="grid grid-cols-2">
                      <div v-for="item in category.featured" :key="item.name" class="group relative text-sm">
                        <div class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                          <img :alt="item.imageAlt" :src="item.imageSrc" class="object-cover object-center" />
                        </div>
                        <a :href="item.href" class="mt-6 block font-medium text-gray-900">
                          <span aria-hidden="true" class="absolute inset-0 z-10" />
                          {{ item.name }}
                        </a>
                        <p aria-hidden="true" class="mt-1">Shop now</p>
                      </div>
                    </div>
                    <div v-for="section in category.sections" :key="section.name">
                      <p :id="`${category.id}-${section.id}-heading-mobile`" class="font-normal text-gray-900 mb-1 text-left">
                        <a :href=" section.href " class="block p-4 text-[#0e1b35] transition duration-200 hover:text-[#591734] hover:bg-[#e0e4ea]/50">{{ section.name }}</a> <!--hover:bg-[#3e1025]/20-->
                      </p>
                      <!--                                            <ul :aria-labelledby="`${category.id}-${section.id}-heading-mobile`" class="mt-6 flex flex-col" role="list">-->
                      <!--                                                <li v-for="item in section.items" :key="item.name" class="flow-root">-->
                      <!--                                                    <a :href=" item.href " class="-m-2 block p-2 text-gray-500">{{ item.name }}</a>-->
                      <!--                                                </li>-->
                      <!--                                            </ul>-->
                    </div>
                  </div>
                </div>
              </div>
              <!--                            <TabGroup as="div" class="mt-2 px-3">
                                                                          <h2 class="mb-2 font-medium">MENU</h2>
                                                                          <div as="template" class="border-b border-gray-200" v-for="category in navigation.categories" :key="category.name">
                                                                              <TabList>
                                                      <Tab v-slot="{ selected }" class="w-full">
                                                          <button :class="[selected ? 'text-indigo-600 border-indigo-600 font-medium' : 'text-gray-600 border-transparent', 'text-left w-full block whitespace-nowrap border-b-2 py-3 px-1 text-base font-normal flex justify-between']">
                                                              <span>{{ category.name }}</span>
                                                              <span>&gt;</span>
                                                          </button>
                                                      </Tab>
                                                  </TabList>
                                              </div>
                                          <TabPanels as="template">
                                                  <TabPanel v-for="category in navigation.categories" :key="category.name" class="px-4 pt-4 pb-4 mb-2" style="background: #f5f5f9;">
                                                      <div class="grid grid-cols-2">
                                                          <div v-for="item in category.featured" :key="item.name" class="group relative text-sm">
                                                              <div class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                                  <img :alt="item.imageAlt" :src="item.imageSrc" class="object-cover object-center" />
                                                              </div>
                                                              <a :href="item.href" class="mt-6 block font-medium text-gray-900">
                                                                  <span aria-hidden="true" class="absolute inset-0 z-10" />
                                                                  {{ item.name }}
                                                              </a>
                                                              <p aria-hidden="true" class="mt-1">Shop now</p>
                                                          </div>
                                                      </div>
                                                      <div v-for="section in category.sections" :key="section.name">
                                                          <p :id="`${category.id}-${section.id}-heading-mobile`" class="font-normal text-gray-900 mb-1">
                                                              <a :href=" section.href " class="-m-2 block p-2 text-gray-500">{{ section.name }}</a>
                                                          </p>
                                                          <ul :aria-labelledby="`${category.id}-${section.id}-heading-mobile`" class="mt-6 flex flex-col" role="list">
                                                              <li v-for="item in section.items" :key="item.name" class="flow-root">
                                                                  <a :href=" item.href " class="-m-2 block p-2 text-gray-500">{{ item.name }}</a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                  </TabPanel>
                                              </TabPanels>
                                          </TabGroup>-->

              <div v-if="navigation.pages" class="border-t border-gray-200 py-6 px-4">
                <div v-for="page in navigation.pages" :key="page.name" class="flow-root">
                  <a :href="page.href" class="-m-2 block p-2 font-medium text-gray-900">{{ page.name }}</a>
                </div>
              </div>


              <div class="border-gray-200 py-6 px-4" style="padding-bottom:0;">
                <div class="flow-root mb-2" v-if=" isLoggedIn ">
                  <a class="block p-2 font-medium text-white bg-[#591734] rounded-lg border-[#591734] transition duration-200 hover:bg-[#350e1f] border text-center" href="#" v-on:click=" logout ">ログアウト</a>
                </div>
                <!-- 로그아웃 시 -->
                <div v-else>
                  <div class="flow-root mb-2">
                    <a class="block p-2 font-medium text-white bg-[#172e59] rounded-lg border-[#172e59] transition duration-200 hover:bg-[#0e1b35] border text-center" href="#" v-on:click=" login ">ログイン</a>
                  </div>
                  <div class="flow-root">
                    <a class="block p-2 font-medium text-[#243a63] rounded-lg border-[#243a63] transition duration-200 hover:bg-[#d1d5de] border text-center" href="#" v-on:click="register">会員入会</a>
                  </div>
                </div>
              </div>

            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <header class="relative bg-white border-b border-gray-200">
      <!--      상단 문구      -->
      <!--            <p class="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">Get free delivery on orders over $100</p>-->

      <nav aria-label="Top" class="mx-auto px-0  border-b border-gray-200 xl:border-0 lg:border-0"> <!--max-w-7xl-->
        <div class="mx-auto px-2 xl:px-0 lg:px-0 bg-slate-200 " v-show="isLoggedIn">
          <div v-if="isDirect===true" class="flex justify-center h-6   lg:h-12 xl:h-12 items-center p-2 text-xs md:text-base ">
            <button class="font-bold text-align: center; " v-on:click=" home"><i class="fas fa-user-circle " aria-hidden="true"></i>
              {{user}}
            </button>({{username}}) 様 ようこそ
            <button class="ml-4 hidden lg:inline xl:inline" v-on:click="home">
              <i class="fa fa-shopping-bag" aria-hidden="true"></i>
              ショッピングモール(GCOOP)
            </button>
          </div>
          <div v-else class="flex justify-center h-6   lg:h-12 xl:h-12 items-center p-2 text-xs md:text-base ">
            <button class="font-bold text-align: center; " v-on:click=" myofficeLink"><i class="fas fa-user-circle " aria-hidden="true"></i> {{user}}</button>({{username}}) 様 ようこそ
            <button class="ml-4 hidden lg:inline xl:inline" v-on:click="myofficeLink"><i class="fal fa-chart-line " aria-hidden="true"></i>マイオフィス メニュー ></button>
          </div>
        </div>
        <div class="max-w-7xl mx-auto px-2 xl:px-0 lg:px-0"> <!--lg:border-b xl:border-b border-gray-200-->
          <div class="flex h-16 lg:h-20 xl:h-20 items-center ">
            <button class="rounded-md bg-white p-2 text-gray-800" type="button" @click="open = true">
              <span class="sr-only">Open menu</span>
              <MenuIcon aria-hidden="true" class="xl:w-10 lg:h-10 xl:w-10 lg:h-10 w-6 h-6" />
            </button>
            <!-- Logo -->
            <div v-if="isDirect===true" class="xl:ml-4 lg:ml-4 ml-2 flex lg:ml-0">
              <a @click="directMain" style="cursor:pointer;">
                <span class="sr-only">GENERAL BIO</span>
                <img alt="GENERAL BIO LOGO" class="lg:w-60 xl:w-56 w-48 " src='/images/general_bio_ci.png' />
              </a>
            </div>
            <div v-else class="xl:ml-4 lg:ml-4 ml-2 flex lg:ml-0">
              <a href="/">
                <span class="sr-only">GCOOP JP</span>
                <img alt="GSC_logo" class="lg:w-60 xl:w-56 w-48 " src='/images/GCOOP_JAPAN_CI_C.png' />
              </a>
            </div>

            <!-- Flyout menus -->
            <PopoverGroup class="hidden lg:ml-8 lg:self-stretch">
              <div class="flex h-full space-x-8">
                <Popover v-for="category in navigation.categories" :key="category.name" class="flex" v-slot="{ open }">
                  <div class="relative flex">
                    <PopoverButton :class="[open ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-black hover:text-gray-800', 'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm lg:text-base xl:text-base font-medium transition-colors duration-200 ease-out']">{{ category.name }}</PopoverButton>
                  </div>

                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <PopoverPanel class="absolute inset-x-0 top-full text-sm text-gray-500 z-10">
                      <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                      <div aria-hidden="true" class="absolute inset-0 bg-white shadow top-[2]" />

                      <div class="relative bg-white border-t border-gray-200">
                        <div class="mx-auto px-8"> <!--max-w-7xl-->
                          <div class="flex py-6 px-4 mx-auto max-w-7xl">
                            <div class="">
                              <div v-for="item in category.featured" :key="item.name" class="group relative text-base sm:text-sm">
                                <div class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                  <img :alt="item.imageAlt" :src="item.imageSrc" class="object-cover object-center" />
                                </div>
                                <a :href="item.href" class="mt-6 block font-medium text-gray-900">
                                  <span aria-hidden="true" class="absolute inset-0 z-10" />
                                  {{ item.name }}
                                </a>
                                <p aria-hidden="true" class="mt-1">Shop now</p>
                              </div>
                            </div>
                            <div class="flex text-sm">
                              <div class="w-16 lg:w-24 xl:w-24"></div>
                              <div v-for="section in category.sections" :key="section.name" class="ml-8">
                                <p :id="`${section.id}-heading`" class="font-medium text-gray-900 lg:text-base xl:text-base">
                                  <a :href="section.href" class="text-gray-800 hover:text-gray-600">{{ section.name }}</a>
                                </p>
                                <ul :aria-labelledby="`${section.id}-heading`" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4" role="list" v-if="section.items">
                                  <li v-for="item in section.items" :key="item.name" class="flex">
                                    <a :href="item.href" class="text-gray-800 hover:text-gray-600">{{ item.name }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>

                <a v-for="page in navigation.pages" :key="page.name" :href="page.href" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">{{ page.name }}</a>
              </div>
            </PopoverGroup>

            <div class="ml-auto flex items-center justify-evenly">

              <!-- pc Search -->
              <div class="relative right-0 mr-6 hidden xl:block lg:block">
                <transition class="lg:relative" v-show=" isActiveSearch "> <!--top-16 xl:top-16 lg:top-16-->
                  <div class="flex justify-center rounded-2xl border-2 py-0 right-0 py-1.5 px-2.5 top-[40] lg:top-[-20px] xl:top-[-20px] min-w-[300] xl:min-w-[250] lg:min-w-[250] w-full bg-white z-10"  style="position:absolute !important" >
                    <form name="search_form" method="post" class="w-full mb-0" v-on:submit.prevent=" search_product ">
                      <input type="hidden" name="_token" :value="csrf">
                      <input type="search" ref="searchInput" class="outline-none outline-0 hover:outline-0 focus:outline-0 w-11/12 text-sm sm:text-base sm:ml-2 pt-1.5 pb-1 border-1 border-white rounded-lg lg:border-transparent lg:rounded-0 lg:border-0 lg:rounded-none lg:w-full" name="keyword" placeholder="検索キーワード入力" style="outline: none;">
                    </form>
                    <button @click=" search_product "><SearchIcon class="h-6 sm:h-8 w-6 sm:w-8 text-gray-400" aria-hidden="true" /></button>
                  </div>
                </transition>

                <a class="p-2 text-gray-400 hover:text-gray-500 top-[-20] absolute right-0" href="#" @click="isActiveSearch = !isActiveSearch;this.$refs.searchInput.focus();">
                  <span class="sr-only">Search</span>
                  <SearchIcon aria-hidden="true" class="h-6 w-6" />
                </a>
              </div>


              <!-- 비로그인 시에만 노출 필요-->
              <div class="flex items-center gap-1">
                <div class="hidden xl:flex lg:flex xl:items-center lg:items-center xl:justify-end lg:justify-end gap-2" v-if="!isLoggedIn">
                  <a class="text-sm font-medium text-gray-700 hover:text-gray-800" href="#" v-on:click=" login ">ログイン</a>
                  <span aria-hidden="true" class="h-6 w-px bg-gray-200" />
                  <a class="text-sm font-medium text-gray-700 hover:text-gray-800" href="#" v-on:click=" register ">会員入会</a>
                </div>

                <!--                            <div class="hidden lg:ml-8 lg:flex">-->
                <!--                                <a class="flex items-center text-gray-700 hover:text-gray-800" href="#">-->
                <!--                                    <img alt="" class="block h-auto w-5 flex-shrink-0" src="https://cdn-icons-png.flaticon.com/128/206/206626.png" />-->
                <!--                                    <span class="ml-3 block text-sm font-medium">USD</span>-->
                <!--                                    <span class="sr-only">, change currency</span>-->
                <!--                                </a>-->
                <!--                            </div>-->


                <!-- 모바일 Search -->
                <div class="flex lg:ml-6 xl:hidden lg:hidden">
                  <transition class="top-18  lg:relative" v-show=" isActiveSearch ">
                    <div class="w-full flex justify-center  lg:rounded-2xl lg:border-2 lg:relative lg:top-0 lg:py-0 absolute top-16 right-0 py-1.5 px-2.5 border-b border-gray-200 z-[10] bg-white border-t border-gray-200">
                      <form name="search_form_m" method="post" class="w-full" v-on:submit.prevent=" search_product_m">
                        <input type="hidden" name="_token" :value="csrf">
                        <input type="search" ref="searchInput" class="w-11/12 text-sm sm:text-base sm:ml-2 pt-1.5 pb-1 border-2 border-slate-300 rounded-lg" name="keyword" placeholder="検索キーワード入力">
                      </form>
                      <button @click=" search_product "><SearchIcon class="h-6 sm:h-8 w-6 sm:w-8 text-gray-300" aria-hidden="true" /></button>
                    </div>
                  </transition>
                  <a class="p-2 text-gray-400 hover:text-gray-500" href="#" @click="isActiveSearch = !isActiveSearch;this.$refs.searchInput.focus();">
                    <span class="sr-only">Search</span>
                    <SearchIcon aria-hidden="true" class="h-6 w-6" />
                  </a>
                </div>

                <!-- Cart -->
                <div class="p-1 lg:p-2" v-if="isLoggedIn">
                  <a class="group xl:-m-2 lg:-m-2  flex items-center p-2 relative" href="#" v-on:click=" cartLink ">
                    <ShoppingCartIcon aria-hidden="true" class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                    <span class="ml-1 text-sm font-medium text-gray-700 group-hover:text-gray-800" style="background: red; width: 20px; height: 20px; border-radius: 50%; text-align: center; color: #fff; font-size: 9px; position: absolute; top: -5px; right: -3px;">{{ countCartItems }}</span>
                    <span class="sr-only">items in cart, view bag</span>
                  </a>
                </div>
                  <div class="p-1 lg:p-2" v-if="isLoggedIn">
                      <a class="group xl:-m-2 lg:-m-2  flex items-center p-1 lg:p-2 relative" href="#" v-on:click=" AutoShipLink ">
                          <img src="/images/autoship_icon_gray.png" class="h-[24px] w-[24px] max-w-[24px] flex-shrink-0 text-gray-400 group-hover:text-gray-500">
                          <!--                                        <ShoppingBagIcon aria-hidden="true" class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />-->
                          <span class="ml-1 text-sm font-medium text-gray-700 group-hover:text-gray-800" style="background: red; width: 20px; height: 20px; border-radius: 50%; text-align: center; color: #fff; font-size: 9px; position: absolute; top: -5px; right: -3px;">{{ countAutoShipItems }}</span>
                          <span class="sr-only">items in AutoShip</span>
                      </a>
                  </div>

                  <!-- Profile dropdown -->
                <Menu as="div" class="relative">
                  <div v-if=" isLoggedIn && isDirect === false" class="p-2">
                    <MenuButton class="relative  flex text-sm rounded-full text-gray-400  ">
                      <UserIcon class="h-6 sm:h-6 w-6 sm:w-6" aria-hidden="true" />
                      <!--                                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />-->
                    </MenuButton>
                  </div>

                  <div v-if=" isLoggedIn && isDirect === true" class="p-2">
                    <button class="h-8 w-8 flex-shrink-0 text-gray-400 group-hover:text-gray-500" v-on:click="home">
                      <i class="far fa-shopping-bag fa-2"></i>
                    </button>
<!--                    <ArrowCircleLeftIcon aria-hidden="true" class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />-->
<!--                    <button class="h-8 w-8 flex-shrink-0 text-gray-400 group-hover:text-gray-500" v-on:click="home">-->
<!--                      <i class="far fa-shopping-bag"></i>-->
<!--                    </button>-->
                  </div>

                  <transition class="z-10" enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="origin-top-right absolute z-30 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem v-slot="{ active }" v-if="rank_cd !='00'">
                        <a href="#" @click="register('register','')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">会員入会</a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a href="#" @click="myofficeLink" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">マイオフィス</a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>

                <div v-if="isLoggedIn" class="p-1">
                  <button v-on:click.native='logout' class=" flex text-sm rounded-full text-gray-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <LogoutIcon class="w-6 h-6 sm:h-6 " />
                    <!--                                <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />-->
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </nav>
    </header>

  </div>



  <!--로그인-->
  <div class="absolute w-full" v-if=" loginForm ">
    <div class="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div
        class="w-full p-6 m-auto bg-white border-t border-purple-600 rounded shadow-lg shadow-purple-800/50 lg:max-w-md">
        <h1 class="text-3xl font-semibold text-center text-purple-700">LOGO</h1>

        <form class="mt-6">
          <div>
            <label for="email" class="block text-sm text-gray-800">ID</label>
            <input type="email"
                   class="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40">
          </div>
          <div class="mt-4">
            <div>
              <label for="password" class="block text-sm text-gray-800">Password</label>
              <input type="password"
                     class="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40">
            </div>
            <a href="#" class="text-xs text-gray-600 hover:underline">Forget Password?</a>
            <div class="mt-6">
              <button
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
                Login
              </button>
            </div>
          </div>
        </form>
        <p class="mt-8 text-xs font-light text-center text-gray-700"> Don't have an account? <a href="#" class="font-medium text-purple-600 hover:underline">Sign up</a></p>
      </div>
    </div>
  </div>
  <!--로그인-->
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot } from '@headlessui/vue'
import { SearchIcon, GlobeIcon, BellIcon, ShoppingCartIcon, ChartSquareBarIcon, LoginIcon,LogoutIcon, UserIcon, MenuIcon, XIcon, UserCircleIcon, ArrowCircleLeftIcon } from '@heroicons/vue/outline'
import VueSimpleAlert from "vue3-simple-alert";
import {eventBus} from "../../app";


export default {
  name: "LayoutTop",
  props: ['product_category', 'left_menu_icon'],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SearchIcon,
    GlobeIcon,
    BellIcon,
    ShoppingCartIcon,
    ChartSquareBarIcon,
    UserIcon,
    LoginIcon,
    LogoutIcon,
    MenuIcon,
    XIcon,
    UserCircleIcon,
    ArrowCircleLeftIcon,
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
    // Bars3Icon,
    // MagnifyingGlassIcon,
    // ShoppingBagIcon,
    // XMarkIcon,
    VueSimpleAlert
  },
  setup() {
    return {
      // navigation,
    }
  },
  data: () => ({
    isDirect : (window.isDirect === true),
    navigation: [],
    isLoggedIn: false,
    rank_cd: '',
    isNotHome: false,
    i18n: window.i18n,
    isActiveSearch: false,
    countCartItems: 0,
      countAutoShipItems : 0,
    selected: false,
    activeTabIndex: ['all'],
    userId : window.login_userid,
    username : '',
    open: false,
    loginForm: false,
    csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
  }),
  created() {

    // 카테고리 메뉴
    this.navigation = this.product_category;
    this.emitter.on('getCountCartItems',this.getCountCartItems)
      this.emitter.on('getCountAutoShipItems',this.getCountAutoShipItems);

  },
  mounted() {
    if (window.Laravel.isLoggedin) {
      this.isLoggedIn = true;
      this.user = window.Laravel.user;
      this.username = window.Laravel.username;
      this.rank_cd = window.Laravel.rank_cd;
      // 장바구니 담긴 상품수
      if(this.isLoggedIn)  this.getCountCartItems();
        if(this.isLoggedIn)  this.getCountAutoShipItems();
    }
    if(window.Laravel.current_route !== 'home'){
      this.isNotHome = true;
    }
  },
  methods: {
    // searchFormOpen: function(event) {if(isActiveSearch)},
    historyBack: function(event) {history.back();},
    cartLink: function(event) {
      location.href = (this.isDirect===true) ? '/cart/direct' : '/cart';
    },
      AutoShipLink: function(event) {location.href = "/AsCart";},
      mypageLink: function(event) {location.href = "/Mypage";},
    myofficeLink: function(event) {location.href = "/MyofficeMain";},
    announceLink: function(event) {location.href = "/Mypage/Notice";},
    login: function(event) {location.href = "/sso/login?bz_cd="+window.Laravel.bz_cd;},
    logout: function(event) {jslogout(); location.href = "/sso/logout";},
    register: function(event) { location.href = "/Register";},
    home: function(event) { location.href = "/";},
    directMain: function(event) { location.href = "/product/list/114";},
    addActiveIndex: function(idx) {
      this.activeTabIndex = [];
      this.activeTabIndex.push(idx);
    },
    // 장바구니 담긴 상품수
    getCountCartItems(){

      // 직구 카테고리 ( 114 ) 인 경우, 호출하는 api 를 분기하여 적용한다. add by hskim :: 2024-01-02 오후 2:14
      const apiPath = (this.isDirect===true) ? '/api/dir_cart_count' : '/api/cartCount';

      // axios.post(process.env.MIX_BACK_URL+'/api/cartCount',{
      axios.post(process.env.MIX_BACK_URL + apiPath, {
        bz_cd: window.Laravel.bz_cd,
      }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}})
        .then(response => {
          this.countCartItems = response.data.cartCount;
        });
    },
      getCountAutoShipItems(){
          axios.post(process.env.MIX_BACK_URL+'/api/getAsCartList',{
              bz_cd: window.Laravel.bz_cd
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
              .then(response => {

                  // this.countAutoShipItems = (response.data.length);

                  if ( response.data.length > 0 ) {
                      this.countAutoShipItems = response.data.reduce((sum, item) => sum + parseInt(item.qty, 10), 0);
                  }else{
                      this.countAutoShipItems = 0;
                  }

              });
      },

      search_product(){
      const action = (this.isDirect === true ) ? '/product/search/direct' : '/product/search';
      let form = document.search_form;
      if(form.keyword.value == ""){
        VueSimpleAlert.alert("キーワードを入力してください","","",{confirmButtonText:"確認"});
      }else{
        form._token.value = this.csrf;
        form.method = 'POST';
        form.action = action;
        form.submit();
      }
    },
    search_product_m(){
      let form = document.search_form_m;
      if(form.keyword.value == ""){
        VueSimpleAlert.alert("キーワードを入力してください","","",{confirmButtonText:"確認"});
      }else{
        form._token.value = this.csrf;
        form.method = 'POST';
        form.action = '/product/search';
        form.submit();
      }
    },

    goAutoLogin(mode, url) {
      window.open("https://register-global.gcoop.com/AutoLogin?xid="+window.Laravel.xid_info+"&token="+window.Laravel.xid_info);

      // let config = {
      //     headers: {
      //         Authorization: 'Bearer ' + window.Laravel.access_token
      //     }
      // };
      //
      // axios.post(window.Laravel.back_url +'/api/goAutoLogin',{
      //     mode: mode,
      //     url: url,
      //     bz_cd: window.Laravel.bz_cd,
      // },config).then(response => {
      //     if(response.data.href_url != ""){
      //         window.open(response.data.href_url);
      //     }
      // }).catch(e => {
      // });
    },

  },
}
</script>
