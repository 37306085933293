<template>
    <!--  <section class="absolute w-full min-h-screen">-->
    <!--    <div :style="full_height" class="absolute w-full min-h-screen m-0 p-0 item-center bg-gray-500 opacity-50 z-30"></div>-->
    <!--    <div :style="item_top" class="absolute w-full h-full flex justify-center z-40">-->
    <!--      <div class="absolute lg:w-1/3 my-auto mx-auto px-2 text-start bg-white">-->
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
        <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <div class="w-full p-1 justify-between ">
        <div class="text-right"><span @click=" close_address_book " class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
        <h2 class="w-full text-lg xl:text-xl lg:text-xl font-semibold text-gray-800 border-l-[10px] border-[#4493d5] pl-3">
            {{ i18n.shop.mypage.YourAddresses }}
            <div class="text-[#172e59] text-xs font-semibold">( {{ i18n.shop.shopping.newshippingaddress }} )</div>
        </h2>
    </div>
    <div class="w-full py-2 mb-24 md:mb-4 overflow-scroll sm:overflow-hidden">
        <ul class="flex py-2">
            <li class="flex-1 mr-2 items-center border-blue-500 py-2 px-4" @click="currentTab = 1" :class="currentTab === 1 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white border rounded' : 'border-white border-gray-200 text-gray-500 hover:bg-gray-200 border rounded'">
                <a class="text-center block xl:text-base lg:text-base text-sm" href="#">{{ i18n.shop.shopping.SavedShippingAddresses }}</a>
            </li>
            <li class="flex-1 mr-2 items-center py-2 px-4" @click=" get_create_address_book " :class="currentTab === 2 ? 'border-blue-500  bg-blue-500 hover:bg-blue-700 text-white border rounded' : 'border-white border-gray-200 text-gray-500 hover:bg-gray-200 border rounded'">
                <a class="text-center block xl:text-base lg:text-base text-sm" href="#">{{ i18n.shop.shopping.SaveMyShippingInformation }}</a>
            </li>
        </ul>

        <div v-for="(address) in address_list" :key="address.seq_oab" class="border bg-white dark:bg-gray-200 dark:border-gray-100 font-thin block items-center mb-2 xl:mb-0 lg:mb-0 shadow-md shadow-slate-200/40 border-t rounded-md py-3 last:mb-14 " v-if="currentTab === 1">
            <div class="w-full px-4 py-2 flex justify-between border-b items-center">
                <h4 class="font-semibold text-gray-800">{{ address.r_nick }}</h4>
                <p @click=" delete_item(address.seq_oab) " class="cursor-pointer p-2 font-semibold text-gray-800">X</p>
            </div>
            <div class="">
                <div class="py-1.5 px-3 block mt-1 mb-2 ">
                    <p v-show="address.default_yn == 'Y'" class="bg-rose-600 cursor-default text-white w-16 mr-1 xl:text-sm lg:text-sm text-xs text-white py-1 font-semibold text-center">{{ i18n.shop.shopping.SelectDefault }}</p>
                </div>
                <div class="w-full lg:basis-2/5 text-md lg:text-md xl:text-md">
                    <div class="py-1.5 px-3 text-[#591734] font-medium block">{{ i18n.shop.shopping.Address }}</div>
                    <div class="py-1.5 px-3 font-normal w-full lg:text-left">{{ (address.r_addr1).replace(/\|@\|/g, " ") }} {{ (address.r_addr2).replace(/\|@\|/g, " ") }}</div>
                </div>
                <div class="w-full lg:basis-2/5 text-md lg:text-md xl:text-md">
                    <div class="py-1.5 px-3 text-[#591734] font-medium block">{{ i18n.shop.shopping.Name }}</div>
                    <div class="py-1.5 px-3 font-normal w-full lg:text-left">{{ address.r_firstname }} {{ address.r_lastname }}</div>
                </div>
                <div class="w-full lg:basis-2/5 text-md lg:text-md xl:text-md">
                    <div class="py-1.5 px-3 text-[#591734] font-medium block">{{ i18n.shop.shopping.CellPhoneNumber }}</div>
                    <div class="py-1.5 px-3 font-normal w-full lg:text-left">{{ address.r_mobile }}</div>
                </div>
                <div class="text-center w-full flex px-2 mt-4" v-show="this.mypage_yn != 'Y'">
                    <button @click=" get_info_address_book(address.seq_oab) " class="my-2 py-2 px-2 bg-[#d1d5de] hover:bg-[#cbcfdd] text-[#591734] text-center text-sm w-full mr-2 xl:text-base lg:text-base text-sm">{{ i18n.shop.shopping.Select }}</button>
                    <button @click=" get_edit_address_book(address.seq_oab) " class="my-2 py-2 px-2 bg-[#2c7fc3] hover:bg-[#24679f] text-white text-center w-full xl:text-base lg:text-base text-sm ">{{ i18n.shop.shopping.Edit }}</button>
                </div>
                <div class="text-center w-full flex px-2 mt-4" v-show="this.mypage_yn == 'Y'">
                    <button @click=" delete_item(address.seq_oab) " class="my-2 py-2 px-2 bg-[#d1d5de] hover:bg-[#cbcfdd] text-[#591734] text-center text-sm w-full mr-2 xl:text-base lg:text-base text-sm">{{ i18n.shop.shopping.Delete }}</button>
                    <button @click=" get_edit_address_book(address.seq_oab) " class="my-2 py-2 px-2 bg-[#2c7fc3] hover:bg-[#24679f] text-white text-center w-full xl:text-base lg:text-base text-sm">{{ i18n.shop.shopping.edit }}</button>
                </div>
            </div>
        </div>
        <div v-if="currentTab === 2">
            <form name="shipping_info">
                <input type="hidden" name="_token">
                <input type="hidden" name="client_ip">
                <div class="my-4 space-x-0 lg:flex lg:space-x-4">
                    <div class="w-full lg:w-1/3">
                        <label class="block mb-3 text-sm font-semibold text-gray-500">名字</label>
                        <input type="text" placeholder="名字" maxlength="45" v-model='this.shipping_info["d_firstname"]'
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="w-full lg:w-1/3">
                      <label class="block mb-3 text-sm font-semibold text-gray-500">名前</label>
                      <input type="text" placeholder="名前" maxlength="45" v-model='this.shipping_info["d_lastname"]'
                             class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                    <div class="w-full lg:w-1/3 mt-2 lg:mt-0 ">
                        <label for="mobile" class="block mb-3 text-sm font-semibold text-gray-500">{{ i18n.shop.shopping.CellPhoneNumber }}</label>
                        <input name="mobile" id="mobile" ref="d_mobile" type="text" @keyup="regNumber('d_mobile')" placeholder="携帯電話" v-model='this.shipping_info["d_mobile"]'
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600">
                    </div>
                </div>
                <div class="mt-4 space-x-0 flex-wrap lg:flex ">
                    <div class="relative z-[11] w-full bg-white ">
                        <label for="postcode" class="block mb-3 text-sm font-semibold text-gray-500 mt-2 lg:mt-0">{{ i18n.shop.shopping.title_postcode }}</label>
                        <input name="postcode" id="postcode" ref="d_post" type="text" placeholder="郵便番号" v-model='this.shipping_info["d_post"]' readonly @click="search_address"
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="35">
                    </div>
                    <div class="mt-4 w-full">
                        <input type="button" @click="search_address" value="住所検索" class="bg-blue-500 font-semibold hover:bg-[#2468a0] py-3 text-sm text-white uppercase w-full">
                    </div>

                    <div class="relative z-[13] mt-4 w-full bg-white ">
                        <label for="city" class="block mb-3 text-sm font-semibold text-gray-500 mt-2 lg:mt-0">住所</label>
                        <input name="city" id="city" ref="d_city" type="text" placeholder="住所" v-model='this.shipping_info["d_city"]' readonly @click="search_address"
                               class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 cursor-pointer" maxlength="35">
                    </div>
                    <div class="mt-4 space-x-0 w-full bg-white ">
                        <div class="w-full bg-white ">
                            <label for="address" class="block mb-3 text-sm font-semibold text-gray-500">番地(建物名・部屋番号まで入力してください)</label>
                            <input name="address" id="address" ref="d_address" type="text" placeholder="" v-model='this.shipping_info["d_address"]'
                                   class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"  >
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <input type="checkbox" name="default" id="default_chk" v-model="checked">
                    <label for="default_chk"> {{ i18n.shop.etc.alertdefaultshipping }} </label> <!-- 선택한 주소를 기본 배송 주소로 설정하겠습니다. -->
                </div>
            </form>
            <div class="mt-4 space-x-0 flex justify-center lg:space-x-4 mb-14">
                <button @click="currentTab = 1" class="my-2 py-2 px-2 bg-[#d1d5de] hover:bg-[#cbcfdd] text-[#172e59] text-center text-sm w-full mr-2 xl:text-base lg:text-base text-sm">{{ i18n.shop.shopping.cancle }}</button>
                <button @click=' address_update(this.shipping_info["seq"]) ' class="my-2 py-2 px-2 bg-[#2c7fc3] hover:bg-[#24679f] text-white text-center w-full xl:text-base lg:text-base text-sm" v-if='this.shipping_info["seq"]'>{{ i18n.shop.shopping.Edit }}</button>
                <button @click=" address_add " class="my-2 py-2 px-2 bg-[#2c7fc3] hover:bg-[#24679f] text-white text-center w-full xl:text-base lg:text-base text-sm" v-if='!this.shipping_info["seq"]'>{{ i18n.shop.shopping.Register }}</button>
            </div>
        </div>
    </div>
    <!--      </div>-->
    <!--    </div>-->
    <!--  </section>-->
</template>

<script>
import vSelect from "vue-select";
import alert from "vue3-simple-alert";
import modalView from "./modalView";

export default {
    name: "addressBook",
    components : {
        vSelect,
        alert,
        modalView,
    },
    /*props:{
      openlayer: Boolean
    },*/
    props:['mypage_yn'],
    data(){
        //console.log(this.mypage_yn);
        return {
            i18n: window.i18n,
            address_book: Boolean,
            currentTab: 1,
            csrf: document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').content : '',
            address_list: [],
            state_list: [],
            city_list: [],
            street_list: [],
            post_list: [],
            shipping_info: [],
            height: 0,
            full_height: {},
            openlayer:Boolean,
            item_top: {},
            address_search_open:false,
        };
    },
    computed : {
        openlayer: function(){
            this.address_book = this.openlayer;
            //return this.set_open;
        }
    },
    created : function () {

        this.getAddressBook();
        let body = document.body,
            html = document.documentElement;

        this.height = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight );

    },
    mounted() {
        this.full_height = {'height':this.height};
        this.item_top = {'top':this.height - (this.height*0.75)};
    },
    methods : {
        getAddressBook(){
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook',{
                bz_cd: window.Laravel.bz_cd,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.address_list = response.data;
                })
        },
        regNumber(colum){
            var reg = /'/gi;
            var text = this.shipping_info[colum];
            text = text.replace(/[^0-9]/g,'');
            this.shipping_info[colum] = text;
        },
        get_info_address_book(id){
            this.$emit("get_info_address_book",id);
            this.$emit("close_address_book");
        },
        delete_item(seq){
            alert.confirm("こちらの住所は完全に削除されます。").then(() => {
                axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_delete',{
                    bz_cd: window.Laravel.bz_cd,
                    seq: seq,
                },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                    .then(response => {
                        this.address_list = response.data;
                    })
            })
        },
        get_create_address_book()
        {
            this.currentTab = 2;
            this.shipping_info["seq"] = '';
            this.shipping_info["d_firstname"] = '';
            this.shipping_info["d_lastname"] = '';
            // this.shipping_info["d_state"] = '';
            this.shipping_info["d_city"] = '';
            this.shipping_info["d_post"] = '';
            this.shipping_info["d_address"] = '';
            this.shipping_info["d_telephone"] = '';
            this.shipping_info["d_mobile"] = '';
            this.shipping_info["default"] = '';
        },
        get_edit_address_book(id)
        {
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_item',{
                seq:id
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    this.currentTab = 2;
                    this.shipping_info["seq"] = response.data.seq_oab;
                    this.shipping_info["d_firstname"] = response.data.r_firstname;
                    this.shipping_info["d_lastname"] = response.data.r_lastname;
                    // this.shipping_info["d_state"] = (response.data.r_addr1).split("|@|")[1];
                    this.shipping_info["d_city"] = (response.data.r_addr1).split("|@|")[0];
                    this.shipping_info["d_post"] = response.data.r_post;
                    this.shipping_info["d_address"] = response.data.r_addr2;
                    this.shipping_info["d_telephone"] = response.data.r_tel;
                    this.shipping_info["d_mobile"] = response.data.r_mobile;
                    // this.shipping_info["country"] = response.data.r_country;
                    this.checked = (response.data.default_yn === 'Y');
                })
        },
        // 주소록 수정하기
        address_update(id){
            if(!this.shipping_info["d_firstname"]){
                alert.alert('Please enter reciever name');
                return false;
            }
            if(!this.shipping_info["d_lastname"]){
                alert.alert('Please enter reciever name');
                return false;
            }
            if(!this.shipping_info["d_mobile"]){
                alert.alert('Please enter mobile No.');
                return false;
            }
            if(!this.shipping_info["d_city"]){
                alert.alert('Please select shipping City information');
                return false;
            }
            if(!this.shipping_info["d_post"]){
                alert.alert('Please select shipping Postcode information');
                return false;
            }

            let format = new FormData();
            format.append("_token", this.csrf);
            format.append("seq", id);
            format.append("bz_cd", window.Laravel.bz_cd);
            format.append("firstname", this.shipping_info.d_firstname);
            format.append("lastname", this.shipping_info.d_lastname);
            format.append("mobile", this.shipping_info.d_mobile);
            format.append("address", this.shipping_info.d_address);
            format.append("post", this.shipping_info.d_post);
            format.append("city", this.shipping_info.d_city);
            // format.append("state", this.shipping_info.d_state);
            // format.append("country", this.shipping_info.country);
            format.append("default", this.checked);
            format.append("client_ip", window.Laravel.client_ip);
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_update', format,
                {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.currentTab = 1;
                    this.address_list = response.data;
                });
        },
        // 주소록 추가하기
        address_add(){
            if(!this.shipping_info["d_firstname"]){
                alert.alert('名前を入力してください。'); // 이름을 입력해주세요.
                return false;
            }
            if(!this.shipping_info["d_lastname"]){
                alert.alert('姓を入力してください。'); // 성을 입력해주세요.
                return false;
            }
            if(!this.shipping_info["d_mobile"]){
                alert.alert('携帯電話番号を入力してください'); // 핸드폰 번호를 입력해주세요.
                return false;
            }
            if(!this.shipping_info["d_city"]){
                alert.alert('配送エリアを入力してください。'); // 배송 지역을 입력해주세요.
                return false;
            }
            if(!this.shipping_info["d_city"]){
                alert.alert('配送エリアを入力してください。'); // 배송 지역을 입력해주세요.
                return false;
            }
            if(!this.shipping_info["d_post"]){
                alert.alert('郵便番号を入力してください。'); // 우편번호를 입력해주세요.
                return false;
            }

            let format = new FormData();
            format.append("_token", this.csrf);
            format.append("bz_cd", window.Laravel.bz_cd);
            format.append("firstname", this.shipping_info.d_firstname);
            format.append("lastname", this.shipping_info.d_lastname);
            format.append("mobile", this.shipping_info.d_mobile);
            format.append("address", this.shipping_info.d_address);
            format.append("post", this.shipping_info.d_post);
            format.append("city", this.shipping_info.d_city);
            // format.append("state", this.shipping_info.d_state);
            // format.append("country", this.shipping_info.country);
            format.append("default", this.checked);
            format.append("client_ip", window.Laravel.client_ip);
            axios.post(process.env.MIX_BACK_URL+'/api/getAddressBook_store', format,
                {headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
                .then(response => {
                    // console.log(response.data);
                    if(response.data.msg){
                        alert(response.data.msg);
                    }
                    this.currentTab = 1;
                    this.address_list = response.data;
                });
        },
        close_address_book(){
            this.$emit("close_address_book");
        },
        search_address(){
            this.address_search_open = true;
        },
        set_address(value){
            this.address_search_open = false;
            // this.shipping_info["d_state"] =value.state;
            // this.shipping_info["d_city"] = value.district;
            // this.shipping_info["d_post"] = value.postcode;
            // this.shipping_info["country"] = value.country;

            this.shipping_info["d_city"] = value.address + " " + value.address2 + " " + value.address3;
            this.shipping_info["d_post"]    = value.postcode;
        },
        close_address_search(){
            this.address_search_open = false;
        }

    }
}
</script>

<style>

</style>
