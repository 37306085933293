<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <modalView class="z-50" v-if="address_search_open" @close-modal="address_search_open = false" :harf="true"  >
      <address_search @set_address="set_address" @close_address_search="close_address_search" />
    </modalView>

    <div>
        <div >
            <ul class="border divide-slate-200 mt-5 relative"> <!-- class="mt-5 relative border-gray-400 border-solid"  class="border divide-slate-200 mt-5 relative"-->
                <li class="table w-full table-fixed border-b divide-slate-200">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.refund.profile}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">{{ mem.userid }} ( {{ mem.username }})</span>
                </li>
                <li class="table w-full table-fixed border-b divide-slate-200">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normall">{{ i18n.myoffice.refund.orderNumber}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700"><div class="i_contents">{{ ord_no }}</div>
                        <p class="order_info">{{ i18n.myoffice.Order.PurchaseDate}}<span id="acc_dates"> {{ row.acc_dates }} </span>, {{ i18n.myoffice.Order.PastDueDate}} <span id="diff_days">{{ row.diff_days }}</span></p></span><!-- 구매일 : 경과기일 :-->
                </li>
                <li class="table w-full table-fixed border-b divide-slate-200">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.refund.applicationType}}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label">
                        <label class='radio_input_box'><input type='radio' v-model="refund_type" value='refund_cancle' checked><span class='checkmark'></span>{{ i18n.myoffice.refund.cancel }}</label>
                        <label class='radio_input_box'><input type='radio' v-model="refund_type" value='cancle_gpauto' ><span class='checkmark'></span>{{ i18n.myoffice.refund.cancelWithdrawal }}</label>
<!--                        <label class='radio_input_box' v-if="row_deli.r_city == 'USA' && row_deli.deli_kind !='1'"><input type='radio' v-model="refund_type" value='change_address'><span class='checkmark'></span>{{ i18n.myoffice.refund.changeShippingAddress }}</label>-->
                      <label class='radio_input_box' v-if="row_deli.deli_kind =='2' && diff_days == '0'"><input type='radio' v-model="refund_type" value='change_address'><span class='checkmark'></span>{{ i18n.myoffice.refund.changeShippingAddress }}</label>
                    </span>
                </li>
                <li class="table w-full table-fixed border-b divide-slate-200 " v-show="refund_type == 'change_address'">
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal hidden md:block">
                        <span v-show="r_type == 'C'">{{ i18n.myoffice.refund.changeShippingAddress }}</span>
                        <span v-show="r_type != 'C'">{{ i18n.myoffice.Address}}</span>
                    </span>
                    <span class="table-cell align-middle p-2.5 text-gray-700">
                        <ul class="mgb_30">
                            <li class="table w-full table-fixed border-b divide-slate-200">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.Form.Reciever}}</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents z-30 relative">
                                  <input type="text" placeholder="受領人" v-model='d_name'
                                         class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 h-11">
                                </span>
                            </li>
                            <li class="table w-full table-fixed border-b divide-slate-200">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.Form.phone}}</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents z-30 relative">
                                      <input type="text" placeholder="Mobile" v-model='d_mobile'
                                     class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 h-11">
                                </span>
                            </li>
                            <!-- 주소지 영역-->
                            <li class="table w-full table-fixed border-b divide-slate-200">
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-2/12 break-words border-r border-slate-200 bg-slate-300 font-normal">{{ i18n.myoffice.Form.address}}</span>
                                <span class="block md:table-cell align-middle p-2.5 text-gray-700 item_content_label m_contents">
                                    <input @click="search_address"  name="d_post" id="d_post" class="w-1/2 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px h-11" v-model="d_post" type="text" placeholder="郵便番号">
                                    <input type="button" @click="search_address" value="検索" class="bg-indigo-500 font-semibold hover:bg-[#2468a0] py-3 text-sm text-white uppercase w-1/2">
<!--                                    <input @click="search_address"  name="d_city" id="d_city" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px h-11" v-model="d_city" type="text" placeholder="都市">-->
                                    <input @click="search_address" name="d_addr2_1" id="d_addr2_1" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px h-11" v-model="d_addr2_1" type="text" placeholder="配送地住所 1">
                                    <input class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px h-11" v-model="d_addr2_2" type="text" placeholder="">
<!--                                    <input @click="search_address"  name="d_city" id="d_city" class="w-1/2 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px h-11" v-model="d_city" type="text" placeholder="CITY">-->
<!--                                  <input @click="search_address"  name="d_state" id="d_state" class="w-1/2 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600 mb-px h-11" v-model="d_state" type="text" placeholder="STATE">-->
                                </span>
                            </li>
                        </ul>
                    </span>
                </li>
            </ul>
            <div id="cancelOrderRefund" style="margin:20px 0; padding:0 15px;">
                <div class="myo_condition_search2">
                    <button type="submit" class="search_btn" v-on:click="submitForm" v-show="row.processing < 20">{{ i18n.myoffice.refund.apply }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import vSelect from "vue-select";
import modalView from "../../modalView";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyViewOrderCancel",
    components : {
        alert,
        Loading,
        vSelect,
        modalView
    },
    props : {
        ord_no : String,
        r_type : String,
        searchType : String,
        schUserId : String,
    },
    data: () => ({
        i18n: window.i18n,
        row: "",
        mem : "",
        refund_type: "refund_cancle",
        loading : false,
        d_name : "",
        d_mobile : "",
        d_state : "",
        d_city : "",
        d_post : "",
        address_search_open:false,
        row_deli : "",
        r_state : "",
        d_addr2_1 : "",
        d_addr2_2 : "",
        diff_days : "",
    }),
    watch : {
      d_mobile : function(val){
        return this.d_mobile = this.d_mobile.replace(/[^0-9]/g, '');
      },
    },
    created(){
        this.loading = true;
        console.log(this.ord_no);
        axios.post(window.Laravel.back_url+'/api/getOrdMst',{
            ord_no : this.ord_no,
        },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {

            // isDirect : 전역으로 사용 ( 우편번호 찾기에서도 isDirect 값을 전역으로 받아와 사용하므로 우편번호 찾기에 값을 전달하기 위해서라도 전역으로 사용 )
            window.isDirect = response.data.row.isDirect

            if(response.data.c_num != ""){ // 이미 신청한 기록이 있습니다.
                alert.alert("すでに申請した記録があります。","","",{allowOutsideClick : false}).then(r => {
                    this.$emit('close-modal');
                    location.replace("/MyOrderCancel");
                });
            }

            //배송지 주소 로딩
            axios.post(window.Laravel.back_url+'/api/getOrdDeli',{
              ord_no : this.ord_no,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response2 => {
              this.row_deli = response2.data.row_deli;
              // this.r_state = (this.row_deli.r_addr1).split("|@|")[1];

              this.row = response.data.row;
              this.mem = response.data.mem;

              this.d_mobile  = this.row_deli.r_mobile;
              this.d_name    = this.row_deli.r_name;
              this.d_city    = this.row_deli.r_city;
              this.d_post    = this.row_deli.r_post;
              this.d_addr2_1 = this.row_deli.r_addr1;
              this.d_addr2_2 = this.row_deli.r_addr2;

              this.diff_days = response.data.row.diff_days; // 당일만 주문변경 가능

              this.loading = false;
            });
        });
    },
    methods : {

        isJapaneseCheck(str){
          const japaneseRegex = /[\u3040-\u309F\u30A0-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uFF66-\uFF9F]+/;
          return (japaneseRegex.test(str))
        },
        submitForm : function (){

            var url = "orderCancel";
            if(this.refund_type == "change_address"){
                url = "shippingChange";
            }

            // 주소 변경 시, 직구 제품에 대해서는 영문으로 이름 / 주소지를 입력하도록 알럿 기능을 추가
            if (window.isDirect === true && this.refund_type === 'change_address') {

              if (this.isJapaneseCheck(this.d_name) || this.d_name === '') {
                alert.alert('氏名を英字で入力して下さい。', '', '', {}).then((result) => {
                  this.d_name = '';
                });
                return;
              } else if (this.isJapaneseCheck(this.d_addr2_1)) {
                alert.alert('郵便番号を利用してお届け先の住所を英文でご記入ください。', '', '', {}).then((result) => {
                  this.d_addr2_1 = '';
                  this.d_addr2_2 = '';
                });
                return;
              } else if (this.isJapaneseCheck(this.d_addr2_2)) {
                alert.alert('詳細住所を英文で入力してください。', '', '', {}).then((result) => {
                  this.d_addr2_2 = '';
                });
                return;
              }
            }

            this.loading = true;

            axios.post(window.Laravel.back_url+'/api/'+url,{
              refund_type : this.refund_type,
              // d_addr2 : this.d_addr2_1 + "|@|" + this.d_addr2_2,
              d_addr2  : this.d_addr2_2,
              d_mobile : this.d_mobile,
              d_name : this.d_name,
              ord_no : this.ord_no,
              d_post : this.d_post,
              // d_state : this.d_state,
              d_state : this.d_addr2_1,
              d_city : this.d_city,
              processing : 1,
              cmemo : ''
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.result == "0"){
                  alert.alert(response.data.msg,"","",{allowOutsideClick : false}).then(r => {});
                  return;
                }
                alert.alert(response.data.msg,"","",{allowOutsideClick : false}).then(r => {
                    this.$emit('close-modal');
                    window.location.reload();
                });
                this.loading = false;

                // this.row = response.data.row;
                // this.list_temp_str = response.data.list_temp_str;
                // this.loading = false;
            });

        },
        search_address(){
          this.address_search_open = true;
        },
        set_address(value){
          const okinawa_msg = "配送先変更：「沖縄・離島・僻地」地域への注文は、同地域内でのみ配送先の変更が可能です。別地域への変更をご希望の場合は１：１問い合わせフォームか再度ご注文いただきますようお願いいたします。";
          if(this.d_post.substring(0, 2) == '90') { // 변경 전이 오키나와 주소일 때
            if(value.postcode.substring(0, 2) != '90') {
              alert.alert(okinawa_msg,"","",{});
              return; // 오키나와 - 다른 지역 : 변경 불가, 오키나와 - 오키나와 : 변경 가능
            }
          } else { // 오키나와 지역이 아닌 주소일 때
            if(value.postcode.substring(0, 2) == '90') {
              alert.alert(okinawa_msg,"","",{});
              return; // 다른 지역 - 오키나와 : 변경 불가, 다른 지역 - 다른 지역 : 변경 가능
            }
          }
          this.address_search_open = false;
          // this.d_state =value.state;
          // this.d_city = value.district;
          this.d_post    = value.postcode;
          this.d_city    = value.address;
          this.d_addr2_1 = value.address + " " + value.address2 + " " + value.address3;
        },
        close_address_search(){
          this.address_search_open = false;
        }

    }

}
</script>

<style>
</style>
