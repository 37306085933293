<template>
    <div class="w-full p-1 justify-between ">
        <div class="text-right"><span @click=" close_bank_search "  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
        <h2 class="w-full text-lg xl:text-xl lg:text-xl font-semibold text-gray-800 border-l-[10px] border-[#4493d5] pl-3">
          金融機関コード / 支店コード
          <div class="text-[#2c7fc3] text-xs font-semibold">( 金融機関コード(４桁)、支店コード(３桁)を入力してください。)</div> <!-- 금융 기관 코드 (4 자리), 지점 코드 (3 자리)를 입력하십시오. -->
        </h2>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        <div class="" >
          <div v-if="bank_type === 'bank'" class="w-full justify-between border-b">
                <div class="w-full justify-between bg-blue-500 font-semibold hover:bg-[#2468a0] text-white px-2 py-2">
                  <a class="text-center block xl:text-base lg:text-base text-sm" target='_blank' href="http://zengin.ajtw.net">銀行コード検索</a>
                </div>
                <label class="block my-4 mb-3 text-sm font-semibold text-gray-500">金融機関コード</label>
                <div class="flex">
                    <input type="text" v-model="bank_code" class="w-full lg:w-3/6 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="4" oninput="this.value = this.value.replace(/\D/g, '')">
                    <input type="button" @click="bank_code_Search" value="検索" class="bg-blue-500 font-semibold hover:bg-[#2468a0] px-4 py-3 text-sm text-white uppercase w-full lg:w-3/6 mx-1 cursor-pointer">
                </div>
            </div>
            <div v-if="bank_type === 'branch'" class="w-full px-2 py-4 justify-between border-b">
                <label class="block mb-3 text-sm font-semibold text-gray-500">支店コード</label>
                <div class="flex">
                    <input type="text" v-model="branch_code" class="w-full lg:w-3/6 px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" maxlength="3" oninput="this.value = this.value.replace(/\D/g, '')">
                    <input type="button" @click="branch_code_Search" value="検索" class="bg-blue-500 font-semibold hover:bg-[#2468a0] px-4 py-3 text-sm text-white uppercase w-full lg:w-3/6 mx-1 cursor-pointer">
                </div>
            </div>
            <div class="w-full p-4 overflow-auto	">
                <div class="grid grid-cols-5 bg-gray-200 font-semibold text-sm text-center">
                    <div class="p-2"><br>コード</div>
                    <div class="p-2">金融機関<br>コード<br>(漢字)</div>
                    <div class="p-2">金融機関<br>コード<br>(カタカナ)</div>
                    <div class="p-2">金融機関<br>コード<br>(ひらがな)</div>
                    <div class="p-2">金融機関<br>コード<br>(ローマ字)</div>
                </div>
                <div class="grid grid-cols-5 border border-gray-200 w-full hover:bg-slate-100	cursor-pointer text-sm text-center"
                     v-if="bankList.length > 0" v-for="(value,index) in bankList" @click="onChangeBank(value.id)">
                    <div class="p-1">{{ value.id.code }}</div>
                    <div class="p-2 break-words">{{ value.id.name }}</div>
                    <div class="p-2 break-words">{{ value.id.kana }}</div>
                    <div class="p-2 break-words">{{ value.id.hira }}</div>
                    <div class="p-2 break-words">{{ value.id.roma }}</div>
                </div>
                <div class="grid grid-cols-5 border border-gray-200 w-full hover:bg-slate-100	cursor-pointer text-sm" v-else>
                    <div class="p-2 col-span-5 text-gray-500 text-center">{{ result_label }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "bankSearch",
    components : {
        vSelect,
    },
    props:['bank_type', 'bank_cd'],
    data: () => ({
        bankList     : [],
        bank_code    : "",
        branch_code  : "",
        result_label : "検索語を入力してください。", // 검색어를 입력해주세요.
    }),
    methods:{
      bank_code_Search(){
          if(!this.bank_code) { // 금융 기관 코드 (4 자리)를 입력해주세요.
            VueSimpleAlert.alert("金融機関コード（4桁）を入力してください。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return;
          }
          axios.post(process.env.MIX_BACK_URL+'/api/getBankSearchList',{
            bank_type : this.bank_type.trim(),
            bank_code : this.bank_code.trim(),
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
              .then(response => {
                  this.result_label = "";
                  if(response.data.ret === '-1'){
                       VueSimpleAlert.alert(response.data.result,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
                       return;
                  }
                  if(response.data.result.length < 1){
                      this.result_label = "検索された結果はありません。"; // 검색된 결과가 없습니다.
                      return;
                  }
                  this.bankList = [];
                  response.data.result.forEach((value, index) => {
                      this.bankList.push({
                          label : value.code,
                          id    : {"code" : value.code, "name" : value.name, "hira" : value.hira, "kana" : value.kana, "roma" : value.roma, 'bank_type' : this.bank_type}
                      });
                  });
              })
              .catch(e => {
              });
        },
        branch_code_Search(){
          if(!this.branch_code) { // 지점 코드 (3 자리)를 입력하십시오.
            VueSimpleAlert.alert("支店コード（3桁）を入力します。","","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
            return;
          }
          axios.post(process.env.MIX_BACK_URL+'/api/getBankSearchList',{
            bank_type   : this.bank_type.trim(),
            branch_code : this.branch_code.trim(),
            bank_code   : this.bank_cd.trim()
          },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
            .then(response => {
              this.result_label = "";
              if(response.data.ret === '-1'){
                  VueSimpleAlert.alert(response.data.result,"","",{allowOutsideClick : false,confirmButtonText:"確認"}).then((result) => {});
                  return;
              }
              if(response.data.result.length < 1){
                  this.result_label = "検索された結果はありません。"; // 검색된 결과가 없습니다.
                  return;
              }
              this.bankList = [];
              response.data.result.forEach((value, index) => {
                  this.bankList.push({
                      label : value.bank_code + value.branch_code,
                      id    : {"code" : value.branch_code, "name" : value.name, "hira" : value.hira, "kana" : value.kana, "roma" : value.roma, 'bank_type' : this.bank_type}
                  });
              });
            })
            .catch(e => {
            });
        },
        onChangeBank(bank_select){
            this.$emit("set_bank", bank_select);
        },
        close_bank_search(){
            this.$emit("close_bank_search");
        },

    }
}
</script>

<style scoped>

</style>
