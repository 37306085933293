<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">【AUTOSHIP System Operation Guide】</h2>
        <p @click=" close_address_search " class="cursor-pointer p-2">X</p>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        As we operate AUTOSHIP in Malaysia 2023,<br>
        we are announcing the operation policy of the ‘HEART system’ as follows.<br><br>

        This ‘HEART System’ (AUTOSHIP Point System) is a policy to expand the convenience of purchasing and product selection for members who continue to use AUTOSHIP, and to continue to express their gratitude for continuous subscriptions, so please refer to it for your business.<br><br>

        - Information –<br><br>

        ■ Launch Date: 2023. 4. 5 (TBD)<br><br>

        ■ Heart System : Accrual System (When purchase the products through AUTOSHIP with 20BV Units)<br><br>

        ■ Application Target: Every GCOOPER (Including Consumer)<br><br>

        ■ Excluded Items<br><br>

        −	Electric Products<br>
        −	Promotion Products<br>
        −	Overseas Direct Products<br><br>

        ■ AUTOSHIP Application Criteria<br>
        −	Minimum 20BV above (Hearts are saved only for purchases over 20BV.)<br>
        * If you achieve the ‘Maintenance’ conditions through regular subscription, please make sure that your shopping cart contains products purchased over 20BV.<br>
        −	AUTOSHIP Only can apply with below ‘500BV in 1 Order’<br>
        If you don't have a product in your AUTOSHIP cart, automatic payment will fail.<br><br>

        ■ HEART (AUTHOSHIP Point) Save Criteria<br>
        −	>> Hearts are saved only for purchases over 20BV 1Heart = RM1<br>
        −	40% of AUTOSHIP Purchase will be saved with ‘HEART’ (NO LIMIT)<br>
        *Example<br>
        1) 50BV AUTOSHIP 50*40%=20 HEART<br>
        2) 20BV AUTOSHIP 20*40%=8HEART<br><br>

        ■ HEART Saved Date<br>
        −	Next day of AUTOSHIP Purchase (Based on Business-day)<br><br>

        ■ HEART Terms of Use<br><br>

        −	1 HEART = RM 1 / Can be use like a Cash<br>
        −	You can use the HEART when you reach to 80 HEAR><br>
        *Cannot use the HEART less than 80<br>
        −	Only can use with “Normal Purchase” (Not applicable for AUTOSHIP, Overseas Direct Purchase)<br>
        −	“Shipping Fee” also can be used by HEART<br>
        −	Available to use with ‘Combine Payment’ – HEART + CARD (But, cannot use with multiple card)<br>
        −	You must use all of the HEART balance you have when paying for an order<br>
        −	(Not available to use “Part of HEART + Card” )<br>
        −	If the price you want to pay is higher than the HEART you have, you can pay the difference by credit card.<br>
        −	Using HEARTs, the BV of the purchased product is recognized as a performance.<br>
        −	HEARTs are used sequentially from the accumulated amount with the old payment date.<br>
        −	HEART is not transferable<br><br>

        ■ HEART Extinction condition<br><br>

        -	If you fail to pay continuously for AUTOSHIP, the accumulated hearts will disappear.<br>
        Ex) If the payment fails on Wednesday, the first regular purchase date, and the payment is not made by next Tuesday, the handwritten payment date, all HEARTs accumulated at 00 a.m. on Saturday will disappear.<br><br>

        −	Accumulated HEARTs will be extinguished upon membership withdrawal.<br><br>

        −	Accumulated HEARTs will automatically disappear 24 months after the payment date.<br><br>

        Example 1) 2,000 hearts were set aside on April 13, 2022 (Wed) and 1,000 hearts were set aside on May 11, 2022 (Wed) If you have never used a heart, 2,000 hearts will disappear on April 13, 2024 (Sat) at 00:00 a.m.<br><br>

        Example 2) 2,000 hearts were accumulated on April 13, 2022 (Wed), 1,000 hearts on May 11, 2022 (Wed), and 2,000 hearts on June 8 (Wed). 1,000 hearts out of 5,000 hearts will be used on June 15 (Wed), and if hearts are not used after that, the remaining 1,000 hearts accumulated on April 13, 2022 (Wed) will disappear on June 15 (Sat), 2024.<br><br>

        ■ Exchange/Return<br><br>

        - You can cancel the order on the day of the order after purchasing with HEART. (If you cancel, automatically restore the HEART)<br><br>

        - You can return the product purchased with HEART<br>
        (However, 10% of the purchase amount and round-trip shipping fee will be deducted like a general return.) After processing the return, the HEART will be restored to the staff handwriting.)<br><br>

        1) When returning a product purchased using only HEARTs<br>
        [Fee + shipping fee] deposited into the account → Refund of return amount → Recovery of HEARTs for all use<br>
        2) When returning a product purchased using [HEARTs + Card],<br>
        [Fee + Shipping Cost] Card payment → Return amount → Full use HEART recovery<br><br>

        - Products purchased with accumulated HEARTs may also be collected when returning the product that purchased by AUTOSHIP.<br><br>

        - Partial returns of the products purchased with HEARTs are not possible.<br><br>

        - You cannot exchange the product purchased with Heart, and you must purchase it again after full return. (However, 10% of the purchase amount and round-trip shipping will be deducted.)<br><br>

        ■ SHIPPING FEE<br>
        −	Same condition with ‘Normal Purchase’<br><br>

        ■ You can check the accumulated HEARTs on the shopping mall -> My Page.
    </div>
</template>

<script>

export default {
    name: "AutoShipAgreeText",
    components : {
        // address_book,
    },
    props:{
        openlayer: Boolean
    },
    data: () => ({
    }),
    methods:{
        close_address_search(){
            // this.openlayer = false;
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
