<template>
  <div class="w-full p-1 justify-between ">
    <div class="text-right"><span @click=" close_tax_detail "  class="cursor-pointer p-2 font-semibold text-gray-800">X</span></div>
    <h2 class="w-full text-lg xl:text-xl lg:text-xl font-semibold text-gray-800 border-l-[10px] border-[#4493d5] pl-3">
      Tax Detail
    </h2>
  </div>
  <div class="w-full p-2 mb-24 md:mb-4">
    <div>
      <div class="w-full px-2 py-4 justify-between border-b">
      </div>
      <div class="w-full p-4 overflow-auto	">
        <div class="hidden lg:flex w-full" >
          <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-6/12 p-2 border">Product Details</h3>
          <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-1/12 p-2 border">Q'ty</h3>
          <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-2/12 p-2 border">Gross Sales</h3>
          <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-1/12 p-2 border">Tax Rate</h3>
          <h3 class="font-semibold text-center text-[#2c7fc3] text-sm uppercase w-2/12 p-2 border">Tax Amount</h3>
        </div>
        <div v-for="(product, i) in products" :key="product.seq_pd" class="sm:flex sm:items-center border-b border-slate-200 last:border-b-0 xl:py-5 lg:py-5 py-0 w-full">
          <div class="flex w-full xl:px-0 lg:px-0 px-2">
              <div class="flex w-6/12">
            <div class="w-16 h-16 lg:w-20 lg:h-20 aspect-w-1 aspect-h-1 border rounded-md">
              <img class="w-16 h-16 lg:w-20 lg:h-20 max-w-none	" v-bind:src="'https://cdnus.gcoop.me/public' + product.images[0].path + '/' + product.images[0].filename" v-bind:alt=" product.images[0].filename " alt="">
            </div>
            <div class="flex flex-col justify-between flex-grow relative px-2">
                <div class="dark:text-white xl:text-base lg:text-base text-sm font-semibold leading-6 text-gray-800 mb-2">{{ product.product.pdt_name }}</div>
            </div>
              </div>
            <div class="flex flex-col w-1/12 text-center flex-grow relative px-2">
              {{ product.qty }}
            </div>
            <div class="flex flex-col w-2/12 text-center flex-grow relative px-2">
              {{ product.qty * product.prices.amt }}
            </div>
            <div class="flex flex-col w-1/12 text-center flex-grow relative px-2">
              {{ tax_rate }}
            </div>
            <div class="flex flex-col w-2/12 text-center flex-grow relative px-2">
              {{ formatPrice(product.qty * product.prices.amt * tax_rate, 2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueSimpleAlert from "vue3-simple-alert";

export default {
  name: "taxDetail",
  components : {
    vSelect,
  },
  props:['tax_rate'],
  created() {
    this.getCart();
  },
  data: () => ({
    title: [],
    products: [],
    post_label : "",
    street_label : "",
    country : "",
  }),
  methods:{
    // 장바구니 가져오기
    getCart(){
      this.loading = true;
      axios.post(process.env.MIX_BACK_URL+'/api/cart',{
        bz_cd: window.Laravel.bz_cd,
      },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }})
        .then(response => {
          this.products = response.data;
          console.log(this.products);
        })
        .catch(e => {
        });
    },
    close_tax_detail(){
      this.$emit("close_tax_detail");
    },
// 숫자 포멧
    formatPrice(value,digit) {
      let val = val = (value/1).toFixed(digit).replace('.', '.')
      val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //val = (value/1).toFixed(digit).replace(/(.?0+$)/, '')// 소수점 끝자리 0 제거
      return val;
    },
  }
}
</script>

<style scoped>

</style>
