<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true"></loading>
    <div>
        <div class="bobo">
            <!-- 버튼 영역-->

            <div class="fixed bottom-0 left-0 bg-white w-full px-4 bg-white" v-show="step == 2">
                <div class="myo_condition_search3 my-4 mx-0">
                    <div style="display:block; font-weight:700;height:42px;">
                        <div class="" style="display: flex;align-items: center;">
                            <div style="width:50%;text-align:center;">返品数量：{{formatPrice(returnQty, 0) }}点</div>
                            <div style="width:50%;text-align:center;">
                                <ul>
                                    <li>合計金額：{{  formatPrice(returnAmt, 0)  }}円</li>
                                    <li v-if="isAutoShip===true">合計PV：{{ formatPrice(returnPv, 1) }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display:block">
                        <button type="button" class="go_list_btn" @click="previous">以前</button>
                        <button type="button" class="search_btn" v-on:click="nextStep()">つぎ</button>
                    </div>
                </div>
            </div>
            <div id="cancelOrderRefund" class="fixed bottom-0 left-0 bg-white w-full px-4 bg-white" v-show="step == 3">
                <div class="myo_condition_search3 my-4 mx-0">
                    <div style="display:block; font-weight:700;height:42px;">
                        <div class="" style="display: flex;align-items: center;">
                            <div style="width:50%;text-align:center;">交換数量：{{formatPrice(exchangeQty, 0) }}点</div>
                            <div style="width:50%;text-align:center;">
                                <ul>
                                    <li>合計金額：{{  formatPrice(exchangeAmt, 0)  }}円</li>
                                    <li v-if="isAutoShip===true">合計PV：{{ formatPrice(exchangePv, 1) }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display:block">
                        <button type="button" class="go_list_btn" @click="previous">以前</button>
                        <button type="submit" class="search_btn" v-on:click="nextStep()">つぎ</button>
                    </div>
                </div>
            </div>
            <div class="fixed bottom-0 left-0 bg-white w-full px-4 bg-white" v-show="step == 4">
                <div class="myo_condition_search2 my-4 mx-0 z-[13]">
                    <button type="button" class="go_list_btn" @click="previous">以前</button>
                    <button type="button" class="search_btn" v-on:click="submitForm()">{{
                            i18n.myoffice.refund.apply
                        }}
                    </button>
                </div>
            </div>
        </div>
        <div style="width:100%;">
            <ul id="first_step1" class="xl:max-h-[550px] lg:max-h-[550px] max-h-[400px] overflow-auto"
                v-show="step == 1">
                <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                    <span
                        class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.refund.profile }}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                        {{ mem.userid }} ( {{ mem.username }})</span>
                </li>
                <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                    <span
                        class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.refund.orderNumber }}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                      {{ ord_no }} <span class="text-rose-500">{{ i18n.myoffice.Order.PurchaseDate }} {{
                            row.ord_date
                        }}, {{ i18n.myoffice.Order.PastDueDate }} {{ row.diff_days }}</span>
                    </span><!-- 구매일 : 경과기일 :-->
                </li>
                <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                    <span
                        class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-normal">{{ i18n.myoffice.refund.applicationType }}</span>
                    <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                        <label class='radio_input_box' style="height: auto;">
                            <input type='radio' v-model="refund_type" v-on:click="selectRefund(1)"
                                   value='refund_return'>
                            <span class='checkmark'></span>{{ i18n.myoffice.refund.return }}
                        </label>
                        <label class='radio_input_box' style="height: auto;">
                            <input type='radio' v-model="refund_type" v-on:click="selectRefund(2)"
                                   value='refund_exchange'>
                            <span class='checkmark'></span>{{ i18n.myoffice.refund.exchange }}
                        </label>
                    </span>
                </li>
            </ul>


            <div id="first_step2" class="xl:max-h-[500px] lg:max-h-[500px] max-h-[400px] overflow-auto" v-show="step == 2">
                <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 text-base md:text-xl mb-2">{{
                        this.refund_type == "refund_exchange" ? '交換する製品の数量を入力してください。' : '返品する商品を選択してください。'
                    }}</h2>

                <table class="table-auto w-full h-[400px] overflow-auto border border-slate-200 text-[13px] ">
                    <thead>
                    <tr class='text-gray-700 bg-gray-50 border-b border-slate-200 font-semibold leading-normal sticky-top-0' >
                        <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">
                            {{ i18n.myoffice.refund.additionalProductName }}
                        </th>
                        <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">{{ i18n.myoffice.refund.order }}</th>
                        <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">数量</th> <!--Qty-->
                        <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">PV</th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr v-for="(value,index) in pdt_qty" class="border-b border-slate-200">
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_name }}</td>
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap text-center">{{
                                value.purchase_qty
                            }}
                        </td>
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap text-center" >
                            <input type="number" style="width: 50%;min-width:50px;max-width:100px;"
                                   @keypress="isNumber($event)" @change="maxQty(index,value.purchase_qty)"
                                   v-model="pdt_qty[index].qty">
                        </td>
                        <td class="py-px md:py-3 px-2 md:px-6 whitespace-nowrap">金額 : {{
                                formatPrice(value.sum_amt, 0)
                            }} {{ i18n.currency.jp }}<br>PV : {{ formatPrice(value.sum_pv, 1) }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="first_step3" class=""
                 v-show="step == 3">

                <h2 style="display:inline-block" class="border-l-4 border-solid border-gray-400 pl-1.5 mb-2 text-base md:text-xl">
                    交換したい製品を選択してください。
                </h2>
                <div style="display:inline-block;float:right;" class="mb-2">
                    <div class="" style="display: flex;align-items: center;font-weight: 700;color:#ff0000;font-style:italic;">
                        <div style="margin-right:10px;">
                            <ul>
                                <li>不足金額：{{ formatPrice(returnAmt - exchangeAmt) }} 円</li>
                                <li v-if="isAutoShip===true">不足PV：{{ formatPrice((100 - exchangePv), 2) }} PV</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <label class="relative block">
                    <span class="sr-only">{{ i18n.myoffice.Search }}</span>
                    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="h-6 w-65" viewBox="0 0 20 20"
                       stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                  </svg>

                </span>
                    <input text="text"
                           class='placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 mb-4 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm'
                           placeholder="検索" v-model="searchQuery" style="border: 1px solid;">
                </label>
                <div class="items_list xl:max-h-[450px] lg:max-h-[450px] max-h-[350px] overflow-auto">
                    <div class="overflow-y-auto overflow-x-auto max-h-52 h-auto">
                        <table class="table-auto w-full border border-slate-200 text-[13px]">
                            <thead>
                            <tr class='text-gray-700 bg-gray-50 border-b border-slate-200 font-semibold leading-normal'>
                                <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">SKU</th>
                                <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap">
                                    {{ i18n.myoffice.refund.additionalProductName }}
                                </th>
                                <th class="py-2 md:py-3 px-2 md:px-6 whitespace-nowrap min-w-28">PV</th>
                            </tr>

                            </thead>
                            <tbody>
                            <tr v-for="(value,index) in resultQuery" v-on:click="select_exchange_item(value.pdt_cd)"
                                class="border-b border-slate-200 even:bg-gray-50 cursor-pointer">
                                <td class="py-px md:py-2 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_cd }}</td>
                                <td class="py-px md:py-2 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_name }}</td>
                                <td class="py-px md:py-2 px-2 md:px-6 whitespace-nowrap min-w-28">金額 :
                                    {{ formatPrice(value.amt, 0) }} {{ i18n.currency.jp }}<br>PV :
                                    {{ formatPrice(value.pv, 1) }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 mb-2 text-base md:text-xl">
                        選択した商品</h2>
                    <!--                <div class="overflow-y-auto overflow-x-auto xl:max-h-72 lg:max-h-72 max-h-52 h-auto">-->
                    <div class="overflow-y-auto overflow-x-auto xl:max-h-[120px] lg:max-h-[120px] h-auto">
                        <table class="table-auto w-full border border-slate-200 text-[13px]">
                            <thead>
                            <tr class='text-gray-700 bg-gray-50 border-b border-slate-200 font-semibold leading-normal'>
                                <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">SKU</th>
                                <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">
                                    {{ i18n.myoffice.refund.additionalProductName }}
                                </th>
                                <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">数量</th>
                                <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap">PV</th>
                                <th class="py-2 md:py-2 px-2 md:px-6 whitespace-nowrap"></th>
                            </tr>

                            </thead>
                            <tbody>
                            <tr v-for="(value,index) in pdt_exchange_qty" class="border-b border-slate-200 even:bg-gray-50">
                                <td class="py-px md:py-1 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_cd }}</td>
                                <td class="py-px md:py-1 px-2 md:px-6 whitespace-nowrap">{{ value.pdt_name }}</td>
                                <td class="py-px md:py-1 px-2 md:px-6 whitespace-nowrap">
                                    <input type="number" style="width: 50%;min-width:50px;max-width:100px;"
                                           @keypress="isNumber($event)" @change="maxEQty(index,value.qty)"
                                           v-model="pdt_exchange_qty[index].qty">
                                </td>
                                <td class="py-px md:py-1 px-2 md:px-6 whitespace-nowrap">金額 : {{
                                        formatPrice(value.amt, 0)
                                    }} {{ i18n.currency.jp }}<br>PV : {{ formatPrice(value.pv, 1) }}
                                </td>
                                <td class="py-px md:py-1 px-2 md:px-6 whitespace-nowrap min-w-28"><input type="button"
                                                                                                         v-on:click="delete_exchange_qty(index)"
                                                                                                         value="削除"></td>
                            </tr>
                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
            <div id="first_step4" class="items_list xl:max-h-[550px] lg:max-h-[550px] max-h-[400px] overflow-auto"
                 v-show="step == 4">
                <div class="" id="">
                    <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7 mb-2 text-base md:text-xl">
                        {{ i18n.myoffice.refund.paymentInfo }}</h2> <!-- 決済情報 -->
                    <ul class="text-sm md:text-base">
                        <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">返品金額</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ formatPrice(paidRefund, 0) }} {{ i18n.currency.jp }} <!-- 원 -->
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">交換金額</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ formatPrice(paidExchange, 0) }} {{ i18n.currency.jp }} <!-- 원 -->
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">変更されるPV</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                 {{ formatPrice(paidbv, 2) }} PV <!-- 원 -->
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">入金/返金金額</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
                                {{ formatPrice(paid, 0) }} {{ i18n.currency.jp }}
                                <span style="font-size:12px;font-weight: 600">{{ i18n.myoffice.refund.listText10 }}</span>
                            </span><!-- 원 -->
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">{{
                                    i18n.myoffice.refund.shippingAddress
                                }}</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
<!--                                〒106-0044 東京都港区東麻布1-28-12 VORT麻布maxim 4F-->
                                <span v-if="(Laravel.today >= 20240628)">
                                    {{ i18n.shop.address.new_address }}
                                </span>
                                <span v-else>
                                    {{ i18n.shop.address.old_address }}
                                </span>
                            </span>
                        </li>
                    </ul>
                    <!--                    <p class="text-rose-500">-->
                    <!--                        It will be refunded in the method you paid when ordering<br>-->
                    <!--                        (Crdit/Debit Card - Transaction will be canceled / FPX/E-wallet - A refund will be made to the payment account)-->
                    <!--                    </p>-->
                </div>
                <div>
                    <h2 class="border-l-4 border-solid border-gray-400 pl-1.5 mt-7  mb-2 text-base md:text-xl">
                        返品情報</h2>
                    <ul class="text-sm md:text-base">
                        <li class="table w-full table-fixed border md:border-y md:border-x divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">{{
                                    i18n.myoffice.refund.shippingAddress
                                }}</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">
<!--                                〒106-0044 東京都港区東麻布1-28-12 VORT麻布maxim 4F-->
                              <span v-if="(Laravel.today >= 20240628)">
                                {{ i18n.shop.address.new_address }}
                              </span>
                              <span v-else>
                                {{ i18n.shop.address.old_address }}
                              </span>
                            </span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">{{
                                    i18n.myoffice.Order.Recipient
                                }}</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">GCOOP JAPAN</span>
                        </li>
                        <li class="table w-full table-fixed border-x border-b divide-slate-200 relative">
                            <span
                                class="block md:table-cell align-middle p-2.5 text-gray-700 w-full md:w-4/12 break-words md:border-r border-slate-200 bg-gray-50 font-semibold">{{
                                    i18n.myoffice.Order.Contact
                                }}</span>
                            <span class="block md:table-cell align-middle p-2.5 text-gray-700">03-6284-4545</span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import alert from "vue3-simple-alert";
import Loading from 'vue3-loading-overlay';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyViewOrderRefund",
    props: {
        isAutoShip : Boolean,
        ord_no: String,
        r_type: String,
        searchType: String,
        schUserId: String,
    },
    components: {
        Loading
    },
    data: () => ({
        i18n: window.i18n,
        Laravel: window.Laravel,
        gold_check : window.Laravel.gold_check,
        list: "",
        product_list: "",
        mem: "",
        row: "",
        refund_type: "",
        step: 1,
        pdt_exchange_qty: [],
        pdt_qty: [],
        searchQuery: "",
        r_address1: "",
        r_address2: "",
        r_name: "",
        memo: "",
        r_mobile: "",
        r_account: "",
        r_bankname: "",
        r_holder: "",
        ords: "",
        regs: "",
        ins: "",
        appends: "",
        paid: 0,
        paidbv: 0,
        paidRefund: 0,
        paidExchange: 0,
        loading: false,

        returnQty : 0,
        returnAmt : 0,
        returnPv : 0.0,

        exchangeQty : 0,
        exchangeAmt : 0,
        exchangePv : 0.0,

    }),
    created() {
        axios.post(window.Laravel.back_url + '/api/getOrdMst', {
            ord_no: this.ord_no,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
        });

        axios.post(window.Laravel.back_url + '/api/getOrdPdt', {
            ord_no: this.ord_no,
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.list = response.data.list;
            this.mem = response.data.mem;
            this.row = response.data.row;
            response.data.list.forEach((value, index) => {
                if (value.pdt_cd == "000240" || value.pdt_cd == "000243") {

                } else {
                    this.pdt_qty.push({
                        pdt_cd: value.pdt_cd,
                        pdt_name: value.pdt_name,
                        sum_amt: value.sum_amt,
                        sum_pv: value.sum_pv,
                        amt: value.amt,
                        pv: value.pv,
                        purchase_qty: value.qty,
                        qty: 0,
                    });
                }
            });
        });

        console.log(this.gold_check + " <= this.gold_check")
        axios.post(window.Laravel.back_url + '/api/getAllProduct', {
            ord_no: this.ord_no,
            gold_check : this.gold_check
        }, {headers: {Authorization: 'Bearer ' + window.Laravel.access_token}}).then(response => {
            this.product_list = response.data;
        });

    },
    computed: {
        resultQuery() {
            if (this.searchQuery) {
                const product_list = this.product_list.filter((item) => {
                    return this.searchQuery.toLowerCase().split(' ').every(v => item.pdt_name.replace(/ /gi, "").toLowerCase().includes(v))
                })
                return product_list;
            } else {
                return this.product_list;
            }
        }
    },
    methods: {
        selectRefund(id) {
            if (id == 1) {
                this.step = 2;
            }
            if (id == 2) {
                this.step = 2;
            }
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        },
        maxQty(index, max) {
            if (this.pdt_qty[index].qty > max) {
                this.pdt_qty[index].qty = max;
            }
            var qty = this.pdt_qty[index].qty;
            if (qty != 0) {
                this.pdt_qty[index].qty = qty.toString().replace(/(^0+)/, "");
            }
            if (!qty) {
                this.pdt_qty[index].qty = 0;
            }

            this.getPaidAmount();

        },
        maxEQty(index, max) {
            if (this.pdt_exchange_qty[index].qty > max) {
                this.pdt_exchange_qty[index].qty = max;
            }
            var qty = this.pdt_exchange_qty[index].qty;
            this.pdt_exchange_qty[index].qty = qty.toString().replace(/(^0+)/, "");

            //this.paid -= this.pdt_exchange_qty[index].amt * this.pdt_exchange_qty[index].qty;
            this.getPaidAmount();

        },
        getPaidAmount() {

            this.paid = 0.0;
            this.paidRefund = 0.0;
            this.paidbv = 0.0;
            this.paidExchange = 0.0;

            this.returnQty = 0;
            this.returnAmt = 0;
            this.returnPv = 0.0;

            this.exchangeQty = 0;
            this.exchangeAmt = 0;
            this.exchangePv = 0.0;

            this.pdt_qty.forEach((value, index) => {

                this.paid = parseFloat(this.paid) - parseFloat(this.pdt_qty[index].amt) * parseFloat(this.pdt_qty[index].qty);
                this.paidbv = parseFloat(this.paidbv) - parseFloat(this.pdt_qty[index].pv) * parseFloat(this.pdt_qty[index].qty);
                this.paidRefund = parseFloat(this.paidRefund) - (parseFloat(this.pdt_qty[index].amt) * parseFloat(this.pdt_qty[index].qty));


                // 반품 개수 / 반품 가격 / 반품 pv
                this.returnQty += Number(this.pdt_qty[index].qty)
                this.returnAmt += (parseFloat(this.pdt_qty[index].amt) * parseFloat(this.pdt_qty[index].qty))
                this.returnPv += parseFloat(this.pdt_qty[index].pv) * parseFloat(this.pdt_qty[index].qty);

                console.log(this.paidRefund)

            })

            this.pdt_exchange_qty.forEach((value, index) => {
                if (this.pdt_exchange_qty[index].qty == "" || !this.pdt_exchange_qty[index].qty) {
                    this.pdt_exchange_qty[index].qty = 0;
                }
                // this.paid = this.formatPrice(this.paid + parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty),2);
                // this.paidbv = this.formatPrice(this.paidbv + parseFloat(this.pdt_exchange_qty[index].pv) * parseFloat(this.pdt_exchange_qty[index].pv),2);
                // this.paidExchange = this.formatPrice(this.paidExchange + parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty),2)
                this.paid = parseFloat(this.paid) + parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty);
                this.paidbv = parseFloat(this.paidbv) + parseFloat(this.pdt_exchange_qty[index].pv) * parseFloat(this.pdt_exchange_qty[index].qty);
                this.paidExchange = parseFloat(this.paidExchange) + parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty);


                // 반품 개수 / 반품 가격 / 반품 pv
                this.exchangeQty += Number(this.pdt_exchange_qty[index].qty)
                this.exchangeAmt += (parseFloat(this.pdt_exchange_qty[index].amt) * parseFloat(this.pdt_exchange_qty[index].qty))
                this.exchangePv += parseFloat(this.pdt_exchange_qty[index].pv) * parseFloat(this.pdt_exchange_qty[index].qty);
            })

        },
        nextStep() {

            // console.group('=== nextStep ===')
            // console.log(this.refund_type + " <-- this.refund_type")
            // console.log(this.step + " <-- this.step")
            // console.log(this.isAutoShip + " <-- isAutoShip")
            // console.groupEnd('=== nextStep ===')

            if (this.refund_type == "refund_return") {
                if (this.step == "2") {
                    this.step = 4;
                }
            } else {
                if (this.step == "2") {
                    this.step = 3;
                } else if (this.step == "3") {

                    if ( this.isAutoShip === true ) {
                        // 교환 상품의 총 금액보다 선택했던 금액보다 클 수는 없다.
                        if (this.returnAmt - this.exchangeAmt > 0 || 100 - this.exchangePv > 0 ) {
                            VueSimpleAlert.alert(this.i18n.myoffice.refund.listText8, "", "", {confirmButtonText: "確認"});
                            return;
                        }
                    }else{
                        if ( this.returnAmt - this.exchangeAmt > 0 ){
                            VueSimpleAlert.alert(this.i18n.myoffice.refund.listText9, "", "", {confirmButtonText: "確認"});
                            return ;
                        }
                    }

                    this.step = 4;
                }
            }
        },
        previous() {
            if (this.refund_type == "refund_return") {
                if (this.step == "4") {
                    this.step = 2;
                }
            } else {
                if (this.step == "3") {
                    this.step = 2;
                } else if (this.step == "4") {
                    this.step = 3;
                }
            }

            if (this.step == "2") {
                this.step = 1;
            }
        },

        select_exchange_item(pdt_cd) {

            var end = "";
            this.pdt_exchange_qty.forEach((value, indexs) => {
                if (value.pdt_cd == pdt_cd) {
                    end = "1";
                }
            });

            if (end == "1") {
                return;
            }

            let result = this.product_list.find(element => element.pdt_cd === pdt_cd);
            this.pdt_exchange_qty.push({
                pdt_cd: result.pdt_cd,
                pdt_name: result.pdt_name,
                amt: result.amt,
                pv: result.pv,
                qty: 0,
            });
        },
        delete_exchange_qty(index) {
            this.pdt_exchange_qty.splice(index, 1);
        },
        submitForm: function () {

            // 금액도 기존 주문금액 이상 시만 신청할 수 있게 해주세요. => 반영 add by hskim :: 2024-06-12 오후 5:04
            if (this.paid < 0) {
                VueSimpleAlert.alert("", "", "", {confirmButtonText: "確認"});
                return;
            }

            var formData = new FormData();
            formData.append('refund_type', this.refund_type);
            for (var i = 0; i < this.pdt_exchange_qty.length; i++) {
                this.appends += this.pdt_exchange_qty[i].pdt_cd + "|" + this.pdt_exchange_qty[i].qty + "|" + this.pdt_exchange_qty[i].amt + "|" + this.pdt_exchange_qty[i].pv + "||" + this.pdt_exchange_qty[i].pdt_name + "|@|";
            }
            formData.append('append_qtys', this.appends);


            //formData.append('pdt_exchange_qty', JSON.stringify(this.pdt_exchange_qty));

            for (var i = 0; i < this.pdt_qty.length; i++) {
                this.ords += this.pdt_qty[i].pdt_cd + "|" + this.pdt_qty[i].qty + "|" + this.pdt_qty[i].amt + "|" + this.pdt_qty[i].pv + "||" + this.pdt_qty[i].pdt_name + "|@|";
                this.regs += this.pdt_qty[i].pdt_cd + "|" + this.pdt_qty[i].qty + "|@|";
            }
            formData.append('ord_qtys', this.ords);
            formData.append('reg_qtys', this.regs);
            formData.append('paid', this.paid);
            formData.append('refund_type', this.refund_type);
            formData.append('r_address1', this.r_address1);
            formData.append('r_address2', this.r_address2);
            formData.append('r_mobile', this.r_mobile);
            formData.append('r_name', this.r_name);
            formData.append('r_bankname', this.r_bankname);
            formData.append('r_account', this.r_account);
            formData.append('r_holder', this.r_holder);
            formData.append('ord_no', this.ord_no);
            formData.append('processing', 1);


            //formData.append('pdt_qty', JSON.stringify(this.pdt_qty));

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + window.Laravel.access_token
                }
            }
            axios.post(window.Laravel.back_url + '/api/orderRefund', formData, config).then(response => {

                if (response.data.ret === 0) {
                    alert.alert("確認", "", "", {allowOutsideClick: false}).then(r => {
                        this.$emit('close-modal');
                        window.location.reload();
                    });
                } else {
                    alert.alert(response.data.msg, "", "", {allowOutsideClick: false}).then(r => {
                        this.loading = false;
                    });
                }

            });

        },
        formatPrice(value, digit) {
            let val = (value / 1).toFixed(digit).replace('.', '.')
            // return parseFloat(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

    }
}
</script>

<style scoped>
    .sticky-top-0 {
        position: sticky;top: 0px;
    }
</style>
