<template>

</template>


<script>
export default {
  name: "DirectJoinModel"
}
</script>


<style scoped>

</style>
