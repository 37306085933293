<template>
    <div class="w-full p-4 flex justify-between border-b">
        <h2 class="w-full text-2xl">[ 定期購入利用規約 ]</h2>
        <p @click=" close_address_search " class="cursor-pointer p-2">X</p>
    </div>
    <div class="w-full p-2 mb-24 md:mb-4">
        第１条（規約の適用）<br>
        定期購入に関する株式会社GCOOP JAPAN（以下「弊社」といいます。）と会員（本規約において「会員」とは、定期購入の申請をした会員をいいます。）との契約には、会員が定期購入に申し込んだことをもって、本規約及び利用規約を理解した上でこれに同意したものとみなし、本規約及び本規約に規定のない事項については会員規約が適用されるものとします。<br>
        第2条（定期購入の概要）<br>
        定期購入は、一度お手続きいただくだけで、以後定期的（月1回）に、本規約第3条に定める定期購入の対象となる製品のうち、ご登録いただいた製品をお届けするサービスです。<br>
        (1)定期購入は、注文を自動的に作成するサービスであり、売買成立、返品・交換、その他購入に関する事項については、会員規約を適用します。<br>
        (2)1次決済日に何らかの原因で決済が不成立だった場合、2次決済日に再決済が行われます。<br>
        1次決済：1日→2次決済：6日<br>
        1次決済：11日→2次決済：16日<br>
        1次決済：21日→2次決済：26日<br>
        (3)定期購入においては、会員から解約の申出(解約申請)がない、または連続して決済が不成立にならない限り、自動的に売買が成立します。<br>
        (4)決済不成立、システム障害、その他理由の如何を問わず、注文が完了しなかった場合、該当回はスキップされ、製品をご購入いただくことができません。<br>
        (5)会員が申込中の定期購入は、製品提供体制の変更、システム更改、その他の弊社の都合により、終了することがあります。弊社は、この場合において、やむを得ない事由があるときは、事前に予告又は通知することなく直ちに当該定期購入を終了することができるものとします。なお、当該定期購入が終了した場合であっても、既に決済が完了している注文に関しては、原則、製品を発送いたします。但し、場合によっては返金対応または代替製品の発送となることがあります。<br>
        第3条（対象製品）<br>
        定期購入の対象となる製品は、製品詳細ページに「定期購入」のボタンが表示された製品のみになります。<br>
        第4条（お支払い方法）<br>
        お支払いは、クレジットカードまたはデビットカードのみご利用いただけます。利用可能なブランドはVISA、master card、JCB、American Express、Diners Clubに限ります。<br>
        (1)利用されるカードは会員本人名義のカードに限るものとします。なお、クレジットカードによるお支払いは、会員がクレジットカード会社との間で別途契約する条件に従うものとします。<br>
        ２．会員と当該クレジットカード会社との間で弊社の責によらない事由により紛争が発生した場合、当該当事者双方で解決するものとし、弊社に迷惑及び損害を与えないものとします。<br>
        ３．弊社と会員との間に成立した売買契約に基づく代金は、会員とクレジットカード会社との約定に基づき、会員は当該クレジットカード会社に支払い、弊社は決済代行会社から支払いを受けるものとします。<br>
        第5条（登録内容の変更）<br>
        定期購入の利用に当たりご登録いただいた次の各号の内容は、当サイトの定期購入管理ページ（以下「管理ページ」といいます。）または買い物かごからご変更いただけます。登録内容の変更は次回決済予定日の前日まで行うことができ、決済日当日は変更することはできません。<br>
        (1)お支払方法（注１）<br>
        (2)配送先のご住所（注2）<br>
        (3)製品の変更（注3）<br>
        (3)定期購入の解約<br>
        （注１）あらかじめ管理ページにご登録いただいたお支払方法への変更に限ります。<br>
        （注2）決済後の配送先変更はマイオフィスの注文管理→配送先変更より可能です。但し、決済日当日に限ります。<br>
        （注3）決済後に変更する場合は、一度マイオフィスの注文管理→注文取消しから、決済された注文をキャンセルした後に手動決済を行ってください。<br>
        第6条（定期購入の解約）<br>
        お申込みいただいた定期購入は管理ページからご解約いただけます。定期購入の解約は決済日前日まで行うことができ、決済後の解約は2パターンあります。<br>
        (1)次回以降の定期購入を解約したい場合<br>
        管理ページより次回決済予定日の前日までにお手続きください。<br>
        (2)決済された製品もキャンセルして、定期購入も解約したい場合<br>
        マイオフィスの注文管理→注文取消し、申請区分を『注文取消し/定期購入解約』を選択して申請してください。申請は必ず決済日当日中に行ってください。<br>
        第7条（免責事項）<br>
        (1)以下の理由で決済が不成立になったことにより発生する損害等は、弊社は一切の責任を負わないものとします。<br>
        ・カードの利用可能限度額が決済日時点で、請求金額に満たなかった場合<br>
        ・カードの有効期限が切れていた場合<br>
        ・延滞など会員の過失によりカード決済が不成立になった場合<br>
        (2) 会員は、定期購入において行う、クレジットカード番号を含む個人情報のインターネットを介した送信行為等が、情報の漏洩等の危険性を有していることを認識し、自己の責任の下にこれを行うものとし、弊社は一切の責任を負わないものとします。<br>
        (3) 弊社は、会員が弊社に届け出た連絡先へ連絡、及び製品購入の際指示された配送先へ製品の配送等を行うことにより、売主としての製品の引渡責任から免責されるものとします。<br>
        (4)弊社が指定された通りに製品を配送したにもかかわらず、会員の不在等により製品の引渡しができなかった場合において、配送業者の保管期限を経過した製品の再発送は、購入金額に関わらず、着払いにて配送いたします。また、転居等の理由で配送先を変更する場合は、配送業者規定の転送料を会員負担で行うものとします。<br>
        (5)弊社は、通信回線又はコンピューター等の障害による定期購入の中断、遅延、中止、データの消失、データへの不正アクセスにより会員に生じた損害について、弊社の責任による場合を除き、一切責任を負わないものとします。<br>
        (6)弊社は、法律上の請求原因の如何を問わず、いかなる場合においても定期購入の利用及び定期購入において販売される製品に関する損害、損失及び不利益、並びに定期購入の提供が遅延し又は中断したことに起因して会員又は第三者が被った損害に関して、前各項及び本サイトの運営に関するその他の規定や規約に定める以外の責任を負わないものとします。<br>
        (7)会員本人の過失によるカードの盗用、紛失カードの使用、他人名義のカード決済などによって発生するすべての民事・刑事上の法的責任は会員本人にあり、弊社はこれに対して一切責任を負わないものとします。<br>
        第8条（本規約の変更）<br>
        弊社は、本規約を変更することがあります。この場合、弊社は、弊社ウェブサイトにて変更適用日及び変更後の内容を公表するものとし、会員が変更適用日後に定期購入の利用を継続した場合には、本規約の変更に合意したものとみなし、定期購入に関する弊社と会員との契約には、変更後の本規約が適用されるものとします。<br>
        付則　本規約は2023年10月1日から適用されます。<br>
        <br>
    </div>
</template>

<script>

export default {
    name: "AutoShipAgreeText",
    components : {
        // address_book,
    },
    props:{
        openlayer: Boolean
    },
    data: () => ({
    }),
    methods:{
        close_address_search(){
            // this.openlayer = false;
            this.$emit("close_address_search");
        },

    }
}
</script>

<style scoped>

</style>
