<template>
  <direct_auth v-if="isDirect===true" join_check="nonMember"></direct_auth>
  <div>
    <div class="cbs-product-visual">
      <img src="/images/dir_img2.png?ver=1.0" alt="">
    </div>
    <div class="inner cbs-box">
      <div class="cbs-page-tit">
        <h2>韓国メーカー直売</h2>
      </div>

      <div class="cbs-cont-tit">
        <p>アカウント作成について</p>
      </div>

      <div id="cbs-contentTxt">
        <b>第１条　（定義）</b><br>
        本規約において、以下の用語は以下に定める意味を有するものとします。<br>
        １．「会員」とは、本規約に同意の上、当社所定の方法により会員登録をした人を指します。<br>
        ２．「当社」とは、 General Bio Co., Ltd.のことを指します。<br>
        ３．「本サービス」とは、 General Bio Co., Ltd.が提供する韓国メーカー直売の全てのサービスのことを指します。<br>
        <br>
        <b>第２条　（サービスの登録）</b><br>
        １．本規約に同意の上、サービス登録を行ってください。<br>
        ２．サービスは会員となるご本人が行ってください。<br>
        ３．同じメールアドレスによる複数の会員登録はできません。<br>
        ４．会員は、本サービスに登録したパスワードを第三者に開示、貸与、共有しないとともに、<br>
        第三者に知られないよう厳重に管理してください。第三者によって本サービスが利用されたことにより生じた損害については、当社は一切責任を負わないものとします。<br>
        <br>
        <b>第３条　（会員情報の変更）</b><br>
        会員の住所や電話番号など、会員登録の内容に変更があった場合は、 General Bio Co., Ltd.
        (master@generalbio.co.kr)までご連絡ください。<br>
        <br>
        <b>第４条　（会員の退会）</b><br>
        退会を希望する場合は、 General Bio Co., Ltd.
        (master@generalbio.co.kr)までご連絡ください。会員が退会した場合は、登録情報を当社のデータベースから削除することができるものとします。<br>
        <br>
        <b>第５条　（本サービスの変更・廃止）</b><br>
        当社の判断により、本サービスを変更・廃止をすることができるものとしますが、主な変更事項がある際は1ヵ月前にウェブページのお知らせより事前告知いたします。変更に同意できない会員は当社所定の手続きをとることにより、当サービスから退会することが可能です。また、当サービスの変更・廃止により会員に生じた損害について当社は一切責任を負わないものとします。
      </div><!--//contentTxt-->

      <div class="agree_check">
        <input type="checkbox" id="checkCondition1"/>
        <label for="checkCondition1"> 利用規約に同意します。</label><!-- 이용약관에 동의합니다. -->
      </div><!--//agree_check-->

      <div class="cbs-btn-box">
        <ul>
          <li><span @click="form_href('/dirJoin')">アカウント作成へ続行</span></li><!--정기구매 등록 -->
        </ul>
      </div><!--//btn-box-->
    </div>
  </div>
</template>


<script>
import {getApiCall} from "../../../assets/api/rest";

export default {
  name: "DirectAgree",
  data: () => ({
    i18n: window.i18n,
    laravel: window.Laravel,
  }),
  props: ['is_direct'],
  computed: {
    isDirect() {
      return this.is_direct;
    }
  },
  methods: {
    form_href(url) {
      if (!$('#checkCondition1').is(':checked')) {
        alert(i18n.myoffice.myofficeAlert.SiteTermsAlert);
      } else {
        location.href = url;
      }
    },
    isDirectMember() {
      getApiCall('isDirectMember').then(response => {
        if (response.data.resultCode === 0) {
          this.is_member = response.data.isDirectMember;
          this.loading = false;
        }
      })
    }
  }
}
</script>


<style scoped>
/*reset*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  width: 100%;
  margin: 0 auto;
}

body {
  font-family: "Noto Sans JP";
  font-size: 0.88em;
  color: #666;
  font-weight: 300;
  line-height: 1.56;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  word-break: keep-all;
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  font-size: 1em;
  font-weight: normal;
}

/* font-size:100%;  기본 폰트사이즈 가지지 않도록 선언 필요*/
ul, ol, li {
  list-style: none
}

a, a:hover, a:active, a:visited {
  text-decoration: none;
  color: #000;
}

img {
  border: 0;
  vertical-align: top;
  width: 100%;
  min-height:190px;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  color: #666;
  vertical-align: middle;
  margin-top: -2px;
}

textarea {
  resize: none;
  width: 100%;
  height: 248px;
  padding: 5px;
  vertical-align: top;
  font-size: 0.88em;
  font-family: inherit;
  color: #666
}

em, address {
  font-style: normal
}

table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  color: transparent
}

strong {
  font-weight: 500;
  color: #222
}

label {
  vertical-align: middle;
  cursor: pointer
}

.fa, .fas, .fal {
  vertical-align: middle;
}

input {
  font-size: 0.88em;
  vertical-align: middle;
}

img {
  vertical-align: middle;
}

/*페이지 css*/
/*작업 시 페이지에 적용 시키고 작업 후 추후 css 파일로 옮기겠음*/
.inner {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.cbs-box {
  word-break: break-all;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px
}

.cbs-page-tit {
  margin: 20px 0 30px 0;
}

.cbs-page-tit > h2 {
  font-size: 1.13em;
}

.cbs-cont-tit {
  text-align: center;
  font-size: 1.13em;
  font-weight: bold;
  margin: 15px 0;
}

.cbs-notice {
  font-size: 1.13em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 20px;
}

.cbs-notice p {
  text-indent: 0px;
}

#cbs-contentTxt {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #d4d4d4;
  background: #ffffff;
  height: 200px;
  overflow: auto;
  color: #000;
  padding: 10px 15px;
  font-size:0.88em;
}

.agree_check {
  width: 100%;
  max-width: 1200px;
  margin: 15px auto 0 auto;
}

.cbs-btn-box {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 80px;
}

.cbs-btn-box ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 5px;
}

.cbs-btn-box ul li {
  display: table-cell;
}

.cbs-btn-box ul li span {
  display: block;
  height: 62px;
  line-height: 62px;
  font-size: 1.13em;
  color: #222;
  border: 1px solid #0abab5;
  cursor: pointer;
  text-align: center;
}

.cbs-btn-box ul li span:last-child {
  color: #fff;
  border: 1px solid #0abab5;
  background: #0abab5;
}
</style>
