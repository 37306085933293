<template>
    <dl class="myo_search_list">
        <dt>{{ i18n.myoffice.MemberId }}</dt>
        <dd><input type="text" name="down_name" id="down_name" v-on="down_name"  :placeholder="i18n.myoffice.MemberId"></dd>
    </dl>

</template>

<script>
export default {
    name: "nameForm",
    data: () => ({
        i18n: window.i18n,
    }),
    props : {
        down_name : String
    }
}
</script>

<style scoped>

</style>
