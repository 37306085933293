<template>
    <loading :active="loading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="true">
    </loading>

  <article class="myo_search_area">
      <section class="myo_condition_box">
          <section class="flex_box">
              <legForm></legForm>
              <nameForm></nameForm>
              <stepForm></stepForm>
          </section>
      </section><!-- // myo_condition_box -->
      <div class="myo_condition_search">
          <button type="reset" class="page_reset_btn" onclick="location.href='/kr/MyMemberLegBox';">{{ i18n.myoffice.Refresh }}</button>
          <button type="submit" v-on:click="searchLeg" class="search_btn">{{ i18n.myoffice.Search }}</button>
      </div>
    </article>

    <div id="search_member-wrap" v-show="search_member_div">
        <i class="fa fa-times-circle btnCloseLayer" aria-hidden="true"></i>
        <div id="member-list">
            <table class="list_members relative overflow-x-auto shadow-md sm:rounded-lg">
                <thead>
                <tr class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <th>{{ i18n.myoffice.jsALert.tablecon1 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon2 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon3 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon4 }}</th>
                    <th>{{ i18n.myoffice.jsALert.tablecon5 }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="Value in legMember" class='list_of_members sel_user' data-id='{{ Value.user_id }}'>
                    <td>{{ Value.level }}</td>
                    <td>{{ Value.user_id }}({{ Value.user_name }})</td>
                    <td>{{ Value.rank }}</td>
                    <td>{{ Value.p_info }}</td>
                    <td>{{ Value.r_info }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div style="text-align: right;">
        <button class="reg_notice_btn" href="#reg_notice_box">계보도 이용방법</button>
    </div>
    <!-- 계보도 노티스 팝업 -->
    <div id="reg_notice_box" class="reg_notice">
        <div id="reg_notice_dim"></div>
        <div class="reg_notice_cont">
            <div class="reg_notice_head">
                <span class="reg_close_btn">×</span>
                <h2>박스 계보도 이용방법</h2>
            </div>
            <div class="reg_notice_body">
                <div class="red_cont_box">
                    <p>· 계보도는 기본적으로 본인을 포함하여 2단계 까지 조회가 가능합니다.<br></p>
                    <p>· 단계 설정을 변경하신 뒤 검색을 누르면 추가 조회가 가능합니다.<br></p>
                    <p>· 0을 선택하신 후 검색하시면 전체 계보 조회가 가능합니다.<br></p>
                    <p>· 계보도의 확대 축소는 마우스 휠버튼으로 가능합니다.<br></p>
                    <p>· 계보도는 최대 2000명까지 조회가 가능합니다. &nbsp;추가 확인은 기준 회원을 설정하신 후 &nbsp;조회하실 수 있습니다.</p><br>
                </div>
            </div>
        </div>
    </div><!-- // reg_notice -->

    <div id="tree_wrap">
        <div style="position:absolute;  z-index: 999999 ">
            <LegDetail @goTree="goTree" ref="showDetailRequest"></LegDetail>
        </div>
        <template>
            <tree :config="config" :nodes="items"> </tree>
        </template>
    </div>
    {{ selectedItems }}
</template>

<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import nameForm from '../../searchForm/nameForm.vue';
import stepForm from '../../searchForm/stepForm.vue';
import legForm from '../../searchForm/legForm.vue';
import OrgChart from '@balkangraph/orgchart.js';
import LegDetail from './LegDetail.vue';


export default {
    name: "MyMemberLegBox",
    components : {
        nameForm,
        stepForm,
        Loading,
        legForm,
        LegDetail
    },
    data: () => ({
        i18n: window.i18n,
        nodes : [],
        legMember : [],
        search_member_div : false,
        loading : false,
        search_tag : "",
        selectedItems : "",
        items : "",
        config: {roots : ["GB115713"]}
    }),
    props:['level','down_name','tree_node'],
    methods: {
        searchLeg(){
            this.loading = true;
            this.search_member_div = false;
            axios.post(window.Laravel.back_url+'/api/leg/search',{
                level : level.value,
                node : tree_node.value,
                keyword : down_name.value,
            }).then(response => {
                if(response.data.count > 1){
                    this.search_member_div = true;
                    this.legMember = response.data.data;
                    this.loading = false;

                }else{
                    this.submitForm();
                }
            });
        },
        goTree : function (userid){
            down_name.value = userid;
            this.submitForm();
        },
        submitForm : function (){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/line',{
                level : level.value,
                tree_node : tree_node.value,
                uid : down_name.value,
            }).then(response => {
                this.loading = false;
                this.items = response.data;
            });

        },
    },

    mounted(){
        this.submitForm();
    }
}

</script>

<style scoped>

</style>
