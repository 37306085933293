<template>
    <div class="member-search">
        <loading :active="loading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
        <form name='form_search' id="form_search" v-on:submit.prevent="submitForm('1')">
            <input type="hidden" name="order" id="order">
            <input type="hidden" name="sort" id="sort">
            <section class="myo_condition_box">
                <div class="relative z-50">
                    <dateForm @set_s_date="set_s_date" @set_e_date="set_e_date" ></dateForm>
                </div>

                <section class="flex_box_quad">
                    <memberForm :selectid="selectid"></memberForm>
                    <stepForm></stepForm>
                </section>

                <div class="myo_accordion">
                    <div class="accordion_item">
                        <a class="accordion_tit" href="#n">{{ i18n.myoffice.myoSearchForm.searchSetting }} <span class="accordion_sub_tit">{{ i18n.myoffice.moreview }}</span>
                            <div class="bmenu x7"><span class="btop"><i class="fas fa-chevron-down"></i></span></div></a>
                        <div class="accordion_cont">
                            <section class="flex_box">
                                <nameForm></nameForm>
                                <countryForm></countryForm>
                                <endDateForm @set_end_type="set_end_type"></endDateForm>
                            </section>

                            <div class="relative " style="z-index: 5">
                                <rankForm  @setSelected="setSelected"></rankForm>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <div class="myo_condition_search">
                <button type="button" v-on:click="reset" class="reset_btn">{{ i18n.myoffice.Refresh }}</button>
                <button type="submit" id="submit" v-on:click="page_reset" class="search_btn">{{ i18n.myoffice.Search }}</button>
            </div>
        </form>
    </div>

    <p class="p_alert">{{ i18n.myoffice.myofficeAlert.ClickStarButtonNotice }}</p><!-- 아래 리스트 중 회원번호 옆 별을 클릭(터치)하여 회원을 즐겨찾기에 등록 및 삭제가 가능합니다. -->

    <div class="summary position_relate_box mt-6" style="clear: both;"><strong>Total</strong> <em>{{ total_count }}</em></div>

        <input type=hidden name=exec_mode value=''>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table id="member_list" class="relative overflow-x-auto shadow-md sm:rounded-lg w-full" >
            <thead>
            <tr class="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                <th class="px-6 py-3 whitespace-nowrap">No</th>
                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('level'), asc_on : asconCheck('level'), desc_on : descCheck('level') }" @click="reSortArray('level')">{{ i18n.myoffice.step }}</div></th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.MemberRank.Favorite }}</th><!-- 즐겨찾기 -->
                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('userid'), asc_on : asconCheck('userid'), desc_on : descCheck('userid') }" @click="reSortArray('userid')">{{ i18n.myoffice.MemberId }}</div></th>
                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('username'), asc_on : asconCheck('username'), desc_on : descCheck('username') }" @click="reSortArray('username')">{{ i18n.myoffice.memberName }}</div></th>
                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('regdate'), asc_on : asconCheck('regdate'), desc_on : descCheck('regdate') }" @click="reSortArray('regdate')">{{ i18n.myoffice.RegistrationDate }}</div></th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.MemberStatus }}</th>
                <th class="px-6 py-3 whitespace-nowrap"><div  v-bind:class="{ order : true, not : notCheck('daydiff'), asc_on : asconCheck('daydiff'), desc_on : descCheck('daydiff') }" @click="reSortArray('daydiff')">最終購入履歴</div></th><!-- 무실적경과일 -->
                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('rank'), asc_on : asconCheck('rank'), desc_on : descCheck('rank') }"  @click="reSortArray('rank')">現職級</div></th>
                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('rank'), asc_on : asconCheck('rank'), desc_on : descCheck('rank') }"  @click="reSortArray('rank')">達成職級</div></th>
                <th class="px-6 py-3 whitespace-nowrap">{{ i18n.myoffice.EnrollmentSponsor }}</th>
<!--                <th class="px-6 py-3 whitespace-nowrap"><div v-bind:class="{ order : true, not : notCheck('ujieweek'), asc_on : asconCheck('ujieweek'), desc_on : descCheck('ujieweek') }" @click="reSortArray('ujieweek')">Maintenance Status</div></th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(Value,index) in memberList" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ total_count - ((page-1)*20 + index) }}</td>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.lev -1 }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">
                    <span class='m_favorites' title='Member Favorites' v-show="Value.fav_cnt != 0" v-on:click="favorChk(index,Value.fav_cnt,Value.userid)" ><i class="fas fa-star fav_on" aria-hidden="true"></i></span>
                    <span class='m_favorites' title='Member Favorites' v-show="Value.fav_cnt == 0" v-on:click="favorChk(index,Value.fav_cnt,Value.userid)"><i class="far fa-star fav_off" aria-hidden="true"></i></span>
                </th><!-- 즐겨찾기 -->
                <th class="px-6 py-3 w-3 whitespace-nowrap"><div class="user_menu" :data-enc_id="Value.enc_id" :data-userid="Value.userid" >{{ Value.userid }}</div></th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.username }}</th>
                <th class="px-6 py-3 w-3 whitespace-nowrap">{{ Value.reg_date }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ Value.status == '1' ? i18n.myoffice.normality : i18n.myoffice.withdrawal }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ Value.ord_date }}</th><!-- 무실적경과일 -->

                <th class="px-6 py-3 whitespace-nowrap">{{ Value.rank_name }}</th>
                <th class="px-6 py-3 whitespace-nowrap">{{ Value.rank_name }}</th>



                <th class="px-6 py-3 whitespace-nowrap">{{ Value.r_names }}</th>
<!--                <th class="px-6 py-3 whitespace-nowrap">{{ Value.act_yn }}</th>&lt;!&ndash;20230224 DYLEE 마감이후유지여부추후변경&ndash;&gt;-->
            </tr>
            <tr class="h-2" v-if="total_count == 0">
              <td class="text-left md:text-center pl-5 py-5" colspan="12">{{ this.list_msg }}</td>
            </tr>
            </tbody>
        </table>
        </div>
  <div style="  display: flex;  align-items: center;   justify-content: center;" class="mt-4">
    <v-pagination
      v-model="page"
      :pages=total_page
      :range-size="1"
      active-color="#DCEDFF">
    </v-pagination>
  </div>
</template>
<script>
import dateForm from '../../searchForm/dateForm.vue'
import memberForm from '../../searchForm/memberForm.vue'
import stepForm from '../../searchForm/stepForm.vue'
import divisionForm from '../../searchForm/divisionForm.vue'
import nameForm from '../../searchForm/nameForm.vue'
import gpStatusForm from '../../searchForm/gpStatusForm.vue'
import rankForm from '../../searchForm/rankForm.vue'
import consumerChangeForm from '../../searchForm/consumerChangeForm.vue'
import endDateForm from '../../searchForm/endDateForm.vue'
import VPagination from "@hennge/vue3-pagination";
import countryForm from '../../searchForm/countryForm.vue'
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import VueSimpleAlert from "vue3-simple-alert";

export default {
    name: "MyMember",
    components : {
        dateForm,
        memberForm,
        stepForm,
        divisionForm,
        nameForm,
        gpStatusForm,
        rankForm,
        consumerChangeForm,
        endDateForm,
        VPagination,
        Loading,
        VueSimpleAlert,
        countryForm
    },
    data: () => ({
        i18n: window.i18n,
        rank_cd : "",
        memberList : [],
        total_count : 0,
        page : 1,
        total_page : 1,
        loading : false,
        fullPage : true,
        size: '45px',
        order : 'level',
        sort: 'desc',
        s_date: '',
        e_date: '',
        end_date : '',
        list_msg : "検索ボタンを押して照会可能です。",
    }),
    computed : {

    },
    props:['level','search_id','sid_name','diff','down_name','reg_type','gpstatus','selectid','end_type'],
    watch : {
        page: function(){
            this.submitForm();
        }
    },
    mounted : function (){
        this.submitForm();
    },
    methods : {
        submitForm : function (page = ''){
            if(page) { // 2페이지 이상에서 검색 버튼 클릭 시 페이지 변수 초기화
              this.page = page;
            }
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/getMemberList',{
                s_date : this.s_date,
                e_date : this.e_date,
                level : level.value,
                sid : search_id.value,
                rank_cd : this.rank_cd,
                down_name : down_name.value,
                end_date : this.end_date,
                reg_type : "R",
                page : this.page,
                order : this.order,
                sort: this.sort,
                end_type : end_type.value,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.total_count == 0){
                    VueSimpleAlert.alert("検索結果がありません","","",{confirmButtonColor: '#0abab5',confirmButtonText:"確認"});
                    this.list_msg = "データがありません。";
                }
                this.memberList = response.data.list.data;
                this.total_count = response.data.total_count;
                this.total_page = parseInt(this.total_count/20)+parseInt(this.total_count%20 > 0 ? 1 : 0);
                this.loading = false;
            }).catch(error => console.error(error));

        },
        setSelected(rank){
            this.rank_cd = "";
            rank.forEach((value, index) => {
                this.rank_cd += value.id
                if(rank.length != index+1){
                    this.rank_cd += ",";
                }
            });
        },
        updatePageHandler(page){
            //this.page = page;
        },
        notCheck(id){
            return this.order != id ? true : false;
        },
        asconCheck(id){
            return this.order == id && this.sort == "asc" ? true : false;
        },
        descCheck(id){
            return this.order == id && this.sort == "desc" ? true : false;
        },
        reSortArray(id){
            this.order = id;
            this.sort = this.sort == "asc" ? "desc" : "asc";
            $("#submit").click();

        },
        set_s_date(s_date){
            this.s_date = s_date;
        },
        set_e_date(e_date){
            this.e_date = e_date;
        },
        set_end_date(end_date){
            this.end_date = end_date;
        },
        set_end_type(end_type){
          this.end_type  = end_type;
        },
        favorChk(index,fav_cnt,userid){
            this.loading = true;
            axios.post(window.Laravel.back_url+'/api/setMemberFavorites',{
                fid : userid,
            },{headers: { Authorization: 'Bearer ' + window.Laravel.access_token }}).then(response => {
                if(response.data.ret == 1){
                    if(fav_cnt == 0){
                        this.memberList[index].fav_cnt = 1;
                    }
                    if(fav_cnt == 1){
                        this.memberList[index].fav_cnt = 0;
                    }

                }
                VueSimpleAlert.alert(response.data.msg,"","",{allowOutsideClick : false,confirmButtonText:"確認"});
                this.loading = false;
            });
        },
        reset(){
            location.replace("/MyMember")
        },

        //회원가입 URL 복사
        copy_register_url(xid){
            this.detailshow = false;
            var link = window.Laravel.back_url+'/api/RegToken?xid='+encodeURIComponent(xid);

            var md = $.Zebra_Dialog('<textarea id="reg_url">'+link+'</textarea>', {
                width: 1000,
                title:  i18n.myoffice.myofficeAlert.RegisterURL,
                type :  false,
                modal: true,
                overlay_close: false,
                buttons:
                    [
                        {caption: 'OK', callback: function() {
                                $('#reg_url').select();
                                document.execCommand('copy');
                            }}
                    ]
            });
        },
      page_reset(){
          this.page = 1;
      }

    }
}

$(document).ready( function () {
});
</script>
